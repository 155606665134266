/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { LibToolCommonModule } from '../tool-common/lib-tools-common.module';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';
import { CropEditorComponent } from './components/crop-editor/crop-editor.component';
import { CropToolbarComponent } from './components/crop-toolbar/crop-toolbar.component';

const entryComponents = [CropEditorComponent, CropToolbarComponent];

@NgModule({
  declarations: [...entryComponents],
  imports: [
    CommonModule,
    MatIconModule,
    LibToolCommonModule,
    MatButtonModule,
    LibToolSidebarModule,
  ],
  exports: [CropEditorComponent],
  entryComponents,
})
export class LibCropEditorModule {}
