/*!
 * Copyright 2018 Screencastify LLC
 */
import { ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { Rectangle } from '@castify/edit-models';
import { Size, unitLimit } from '@castify/models';
import { ShadowBoxComponent } from 'lib-editor/lib/tool-common/components/shadow-box/shadow-box.component';
import { applyPosition, resizeUnitRect, } from 'lib-editor/lib/tool-common/helpers';
import { Subscription } from 'rxjs';
import { CropEditorControllerService } from '../../crop-editor-controller.service';
var kCropRectMinDims = new Size(0.1, 0.1);
var keepOpenSelectors = [
    'lib-crop-editor',
    'lib-crop-toolbar',
    '#remove-button',
];
var CropEditorComponent = /** @class */ (function () {
    function CropEditorComponent(ctrl, hostElm) {
        this.ctrl = ctrl;
        this.hostElm = hostElm;
        this.subscriptions = new Subscription();
    }
    CropEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.ctrl._cropRect.subscribe(function (crop) { return _this.updateResizeBox(crop); }));
        this.handleWindowResize();
    };
    CropEditorComponent.prototype.ngOnDestroy = function () {
        // remove all subscriptions when destroy
        this.subscriptions.unsubscribe();
    };
    CropEditorComponent.prototype.onEditStart = function () {
        this._editStartRect = Rectangle.fromObj(this.ctrl._cropRect.value);
    };
    CropEditorComponent.prototype.onMove = function (event) {
        if (!this._editStartRect)
            return;
        var hostRect = this.bounds.nativeElement.getBoundingClientRect();
        var shiftX = event.shiftX / hostRect.width;
        var shiftY = event.shiftY / hostRect.height;
        this.ctrl._setCropRect(new Rectangle(unitLimit.apply(this._editStartRect.left + shiftX), unitLimit.apply(this._editStartRect.top + shiftY), this._editStartRect.width, this._editStartRect.height));
    };
    CropEditorComponent.prototype.onResize = function (event, topFixed, leftFixed) {
        if (!this._editStartRect)
            return;
        var hostRect = this.bounds.nativeElement.getBoundingClientRect();
        var resized = resizeUnitRect(Rectangle.fromObj(this._editStartRect), event.shiftX / hostRect.width, event.shiftY / hostRect.height, topFixed, leftFixed, kCropRectMinDims);
        this.ctrl._setCropRect(resized);
    };
    CropEditorComponent.prototype.updateResizeBox = function (cropRect) {
        if (!this.shadowBox || !this.resizeBox || !this.ctrl.clip.value)
            return;
        this.shadowBox.setCutout(cropRect);
        applyPosition(this.resizeBox, cropRect);
    };
    CropEditorComponent.prototype.closeEditor = function (event) {
        var toolClicked = event.composedPath().some(function (v) {
            return v &&
                // check if tag name indicates it is part of the tool
                ((v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
                    // check if element id indicates it is part of the tool
                    (v.id && keepOpenSelectors.includes("#" + v.id)));
        });
        if (!toolClicked) {
            this.ctrl.save();
            this.ctrl.close();
        }
    };
    CropEditorComponent.prototype.handleWindowResize = function () {
        if (!this.bounds || !this.ctrl.clip.value)
            return;
        var hostDims = Size.fromObj(this.hostElm.nativeElement.getBoundingClientRect());
        var videoDims = this.ctrl.clip.value.srcDimensions.scaleToFit(hostDims);
        applyPosition(this.bounds, {
            width: videoDims.width / hostDims.width,
            height: videoDims.height / hostDims.height,
        });
        this.updateResizeBox(this.ctrl._cropRect.value);
    };
    return CropEditorComponent;
}());
export { CropEditorComponent };
