/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-confirm-new-project',
  templateUrl: './confirm-new-project.component.html',
  styleUrls: ['./confirm-new-project.component.scss'],
})
export class ConfirmNewProjectComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
