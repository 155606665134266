/*!
 * Copyright 2020 Screencastify LLC
 */

import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { makeQuillInstance } from '../../quill-config';
import { QuillContainerViewComponent } from './quill-container-view.component';

@Component({
  selector: 'lib-quill-edit',
  templateUrl: './quill-container.component.html',
  styleUrls: ['./quill-container.component.scss'],
})
export class QuillContainerEditComponent
  extends QuillContainerViewComponent
  implements AfterViewInit {
  cursorStyle = 'text';
  overflow = 'visible';

  constructor(
    hostElm: ElementRef<HTMLDivElement>,
    public ctrl: AnnotationsToolControllerService
  ) {
    super(hostElm, ctrl);
  }

  ngAfterViewInit(): void {
    // Initialize Quill instance
    this.quill = makeQuillInstance(
      this.hostElm.nativeElement.querySelector('[quill-editor-element]'),
      false,
      this.onEscapeKeyPress.bind(this)
    );
    this.quill.setContents(this.textBox.delta, 'silent');

    // pass quill instance into the controller, as this is the active instance
    this.ctrl.activeQuill.next(this.quill);

    // Set up subscriptions to text box updates
    // Note that this overrides the way this is called in parent
    this.subscriptions.add(
      this.ctrl.activeBox.subscribe((box) => {
        if (box) {
          this.setVerticalAlignment(box.verticalAlignment);
        }
      })
    );
  }

  /**
   * 1. Makes sure clicks don't make it up to drag handlers in the components
   * higher than this in the view.
   * 2. When user clicks on empty areas of textbox that are not clicks on Quill
   * text, select all text.
   *
   * Note that this method is an override.
   */
  onQuillMousedown(event: MouseEvent): void {
    event.stopPropagation();

    // focus quill and select all text
    // setTimeout needed because Quill is weird
    if ((<HTMLDivElement>event.target).classList.contains('ql-editor')) {
      setTimeout(() => {
        this.ctrl.activeQuill.value.focus();
        const length = this.ctrl.activeQuill.value.getLength();
        this.ctrl.activeQuill.value.setSelection(0, length, 'api');
      }, 0);
    }
  }

  /**
   * Closes edtitor. Intended to run when the user hits escape
   * inside of a Quill instance.
   */
  onEscapeKeyPress(): void {
    this.ctrl.close();
  }
}
