/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  BlurBox,
  BlurEffect,
  BlurEffectEditor,
  ClipModel2,
  EffectTimeline,
  SceneEditor2,
  sty,
  VideoClip,
} from '@castify/edit-models';
import { Log, Logger } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../timeline/timeline-state.service';

const kDefaultBlurDuration: sty.Milliseconds = 4000;
const kInitialBlurBox: BlurBox = {
  width: 0.2,
  height: 0.2,
  top: 0.4,
  left: 0.4,
  intensity: 0.05,
};

@Injectable({
  providedIn: 'root',
})
export class BlurEditorControllerService extends SingleClipEditorControllerService {
  private _log: Logger<any> = Log.create('BlurEditorControllerService');
  readonly effect = new BehaviorSubject<BlurEffect>(null);
  readonly selectedBox = new BehaviorSubject<number | null>(null); // index of the currently selected box

  get numBlurBoxes() {
    return this.effect.value.boxes.length;
  }

  protected sceneEditor: SceneEditor2;

  constructor(
    public timelineState: TimelineStateService,
    private undoManager: UndoManagerService,
    public previewState: PreviewStateService
  ) {
    super(timelineState, previewState);
    // sync canActivate
    combineLatest([this.timelineState.selection, this.isActive])
      .pipe(
        map(([selection, isActive]) => {
          if (isActive) {
            return false;
          } else {
            return (
              !!this.filterVideoSelection(selection) ||
              !!this.filterBlurSelection(selection)
            );
          }
        })
      )
      .subscribe(this.canActivate);
  }

  open(): void {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening editor not fulfilled');

    this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());

    // If the current selection is a blur effect, edit existing blur effect
    const blurSelection = this.filterBlurSelection(
      this.timelineState.selection.value
    );
    if (blurSelection) {
      this.clip.next(blurSelection.clip);
      this.effect.next(<BlurEffect>blurSelection.effect);
      this.selectedBox.next(this.numBlurBoxes - 1);

      // Otherwise if we're selecting a video without a blur effect, create one with a blur box
    } else if (this.filterVideoSelection(this.timelineState.selection.value)) {
      this._createBlurEffect();
      this.addBlurBox(kInitialBlurBox);
      this.save();

      this.timelineState.selection.next([
        new EffectSelection({
          clip: this.clip.value,
          effect: this.effect.value,
        }),
      ]);
    } else {
      throw new Error('insufficient selection to open blur editor');
    }

    // set override scene
    const scene = this.sceneEditor.scene.filterClipType(['video']).copy(); // remove text clips
    const clip = <VideoClip>scene.clips.byId(this.clip.value.id);
    clip.effects = new EffectTimeline(); // remove effects
    clip.transform = {}; // remove transforms
    this.previewState.overrideScene.next(scene);
    super.open();
  }

  close() {
    super.close();
    this.clip.next(null);
    this.effect.next(null);
    this.sceneEditor = null;
    this.selectedBox.next(null);
  }

  save() {
    if (this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
      this._log.info('Blur effects saved');
      this.undoManager.update(this.sceneEditor.scene);
    }
  }

  /**
   * Creates a blur effect and attaches it to a clip near the current playheads location
   */
  _createBlurEffect(): void {
    this.clip.next(
      this.filterVideoSelection(this.timelineState.selection.value)
    );

    const effect = this.sceneEditor
      .getClipEditor(this.clip.value)
      .addBlur(
        this.previewState.playhead.value - this.clip.value.startInScene,
        kDefaultBlurDuration
      );

    if (!effect) {
      throw Error('could not add blur');
    }
    this.effect.next(effect);
  }

  _editBlur(editFn: (editor: BlurEffectEditor) => void): void {
    const blurEditor = this.sceneEditor
      .getClipEditor(this.clip.value)
      .editBlur(this.effect.value);
    editFn(blurEditor);
    this.effect.next(blurEditor.effect.copy());
  }

  addBlurBox(newBlurDimensions: BlurBox = kInitialBlurBox): void {
    const newBox = {
      ...newBlurDimensions,
    };

    // If box overlaps box already placed, move it slightly and retry
    this.effect.value.boxes.forEach((box) => {
      if (box.top === newBox.top && box.left === newBox.left) {
        newBox.top -= 0.05;
        newBox.left += 0.05;

        if (newBox.top <= 0 || newBox.left >= 1 - newBox.width) {
          newBox.top = 1 - newBox.height;
          newBox.left = 0;
        }
      }
    });

    this._editBlur((editor) => editor.addBox(newBox));
    this.selectedBox.next(this.effect.value.boxes.length - 1);
    this._log.info('Add new blur box');
  }

  /*
   * Deletes the selected blur box before moving the users selection to the front-most box
   */
  deleteSelectedBox(): void {
    const boxIdx = this.selectedBox.value;
    this._editBlur((editor) => editor.removeBox(boxIdx));

    // If theres still boxes, make currently selected box the top-most box in the array
    if (this.effect.value.boxes[boxIdx]) this.selectedBox.next(boxIdx);
    else if (this.numBlurBoxes > 0)
      this.selectedBox.next(this.numBlurBoxes - 1);
    else this.selectedBox.next(null);
    this._log.info('Delete selected blur box');
  }

  protected filterBlurSelection(
    selection: (ClipModel2 | EffectSelection)[]
  ): EffectSelection | null {
    return (
      <EffectSelection>(
        selection.find(
          (s) =>
            s instanceof EffectSelection &&
            !!s.clip &&
            s.effect instanceof BlurEffect
        )
      ) || null
    );
  }
  protected filterVideoSelection(
    selection: (ClipModel2 | EffectSelection)[]
  ): VideoClip | null {
    return <VideoClip>selection.find((s) => s instanceof VideoClip) || null;
  }
}
