/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef } from '@angular/core';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { makeQuillInstance } from '../../quill-config';
import { QuillContainerViewComponent } from './quill-container-view.component';
var QuillContainerEditComponent = /** @class */ (function (_super) {
    __extends(QuillContainerEditComponent, _super);
    function QuillContainerEditComponent(hostElm, ctrl) {
        var _this = _super.call(this, hostElm, ctrl) || this;
        _this.ctrl = ctrl;
        _this.cursorStyle = 'text';
        _this.overflow = 'visible';
        return _this;
    }
    QuillContainerEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Initialize Quill instance
        this.quill = makeQuillInstance(this.hostElm.nativeElement.querySelector('[quill-editor-element]'), false, this.onEscapeKeyPress.bind(this));
        this.quill.setContents(this.textBox.delta, 'silent');
        // pass quill instance into the controller, as this is the active instance
        this.ctrl.activeQuill.next(this.quill);
        // Set up subscriptions to text box updates
        // Note that this overrides the way this is called in parent
        this.subscriptions.add(this.ctrl.activeBox.subscribe(function (box) {
            if (box) {
                _this.setVerticalAlignment(box.verticalAlignment);
            }
        }));
    };
    /**
     * 1. Makes sure clicks don't make it up to drag handlers in the components
     * higher than this in the view.
     * 2. When user clicks on empty areas of textbox that are not clicks on Quill
     * text, select all text.
     *
     * Note that this method is an override.
     */
    QuillContainerEditComponent.prototype.onQuillMousedown = function (event) {
        var _this = this;
        event.stopPropagation();
        // focus quill and select all text
        // setTimeout needed because Quill is weird
        if (event.target.classList.contains('ql-editor')) {
            setTimeout(function () {
                _this.ctrl.activeQuill.value.focus();
                var length = _this.ctrl.activeQuill.value.getLength();
                _this.ctrl.activeQuill.value.setSelection(0, length, 'api');
            }, 0);
        }
    };
    /**
     * Closes edtitor. Intended to run when the user hits escape
     * inside of a Quill instance.
     */
    QuillContainerEditComponent.prototype.onEscapeKeyPress = function () {
        this.ctrl.close();
    };
    return QuillContainerEditComponent;
}(QuillContainerViewComponent));
export { QuillContainerEditComponent };
