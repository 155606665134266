/*!
 * Copyright 2020 Screencastify LLC
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { GapiLoaderService } from '../../../common/gapi-loader.service';
import { UserService } from '../../user.service';
var log = Log.create('SignInComponent');
var SignInComponent = /** @class */ (function () {
    function SignInComponent(user, router, route, gapiLoader) {
        this.user = user;
        this.router = router;
        this.route = route;
        this.gapiLoader = gapiLoader;
        this.hasError = new BehaviorSubject(false);
    }
    SignInComponent.prototype.ngOnInit = function () {
        if (environment.e2e) {
            this.e2eSignIn();
        }
        else {
            this.signIn();
        }
    };
    SignInComponent.prototype.e2eSignIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pass, returnUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // wait for firebase cached user:
                    return [4 /*yield*/, this.user.user
                            .pipe(filter(function (v) { return v !== undefined; }), first())
                            .toPromise()];
                    case 1:
                        // wait for firebase cached user:
                        _a.sent();
                        if (!!this.user.isSignedIn) return [3 /*break*/, 3];
                        pass = window.prompt('e2e_signin');
                        return [4 /*yield*/, this.user._signInFirebaseWithPassword(environment.e2e.email, pass)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
                        return [4 /*yield*/, this.router.navigateByUrl(returnUrl)];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SignInComponent.prototype.signIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token, _a, returnUrl, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        if (!!!environment.auth.signInUrl) return [3 /*break*/, 1];
                        _a = this.getAccessTokenFromCookie();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getAccessTokenViaPopup()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        token = _a;
                        if (!token || !token.length)
                            throw new Error('unable to authenticate without a token');
                        return [4 /*yield*/, this.user.signInWithGoogleAccessToken(token)];
                    case 4:
                        _b.sent();
                        returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
                        return [4 /*yield*/, this.router.navigateByUrl(returnUrl)];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _b.sent();
                        log.error(err_1);
                        this.hasError.next(true);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    SignInComponent.prototype.getAccessTokenFromCookie = function () {
        var value = "; " + document.cookie;
        var parts = value.split("; castifyAuthToken=");
        return parts.length === 2 ? parts.pop().split(';').shift() : null;
    };
    SignInComponent.prototype.getAccessTokenViaPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authResp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.gapiLoader.loadAuth2()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                gapi.auth2.authorize({
                                    client_id: environment.auth.clientId,
                                    scope: environment.auth.scopes.join(' '),
                                    prompt: 'select_account',
                                }, function (resp) {
                                    if (resp.error) {
                                        reject(resp.error);
                                    }
                                    else {
                                        resolve(resp);
                                    }
                                });
                            })];
                    case 2:
                        authResp = _a.sent();
                        return [2 /*return*/, authResp.access_token];
                }
            });
        });
    };
    SignInComponent.prototype.switchAccount = function () {
        this.user.signOut();
    };
    return SignInComponent;
}());
export { SignInComponent };
