/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { ClipViewComponent } from '../clip-view/clip-view.component';
var StillClipViewComponent = /** @class */ (function (_super) {
    __extends(StillClipViewComponent, _super);
    function StillClipViewComponent(hostElm) {
        var _this = _super.call(this) || this;
        _this.hostElm = hostElm;
        _this._playStartTime = Date.now();
        _this._seekPos = 0;
        _this.canPlay.next(true);
        return _this;
    }
    Object.defineProperty(StillClipViewComponent.prototype, "currentTime", {
        get: function () {
            return (this._seekPos + (this.paused.value ? 0 : Date.now() - this._playStartTime));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StillClipViewComponent.prototype, "startInScene", {
        get: function () {
            return this._clip.startInScene;
        },
        enumerable: true,
        configurable: true
    });
    StillClipViewComponent.prototype.play = function () {
        this._playStartTime = Date.now();
        this.paused.next(false);
        return;
    };
    StillClipViewComponent.prototype.pause = function () {
        this.seek(this.currentTime);
        this.paused.next(true);
        return;
    };
    StillClipViewComponent.prototype.seek = function (pos) {
        this._seekPos = pos;
        this._playStartTime = Date.now();
        return;
    };
    StillClipViewComponent.prototype.sync = function (refPos) {
        if (Number.isFinite(refPos)) {
            // clip is not the sync master, update current position to match sync reference
            this._seekPos = refPos;
            this._playStartTime = Date.now();
        }
        return 0;
    };
    StillClipViewComponent.prototype.onDisplay = function () {
        if (!this.hostElm || !this._clip)
            return;
        this.hostElm.nativeElement.style.backgroundColor = "#" + this._clip.backgroundColor;
    };
    return StillClipViewComponent;
}(ClipViewComponent));
export { StillClipViewComponent };
