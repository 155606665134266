/*!
 * Copyright 2019 Screencastify LLC
 */

import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { sty } from '@castify/models';

export interface DragHandleEvent {
  event: MouseEvent | TouchEvent;
  startEvent: MouseEvent | TouchEvent;
  shiftX: sty.Pixels;
  shiftY: sty.Pixels;
}

@Directive({
  selector: '[libDragHandle]',
})
export class DragHandleDirective {
  @Output() dragStart = new EventEmitter<MouseEvent | TouchEvent>();
  @Output() dragMove = new EventEmitter<DragHandleEvent>();
  @Output() dragEnd = new EventEmitter<DragHandleEvent>();

  constructor() {}

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  startDragWithHandlers(startEvent: MouseEvent | TouchEvent): void {
    // Logs the movement delta contained in an emitted dragMove event
    const onMove = (event: MouseEvent | TouchEvent) => {
      this.dragMove.emit(this.createDragHandleEvent(startEvent, event));
    };

    // When the drag starts...
    // emit the change on every touchMove or mouseMove
    window.addEventListener(
      startEvent instanceof MouseEvent ? 'mousemove' : 'touchmove',
      onMove
    );

    // When the drag ends...
    window.addEventListener(
      startEvent instanceof MouseEvent ? 'mouseup' : 'touchend',
      (event: MouseEvent | TouchEvent) => {
        // Stop emitting further movements
        window.removeEventListener(
          event instanceof MouseEvent ? 'mousemove' : 'touchmove',
          onMove
        );

        // Emit one final movement
        this.dragEnd.emit(this.createDragHandleEvent(startEvent, event));
      },
      { once: true }
    );

    this.dragStart.emit(startEvent);

    // avoid starting multiple drag actions at once on stacked elements
    startEvent.stopPropagation();
  }

  /*
   * Creates an appropriate drag event for our listeners to emit
   */
  protected createDragHandleEvent(
    startEvent: MouseEvent | TouchEvent,
    event: MouseEvent | TouchEvent
  ): DragHandleEvent {
    const shiftY = this.calcYDelta(startEvent, event);
    const shiftX = this.calcXDelta(startEvent, event);

    return {
      event,
      startEvent,
      shiftX,
      shiftY,
    };
  }

  /*
   * Finds the difference between the current X position and our starting X position
   */
  protected calcXDelta(
    startEvent: MouseEvent | TouchEvent,
    event: MouseEvent | TouchEvent
  ): sty.Pixels {
    // Determines if we're using a mouse event or a touch event
    const getXValue = (e: MouseEvent | TouchEvent) =>
      e instanceof MouseEvent ? e.clientX : e.changedTouches[0].clientX;

    return getXValue(event) - getXValue(startEvent);
  }

  /*
   * Finds the difference between the current Y position and our starting Y position
   */
  protected calcYDelta(
    startEvent: MouseEvent | TouchEvent,
    event: MouseEvent | TouchEvent
  ): number {
    // Determines if we're using a mouse event or a touch event
    const getYValue = (e: MouseEvent | TouchEvent) =>
      e instanceof MouseEvent ? e.clientY : e.changedTouches[0].clientY;

    return getYValue(event) - getYValue(startEvent);
  }
}
