/*!
 * Copyright 2020 Screencastify LLC
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { of } from 'rxjs';
import { combineLatest, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, last, map, startWith, } from 'rxjs/operators';
import { KeyboardService } from '../../keyboard-shortcuts/keyboard.service';
import { DriveImportBrainService } from './drive-import-brain.service';
var DriveImportComponent = /** @class */ (function () {
    function DriveImportComponent(dialogRef, keyboardService, brain) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.keyboardService = keyboardService;
        this.brain = brain;
        this.logger = Log.create('DriveImportComponent');
        this.subscriptions = new Subscription();
        /**
         * Observable instance member which derives UI state from
         * the latest values of observables in the brain service
         */
        this.activeModal = combineLatest([
            this.brain.readyToAskUserForFile,
            this.brain.gotDriveFiles.pipe(startWith(null)),
            this.brain.progress.pipe(startWith(null)),
            this.brain.complete.pipe(last(), startWith(null)),
        ]).pipe(map(function (_a) {
            var _b = __read(_a, 2), driveImportFiles = _b[1];
            if (driveImportFiles)
                return 'progress';
            else
                return 'spinner';
        }), 
        /**
         * This overrides the above to show the error modal
         * if any of the observables throw
         */
        catchError(function (err) {
            _this.logger.error(err);
            return of('error');
        }), distinctUntilChanged());
    }
    DriveImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Temporarily pause all editor keyboard shortcuts
        this.keyboardService.pauseAll();
        // Start the brain
        this.brain.init();
        // Close modal on flow cancellation
        this.subscriptions.add(this.brain.flowCancelled.subscribe(function () {
            _this.close();
        }));
        // Close modal on import completion
        this.subscriptions.add(this.brain.complete.pipe(last()).subscribe(function () { return _this.close(); }));
    };
    DriveImportComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        // turn the keyboard shortcuts back on
        this.keyboardService.resumeAll();
    };
    /**
     * Any of the modal subcomponents can call this to close
     * the MatDialogue modal.
     */
    DriveImportComponent.prototype.close = function () {
        this.subscriptions.unsubscribe();
        this.dialogRef.close({ name: '' });
    };
    return DriveImportComponent;
}());
export { DriveImportComponent };
