/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2018 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".content[_ngcontent-%COMP%] {\n  padding: 14px; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvbW9kdWxlcy9lZGl0LXVpL3Byb2plY3RzL2VkaXQtdWkvc3JjL2FwcC9icm93c2VyLWd1YXJkL2NvbXBvbmVudHMvYnJvd3Nlci1ndWFyZC9icm93c2VyLWd1YXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsYUFBYSxFQUFBIiwiZmlsZSI6InByb2plY3RzL2VkaXQtdWkvc3JjL2FwcC9icm93c2VyLWd1YXJkL2NvbXBvbmVudHMvYnJvd3Nlci1ndWFyZC9icm93c2VyLWd1YXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG4uY29udGVudCB7XG4gIHBhZGRpbmc6IDE0cHg7XG59XG4iXX0= */"];
export { styles as styles };
