/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'lib-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  /**
   * Opens the support page in a new tab
   */
  onHelpClick(): void {
    window.open(environment.externalLinks.support);
  }

  /**
   * Emits an event to the parent which indicates the user has
   * requested the modal be closed
   */
  public onCloseClick(): void {
    this.closeEvent.emit();
  }
}
