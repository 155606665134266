var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AudioClip, SceneEditor2, VideoClip, } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService, } from '../timeline/timeline-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../preview/preview-state.service";
import * as i3 from "../common/undo-manager.service";
var log = Log.create('AudioToolControllerService');
/**
 * Not really using SingleClipEditorControllerService.clips
 */
var AudioToolControllerService = /** @class */ (function (_super) {
    __extends(AudioToolControllerService, _super);
    function AudioToolControllerService(timelineState, previewState, undoManager) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.previewState = previewState;
        _this.undoManager = undoManager;
        _this.gain = new BehaviorSubject(null);
        _this._audioClip = new BehaviorSubject(null);
        // update clip timeline from selection
        _this.timelineState.selection
            .pipe(map(function (selection) { return _this._getSelectedClips(selection); }))
            .subscribe(function (_a) {
            var audioClip = _a.audioClip, videoClip = _a.videoClip;
            _this._audioClip.next(audioClip);
            // NOTE: using videoClip as this.clip because timelineState uses it for
            // highlighting selected clips, consider renaming this to parentClip or rootClip
            // see logic in parent
            _this.clip.next(videoClip);
        });
        // sync canActivate
        combineLatest([_this._audioClip, _this.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), clip = _b[0], isActive = _b[1];
            return !!clip && !isActive;
        }))
            .subscribe(_this.canActivate);
        return _this;
    }
    AudioToolControllerService.prototype.open = function () {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening editor not fulfilled');
        if (!this._audioClip.value)
            throw new Error('Failed to open audio tool: no clip selected');
        this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
        this.gain.next(this._audioClip.value.gain);
        _super.prototype.open.call(this);
    };
    AudioToolControllerService.prototype.close = function () {
        _super.prototype.close.call(this);
        this.gain.next(null);
        this.sceneEditor = null;
        // select the video when editor sidebar closes
        var videoClip = this.timelineState.selection.value.find(function (v) { return v instanceof VideoClip; });
        this.timelineState.selection.next([videoClip]);
    };
    AudioToolControllerService.prototype.save = function () {
        if (this.sceneEditor &&
            this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
            log.info("save audio gain: " + this.gain.value);
            this.undoManager.update(this.sceneEditor.scene);
        }
    };
    /**
     * manipulate gain
     */
    AudioToolControllerService.prototype.setGain = function (gain) {
        var clip = this._audioClip.value;
        var clipEditor = this.sceneEditor.getClipEditor(clip);
        clipEditor.setGain(gain);
        this.gain.next(gain);
        this._updateIntermediateScene();
    };
    AudioToolControllerService.prototype._updateIntermediateScene = function () {
        // NOTE: using scene.copy() to change reference since async pipe does not
        // trigger change detection where there's no reference change.
        // see `[clip]="_clip | async"` see “Marking things for check” in
        // https://blog.thoughtram.io/angular/2017/02/27/three-things-you-didnt-know-about-the-async-pipe.html
        this.timelineState.overrideScene.next(this.sceneEditor.scene.copy());
    };
    /**
     * Audio's clip and video clip
     */
    AudioToolControllerService.prototype._getSelectedClips = function (timelineSelection) {
        var audioClip = null;
        var videoClip = null;
        // get audioClip
        audioClip = timelineSelection.find(function (clip) {
            return clip instanceof AudioClip;
        }) || null;
        if (!audioClip)
            return { audioClip: audioClip, videoClip: videoClip };
        // get videoClip
        var sceneModel = this.timelineState.scene.value.filter(function (clip) {
            return clip instanceof VideoClip && clip.insertId === audioClip.insertId;
        });
        videoClip = (sceneModel.clips.items.find(function (clip) { return clip instanceof VideoClip; }));
        return { audioClip: audioClip, videoClip: videoClip };
    };
    AudioToolControllerService.ngInjectableDef = i0.defineInjectable({ factory: function AudioToolControllerService_Factory() { return new AudioToolControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.PreviewStateService), i0.inject(i3.UndoManagerService)); }, token: AudioToolControllerService, providedIn: "root" });
    return AudioToolControllerService;
}(SingleClipEditorControllerService));
export { AudioToolControllerService };
