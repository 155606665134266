/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./still-clip-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./still-clip-view.component";
var styles_StillClipViewComponent = [i0.styles];
var RenderType_StillClipViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StillClipViewComponent, data: {} });
export { RenderType_StillClipViewComponent as RenderType_StillClipViewComponent };
export function View_StillClipViewComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_StillClipViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-still-clip-view", [], null, null, null, View_StillClipViewComponent_0, RenderType_StillClipViewComponent)), i1.ɵdid(1, 49152, null, 0, i2.StillClipViewComponent, [i1.ElementRef], null, null)], null, null); }
var StillClipViewComponentNgFactory = i1.ɵccf("lib-still-clip-view", i2.StillClipViewComponent, View_StillClipViewComponent_Host_0, {}, {}, []);
export { StillClipViewComponentNgFactory as StillClipViewComponentNgFactory };
