<div
  class="placeholder-container"
  *ngIf="shouldRender | async"
  [ngStyle]="{
    alignItems: (verticalAlignment | async)
  }"
>
  <div class="placeholder" [ngStyle]="{
    textAlign: (horizontalAlignment | async)
  }">Write something...</div>
</div>
