/*!
 * Copyright 2020 Screencastify LLC
 */
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
var AppComponent = /** @class */ (function () {
    function AppComponent(angulartics) {
        angulartics.settings.anonymizeIp = true;
        angulartics.startTracking();
    }
    return AppComponent;
}());
export { AppComponent };
