/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drive-import-progress-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "./drive-import-progress-modal.component";
var styles_DriveImportProgressModalComponent = [i0.styles];
var RenderType_DriveImportProgressModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DriveImportProgressModalComponent, data: {} });
export { RenderType_DriveImportProgressModalComponent as RenderType_DriveImportProgressModalComponent };
export function View_DriveImportProgressModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, [" Processing video ", " of ", "... "])), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(9, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_6 = (i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co._progress)) * 100); var currVal_7 = (((i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 11).transform(_co._progress)) > 0) && (i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 12).transform(_co._progress)) <= 1)) ? "determinate" : "indeterminate"); _ck(_v, 9, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co._complete)); var currVal_2 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 5).transform(_co._importCount)); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 9).value; var currVal_4 = i1.ɵnov(_v, 9).mode; var currVal_5 = i1.ɵnov(_v, 9)._isNoopAnimation; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); var currVal_8 = (i1.ɵnov(_v, 16).disabled || null); var currVal_9 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_8, currVal_9); }); }
export function View_DriveImportProgressModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-drive-import-progress-modal", [], null, null, null, View_DriveImportProgressModalComponent_0, RenderType_DriveImportProgressModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.DriveImportProgressModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DriveImportProgressModalComponentNgFactory = i1.ɵccf("lib-drive-import-progress-modal", i11.DriveImportProgressModalComponent, View_DriveImportProgressModalComponent_Host_0, { progress: "progress", importCount: "importCount", complete: "complete" }, { closeEvent: "closeEvent" }, []);
export { DriveImportProgressModalComponentNgFactory as DriveImportProgressModalComponentNgFactory };
