/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i8 from "@angular/material/toolbar";
import * as i9 from "@angular/common";
import * as i10 from "../../../common/components/editor-logo/editor-logo.component.ngfactory";
import * as i11 from "../../../common/components/editor-logo/editor-logo.component";
import * as i12 from "./sign-in.component";
import * as i13 from "../../user.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../common/gapi-loader.service";
var styles_SignInComponent = [i0.styles];
var RenderType_SignInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
function View_SignInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "cf-card cf-loading-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "cf-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["cf-loading-arrow", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Signing in..."]))], null, null); }
function View_SignInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "cf-card cf-loading-card no-auth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "cf-card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Whoops! "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "cf-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We couldn't connect to your Google Drive. Please sign in again. "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "cf-card-button-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "cf-button"], ["color", "accent"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchAccount() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Switch account "]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 7).disabled || null); var currVal_1 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_SignInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "solid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-toolbar", [["class", "solid mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i7.View_MatToolbar_0, i7.RenderType_MatToolbar)), i1.ɵdid(2, 4243456, null, 1, i8.MatToolbar, [i1.ElementRef, i4.Platform, i9.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "lib-editor-logo", [], null, null, null, i10.View_EditorLogoComponent_0, i10.RenderType_EditorLogoComponent)), i1.ɵdid(5, 114688, null, 0, i11.EditorLogoComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SignInComponent_1)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SignInComponent_2)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); _ck(_v, 5, 0); var currVal_3 = !i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.hasError)); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.hasError)); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 2)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SignInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i1.ɵdid(1, 114688, null, 0, i12.SignInComponent, [i13.UserService, i14.Router, i14.ActivatedRoute, i15.GapiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInComponentNgFactory = i1.ɵccf("lib-sign-in", i12.SignInComponent, View_SignInComponent_Host_0, {}, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
