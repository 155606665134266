/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatToolbarModule } from '@angular/material';
import { LibCommonModule } from '../common/lib-common.module';
import { SignInComponent } from './components/sign-in/sign-in.component';

const exportDeclarations = [SignInComponent];

@NgModule({
  declarations: [...exportDeclarations],
  imports: [CommonModule, LibCommonModule, MatToolbarModule, MatButtonModule],
  exports: exportDeclarations,
})
export class LibAuthModule {}
