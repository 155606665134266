<div class="solid">
  <mat-toolbar class="solid" color="primary">
    <lib-editor-logo></lib-editor-logo>
  </mat-toolbar>

  <div class="content">
    <div *ngIf="!(hasError | async)" class="cf-card cf-loading-card">
      <div class="cf-card-body">
        <img cf-loading-arrow>
        <div>Signing in...</div>
      </div>
    </div>

    <div *ngIf="hasError | async" class="cf-card cf-loading-card no-auth">
      <div class="cf-card-title">
        Whoops!
      </div>
      <div class="cf-card-body">
        We couldn't connect to your Google Drive. Please sign in again.
      </div>
      <div class="cf-card-button-group">
        <button mat-flat-button class="cf-button" color="accent" (click)="switchAccount()">
          Switch account
        </button>
      </div>
    </div>

  </div>
</div>
