/*!
 * Copyright 2020 Screencastify LLC
 */

import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { SideToolbarContentComponent } from 'lib-editor/lib/tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { InjectableToolbarComponent } from '../../../tool-sidebar/components/tool-sidebar/tool-sidebar.component';
import { CropEditorControllerService } from '../../crop-editor-controller.service';

@Component({
  selector: 'lib-crop-toolbar',
  templateUrl: './crop-toolbar.component.html',
  styleUrls: ['./crop-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropToolbarComponent implements InjectableToolbarComponent {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;

  constructor(protected ctrl: CropEditorControllerService) {}

  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      await this._toolbarContainer.close();
    }
  }

  closeEditor(): void {
    this.ctrl.save();
    this.ctrl.close();
  }
}
