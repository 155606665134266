/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { of } from 'rxjs';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  last,
  map,
  startWith,
} from 'rxjs/operators';
import { KeyboardService } from '../../keyboard-shortcuts/keyboard.service';
import { DriveImportBrainService } from './drive-import-brain.service';

type modalName = 'spinner' | 'progress' | 'error';

@Component({
  selector: 'lib-drive-import',
  templateUrl: './drive-import.component.html',
  styleUrls: ['./drive-import.component.scss'],
  providers: [KeyboardService, DriveImportBrainService],
})
export class DriveImportComponent implements OnInit, OnDestroy {
  private logger = Log.create('DriveImportComponent');

  private subscriptions = new Subscription();

  /**
   * Observable instance member which derives UI state from
   * the latest values of observables in the brain service
   */
  public activeModal: Observable<modalName> = combineLatest([
    this.brain.readyToAskUserForFile,
    this.brain.gotDriveFiles.pipe(startWith(null)),
    this.brain.progress.pipe(startWith(null)),
    this.brain.complete.pipe(last(), startWith(null)),
  ]).pipe(
    map(([, driveImportFiles]) => {
      if (driveImportFiles) return <modalName>'progress';
      else return 'spinner';
    }),
    /**
     * This overrides the above to show the error modal
     * if any of the observables throw
     */
    catchError<modalName, Observable<modalName>>((err: any) => {
      this.logger.error(err);
      return of(<modalName>'error');
    }),
    distinctUntilChanged()
  );

  constructor(
    private dialogRef: MatDialogRef<DriveImportComponent>,
    private keyboardService: KeyboardService,
    private brain: DriveImportBrainService
  ) {}

  ngOnInit() {
    // Temporarily pause all editor keyboard shortcuts
    this.keyboardService.pauseAll();

    // Start the brain
    this.brain.init();

    // Close modal on flow cancellation
    this.subscriptions.add(
      this.brain.flowCancelled.subscribe(() => {
        this.close();
      })
    );

    // Close modal on import completion
    this.subscriptions.add(
      this.brain.complete.pipe(last()).subscribe(() => this.close())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    // turn the keyboard shortcuts back on
    this.keyboardService.resumeAll();
  }

  /**
   * Any of the modal subcomponents can call this to close
   * the MatDialogue modal.
   */
  close(): void {
    this.subscriptions.unsubscribe();
    this.dialogRef.close({ name: '' });
  }
}
