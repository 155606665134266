/*!
 * Copyright 2020 Screencastify LLC
 */
import { HorizontalAlignment } from '@castify/edit-models';
import Quill from 'quill';

/**
 * This file is currently a bit of a "dumping ground" for various Quill-related
 * configuration and types which may need to be accessed in mutliple places.
 *
 * Also contrains some configuration for the sidebar.
 */

/**
 * Key is actual font value in Quill; value is display name
 */
export const kEditorFonts = {
  roboto: 'Roboto',
  anton: 'Anton',
  kalam: 'Kalam',
  chonburi: 'Chonburi',
  ptMono: 'PT Mono',
};
export type fontKeys = keyof typeof kEditorFonts;

/**
 * Builds an array of whitelisted font size strings of form
 * `10px` from min to max. Edit min and max values to config.
 *
 * The font size multiplier is used to scale font values by a constant
 * factor on the way into / out of Quill.
 *
 * This is necessary at time of this commit because the large
 * size of the logical canvas means that font values passed in
 * will appear smaller than users likely expect given expectations
 * around the meaning of font point values.
 *
 * kEditorFontSizes is the only value used as part of Quill style whitelisting.
 * The other values configure the sidebar
 */
export const kMinFontSize = 6;
export const kMaxFontSizeInclusive = 98;
export const kEditorFontSizeMultiplier = 2;
export const kEditorFontSizes = Array.from({
  length: kMaxFontSizeInclusive * kEditorFontSizeMultiplier - kMinFontSize + 1,
}).map((_, idx) => `${<number>idx + kMinFontSize}px`);

/**
 * These merely reflect Quill's defaults; they are not used to set
 * as of this commit. Intended to show defaults in the sidebar.
 *
 * Necessary because when an attribute has a value Quill considers
 * "default", that attribute will simply not be reported by Quill.
 *
 * These should correspond to the defaults set in the global ql styles:
 * /modules/edit-ui/projects/edit-ui/src/styles/quill/_index.scss
 */
export const kQuillDefaultFontSize = 32;
export const kQuillDefaultAlignment = HorizontalAlignment.left;
export const kQuillDefaultTextColor = '#000000';
export const kQuillDefaultFontName = 'roboto';

/**
 * Text colors selectable in the editor (Quill whitelist)
 */
export const kEditorColors = [
  '#000000',
  '#575757',
  '#b4afaf',
  '#00d1ff',
  '#0066cc',
  '#022069',
  '#af7302',
  '#ea6503',
  '#ff9900',
  '#ffff00',
  '#92fe00',
  '#008a00',
  '#096305',
  '#e60000',
  '#ff00d8',
  '#9933ff',
  '#440178',
  '#ffffff',
];

/**
 * Background colors selectable in the editor (Quill whitelist)
 */
export const kEditorBackgroundColors = [...kEditorColors, 'none'];

/**
 * Config for the maximum number of text boxes and text box length
 */
export const kMaxTextBoxes = 50;
export const kMaxTextBoxLength = 10000;

/**
 * Helper types for Quill attributes
 */
export enum ToggleableAttribute {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
}

export enum NonToggleableAttribute {
  color = 'color',
  background = 'background',
  size = 'size',
  font = 'font',
  align = 'align',
  rotation = 'rotation',
}
export interface QuillRange {
  index: number;
  length: number;
}

/**
 * Constructs a new quill instance.
 */
export const makeQuillInstance = (
  element: HTMLElement,
  readOnly: boolean,
  escapeKeyCallback: () => void
) => {
  // define selectable fonts
  const fonts = Quill.import('attributors/class/font');
  fonts.whitelist = Object.keys(kEditorFonts);
  Quill.register(fonts, true);

  // define selectable font sizes
  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = kEditorFontSizes;
  Quill.register(fontSizeStyle, true);

  return new Quill(element, {
    readOnly,
    debug: 'warn',
    modules: {
      // completely disable toolbar
      toolbar: false,

      // tell quill to bind the callback to the escape key
      keyboard: {
        bindings: {
          escape: {
            key: 27,
            handler: escapeKeyCallback,
          },
        },
      },
    },
    // tells Quill to refuse to render all formats not on the list.
    formats: ['bold', 'italic', 'underline', 'color', 'font', 'size', 'align'],
  });
};
