/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { VerticalAlignment } from '@castify/edit-models';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { AnnotationsPreviewViewComponent } from './annotations-preview-view.component';

const keepOpenSelectors = [
  '.cdk-overlay-backdrop',
  'lib-annotations-preview-edit',
  'lib-annotations-sidebar',
  '.mat-menu-content',
  '#remove-button',
];
@Component({
  selector: 'lib-annotations-preview-edit',
  templateUrl: './annotations-preview-edit.component.html',
  styleUrls: ['./annotations-preview.component.scss'],
})
export class AnnotationsPreviewEditComponent
  extends AnnotationsPreviewViewComponent
  implements OnInit, OnDestroy {
  readonly subscriptions = new Subscription();
  // pass constants into template
  readonly verticalAlignment = VerticalAlignment;
  readonly grayBorderRotation = combineLatest(
    this.ctrl.activeBox,
    this.ctrl.grayBoxRotation
  ).pipe(
    map(([activeBox, grayBoxRotation]) =>
      grayBoxRotation === null ? activeBox.rotation : grayBoxRotation
    )
  );

  constructor(public readonly ctrl: AnnotationsToolControllerService) {
    super(ctrl);
  }

  ngOnInit() {
    // Get clip updates from the controller into this component.
    this.subscriptions.add(
      this.ctrl.clip.subscribe((clip) => (this.clip = clip))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:mousedown', ['$event'])
  onEditorClick(event: MouseEvent): void {
    const editorElements = keepOpenSelectors
      .map((selector) => document.querySelectorAll(selector))
      .map((list) => Array.from(list))
      .reduce((acc, el) => acc.concat(el), []);

    const editorClicked = event
      .composedPath()
      .some((path) => (<EventTarget[]>editorElements).includes(path));

    if (!editorClicked) {
      // save edit and close editor
      this.ctrl.save();
      this.ctrl.close();
    }
  }

  /**
   * Identity function to help the Angular maintain
   * referential equivalence for components accross re-renders
   */
  trackByIndex(_, item): string {
    return item.id;
  }

  /**
   * Background clicks when editor is open do not defocus quill. This makes
   * sure that misclicks do not cause user to lose text selection state
   * or cursor position
   */
  preventBackgroundClicks(event: MouseEvent): void {
    event.preventDefault();
  }
}
