/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import {
  kEditorFonts,
  kEditorFontSizeMultiplier,
  kMaxFontSizeInclusive,
  kMinFontSize,
  kQuillDefaultFontName,
  kQuillDefaultFontSize,
  NonToggleableAttribute as nonToggleable,
} from '../../quill-config';

@Component({
  selector: 'lib-annotations-sidebar-font',
  templateUrl: './annotations-sidebar-font.component.html',
  styleUrls: ['./annotations-sidebar-font.component.scss'],
})
export class AnnotationsSidebarFontComponent implements OnInit {
  /**
   * Expose constants to the template
   */
  minFontSize: number = kMinFontSize;
  maxFontSizeInclusive: number = kMaxFontSizeInclusive;
  editorFonts = Object.entries(kEditorFonts);

  /**
   * Attribute observables
   */
  font = this.ctrl.nonToggleableObsFactory<string>(nonToggleable.font, true);
  fontDisplayName = this.font.pipe(
    startWith(kQuillDefaultFontName),
    map((font) => kEditorFonts[font])
  );
  size = this.ctrl
    .nonToggleableObsFactory<string>(nonToggleable.size, false)
    .pipe(
      // if font is not set (e.g. if is default) show the default
      map((attr) => {
        return attr ? +attr.slice(0, -2) : kQuillDefaultFontSize;
      }),
      // Apply font size multiplier to values coming out of Quill
      map((fontSize) => fontSize / kEditorFontSizeMultiplier)
    );

  /**
   * Enables color in slider
   */
  sliderGradient = this.size.pipe(
    map((x) => x / kMaxFontSizeInclusive),
    map((value) => {
      const leftColor = '#ff8282';
      const rightColor = '#fff';

      return {
        background: `linear-gradient(
            to right,
            ${leftColor} 0%,
            ${leftColor} ${value * 100}%,
            ${rightColor} ${value * 100}%,
            ${rightColor} 100%)`,
      };
    })
  );

  constructor(private ctrl: AnnotationsToolControllerService) {}

  ngOnInit() {}

  /**
   * Non-toggleable attribute setters
   */
  public setFont(font: string): void {
    this.ctrl.triggerSettable(nonToggleable.font, font);
  }
  public setSize(target: HTMLInputElement): void {
    const size = parseInt(target.value, 10);

    // constrains size of numbers entered via keyboard to
    // the input element
    let constrainedSize = size;
    if (size > kMaxFontSizeInclusive) {
      constrainedSize = kMaxFontSizeInclusive;
      target.value = constrainedSize.toString();
    }
    if (size < kMinFontSize) {
      constrainedSize = kMinFontSize;
      target.value = constrainedSize.toString();
    }

    this.ctrl.triggerSettable(
      nonToggleable.size,
      `${
        // Apply font size multiplier to values going in to Quill
        kEditorFontSizeMultiplier * constrainedSize
      }px`
    );

    // refocus quill after each interaction
    this.ctrl.activeQuill.value.focus();
  }
}
