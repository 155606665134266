var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*!
 * Copyright 2019 Screencastify LLC
 */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Range } from '@castify/edit-models';
import { environment } from 'environments/environment';
import { concat, from, of, throwError, throwError as observableError, } from 'rxjs';
import { catchError, delay, ignoreElements, map, retryWhen, switchMap, } from 'rxjs/operators';
import { UserService } from '../auth/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/user.service";
import * as i2 from "@angular/common/http";
var StreamerClientService = /** @class */ (function () {
    function StreamerClientService(userService, http) {
        this.userService = userService;
        this.http = http;
        this._queueStartByFile = {};
    }
    /**
     * makes sure the api processes upcoming requests with priority.
     * All previously issued requests are assumed to be obsolete but will be retried if still subscribed to
     */
    StreamerClientService.prototype.flushVideoQueue = function (fileId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._queueStartByFile[fileId] = Date.now();
                return [2 /*return*/];
            });
        });
    };
    /**
     * fetch a video segment from the api.
     * Request will be retried when api ejects it from the queue, and will be aborted on unsubscribe
     */
    StreamerClientService.prototype.fetchVideo = function (clip, segIdx) {
        return this._fetchHandleApiNotOpen(clip.userFileId, this._retryWhenResponseEmpty(this._doFetchVideo(clip, segIdx)));
    };
    StreamerClientService.prototype._doFetchVideo = function (clip, segIdx) {
        var _this = this;
        // build request
        return from(this.userService.currentUser.getIdToken()).pipe(switchMap(function (authToken) {
            return _this.http.get(_this._getVideoUrl(clip.userFileId, segIdx), {
                responseType: 'arraybuffer',
                withCredentials: true,
                headers: new HttpHeaders({
                    'client-time': "" + Date.now(),
                    'queue-start': "" + (_this._queueStartByFile[clip.userFileId] || 0),
                    authorization: "Bearer " + authToken,
                }),
                params: _this._getClipQueryParams(clip, segIdx),
            });
        }));
    };
    StreamerClientService.prototype._getClipQueryParams = function (clip, segIdx) {
        var params = new HttpParams();
        if (!clip.transform)
            return params;
        // crop
        if (clip.transform.crop) {
            var crop = clip.transform.crop;
            params = params.set('crop', crop.toQueryString());
        }
        // effects
        if (clip.effects) {
            var segDuration_1 = environment.video.segmentDuration;
            var segStart_1 = segIdx * segDuration_1;
            var effects = clip.effects.getAllInRange(segStart_1 - clip.startInFile, segDuration_1, { exclusive: true });
            effects.forEach(function (effect) {
                // translate startInClip to file time for better caching
                var queryEffect = effect.copy();
                queryEffect.startInClip += clip.startInFile;
                // add effect to request
                switch (effect.type) {
                    case 'zoom':
                        params = params.append(effect.type, queryEffect.toQueryString(new Range(segStart_1, segStart_1 + segDuration_1)));
                        break;
                    default:
                        break;
                }
            });
        }
        return params;
    };
    /**
     * fetch audio from the api.
     * Behaves similar to fetchVideo
     */
    StreamerClientService.prototype.fetchAudio = function (clip, segIdx) {
        return this._fetchHandleApiNotOpen(clip.userFileId, this._retryWhenResponseEmpty(this._doFetchAudio(clip, segIdx)));
    };
    StreamerClientService.prototype._doFetchAudio = function (clip, segIdx) {
        var _this = this;
        return from(this.userService.currentUser.getIdToken()).pipe(switchMap(function (authToken) {
            return _this.http.get(_this._getAudioUrl(clip.userFileId, segIdx), {
                responseType: 'arraybuffer',
                withCredentials: true,
                headers: new HttpHeaders({
                    authorization: "Bearer " + authToken,
                }),
            });
        }));
    };
    StreamerClientService.prototype._retryWhenResponseEmpty = function (fetchObs) {
        return fetchObs.pipe(map(function (v) {
            if (!v.byteLength)
                throw new HttpErrorResponse({ status: 202 });
            return v;
        }), retryWhen(function (errObs) {
            return errObs.pipe(switchMap(function (err) {
                if (err && err.status === 202)
                    return of(202).pipe(delay(environment.preview.emptyApiResponseRetryDelay));
                return throwError(err);
            }));
        }));
    };
    StreamerClientService.prototype._fetchHandleApiNotOpen = function (fileId, fetchObs) {
        var _this = this;
        var retryCount = 0;
        var maxRetries = 1; // TODO: read from config
        return fetchObs.pipe(catchError(function (err, caught) {
            if (err instanceof HttpErrorResponse &&
                (err.status === 400 || err.status === 401)) {
                // open and retry request
                return concat(_this._openApi(fileId).pipe(ignoreElements()), caught);
            }
            return observableError(err);
        }), retryWhen(function (errObs) {
            return errObs.pipe(switchMap(function (err) {
                if (++retryCount > maxRetries || // limit number of retry attempts
                    (err && err.status === 404)) {
                    // don't repeat when receiving 404
                    return throwError(err);
                }
                else {
                    return of(null);
                }
            }));
        }));
    };
    StreamerClientService.prototype._openApi = function (fileId) {
        var _this = this;
        return from(this.userService.currentUser.getIdToken()).pipe(switchMap(function (authToken) {
            return _this.http.post(_this._getOpenUrl(fileId), {}, {
                responseType: 'json',
                withCredentials: true,
                headers: new HttpHeaders({
                    authorization: "Bearer " + authToken,
                }),
            });
        }));
    };
    StreamerClientService.prototype._getBaseUrl = function (fileId) {
        return [environment.preview.apiUrl, fileId].join('/');
    };
    StreamerClientService.prototype._getOpenUrl = function (fileId) {
        return [this._getBaseUrl(fileId), 'open'].join('/');
    };
    StreamerClientService.prototype._getVideoUrl = function (fileId, segIdx) {
        return [this._getBaseUrl(fileId), "v" + segIdx].join('/');
    };
    StreamerClientService.prototype._getAudioUrl = function (fileId, segIdx) {
        return [this._getBaseUrl(fileId), "a" + segIdx].join('/');
    };
    StreamerClientService.ngInjectableDef = i0.defineInjectable({ factory: function StreamerClientService_Factory() { return new StreamerClientService(i0.inject(i1.UserService), i0.inject(i2.HttpClient)); }, token: StreamerClientService, providedIn: "root" });
    return StreamerClientService;
}());
export { StreamerClientService };
