/*!
 * Copyright 2018 Screencastify LLC
 */

/*
 * Public API Surface of lib-editor
 */

// auth module
export * from './lib/auth/lib-auth.module';
export * from './lib/auth/auth.guard';
export * from './lib/auth/user.service';
export * from './lib/auth/components/sign-in/sign-in.component';

export * from './lib/common/lib-common.module';
export * from './lib/common/project.service';
export * from './lib/common/project.service.mock';
export * from './lib/common/undo-manager.service';
export * from './lib/common/gdrive.service';
export * from './lib/common/drive-picker.service';
export * from './lib/auth/license.service';
export * from './lib/common/ui-api.service';
export * from './lib/common/logger-adapter';

export * from './lib/crop-editor/lib-crop-editor.module';
export * from './lib/crop-editor/crop-editor-controller.service';

export * from './lib/preview/lib-preview.module';

export * from './lib/annotations-tool/lib-annotations-tool.module';
export * from './lib/annotations-tool/components/annotations-preview/annotations-preview-view.component';
export * from './lib/annotations-tool/annotations-tool-controller.service';

export * from './lib/timeline/lib-timeline.module';
export * from './lib/timeline/timeline-state.service';

export * from './lib/titlebar/lib-titlebar.module';

export * from './lib/toolbar/lib-toolbar.module';

export * from './lib/user-guide/lib-user-guide.module';

export * from './lib/zoom-editor/lib-zoom-editor.module';
export * from './lib/zoom-editor/components/zoom-toolbar/zoom-toolbar.component';

export * from './lib/user-guide/lib-user-guide.module';

export * from './lib/titlebar/lib-titlebar.module';

export * from './lib/sidenav/lib-sidenav.module';

export * from './lib/import-modals/import.module';
export * from './lib/import-modals/drive-import/drive-importer';

export * from './lib/tool-sidebar/tool-sidebar.module';
