/*!
 * Copyright 2018 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Rectangle } from '@castify/edit-models';
import { Size, unitLimit } from '@castify/models';
import { ShadowBoxComponent } from 'lib-editor/lib/tool-common/components/shadow-box/shadow-box.component';
import { DragHandleEvent } from 'lib-editor/lib/tool-common/directives/drag-handle.directive';
import {
  applyPosition,
  resizeUnitRect,
} from 'lib-editor/lib/tool-common/helpers';
import { Subscription } from 'rxjs';
import { CropEditorControllerService } from '../../crop-editor-controller.service';

const kCropRectMinDims = new Size(0.1, 0.1);
const keepOpenSelectors = [
  'lib-crop-editor',
  'lib-crop-toolbar',
  '#remove-button',
];

@Component({
  selector: 'lib-crop-editor',
  templateUrl: './crop-editor.component.html',
  styleUrls: ['./crop-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropEditorComponent implements OnInit, OnDestroy {
  @ViewChild('bounds') protected bounds: ElementRef<HTMLDivElement>;
  @ViewChild('resizeBox') protected resizeBox: ElementRef<HTMLDivElement>;
  @ViewChild(ShadowBoxComponent) shadowBox: ShadowBoxComponent;

  private _editStartRect: Rectangle;
  protected subscriptions = new Subscription();

  constructor(
    public ctrl: CropEditorControllerService,
    private hostElm: ElementRef<HTMLDivElement>
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.ctrl._cropRect.subscribe((crop) => this.updateResizeBox(crop))
    );
    this.handleWindowResize();
  }

  ngOnDestroy(): void {
    // remove all subscriptions when destroy
    this.subscriptions.unsubscribe();
  }

  onEditStart(): void {
    this._editStartRect = Rectangle.fromObj(this.ctrl._cropRect.value);
  }

  onMove(event: DragHandleEvent): void {
    if (!this._editStartRect) return;

    const hostRect = this.bounds.nativeElement.getBoundingClientRect();
    const shiftX = event.shiftX / hostRect.width;
    const shiftY = event.shiftY / hostRect.height;
    this.ctrl._setCropRect(
      new Rectangle(
        unitLimit.apply(this._editStartRect.left + shiftX),
        unitLimit.apply(this._editStartRect.top + shiftY),
        this._editStartRect.width,
        this._editStartRect.height
      )
    );
  }

  onResize(
    event: DragHandleEvent,
    topFixed: boolean,
    leftFixed: boolean
  ): void {
    if (!this._editStartRect) return;
    const hostRect = this.bounds.nativeElement.getBoundingClientRect();
    const resized = resizeUnitRect(
      Rectangle.fromObj(this._editStartRect),
      event.shiftX / hostRect.width,
      event.shiftY / hostRect.height,
      topFixed,
      leftFixed,
      kCropRectMinDims
    );
    this.ctrl._setCropRect(resized);
  }

  protected updateResizeBox(cropRect: Rectangle): void {
    if (!this.shadowBox || !this.resizeBox || !this.ctrl.clip.value) return;
    this.shadowBox.setCutout(cropRect);
    applyPosition(this.resizeBox, cropRect);
  }

  @HostListener('window:mousedown', ['$event'])
  closeEditor(event: MouseEvent) {
    const toolClicked = event.composedPath().some(
      (v: HTMLElement) =>
        v &&
        // check if tag name indicates it is part of the tool
        ((v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
          // check if element id indicates it is part of the tool
          (v.id && keepOpenSelectors.includes(`#${v.id}`)))
    );
    if (!toolClicked) {
      this.ctrl.save();
      this.ctrl.close();
    }
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    if (!this.bounds || !this.ctrl.clip.value) return;
    const hostDims = Size.fromObj(
      this.hostElm.nativeElement.getBoundingClientRect()
    );
    const videoDims = this.ctrl.clip.value.srcDimensions.scaleToFit(hostDims);
    applyPosition(this.bounds, {
      width: videoDims.width / hostDims.width,
      height: videoDims.height / hostDims.height,
    });
    this.updateResizeBox(this.ctrl._cropRect.value);
  }
}
