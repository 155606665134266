/*!
 * Copyright 2020 Screencastify LLC
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy } from '@angular/core';
import { SceneEditor2 } from '@castify/edit-models';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'environments/environment';
import { StatsHelperService } from 'lib-editor/lib/common/stats-helper.service';
import { Log } from 'ng2-logger/browser';
import { combineLatest, defer, from, Subscription } from 'rxjs';
import { filter, first, map, mergeMap, scan, shareReplay, startWith, switchMap, tap, toArray, } from 'rxjs/operators';
import { DrivePickerService } from '../../common/drive-picker.service';
import { ProjectService } from '../../common/project.service';
import { UiApiService } from '../../common/ui-api.service';
import { UndoManagerService } from '../../common/undo-manager.service';
import { TimelineStateService } from '../../timeline/timeline-state.service';
import { DriveImporter } from './drive-importer';
var DriveImportBrainService = /** @class */ (function () {
    function DriveImportBrainService(_uiApi, _project, _undoManager, _timelineState, _drivePicker, _angulartics, _statsHelper) {
        var _this = this;
        this._uiApi = _uiApi;
        this._project = _project;
        this._undoManager = _undoManager;
        this._timelineState = _timelineState;
        this._drivePicker = _drivePicker;
        this._angulartics = _angulartics;
        this._statsHelper = _statsHelper;
        this._log = Log.create('DriveImportBrainService');
        this.subscriptions = new Subscription();
        /**
         * Allows DriveImporter injection for testing
         */
        this._DriveImporter = DriveImporter;
        /**
         * Emits when we're ready to ask the user for files
         */
        this.readyToAskUserForFile = defer(function () { return _this._project.project; }).pipe(filter(function (project) { return !!project; }), 
        // prevents drive picker from opening again after starting a new project
        first());
        /**
         * Emits once the service knows what files should be uploaded
         */
        this.gotDriveFiles = defer(function () { return _this.readyToAskUserForFile; }).pipe(switchMap(function () { return _this.askUserForFiles(); }), shareReplay());
        /**
         * Emits if an import flow is cancelled by closing the picker,
         * (represented by the parent observable emitting null)
         */
        this.flowCancelled = defer(function () { return _this.gotDriveFiles; }).pipe(first(), filter(function (fileIds) { return !fileIds; }));
        /**
         * The number of files the user is importing
         */
        this.importCount = defer(function () { return _this.gotDriveFiles; }).pipe(first(), filter(function (fileIds) { return !!fileIds; }), map(function (fileIds) { return fileIds.length; }));
        /**
         * Emits file import instances, completes when all have been created
         */
        this._driveImporters = defer(function () {
            return combineLatest([
                _this._project.project.pipe(filter(function (v) { return !!v; }), first()),
                // go from array to observable
                _this.gotDriveFiles.pipe(first(), filter(function (fileIds) { return !!fileIds; }), switchMap(function (idsArray) { return from(idsArray); })),
            ]);
        }).pipe(map(function (_a) {
            var _b = __read(_a, 2), project = _b[0], importFile = _b[1];
            return new _this._DriveImporter(_this._uiApi, project.id, importFile);
        }), tap(function (driveFileId) {
            return _this._log.info('Import from Google Drive %s', driveFileId);
        }), shareReplay());
        /**
         * Averages progress of all imports
         */
        this.progress = defer(function () {
            return combineLatest([
                _this._driveImporters.pipe(toArray(), map(function (driveImporters) {
                    return driveImporters.map(function (driveImporter) { return driveImporter.progress; });
                }), switchMap(function (progressObservables) { return combineLatest(progressObservables); })),
                _this.importCount,
            ]);
        }).pipe(map(function (_a) {
            var _b = __read(_a, 2), progressArray = _b[0], importCount = _b[1];
            return progressArray.reduce(function (acc, p) { return acc + p; }, 0) / importCount;
        }));
        /**
         * Emits how many files have completed
         * and completes when all have completed
         */
        this.complete = defer(function () { return _this._driveImporters; }).pipe(
        // Counts the number of completions
        // (these 2 lines same as mergeScan but more readable)
        mergeMap(function (driveImporter) { return driveImporter.complete.pipe(map(function () { return 1; })); }), scan(function (acc, curr) { return acc + curr; }, 0), 
        // make sure it isn't null initially
        startWith(0), shareReplay());
        /**
         * Emits each file as it becomes ready for editing
         */
        this.userFilesReadyForEditing = defer(function () { return _this._driveImporters; }).pipe(mergeMap(function (driveImporter) { return driveImporter.complete; }));
    }
    DriveImportBrainService.prototype.init = function () {
        var _this = this;
        // Add clips to the timeline when import is complete
        this.subscriptions.add(this.userFilesReadyForEditing.subscribe(function (userFile) {
            return _this._addFileToTimeline(userFile);
        }));
    };
    DriveImportBrainService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * Opens the drive file picker.
     * `string[]` when the user picked files; `null` when they cancel.
     * Note that this may throw if there are auth issues with gdrive;
     * errors should be caught/handled in called
     */
    DriveImportBrainService.prototype.askUserForFiles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!environment.e2e) return [3 /*break*/, 1];
                        _a = environment.e2e.drivePickerFiles;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this._drivePicker.openPicker()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        result = _a;
                        return [2 /*return*/, result
                                ? result.map(function (file) { return ({ id: file.id, resourceKey: file.resourceKey }); })
                                : null];
                }
            });
        });
    };
    /**
     * Adds a file to the timeline
     */
    DriveImportBrainService.prototype._addFileToTimeline = function (file) {
        var scene = this._undoManager.scene.value;
        var newClips = new SceneEditor2(scene).addClipsFromFile(file);
        this._undoManager.update(scene);
        this._timelineState.selection.next(newClips);
        this._timelineState.scroll(-newClips[0].startInScene + this._timelineState.displayDuration.value / 10);
        this._log.data('File added to timeline %o', file.mediaInfo);
        if (file.mediaInfo) {
            this._trackMediaInfo(file.mediaInfo);
        }
    };
    DriveImportBrainService.prototype._trackMediaInfo = function (mediaInfo) {
        // track duration
        if (mediaInfo.duration) {
            this._angulartics.eventTrack('drive - duration', {
                label: this._statsHelper.getDurationBucket(mediaInfo.duration),
                category: 'import',
                value: mediaInfo.duration,
                noninteraction: true,
            });
        }
        // track size
        var bounds = this._statsHelper.getValueBucket(mediaInfo.size);
        this._angulartics.eventTrack('drive - size', {
            label: "between " + bounds[0] + "bytes-" + bounds[1] + "bytes",
            category: 'import',
            value: mediaInfo.size,
            noninteraction: false,
        });
        // track codecs
        if (mediaInfo.video) {
            this._angulartics.eventTrack('drive - video codec', {
                label: mediaInfo.video.codec,
                category: 'import',
                noninteraction: false,
            });
        }
        if (mediaInfo.audio) {
            this._angulartics.eventTrack('drive - audio codec', {
                label: mediaInfo.audio.codec,
                category: 'import',
                noninteraction: false,
            });
        }
    };
    return DriveImportBrainService;
}());
export { DriveImportBrainService };
