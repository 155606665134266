<div id="font-row">
  <button
    mat-button
    type="button"
    [matMenuTriggerFor]="fontSelector"
    aria-label="Horizontal alignment dropdown menu"
    [ngStyle] = "{
      fontFamily: fontDisplayName | async
    }"
    id="font-dropdown-button"
  >
    <span id="selected-font">{{ fontDisplayName | async }}</span>
  </button>
  <mat-menu
    #fontSelector="matMenu"
  >
    <button
      mat-menu-item
      type="button"
      *ngFor="let entry of editorFonts"
      (click)="setFont(entry[0])"
      [ngStyle] = "{
        fontFamily: entry[1]
      }"
    >
      {{ entry[1] }}
    </button>
  </mat-menu>


  <mat-form-field appearance="fill" color="primary" id="font-input-container">
    <input
      matInput
      type="number"
      [value]="size | async"
      [min]="minFontSize"
      [max]="maxFontSizeInclusive"
      (change)="setSize($event.target)"
  /></mat-form-field>
</div>

<div id="font-slider-container">
  <input
    type="range"
    class="slider"
    [value]="size | async"
    [min]="minFontSize"
    [max]="maxFontSizeInclusive"
    (input)="setSize($event.target)"
    [ngStyle]="sliderGradient | async"
  />
  <div class="slider-labels">
    <div class="small">Aa</div>
    <div class="big">Aa</div>
  </div>
</div>
