/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { SceneModel2, sty } from '@castify/edit-models';
import { Limits } from '@castify/models';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UndoManagerService } from '../common/undo-manager.service';

@Injectable({
  providedIn: 'root',
})
/**
 * NOTE: use public methods to change values rather then using properties directly
 */
export class PreviewStateService {
  // set by user (through undo manager): The scene that is currently reproduced by preview
  readonly scene = new BehaviorSubject<SceneModel2>(undefined);
  // set by preview: the actual playhead position. This gets periodical updates (not necessarily on every frame)
  readonly playhead = new BehaviorSubject<sty.Milliseconds>(0);
  // set by user: position that the preview should seek to
  readonly seekPos = new Subject<sty.Milliseconds>();
  // set by preview: indicates whether preview is actually playing
  readonly isPlaying = new BehaviorSubject<boolean>(false);
  // set by user: indicates whether playback is requested
  readonly shouldPlay = new BehaviorSubject<boolean>(false);
  // set by timeline indicates the seekable scene bounds
  readonly seekLimits = new BehaviorSubject<Limits>(
    new Limits(0, this.scene.value ? this.scene.value.duration : 0)
  );
  // set this to temporarily ignore updates from undo manager
  readonly overrideScene = new BehaviorSubject<SceneModel2 | null>(null);

  constructor(private undoManager: UndoManagerService) {
    combineLatest(this.undoManager.scene, this.overrideScene)
      .pipe(
        map(([scene, override]) => override || scene),
        map((scene) => scene.copy().removeRedundantCuts())
      )
      .subscribe((scene) => {
        // make sure playhead is in scene
        this.seek(new Limits(0, scene.duration).apply(this.playhead.value));
        this.scene.next(scene);
      });
  }

  /**
   * start playback from current playhead position
   */
  play() {
    this.shouldPlay.next(true);
  }

  /**
   * stop at current position
   */
  pause() {
    this.shouldPlay.next(false);
  }

  /**
   * seek to pos in scene
   *
   * @returns actual new seek pos
   */
  seek(pos: sty.Milliseconds): sty.Milliseconds {
    const seekPos = this.applySeekLimits(pos);
    this.seekPos.next(seekPos);
    return seekPos;
  }

  applySeekLimits(pos: sty.Milliseconds): sty.Milliseconds {
    const sceneBounds = this.seekLimits.value;
    return sceneBounds.apply(pos);
  }

  /**
   * set playhead position (should only be used from preview component)
   */
  setPlayhead(pos: sty.Milliseconds): void {
    this.playhead.next(pos);
  }
}
