/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { AsyncSubject, from } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { GapiLoaderService } from 'lib-editor/lib/common/gapi-loader.service';
import { Log } from 'ng2-logger/browser';
import { UserService } from '../auth/user.service';

const kDiscoveryDocs = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];

export interface OpenFromDriveData {
  userId: string;
  ids: string[];
  resourceKeys: ResourceKeyMap;
}

interface ResourceKeyMap {
  [id: string]: string | undefined;
}

const log = Log.create('GDriveService');

// TODO: this class does not seem to manage any state -> this probably doesn't need to be a service
@Injectable({ providedIn: 'root' })
export class GDriveService {
  protected _gapi = new AsyncSubject<any>();

  constructor(
    private user: UserService,
    private _gapiLoader: GapiLoaderService
  ) {
    // load client as soon as possible
    from(this.user.gapiAuth())
      .pipe(
        filter((auth) => !!auth),
        switchMap((auth) =>
          this._gapiLoader.loadClient(auth.access_token, kDiscoveryDocs)
        ),
        first()
      )
      .subscribe(this._gapi);
  }

  /**
   * Find folder or create it, if it does not exist yet.
   * @returns id of folder.
   */
  async getScreencastifyFolder(): Promise<string> {
    let folderId = environment.e2e
      ? environment.e2e.driveSCFolderId
      : await this.findScreencastifyFolder();
    if (!folderId) folderId = await this.createScreencastifyFolder();
    return folderId;
  }

  /**
   * tries to find the screencastify upload folder in users google drive
   * @returns file id of folder or null if fodler not found
   */
  async findScreencastifyFolder(): Promise<string | null> {
    const query =
      "mimeType='application/vnd.google-apps.folder' and " +
      'trashed=false and ' +
      "appProperties has { key='isScreencastifyUploadFolder' and value='true' }";

    const gapi = await this._gapi.toPromise();
    const resp = await gapi.client.drive.files.list({
      q: query,
      // fields: 'files(id, name, trashed, properties)',   // debug: get more information on files
      fields: 'files(id)',
      pageSize: 1,
    });
    return resp.result.files.length ? resp.result.files[0].id : null;
  }

  /**
   * creates a screencastify upload folder on the users google drive
   * @returns file id of new folder
   */
  async createScreencastifyFolder(): Promise<string> {
    log.i('creating Screencastify drive folder');

    const resp = await (await this._gapi.toPromise()).client.drive.files.create(
      {
        resource: {
          name: 'Screencastify',
          mimeType: 'application/vnd.google-apps.folder',
          description:
            'Screencastify uses this folder to store Screencasts that you upload. ' +
            'You may rename or move it, it will upload to this folder.',
          appProperties: [
            {
              isScreencastifyUploadFolder: 'true',
            },
          ],
        },
        fields: 'id',
      }
    );
    return resp.result.id;
  }

  /**
   * Get link to view file in drive UI.
   * @param fileId drive file id.
   * @returns url to view file in Drive UI.
   */
  getLink(fileId: string): string {
    return `https://drive.google.com/file/d/${fileId}/view`;
  }

  /**
   * Parse drive JSON state parameter
   * @param unparsedState json-serialized state string
   * @return null on failure.
   * (see https://developers.google.com/drive/v3/web/integrate-open)
   */
  parseOpenFromDriveData(unparsedState: string): OpenFromDriveData | null {
    let state: {
      ids: string[];
      action: 'open';
      userId: string;
      resourceKeys: ResourceKeyMap;
    };
    try {
      state = JSON.parse(unparsedState);
    } catch (_) {
      return null;
    }
    if (!state) return null;
    // validate types
    if (
      typeof state.userId !== 'string' ||
      typeof state.ids !== 'object' ||
      !state.ids.length
    )
      return null;
    if (state.ids.some((v) => typeof v !== 'string')) return null;
    return {
      userId: state.userId,
      ids: state.ids,
      resourceKeys: state.resourceKeys || {},
    };
  }

  async listRecordings(maxResults = 1000): Promise<Array<any>> {
    // let parentId = await this.getScreencastifyFolder();
    // '\'' + parentId + '\' in parents and ' +
    const query =
      'trashed = false and ' +
      "appProperties has { key='isScreencastifySyncFile' " +
      "and value='true'}";
    const response = await (
      await this._gapi.toPromise()
    ).client.drive.files.list({
      pageSize: 100,
      q: query,
      fields:
        'files(name, thumbnailLink, id, appProperties, createdTime, md5Checksum)',
      orderBy: 'createdTime desc',
    });
    return response.result.files;
  }
}
