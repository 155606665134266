/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./editor-logo.component";
var styles_EditorLogoComponent = [i0.styles];
var RenderType_EditorLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditorLogoComponent, data: {} });
export { RenderType_EditorLogoComponent as RenderType_EditorLogoComponent };
export function View_EditorLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/screencastify-arrow-white.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"]))], null, null); }
export function View_EditorLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-editor-logo", [], null, null, null, View_EditorLogoComponent_0, RenderType_EditorLogoComponent)), i1.ɵdid(1, 114688, null, 0, i2.EditorLogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorLogoComponentNgFactory = i1.ɵccf("lib-editor-logo", i2.EditorLogoComponent, View_EditorLogoComponent_Host_0, {}, {}, []);
export { EditorLogoComponentNgFactory as EditorLogoComponentNgFactory };
