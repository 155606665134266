/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  BlurBox,
  BlurEffect,
  Rectangle,
  Size,
  sty,
  ZoomEffect,
} from '@castify/edit-models';
import { VideoEffectViewComponent } from 'lib-editor/lib/preview/components/video-effect-view/video-effect-view.component';
import { applyPosition } from 'lib-editor/lib/tool-common/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-blur-view',
  templateUrl: './blur-view.component.html',
  styleUrls: ['./blur-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurViewComponent
  extends VideoEffectViewComponent<BlurEffect>
  implements OnDestroy {
  @ViewChild('bounds') protected bounds: ElementRef<HTMLDivElement>;
  @ViewChild('container') protected container: ElementRef<HTMLDivElement>;

  readonly _blurBoxes = new BehaviorSubject<BlurBox[]>([]);
  protected boxesSubscription: Subscription;
  readonly canPlay = new BehaviorSubject<boolean>(true);

  protected subscriptions = new Subscription();

  constructor(protected hostElm: ElementRef<HTMLElement>) {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  play(): void {
    // empty implementation:
    // playback relies on sync being called periodically. We don't need to track aplayback state
  }

  pause(): void {
    // empty implementation: see this.play()
  }

  seek(pos: number): void {
    if (!this._clip || !this._effect.value) return;
    const posInClip = pos + this._effect.value.startInClip;

    const rect = new Rectangle(0, 0, 1, 1);
    const crop = (this._clip.transform || {}).crop;
    if (crop) {
      const srcDims = this._clip.srcDimensions;
      rect.width *= srcDims.width / Math.max(crop.width, 1);
      rect.height *= srcDims.height / Math.max(crop.height, 1);
      rect.left -= crop.left / Math.max(crop.width, 1);
      rect.top -= crop.top / Math.max(crop.height, 1);
    }
    const zoom = <ZoomEffect>(
      [...this._clip.effects.getAllAtPos(posInClip)].find(
        (v) => v.type === 'zoom'
      )
    );
    if (zoom) {
      const posInZoom = posInClip - zoom.startInClip;
      const buildFactor =
        Math.min(posInZoom / (zoom.buildIn + Number.EPSILON), 1) -
        Math.max(
          (posInZoom - zoom.duration) / (zoom.buildOut + Number.EPSILON) + 1,
          0
        );
      const scale = (zoom.zoom - 1) * buildFactor + 1;
      rect.width *= scale;
      rect.height *= scale;
      rect.left *= scale;
      rect.left -= zoom.left * buildFactor * zoom.zoom;
      rect.top *= scale;
      rect.top -= zoom.top * buildFactor * zoom.zoom;
    }
    applyPosition(this.container, rect);
  }

  sync(refPos: sty.Milliseconds | null): sty.Milliseconds {
    this.seek(refPos);
    this._currentTime = refPos;
    return this.currentTime - refPos;
  }

  onDisplay(): void {
    this.updateDimensions();
    this.boxesSubscription = this._effect
      .pipe(map((v) => v.boxes))
      .subscribe(this._blurBoxes);
    this.subscriptions.add(this.boxesSubscription);
    super.onDisplay();
  }

  onHide(): void {
    if (this.boxesSubscription) this.boxesSubscription.unsubscribe();
  }

  trackByIndex(idx: number, _): number {
    return idx;
  }

  @HostListener('window:resize')
  updateDimensions(): void {
    if (!this.bounds) return;
    const hostDims = Size.fromObj(
      this.hostElm.nativeElement.getBoundingClientRect()
    );
    const videoDims = this._getVideoDims().scaleToFit(hostDims);
    applyPosition(this.bounds, {
      width: videoDims.width / hostDims.width,
      height: videoDims.height / hostDims.height,
    });
  }

  protected _getVideoDims(): Size {
    return this._clip.dimensions;
  }
}
