/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorLogoComponent } from './components/editor-logo/editor-logo.component';

const exportDeclarations = [EditorLogoComponent];

@NgModule({
  declarations: [...exportDeclarations],
  imports: [CommonModule],
  exports: exportDeclarations,
  providers: [DatePipe],
})
export class LibCommonModule {}
