/*!
 * Copyright 2020 Screencastify LLC
 */
import 'hammerjs';
import './fb-wrapper-config';
import './polyfills.ts';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
function bootstrap(AppModule) {
    return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
}
bootstrap(AppModule);
