/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { KeyboardService } from 'lib-editor/lib/keyboard-shortcuts/keyboard.service';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SideToolbarContentComponent } from '../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { InjectableToolbarComponent } from '../../../tool-sidebar/components/tool-sidebar/tool-sidebar.component';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';

@Component({
  selector: 'lib-blur-toolbar',
  templateUrl: './blur-toolbar.component.html',
  styleUrls: ['./blur-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurToolbarComponent
  implements InjectableToolbarComponent, OnDestroy {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;

  intensity = combineLatest([this.ctrl.effect, this.ctrl.selectedBox]).pipe(
    filter(([effect, _]) => !!effect && !!effect.boxes),
    filter(
      ([effect, sel]) => sel !== null && sel >= 0 && sel < effect.boxes.length
    ),
    map(([effect, sel]) => effect.boxes[sel].intensity)
  );

  /**
   * Manually control slider's color. material's native slider color control
   * was removed with `-webkit-appearance: none;`
   */
  sliderGradient = this.intensity.pipe(
    map((value) => {
      const leftColor = '#ff8282';
      const rightColor = '#fff';

      return {
        background: `linear-gradient(
          to right,
          ${leftColor} 0%,
          ${leftColor} ${value * 100}%,
          ${rightColor} ${value * 100}%,
          ${rightColor} 100%)`,
      };
    })
  );

  constructor(
    protected ctrl: BlurEditorControllerService,
    public readonly keyboardShortcuts: KeyboardService
  ) {}

  ngOnDestroy(): void {
    this.keyboardShortcuts.resumeAll();
  }

  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      await this._toolbarContainer.close();
    }
  }

  onAddClick(): void {
    this.ctrl.addBlurBox();
  }

  onIntensityChange(value: number): void {
    const boxIdx = this.ctrl.selectedBox.value;
    if (this._isValidBoxIdx(boxIdx)) {
      this.ctrl._editBlur((editor) => editor.setIntensity(boxIdx, value));
    }
  }

  /**
   * check if a box index represents a valid box selection
   */
  protected _isValidBoxIdx(boxIdx: number): boolean {
    return (
      boxIdx !== null &&
      boxIdx >= 0 &&
      boxIdx < this.ctrl.effect.value.boxes.length
    );
  }

  closeEditor(): void {
    this.ctrl.save();
    this.ctrl.close();
  }
}
