/*!
 * Copyright 2020 Screencastify LLC
 */
import { Limits, Rectangle, Size } from '@castify/models';
var Point = /** @class */ (function () {
    function Point(x, y) {
        this.x = x;
        this.y = y;
    }
    /**
     * This function rotates the current point around a given centerpoint
     * @param center center point to rotate current point around
     * @param angle angle of rotation to rotate point
     * @returns new point with rotated co-ordinates
     */
    Point.prototype.rotateClockwise = function (center, angle) {
        // Make angle negative to rotate clockwise
        var cosT = Math.cos(angle);
        var sinT = Math.sin(angle);
        // Translate point to orgin
        var originX = this.x - center.x;
        var originY = this.y - center.y;
        // Rotate point around origin
        var rotatedX = originX * cosT - originY * sinT;
        var rotatedY = originX * sinT + originY * cosT;
        // Translate the point back to where it was
        return new Point(rotatedX + center.x, rotatedY + center.y);
    };
    return Point;
}());
export { Point };
var RotatedRect = /** @class */ (function () {
    function RotatedRect(fixedCorner, unfixedCorner) {
        this.fixedCorner = fixedCorner;
        this.unfixedCorner = unfixedCorner;
    }
    Object.defineProperty(RotatedRect.prototype, "topLeft", {
        /**
         * Grabs the point on the rectangle closest to the origin of our dom
         */
        get: function () {
            return new Point(this.fixedCorner.x < this.unfixedCorner.x
                ? this.fixedCorner.x
                : this.unfixedCorner.x, this.fixedCorner.y < this.unfixedCorner.y
                ? this.fixedCorner.y
                : this.unfixedCorner.y);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RotatedRect.prototype, "bottomRight", {
        /**
         * Grabs the point on the rectangle furthest from the origin of our dom
         */
        get: function () {
            return new Point(this.fixedCorner.x > this.unfixedCorner.x
                ? this.fixedCorner.x
                : this.unfixedCorner.x, this.fixedCorner.y > this.unfixedCorner.y
                ? this.fixedCorner.y
                : this.unfixedCorner.y);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RotatedRect.prototype, "width", {
        /**
         * Gets the width of our unrotated rectangle
         */
        get: function () {
            return this.bottomRight.x - this.topLeft.x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RotatedRect.prototype, "height", {
        /**
         * Gets the height of our unrotated rectangle
         */
        get: function () {
            return this.bottomRight.y - this.topLeft.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RotatedRect.prototype, "center", {
        /**
         * The center of the rectangle
         */
        get: function () {
            return new Point((this.unfixedCorner.x + this.fixedCorner.x) / 2, (this.unfixedCorner.y + this.fixedCorner.y) / 2);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This function takes a width and height and reduces the values within our rectangle to a more managable state.
     * @param widthChange width of the canvas for our rect to be reduced to
     * @param heightChange height of our canvas for our rect to be reduced to
     */
    RotatedRect.prototype.normalizeValues = function (widthChange, heightChange) {
        this.fixedCorner.x /= widthChange;
        this.fixedCorner.y /= heightChange;
        this.unfixedCorner.x /= widthChange;
        this.unfixedCorner.y /= heightChange;
    };
    /**
     * This function creates a new rectangle with its points rotated around the centerpoint
     * @param theta intensity of our rotation
     * @returns Rotated version of this rectangle
     */
    RotatedRect.prototype.rotate = function (theta) {
        return new RotatedRect(this.fixedCorner.rotateClockwise(this.center, theta), this.unfixedCorner.rotateClockwise(this.center, theta));
    };
    /**
     * This function takes the current rectangle and converts it into a unit rectangle, that way we can save it.
     * @param minDims Smallest possible dimensions for our new rect
     * @returns Rectangle ready for being persisted to our DB
     */
    RotatedRect.prototype.convertToUnitRect = function (minDims) {
        if (minDims === void 0) { minDims = new Size(0, 0); }
        // Adjust top-left corner
        var newTop = new Limits(-this.height / 2, 1 - this.height / 2).apply(this.topLeft.y);
        var newLeft = new Limits(-this.width / 2, 1 - this.width / 2).apply(this.topLeft.x);
        // Calculate and adjust box size according to limits
        var newWidth = new Limits(minDims.width, 1).apply(this.width);
        var newHeight = new Limits(minDims.height, 1).apply(this.height);
        return new Rectangle(newLeft, newTop, newWidth, newHeight);
    };
    return RotatedRect;
}());
export { RotatedRect };
