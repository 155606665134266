/*!
 * Copyright 2019 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  ClipModel2,
  SceneEditor2,
  sty,
  VideoClip,
  ZoomEffect,
} from '@castify/edit-models';
import { Limits } from '@castify/models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../timeline/timeline-state.service';

const log = Log.create('ZoomEditorControllerService');

export interface IZoomBox {
  left: number; // 0...1
  top: number; // 0...1
  zoom: number; // 1...[upper bound]
}

@Injectable({
  providedIn: 'root',
})
export class ZoomEditorControllerService extends SingleClipEditorControllerService {
  zoomBox = new BehaviorSubject<IZoomBox>({ left: 0, top: 0, zoom: 1 });
  buildIn = new BehaviorSubject<number>(null);
  buildOut = new BehaviorSubject<number>(null);
  get effectDuration(): sty.Milliseconds {
    if (!this._editingData) return undefined;
    return this._editingData.effect.duration;
  }

  private _zoomSelection = new BehaviorSubject<EffectSelection>(undefined);
  private _editingData: {
    clip: VideoClip;
    effect: ZoomEffect;
    playhead: sty.Milliseconds;
  };

  constructor(
    protected timelineState: TimelineStateService,
    private undoManager: UndoManagerService,
    protected previewState: PreviewStateService
  ) {
    super(timelineState, previewState);

    // filter selection for zoom effect selected
    this.timelineState.selection
      .pipe(map((sel) => this.filterZoomSelection(sel)))
      .subscribe(this._zoomSelection);

    // sync clip from selection
    this.timelineState.selection
      .pipe(
        map((sel) => {
          const zoomSelection = this.filterZoomSelection(sel);
          if (!!zoomSelection) return zoomSelection.clip;
          const clip = sel.find((s) => s instanceof VideoClip);
          if (clip) return clip;
          return null;
        })
      )
      .subscribe(this.clip);

    // sync canActivate
    combineLatest([this.clip, this.isActive])
      .pipe(map(([clip, isActive]) => !!clip && !isActive))
      .subscribe(this.canActivate);
  }

  applyZoomLimits(zoom: number): number {
    return new Limits(1, 10).apply(zoom);
  }

  setZoomBox(box: IZoomBox) {
    // TODO: this should be done in VideoEditor
    // enforce limits
    box.zoom = this.applyZoomLimits(box.zoom);
    box.top = new Limits(0, 1 - 1 / box.zoom).apply(box.top);
    box.left = new Limits(0, 1 - 1 / box.zoom).apply(box.left);
    // update zoom box
    this.zoomBox.next(box);
  }

  open() {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening zoom editor not fulfilled');

    const clip = this.clip.value;
    if (!clip) throw new Error('Failed to open zoom editor: no clip selected');

    // determine value for this._editingData
    if (!!this._zoomSelection.value) {
      // edit existing clip
      const selection = this._zoomSelection.value;
      this._editingData = {
        clip: selection.clip,
        effect: <ZoomEffect>selection.effect,
        playhead: this.previewState.playhead.value,
      };
    } else {
      const scene = this.undoManager.scene.value;
      // add new clip
      const newEffect = new SceneEditor2(scene)
        .getClipEditor(clip)
        .addZoom(this.previewState.playhead.value - clip.startInScene, 4000, {
          left: 0.25,
          top: 0.25,
          zoom: 2,
          buildIn: 500,
          buildOut: 500,
        });
      // save clip
      if (!!newEffect) {
        this.undoManager.update(scene, 'add zoom');
      } else {
        throw Error('zoom could not be added');
      }
      // update properties
      this._editingData = {
        clip,
        playhead: this.previewState.playhead.value,
        effect: newEffect,
      };
      this.timelineState.selection.next([
        new EffectSelection({ clip, effect: newEffect }),
      ]);
    }

    // set override scene
    const scene = this.undoManager.scene.value.filterClipType(['video']).copy(); // remove text clips
    const overrideClip = <VideoClip>scene.clips.byId(this._editingData.clip.id);
    overrideClip.effects = overrideClip.effects.filterType(['blur']);
    this.previewState.overrideScene.next(scene);

    // init editor
    this.zoomBox.next({
      left: this._editingData.effect.left,
      top: this._editingData.effect.top,
      zoom: this._editingData.effect.zoom,
    });
    this.buildIn.next(this._editingData.effect.buildIn);
    this.buildOut.next(this._editingData.effect.buildOut);

    super.open();
  }

  close() {
    super.close();
    this._editingData = undefined;
  }

  save() {
    if (!this._editingData) return;

    const zoomChanges = {
      left: this.zoomBox.value.left,
      top: this.zoomBox.value.top,
      zoom: this.zoomBox.value.zoom,
      buildIn: this.buildIn.value,
      buildOut: this.buildOut.value,
    };
    const scene = this.undoManager.scene.value;
    // manipulate existing effect
    if (
      zoomChanges.buildIn !== this._editingData.effect.buildIn ||
      zoomChanges.buildOut !== this._editingData.effect.buildOut ||
      zoomChanges.left !== this._editingData.effect.left ||
      zoomChanges.top !== this._editingData.effect.top ||
      zoomChanges.zoom !== this._editingData.effect.zoom
    ) {
      new SceneEditor2(scene)
        .getClipEditor(this._editingData.clip)
        .changeZoom(this._editingData.effect, zoomChanges);
      this.undoManager.update(scene, 'change zoom');
      log.info('zoom saved');
    }
  }

  protected filterZoomSelection(
    selection: (ClipModel2 | EffectSelection)[]
  ): EffectSelection | null {
    return (
      <EffectSelection>(
        selection.find(
          (s) =>
            s instanceof EffectSelection &&
            !!s.clip &&
            s.effect instanceof ZoomEffect
        )
      ) || null
    );
  }

  protected get _effectStart(): sty.Milliseconds | undefined {
    return !!this._editingData
      ? this._editingData.playhead -
          this._editingData.clip.startInFile -
          this._editingData.clip.startInFile
      : undefined;
  }
}
