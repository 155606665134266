import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Base class for the services of editors
 */
var EditorControllerService = /** @class */ (function () {
    function EditorControllerService() {
        this.isActive = new BehaviorSubject(false);
        this.canActivate = new BehaviorSubject(false);
    }
    /**
     * activates editor the editor
     */
    EditorControllerService.prototype.open = function () {
        this.isActive.next(true);
    };
    /**
     * saves current edit
     */
    EditorControllerService.prototype.save = function () {
        throw new Error('not implemented');
    };
    /**
     * Deactivates the editor. Intended to be overridden
     * and then called via super.close() in subclasses
     */
    EditorControllerService.prototype.close = function () {
        this.isActive.next(false);
    };
    EditorControllerService.ngInjectableDef = i0.defineInjectable({ factory: function EditorControllerService_Factory() { return new EditorControllerService(); }, token: EditorControllerService, providedIn: "root" });
    return EditorControllerService;
}());
export { EditorControllerService };
