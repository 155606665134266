/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { TextBoxEditBorderComponent } from './text-box-edit-border.component';
var TextBoxEditRotateBorderComponent = /** @class */ (function (_super) {
    __extends(TextBoxEditRotateBorderComponent, _super);
    function TextBoxEditRotateBorderComponent(ctrl) {
        var _this = _super.call(this, ctrl) || this;
        _this.ctrl = ctrl;
        _this.showGreyBox = combineLatest([
            _this.ctrl.grayBoxRotation,
            _this._isSelected,
        ]).pipe(map(function (_a) {
            var _b = __read(_a, 2), rotation = _b[0], isSelected = _b[1];
            if (rotation === null)
                return false;
            return !!isSelected;
        }));
        return _this;
    }
    return TextBoxEditRotateBorderComponent;
}(TextBoxEditBorderComponent));
export { TextBoxEditRotateBorderComponent };
