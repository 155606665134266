/*!
 * Copyright 2020 Screencastify LLC
 */

import { FisUserFile, IImportResult } from '@castify/edit-models';
import { defer, Observable, of } from 'rxjs';
import {
  endWith,
  last,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import { UiApiService } from '../../common/ui-api.service';
import { DriveImportFile } from './drive-import-brain.service';

export class DriveImporter {
  /**
   * Kicks off import requests
   */
  _gotUserFileId = defer(() => of(this.driveImportFile)).pipe(
    // kick off import via the api
    switchMap((driveImportFile) =>
      this._importFile(this.projectId, driveImportFile)
    ),

    // grab the user file id from the api response
    map((apiResponse) => apiResponse.file.id),

    // multicast
    shareReplay()
  );

  /**
   * Emits userFile when we have it
   */
  _userFileProgress = defer(() => this._gotUserFileId).pipe(
    // subscribe to progress updates from the task
    // and complete on task completion
    map((fisUserFileId) => this._getUserFileById(fisUserFileId)),
    switchMap((userFile) =>
      userFile
        .onProgress()
        .pipe(
          takeWhile((file) => !file.mediaInfo || !file.sourceFileLoaded, true)
        )
    )
  );

  /**
   * Observable of progress updates
   */
  progress = defer(() => this._userFileProgress).pipe(
    map((fisUserFile) => fisUserFile.progress),
    startWith(0),
    endWith(1)
  );

  /**
   * Emits underlying userFile on completion for use in project
   * Can also be used to check for completion of import for other reasons
   */
  complete = defer(() => this._userFileProgress.pipe(last()));

  constructor(
    private _uiApiService: UiApiService,
    private projectId: string,
    private driveImportFile: DriveImportFile
  ) {}

  /**
   * Kicks off an import via the edit API
   */
  _importFile(
    projectId: string,
    driveImportFile: DriveImportFile
  ): Observable<IImportResult> {
    return this._uiApiService.importGoogleDrive({
      projectId,
      driveFileId: driveImportFile.id,
      resourceKey: driveImportFile.resourceKey,
    });
  }

  /**
   * Gets userFile from firestore by ID
   * (exists to facilitate unit testing)
   */
  _getUserFileById(fisUserFileId: string): FisUserFile {
    return FisUserFile.byId<FisUserFile>(fisUserFileId);
  }
}
