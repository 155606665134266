/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tool-sidebar-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tool-sidebar-content.component";
var styles_SideToolbarContentComponent = [i0.styles];
var RenderType_SideToolbarContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideToolbarContentComponent, data: { "animation": [{ type: 7, name: "open", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, timings: 300 }], options: null }], options: {} }, { type: 7, name: "close", definitions: [{ type: 1, expr: "false => true", animation: [{ type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_SideToolbarContentComponent as RenderType_SideToolbarContentComponent };
export function View_SideToolbarContentComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "container"]], [[24, "@open", 0], [24, "@close", 0]], [[null, "@open.start"], [null, "@close.done"], [null, "@close.start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@open.start" === en)) {
        var pd_0 = (_co.onOpenAnimationDone() !== false);
        ad = (pd_0 && ad);
    } if (("@close.done" === en)) {
        var pd_1 = (_co.onCloseAnimationDone($event) !== false);
        ad = (pd_1 && ad);
    } if (("@close.start" === en)) {
        var pd_2 = (_co.onCloseAnimationStart() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = (i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 1).transform(_co.triggerCloseAnimation)) || false); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SideToolbarContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-tool-sidebar-content", [], null, null, null, View_SideToolbarContentComponent_0, RenderType_SideToolbarContentComponent)), i1.ɵdid(1, 49152, null, 0, i3.SideToolbarContentComponent, [], null, null)], null, null); }
var SideToolbarContentComponentNgFactory = i1.ɵccf("lib-tool-sidebar-content", i3.SideToolbarContentComponent, View_SideToolbarContentComponent_Host_0, {}, {}, ["*"]);
export { SideToolbarContentComponentNgFactory as SideToolbarContentComponentNgFactory };
