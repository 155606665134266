/*!
 * Copyright 2020 Screencastify LLC
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../auth/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/user.service";
import * as i2 from "@angular/common/http";
var UiApiService = /** @class */ (function () {
    function UiApiService(userService, http) {
        this.userService = userService;
        this.http = http;
    }
    // auth
    UiApiService.prototype.authSyncLicense = function () {
        return this._postRequest(environment.uiApiUrl + "/client-auth/sync-license");
    };
    // logging
    UiApiService.prototype.postAuthLogs = function (payload) {
        return this._postRequest(environment.uiApiUrl + "/log", payload);
    };
    UiApiService.prototype.postUnauthLogs = function (payload) {
        return this.http.post(environment.uiApiUrl + "/log/unauthenticated", payload, {
            headers: { auth: 'false' },
        });
    };
    // export
    UiApiService.prototype.exportDownload = function (options) {
        return this._postRequest(environment.uiApiUrl + "/export/download", options);
    };
    UiApiService.prototype.exportGoogleDrive = function (options) {
        return this._postRequest(environment.uiApiUrl + "/export/google-drive", options);
    };
    // import
    UiApiService.prototype.importGoogleDrive = function (options) {
        return this._postRequest(environment.uiApiUrl + "/import/google-drive", options);
    };
    UiApiService.prototype.initUpload = function (options) {
        return this._postRequest(environment.uiApiUrl + "/import/upload/init", options);
    };
    UiApiService.prototype.uploadImport = function (options) {
        return this._postRequest(environment.uiApiUrl + "/import/upload/start", options);
    };
    // video registry
    UiApiService.prototype.setDefaultVideoRegistryPrivacy = function (option) {
        return this._postRequest(environment.uiApiUrl + "/video-registry", option);
    };
    UiApiService.prototype._getRequest = function (url, urlParams) {
        var _this = this;
        return from(this.userService.currentUser.getIdToken()).pipe(switchMap(function (authToken) { return _this.http.get(url, {
            responseType: 'json',
            headers: new HttpHeaders({
                authorization: "Bearer " + authToken,
            }),
            params: urlParams,
        }); }));
    };
    UiApiService.prototype._postRequest = function (url, data, urlParams) {
        var _this = this;
        if (data === void 0) { data = {}; }
        return from(this.userService.currentUser.getIdToken()).pipe(switchMap(function (authToken) { return _this.http.post(url, data, {
            responseType: 'json',
            headers: new HttpHeaders({
                authorization: "Bearer " + authToken,
            }),
            params: urlParams,
        }); }));
    };
    UiApiService.ngInjectableDef = i0.defineInjectable({ factory: function UiApiService_Factory() { return new UiApiService(i0.inject(i1.UserService), i0.inject(i2.HttpClient)); }, token: UiApiService, providedIn: "root" });
    return UiApiService;
}());
export { UiApiService };
