<div
  class="ng-content"
  [ngStyle]="{ zIndex: (_isSelected | async) ? '+2' : '+0' }"
>
  <ng-content></ng-content>
</div>

<div class="pink-rotate-handle"
  *ngIf="(_isSelected | async)"
  (dragStart)="onRotateStart($event)"
  (dragMove)="onRotate($event)"
  (dragEnd)="onRotateEnd($event)"
  libDragHandle
  [ngStyle]="{
    display: (this.ctrl.grayBoxRotation | async) === null ? 'block' : 'none'
  }"
  >
  <div
    class="rotate-knob"
  ></div>
</div>

<div id="draggable-border">
  <div
    *ngIf="!(_isSelected | async)"
    class="unselected-border"
    (dragStart)="onMoveStart()"
    (dragMove)="onMove($event)"
    (dragEnd)="onMoveEnd()"
    libDragHandle
  ></div>

  <div
    *ngIf="_isSelected | async"
    class="selected-border"
    (dragStart)="onMoveStart()"
    (dragMove)="onMove($event)"
    (dragEnd)="onMoveEnd()"
    libDragHandle
  ></div>

  <div *ngIf="_isSelected | async" id="drag-handle-container">
    <div
      class="top-left-resizer"
      (dragStart)="onMoveStart()"
      (dragMove)="onCornerResize($event, 'northwest')"
      (dragEnd)="onMoveEnd()"
      libDragHandle
    ></div>
    <div
      class="top-right-resizer"
      (dragStart)="onMoveStart()"
      (dragMove)="onCornerResize($event, 'northeast')"
      (dragEnd)="onMoveEnd()"
      libDragHandle
    ></div>
    <div
      class="bottom-left-resizer"
      (dragStart)="onMoveStart()"
      (dragMove)="onCornerResize($event, 'southwest')"
      (dragEnd)="onMoveEnd()"
      libDragHandle
    ></div>
    <div
      class="bottom-right-resizer"
      (dragStart)="onMoveStart()"
      (dragMove)="onCornerResize($event, 'southeast')"
      (dragEnd)="onMoveEnd()"
      libDragHandle
    ></div>
  </div>
</div>
