/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2019 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-clip-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./video-clip-view.component";
import * as i3 from "../../seek-data-collector.service";
import * as i4 from "../../streamer-client.service";
import * as i5 from "@angular/common/http";
var styles_VideoClipViewComponent = [i0.styles];
var RenderType_VideoClipViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoClipViewComponent, data: {} });
export { RenderType_VideoClipViewComponent as RenderType_VideoClipViewComponent };
export function View_VideoClipViewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { _videoT: 0 }), i1.ɵqud(402653184, 2, { _videoF: 0 }), (_l()(), i1.ɵeld(2, 0, [["container", 1]], null, 2, "div", [["id", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["videoT", 1]], null, 0, "video", [["class", "hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[2, 0], ["videoF", 1]], null, 0, "video", [["class", "hidden"]], null, null, null, null, null))], null, null); }
export function View_VideoClipViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-video-clip-view", [], null, null, null, View_VideoClipViewComponent_0, RenderType_VideoClipViewComponent)), i1.ɵdid(1, 245760, null, 0, i2.VideoClipViewComponent, [i3.SeekDataCollectorService, i1.Renderer2, i4.StreamerClientService, i5.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoClipViewComponentNgFactory = i1.ɵccf("lib-video-clip-view", i2.VideoClipViewComponent, View_VideoClipViewComponent_Host_0, {}, {}, []);
export { VideoClipViewComponentNgFactory as VideoClipViewComponentNgFactory };
