/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AudioClip,
  ClipModel2,
  kMinClipDuration,
  SceneModel2,
  StillClip,
  sty,
  VideoClip,
} from '@castify/edit-models';
import { AnnotationsClip } from '@castify/edit-models';
import { Limits } from '@castify/models';
import * as Hammer from 'hammerjs';
import { IToolSidebarData } from 'lib-editor/lib/common/single-clip-editor-controller.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PreviewStateService } from '../../../preview/preview-state.service';
import { TimelineStateService } from '../../timeline-state.service';
import { TimelineOverlayComponent } from '../timeline-overlay/timeline-overlay.component';

// factor by which the deltaY value from scroll event is multiplied to determine zoom change
const kZoomSpeed = 1 / 250;
// value which is skipped when arrowkeys are pressed (this should be 40 minimally since thats one frame)
const kArrowTimeSkip = 40;

@Component({
  selector: 'lib-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // NOTE: automatic change detection disabled
})
export class TimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TimelineOverlayComponent) overlay: TimelineOverlayComponent;

  clips = {
    videoAndAudio: new BehaviorSubject<
      { video: VideoClip; audio: AudioClip }[]
    >([]),
    annotations: new BehaviorSubject<AnnotationsClip[]>([]),
    still: new BehaviorSubject<StillClip[]>([]),
  };
  private lastDeltaTime = 0;
  protected subscriptions = new Subscription();

  constructor(
    public timelineState: TimelineStateService,
    public previewState: PreviewStateService,
    private hostElm: ElementRef<HTMLDivElement>
  ) {
    this.subscriptions.add(
      this.timelineState.zoomFactor.subscribe(() =>
        this.updateDisplayDuration()
      )
    );
  }

  private filterVideoAndAudioClips(
    scene: SceneModel2
  ): { video: VideoClip; audio: AudioClip }[] {
    const videoClips = scene.filterClipType(['video']).clips.items;
    return videoClips.map((videoClip: VideoClip) => {
      const audioClip = <AudioClip>(
        scene
          .filterClipType(['audio'])
          .clips.filterByInsertId(videoClip.insertId)[0]
      );
      return {
        video: videoClip,
        audio: audioClip,
      };
    });
  }

  ngOnInit() {
    const hm = new Hammer(this.hostElm.nativeElement, {});
    hm.add(
      new Hammer.Pan({
        event: 'twoFingersPan',
        pointers: 2,
        threshold: 0,
        direction: Hammer.DIRECTION_HORIZONTAL,
      })
    );
    hm.on('twoFingersPan', (event) => {
      this.timelineState.scroll(
        this.timelineState.startTime.value +
          this.timelineState.pixelsToTime(
            event.velocity * (event.deltaTime - this.lastDeltaTime)
          )
      );

      this.lastDeltaTime = event.deltaTime;
      if (event.isFinal) this.lastDeltaTime = 0;
    });

    // split clip types
    this.subscriptions.add(
      this.timelineState.scene
        .pipe(
          filter((scene) => !!scene),
          map((scene) => this.filterVideoAndAudioClips(scene))
        )
        .subscribe(this.clips.videoAndAudio)
    );
    this.subscriptions.add(
      this.timelineState.scene
        .pipe(
          filter((scene) => !!scene),
          map((scene) => scene.filterClipType(['annotations']).clips.items),
          distinctUntilChanged(),
          map((v) => v.map((c) => c.copy()))
        )
        .subscribe(this.clips.annotations)
    );
    this.subscriptions.add(
      this.timelineState.scene
        .pipe(
          filter((scene) => !!scene),
          map((scene) => scene.filterClipType(['still']).clips.items),
          distinctUntilChanged(),
          map((v) => v.map((c) => c.copy()))
        )
        .subscribe(this.clips.still)
    );

    // scale background grid
    this.subscriptions.add(
      this.timelineState.zoomFactor.subscribe((zoomFactor) => {
        const pixelsPerSecond = 1000 * zoomFactor;
        this.hostElm.nativeElement.style.setProperty(
          'background-size',
          `${pixelsPerSecond}px`
        );
      })
    );
    this.subscriptions.add(
      this.timelineState.startTime.subscribe((startTime) => {
        this.hostElm.nativeElement.style.setProperty(
          'background-position',
          `${this.timelineState.timeToPixels(startTime)}px`
        );
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.previewState.scene,
        this.timelineState.toolSidebarData,
      ])
        .pipe(
          map(
            ([scene, toolSidebarData]: [SceneModel2, IToolSidebarData]) =>
              <[SceneModel2, boolean, VideoClip]>[
                scene,
                !!toolSidebarData,
                !!toolSidebarData ? toolSidebarData.clip : null,
              ]
          )
        )
        .subscribe(([scene, active, clip]) => {
          if (active) {
            this.overlay.show();
            // max limit is set to last frame -1. Same reason as for last frame in PreviewComponent
            this.previewState.seekLimits.next(
              new Limits(
                Math.max(0, clip.startInScene),
                clip.endInScene - kMinClipDuration - 1
              )
            );
          } else {
            this.overlay.hide();
            this.previewState.seekLimits.next(
              new Limits(0, scene ? scene.duration : 0)
            );
          }
        })
    );

    this.subscriptions.add(
      this.clips.annotations
        .pipe(
          map((clips) => !!clips.length),
          distinctUntilChanged()
        )
        .subscribe((hasText) => {
          if (hasText) {
            this.hostElm.nativeElement.classList.add('has-text');
            window.dispatchEvent(new Event('resize'));
          } else {
            this.hostElm.nativeElement.classList.remove('has-text');
            window.dispatchEvent(new Event('resize'));
          }
        })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => this.updateDisplayDuration(), 1);
  }

  get playheadCursorOffset(): Observable<sty.Pixels | null> {
    return combineLatest([
      this.timelineState.startTime,
      this.timelineState.zoomFactor,
      this.timelineState.playheadCursor,
    ]).pipe(
      map(([startTime, _, playheadPos]) => {
        return this.timelineState.timeToPixels(playheadPos + startTime);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get scrubCursorOffset(): Observable<sty.Pixels | null> {
    return combineLatest([
      this.timelineState.startTime,
      this.timelineState.zoomFactor,
      this.timelineState.scrubCursor,
      this.timelineState.overrideCursor,
    ]).pipe(
      map(([startTime, _, scrubPos, overridePos]) => {
        if (scrubPos === null || overridePos !== null) {
          return null;
        } else {
          return this.timelineState.timeToPixels(scrubPos + startTime);
        }
      })
    );
  }

  @HostListener('window:resize')
  updateDisplayDuration(): void {
    this.timelineState.displayDuration.next(
      this.timelineState.pixelsToTime(this.hostElm.nativeElement.offsetWidth)
    );
  }

  /**
   * handle scrolling and zooming from scroll wheel
   */
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): boolean {
    if (event.ctrlKey) {
      // zoom
      const oldZoomFactor = this.timelineState.zoomFactor.value;
      const oldMouseOffsetInScene =
        this.hostElm.nativeElement.scrollLeft + event.x;
      // apply zoom (updates millisPerPixel synchronously
      const zoomFactor = this.timelineState.zoomFactor.value;
      this.timelineState.setZoom(
        zoomFactor - zoomFactor * event.deltaY * kZoomSpeed
      );
      // adjust scroll to zoom around mouse position
      const zoomFactorChange =
        this.timelineState.zoomFactor.value / oldZoomFactor;
      // calculate leftmost point of the timeline that gets displayed (= scroll position)
      const scrollPixels = oldMouseOffsetInScene * zoomFactorChange - event.x;
      this.timelineState.scroll(
        this.timelineState.startTime.value -
          this.timelineState.pixelsToTime(scrollPixels)
      );
      this.updateDisplayDuration();
    } else {
      // scroll
      // deltaX is for vertical scrolling on touchpads
      const scrollPixels = event.deltaX || event.deltaY;
      this.timelineState.scroll(
        this.timelineState.startTime.value -
          this.timelineState.pixelsToTime(scrollPixels)
      );
    }
    return false; // don't propagate the event further to avoid zooming in on the page
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (
      !this.previewState.isPlaying.value &&
      !this.previewState.shouldPlay.value
    ) {
      // scrub
      const cursorOffsetLeft = event.x + this.hostElm.nativeElement.scrollLeft;
      this.timelineState.scrub(
        this.timelineState.pixelsToTime(cursorOffsetLeft) -
          this.timelineState.startTime.value
      );
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const continuePlaying =
      this.previewState.isPlaying.value && this.previewState.shouldPlay.value;
    this.previewState.pause();
    const seekPosition =
      this.timelineState.pixelsToTime(
        event.x + this.hostElm.nativeElement.scrollLeft
      ) - this.timelineState.startTime.value;
    // seek to where the click occurred
    // update timeline
    this.timelineState.seek(seekPosition);
    // update preview
    this.previewState.seek(seekPosition);
    // clear selection if no clip was hit
    if (event.target === this.hostElm.nativeElement)
      this.timelineState.selection.next([]);
    if (continuePlaying) {
      this.previewState.play();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.timelineState.endScrub();
  }

  seekFrame(frames: number = 1): void {
    if (
      !this.previewState.shouldPlay.value &&
      !this.previewState.isPlaying.value
    ) {
      const skip = frames * kArrowTimeSkip;
      const seekPosition = this.timelineState.playheadCursor.value + skip;
      this.timelineState.seek(seekPosition);
      this.previewState.seek(seekPosition);
    }
  }

  videoAudioTrackBy(
    _,
    videoAndAudio: { video: VideoClip; audio: AudioClip | null }
  ): string {
    return (
      videoAndAudio.video.id +
      (videoAndAudio.audio ? videoAndAudio.audio.id : '')
    );
  }

  clipTrackBy(_, clip: ClipModel2): string {
    return clip.id;
  }
}
