  <div id="button-row">
    <button
      mat-button
      type="button"
      (click)="toggleBold()"
      [class.selected]="bold | async"
    >
      <mat-icon
        svgIcon="bold"
        aria-hidden="false"
        aria-label="Bold text"
      ></mat-icon>
    </button>

    <button
      mat-button
      type="button"
      (click)="toggleItalics()"
      [class.selected]="italic | async"
    >
      <mat-icon
        svgIcon="italic"
        aria-hidden="false"
        aria-label="Italicize text"
      ></mat-icon>
    </button>

    <button
      mat-button
      type="button"
      (click)="toggleUnderline()"
      [class.selected]="underline | async"
    >
      <mat-icon
        svgIcon="underline"
        aria-hidden="false"
        aria-label="Underline text"
      ></mat-icon>
    </button>

    <button
      mat-button
      type="button"
      [matMenuTriggerFor]="alignmentMenu"
      aria-label="Horizontal alignment dropdown menu"
    >
      <mat-icon
        [svgIcon]="(align | async) + '-justify'"
        aria-hidden="true"
      ></mat-icon>
    </button>

    <mat-menu #alignmentMenu="matMenu">
      <button mat-menu-item type="button" (click)="setJustification(horizontalAlignment.left)">
        <mat-icon
          svgIcon="left-justify"
          aria-hidden="false"
          aria-label="Left-align text"
        ></mat-icon>
      </button>
      <button mat-menu-item type="button" (click)="setJustification(horizontalAlignment.center)">
        <mat-icon
          svgIcon="center-justify"
          aria-hidden="false"
          aria-label="Center-align text"
        ></mat-icon>
      </button>
      <button mat-menu-item type="button" (click)="setJustification(horizontalAlignment.right)">
        <mat-icon
          svgIcon="right-justify"
          aria-hidden="false"
          aria-label="Right-align text"
        ></mat-icon>
      </button>
    </mat-menu>

    <button
      mat-button
      type="button"
      [matMenuTriggerFor]="verticalAlignmentMenu"
      aria-label="Vertical alignment dropdown menu"
    >
      <mat-icon
        [svgIcon]="(verticalAlign | async) + '-align'"
        aria-hidden="true"
      ></mat-icon>
    </button>

    <mat-menu #verticalAlignmentMenu="matMenu">
      <button mat-menu-item type="button" (click)="setVerticalAlignment(verticalAlignment.top)">
        <mat-icon
          svgIcon="top-align"
          aria-hidden="false"
          aria-label="Top-align text"
        ></mat-icon>
      </button>
      <button
        mat-menu-item
        type="button"
        (click)="setVerticalAlignment(verticalAlignment.center)"
      >
        <mat-icon
          svgIcon="center-align"
          aria-hidden="false"
          aria-label="Center-align text"
        ></mat-icon>
      </button>
      <button
        mat-menu-item
        type="button"
        (click)="setVerticalAlignment(verticalAlignment.bottom)"
      >
        <mat-icon
          svgIcon="bottom-align"
          aria-hidden="false"
          aria-label="Bottom-align text"
        ></mat-icon>
      </button>
    </mat-menu>
  </div>
