/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blur-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blur-box/blur-box.component.ngfactory";
import * as i3 from "../blur-box/blur-box.component";
import * as i4 from "../../blur-editor-controller.service";
import * as i5 from "@angular/common";
import * as i6 from "./blur-editor.component";
var styles_BlurEditorComponent = [i0.styles];
var RenderType_BlurEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlurEditorComponent, data: {} });
export { RenderType_BlurEditorComponent as RenderType_BlurEditorComponent };
function View_BlurEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "lib-blur-box", [], null, [[null, "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onBoxClick(_v.context.index, $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BlurBoxComponent_0, i2.RenderType_BlurBoxComponent)), i1.ɵdid(1, 245760, null, 0, i3.BlurBoxComponent, [i4.BlurEditorControllerService, i1.ElementRef], { blurBox: [0, "blurBox"], editable: [1, "editable"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.ctrl.selectedBox)) == _v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_BlurEditorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { bounds: 0 }), i1.ɵqud(402653184, 2, { container: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["bounds", 1]], null, 3, "div", [["id", "bounds"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BlurEditorComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co._effect)).boxes; var currVal_1 = _co.trackByIndex; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_BlurEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-blur-editor", [], null, [["window", "resize"], ["window", "mousedown"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).updateDimensions() !== false);
        ad = (pd_0 && ad);
    } if (("window:mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEditorClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_BlurEditorComponent_0, RenderType_BlurEditorComponent)), i1.ɵdid(1, 245760, null, 0, i6.BlurEditorComponent, [i1.ElementRef, i4.BlurEditorControllerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlurEditorComponentNgFactory = i1.ɵccf("lib-blur-editor", i6.BlurEditorComponent, View_BlurEditorComponent_Host_0, {}, {}, []);
export { BlurEditorComponentNgFactory as BlurEditorComponentNgFactory };
