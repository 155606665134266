/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable, NgZone } from '@angular/core';

// HACK: gapi.auth2.GoogleAuth has a property then which produces a circular type when using promises
export type SaneGoogleAuth = Pick<
  gapi.auth2.GoogleAuth,
  Exclude<keyof gapi.auth2.GoogleAuth, 'then'>
>;

@Injectable({
  providedIn: 'root',
})
export class GapiLoaderService {
  constructor(private _zone: NgZone) {}

  async loadClient(accessToken: string, discoveryDocs: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this._zone.run(() => {
        gapi.load('client', {
          callback: () => {
            gapi.client
              .init({
                discoveryDocs: discoveryDocs,
              })
              .then(() => {
                gapi.client.setToken({ access_token: accessToken });
                return resolve(gapi);
              });
          },
          onerror: reject,
          timeout: 1000,
          ontimeout: reject,
        });
      });
    });
  }

  async loadPickerLibs() {
    return new Promise((resolve, reject) => {
      this._zone.run(() => {
        gapi.load('picker', {
          callback: resolve,
          onerror: reject,
          timeout: 1000,
          ontimeout: reject,
        });
      });
    });
  }

  async loadShareLibs() {
    return new Promise((resolve, reject) => {
      this._zone.run(() => {
        gapi.load('drive-share', {
          callback: resolve,
          onerror: reject,
          timeout: 1000,
          ontimeout: reject,
        });
      });
    });
  }

  /**
   * load the auth2 api of gapi
   */
  async loadAuth2(): Promise<void> {
    return new Promise((resolve, reject) => {
      // _zone.run() executes code outside of the angular zone and safely returns back to angular. should be used when
      // fetching libs that leave angular environment e.g. gapi
      this._zone.run(() => {
        gapi.load('auth2', {
          callback: () => resolve(),
          onerror: reject,
          timeout: 5000,
          ontimeout: reject,
        });
      });
    });
  }
}
