var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { AnnotationsClip, ClipTimeline, FileClipModel2, SceneEditor2, SceneModel2, StillClip, } from '@castify/edit-models';
import { applyProximityRounding, Limits } from '@castify/models';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMapTo, tap, } from 'rxjs/operators';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TrimSide } from './types';
import * as i0 from "@angular/core";
import * as i1 from "../common/undo-manager.service";
import * as i2 from "../preview/preview-state.service";
export var kScrollIntervalDistance = 10; // distance (as time) scrolled when trimming crosses window bounds
var kScrollBorderFactor = 0.01; // Factor of displayDuration which is used as an offset at the start of the timeline
export var kTrimSnapDistance = 7; // Distance for trim snap to trigger
var EffectSelection = /** @class */ (function () {
    function EffectSelection(fields) {
        if (fields === void 0) { fields = {}; }
        Object.assign(this, fields);
    }
    return EffectSelection;
}());
export { EffectSelection };
var kFullSceneWidth = 1000;
var kDefaultZoomFactor = 0.01;
var TimelineStateService = /** @class */ (function () {
    function TimelineStateService(undoManager, previewState) {
        var _this = this;
        this.undoManager = undoManager;
        this.previewState = previewState;
        // zoomFactor in units of Pixels/Millisecond -> how wide (in pixels) a millisecond of the scene is displayed
        this.zoomFactor = new BehaviorSubject(kDefaultZoomFactor);
        this.zoomLimits = new BehaviorSubject(new Limits(kDefaultZoomFactor, 1));
        this.startTime = new BehaviorSubject(0); // controls where the scene starts relative to the timeline
        this.displayDuration = new BehaviorSubject(0); // amount of time that is displayed over the width of the timeline
        this.selection = new BehaviorSubject([]);
        this.scene = new BehaviorSubject(new SceneModel2());
        // use this to temporarily (e.g. during editing) display a different scene in the timeline
        this.overrideScene = new BehaviorSubject(null);
        // position of the playhead, diverges from preview.playhead during scrubbing.
        this.playheadCursor = new BehaviorSubject(0);
        this.toolSidebarData = new BehaviorSubject(null);
        this.scrubCursor = new BehaviorSubject(null);
        this.overrideCursor = new BehaviorSubject(null);
        var currentScene = combineLatest([
            this.undoManager.scene,
            this.overrideScene,
        ]);
        // scroll the timeline further when the beginning is visible
        currentScene
            .pipe(
        // only scroll when the scene is not currently under change
        filter(function (_a) {
            var _b = __read(_a, 2), _scene = _b[0], override = _b[1];
            return !override;
        }), 
        // 'private' params since override is null as per filter and scene is not needed for calculation
        map(function (_a) {
            var _b = __read(_a, 2), _scene = _b[0], _override = _b[1];
            if (_this.startTime.value + _this.leftBorderOffset > 0 &&
                _this.startTime.value <= 0) {
                return _this.startTime.value + _this.leftBorderOffset;
            }
            else {
                return _this.startTime.value;
            }
        }))
            .subscribe(this.startTime);
        // apply override scene
        currentScene
            .pipe(
        // select override when it is set, otherwise use scene from undo manager
        map(function (_a) {
            var _b = __read(_a, 2), scene = _b[0], override = _b[1];
            return override || scene;
        }))
            .subscribe(this.scene);
        // update playhead cursor position during playback
        combineLatest([this.previewState.playhead, this.previewState.isPlaying])
            .pipe(filter(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], isPlaying = _b[1];
            return isPlaying;
        }), map(function (_a) {
            var _b = __read(_a, 2), pos = _b[0], _ = _b[1];
            return pos;
        }), distinctUntilChanged(), tap(function (pos) {
            // scroll during playback when playhead is out of display duration
            if (Math.abs(_this.startTime.value) + _this.displayDuration.value <
                pos) {
                // scroll to 10% of displayDuration
                _this.scroll(_this.startTime.value - _this.displayDuration.value * 0.9);
            }
        }))
            .subscribe(this.playheadCursor);
        // make sure playhead is in scene: update playheadCursor when scene changes
        this.undoManager.scene
            .pipe(switchMapTo(this.previewState.playhead.pipe(first())))
            .subscribe(this.playheadCursor);
        // end scrubbing when playback starts
        this.previewState.shouldPlay
            .pipe(filter(function (v) { return !!v; }))
            .subscribe(function () { return _this.endScrub(); });
        // seek preview
        combineLatest([
            this.scrubCursor.pipe(distinctUntilChanged()),
            this.overrideCursor,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), scrubPos = _b[0], overridePos = _b[1];
            // select cursor to use for seek
            if (overridePos !== null) {
                return overridePos;
            }
            else if (scrubPos !== null) {
                return scrubPos;
            }
            else {
                return _this.playheadCursor.value;
            }
        }))
            .subscribe(function (seekPos) { return _this.previewState.seek(seekPos); });
        // update zoom limits to allow fitting the whole scene in the timeline
        this.undoManager.scene
            .pipe(map(function (scene) {
            return new Limits(Math.min(kFullSceneWidth / scene.duration, kDefaultZoomFactor), 1);
        }))
            .subscribe(this.zoomLimits);
    }
    TimelineStateService.prototype.setZoom = function (zoomFactor) {
        this.zoomFactor.next(this.zoomLimits.value.apply(zoomFactor));
    };
    TimelineStateService.pixelsToTime = function (pixels, zoomFactor) {
        return pixels / zoomFactor;
    };
    TimelineStateService.prototype.pixelsToTime = function (pixels) {
        return TimelineStateService.pixelsToTime(pixels, this.zoomFactor.value);
    };
    TimelineStateService.timeToPixels = function (time, zoomFactor) {
        return time * zoomFactor;
    };
    TimelineStateService.prototype.timeToPixels = function (time) {
        return TimelineStateService.timeToPixels(time, this.zoomFactor.value);
    };
    Object.defineProperty(TimelineStateService.prototype, "leftBorderOffset", {
        /**
         * space at the border of the timeline when scrolled to left side
         */
        get: function () {
            return this.displayDuration.value * kScrollBorderFactor;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * scrolls to a given startTime with limits enforced
     * @returns actual scroll position
     */
    TimelineStateService.prototype.scroll = function (startTime) {
        var startTimeLimits = new Limits(this.displayDuration.value - this.scene.value.duration, this.leftBorderOffset);
        startTime = startTimeLimits.apply(startTime);
        this.startTime.next(startTime);
        return startTime;
    };
    /**
     * Make preparations for a trim. Copy the scene into this.overrideScene.
     */
    TimelineStateService.prototype.startTrim = function (originalClip) {
        this.previewState.pause();
        this.scrollData = {
            playheadPosition: this.playheadCursor.value,
            initialStartTime: this.startTime.value,
            maxOverflow: this.startTime.value,
        };
        this.overrideScene.next(this.undoManager.scene.value.copy());
        // change preview: display only clip that is being trimmed in it's entirety
        if (originalClip instanceof FileClipModel2) {
            var tmpClip = originalClip.copy();
            // remove any trim from clip
            tmpClip.startInScene = 0;
            tmpClip.startInFile = 0;
            tmpClip.duration = tmpClip.srcDuration;
            // set preview override scene
            this.previewState.overrideScene.next(new SceneModel2({ clips: new ClipTimeline([tmpClip]) }));
            // set override playhead
            if (this.scrubCursor.value !== null) {
                this.overrideCursor.next(this.scrubCursor.value -
                    originalClip.startInScene +
                    originalClip.startInFile);
            }
            else {
                this.overrideCursor.next(this.playheadCursor.value -
                    originalClip.startInScene +
                    originalClip.startInFile);
            }
        }
    };
    /**
     * Trim a clip and all passively affected clips.
     *
     * @param originalClip - The clip to trim.
     * @param trim - The information needed for a trim operation. Defined in {@link @Types#Trim}.
     */
    TimelineStateService.prototype.updateTrimClip = function (originalClip, trim) {
        if (trim.value === 0 || originalClip === undefined)
            return;
        var scene = this.undoManager.scene.value;
        var resultScene = scene.copy();
        var resultClip = resultScene.clips.byId(originalClip.id);
        var trimAmount;
        // trim clip
        if (trim.side === TrimSide.LEFT) {
            trimAmount =
                resultClip.startInScene -
                    applyProximityRounding(resultClip.startInScene - this.pixelsToTime(trim.value), __spread(scene.clips.changeKeys, [this.scrollData.playheadPosition]), this.pixelsToTime(kTrimSnapDistance), [resultClip.startInScene]);
            new SceneEditor2(resultScene).trimClipStart(resultClip, trimAmount);
            trimAmount = resultClip.endInScene - originalClip.endInScene;
        }
        else {
            trimAmount =
                applyProximityRounding(resultClip.endInScene + this.pixelsToTime(trim.value), __spread(scene.clips.changeKeys, [this.scrollData.playheadPosition]), this.pixelsToTime(kTrimSnapDistance), [resultClip.endInScene]) - resultClip.endInScene;
            new SceneEditor2(resultScene).trimClipEnd(resultClip, trimAmount);
            // update trim amount to reflect actual trim amount with limits applied by scene editor
            trimAmount = resultClip.endInScene - originalClip.endInScene;
        }
        this.overrideScene.next(resultScene);
        // scrolling: make sure the trimmed end of the clip is displayed in the timeline
        var leftBorder = Math.max(0, this.scrollData.initialStartTime);
        if (trim.side === TrimSide.LEFT) {
            var startTime = Math.max(this.scrollData.initialStartTime - trimAmount, -resultClip.startInScene);
            var overflow = startTime - (this.scrollData.initialStartTime - trimAmount);
            this.scrollData.maxOverflow = Math.max(this.scrollData.maxOverflow, overflow);
            this.startTime.next(startTime + this.scrollData.maxOverflow - overflow - leftBorder);
        }
        else {
            var displayDuration = this.displayDuration.value;
            var initialEndTime = displayDuration - this.scrollData.initialStartTime;
            var overflow = resultClip.endInScene - initialEndTime;
            this.scrollData.maxOverflow = Math.max(this.scrollData.maxOverflow, 0, overflow);
            this.startTime.next(this.scrollData.initialStartTime -
                this.scrollData.maxOverflow +
                leftBorder);
        }
        // update overrideCursor
        if (originalClip instanceof FileClipModel2) {
            if (trim.side === TrimSide.LEFT) {
                this.overrideCursor.next(originalClip.startInFile - trimAmount);
                // make playhead cursor appear fixed relative to clip start
                this.playheadCursor.next(this.scrollData.playheadPosition + trimAmount);
            }
            else {
                this.overrideCursor.next(originalClip.endInFile + trimAmount);
            }
        }
    };
    /**
     * End a trim operation. Clear private data and update the undoManager with the new scene.
     */
    TimelineStateService.prototype.trimEnd = function (originalClip) {
        if (this.startTime.value > 0) {
            this.scroll(this.scrollData.initialStartTime);
        }
        this.scrollData = {
            playheadPosition: 0,
            initialStartTime: 0,
            maxOverflow: 0,
        };
        if (!!this.overrideScene) {
            var newTimingHash = this.overrideScene.value.clips
                .byId(originalClip.id)
                .timingHash();
            if (originalClip.timingHash() !== newTimingHash) {
                // only push new undo state when clip was actually trimmed (timing hash changed)
                this.undoManager.update(this.overrideScene.value.copy(), 'trim');
            }
            this.overrideScene.next(null);
        }
        this.previewState.overrideScene.next(null);
        this.overrideCursor.next(null);
    };
    /**
     * Make preparations for a move. Copy the scene into this.overrideScene.
     */
    TimelineStateService.prototype.startMove = function () {
        this.overrideScene.next(this.undoManager.scene.value.copy());
    };
    /**
     * Moves a clips @see ClipModel2 representation and all clips passively affected by
     * the operation
     */
    TimelineStateService.prototype.updateMoveClip = function (clip, offset) {
        if (offset === 0 || !clip) {
            return;
        }
        var resultScene = this.undoManager.scene.value.copy();
        // const currScene = this.overrideScene.value;
        var originalScene = this.undoManager.scene.value;
        var originalClip = originalScene.clips.byId(clip.id);
        // cut clips at start and end of current clip
        if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
            new SceneEditor2(resultScene).cutTerminalAnnotationsClips(resultScene.clips.byId(clip.id));
        }
        var newStart = this._getMoveClipStart(offset, originalScene, originalClip);
        // get clips passively affected by the operation
        var affectedClips = this._getMoveAffectedClips(clip, resultScene, originalScene, Math.sign(offset));
        // put affected clips in selection
        this.selection.next(affectedClips);
        // apply move for every affected clip
        affectedClips.forEach(function (affectedClip) {
            var originalCurrentClip = originalScene.clips.byId(affectedClip.id);
            var originalCurrStart = !!originalCurrentClip
                ? originalCurrentClip.startInScene
                : originalClip.startInScene;
            // Add difference of starts so that affected clips stay over the video clip
            var newStartForClip = newStart + originalCurrStart - originalClip.startInScene;
            // Move clip
            new SceneEditor2(resultScene).ignoreCollisionMoveClip(affectedClip, newStartForClip, affectedClip.id === clip.id);
        });
        // save the current scene
        this.overrideScene.next(resultScene);
    };
    /**
     * calculate the new start time during move with snapping applied
     */
    TimelineStateService.prototype._getMoveClipStart = function (offset, originalScene, originalClip) {
        var newStart = originalClip.startInScene + this.pixelsToTime(offset);
        if (originalClip instanceof AnnotationsClip) {
            newStart = applyProximityRounding(newStart, __spread(originalScene.clips.changeKeys, originalScene.clips.changeKeys
                .map(function (v) { return v - originalClip.duration; })
                .filter(function (v) { return v > 0; }), [
                // snap to playhead
                this.playheadCursor.value,
                this.playheadCursor.value - originalClip.duration,
            ]), this.pixelsToTime(kTrimSnapDistance), [
                originalClip.startInScene,
                originalClip.startInScene - originalClip.duration,
                originalClip.endInScene,
                originalClip.endInScene - originalClip.duration,
            ]);
        }
        return newStart;
    };
    /**
     * Makes the final move of the clip through SceneEditor2.moveClip(), thus respecting bounds etc.
     * @param clip The clip to be actively moved
     * @param offset The final offset to move
     */
    TimelineStateService.prototype.endMove = function (clip, offset) {
        if (clip === undefined || !this.overrideScene.value) {
            return;
        }
        var resultScene = this.undoManager.scene.value.copy();
        var originalScene = this.undoManager.scene.value;
        var originalClip = originalScene.clips.byId(clip.id);
        if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
            new SceneEditor2(resultScene).cutTerminalAnnotationsClips(resultScene.clips.byId(clip.id));
        }
        // calculate the new Start of the clip based on the start before the move
        var newStart = this._getMoveClipStart(offset, originalScene, originalClip);
        // get clips passively affected by the operation
        var affectedClips = this._getMoveAffectedClips(clip, resultScene, originalScene, Math.sign(offset));
        affectedClips.forEach(function (affectedClip) {
            var originalCurrentClip = originalScene.clips.byId(affectedClip.id);
            var originalCurrStart = !!originalCurrentClip
                ? originalCurrentClip.startInScene
                : originalClip.startInScene;
            // Add difference of starts so that affected clips stay over the video clip
            var newStartForClip = newStart + originalCurrStart - originalClip.startInScene;
            // Either trigger a move operation with specified clips to be excluded or without excluded clips for text clips
            new SceneEditor2(resultScene).moveClip(affectedClip, newStartForClip);
            if (affectedClip instanceof FileClipModel2 || clip instanceof StillClip) {
                // add difference if clip was further moved newStart suggests
                // i.e. releasing on another clip will move the clip to the end of said clip
                newStart += affectedClip.startInScene - newStart;
            }
        });
        if (resultScene.clips.byId(clip.id).timingHash() !== originalClip.timingHash()) {
            // only push undo state when clip has actually been moved
            this.undoManager.update(resultScene, 'move');
        }
        this.overrideScene.next(null);
        this.selection.next([resultScene.clips.byId(clip.id)]);
    };
    /**
     * Returns an array of the clips affected by an edit of @param clip
     * @remarks
     * @param clip The actively edited clip
     * @param scene Specifies the {@link SceneModel2} to search in for the clips
     * @param originalScene The {@link SceneModel2} the action is based on.
     * @param sign the sign of the offset. (left negative, right positive)
     * @returns An array of actively and possibly passively edited clips
     */
    TimelineStateService.prototype._getMoveAffectedClips = function (clip, scene, originalScene, sign) {
        var originalClip = originalScene.clips.byId(clip.id);
        var affectedClips = [scene.clips.byId(clip.id)];
        if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
            var annotationsOnly = scene.filterClipType(['annotations']).clips;
            var seekStart = originalClip.startInScene;
            // use previous change pos to not select clips starting at clip.endInScene
            var seekDuration = annotationsOnly.getPreviousChangePos(originalClip.endInScene) -
                seekStart;
            // sort text clips based on distance from start in scene and sign of offset. Those clips should be sorted descending in direction of
            // movement. i.e. right: ------->   left: <-------   otherwise they will interfere during placement.
            //                      [3][2][1]         [1][2][3]
            affectedClips = affectedClips.concat(__spread(annotationsOnly.getAllInRange(seekStart, seekDuration)).sort(function (a, b) {
                return sign *
                    (Math.abs(originalClip.startInScene - b.startInScene) -
                        Math.abs(originalClip.startInScene - a.startInScene));
            }));
        }
        return affectedClips;
    };
    /**
     * set the scrub cursor to a new position
     * @returns actual scrub position
     */
    TimelineStateService.prototype.scrub = function (pos) {
        var limitedPos = this.previewState.applySeekLimits(pos);
        this.scrubCursor.next(limitedPos);
        return limitedPos;
    };
    /**
     * end scrubbing to seek back to playhead pos
     */
    TimelineStateService.prototype.endScrub = function () {
        this.scrubCursor.next(null);
    };
    /**
     * set the playhead to a new position
     * @returns actual seek position
     */
    TimelineStateService.prototype.seek = function (pos) {
        var limitedPos = this.previewState.applySeekLimits(pos);
        this.playheadCursor.next(limitedPos);
        // update scrub cursor as well, when scrubbing is active
        if (this.scrubCursor.value !== null)
            this.scrubCursor.next(limitedPos);
        return limitedPos;
    };
    /**
     * return if effect of selection is of type @param type
     */
    TimelineStateService.prototype.isEffectOfTypeSelected = function (type) {
        var selection = this.selection.value;
        // check if only one clip/effect selected and it's an EffectSelection
        if (selection.length === 1 && selection[0] instanceof EffectSelection) {
            // check if effect has given type
            return selection[0].effect.type === type;
        }
        return false;
    };
    TimelineStateService.ngInjectableDef = i0.defineInjectable({ factory: function TimelineStateService_Factory() { return new TimelineStateService(i0.inject(i1.UndoManagerService), i0.inject(i2.PreviewStateService)); }, token: TimelineStateService, providedIn: "root" });
    return TimelineStateService;
}());
export { TimelineStateService };
