/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { Size } from '@castify/models';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';
import { BlurViewComponent } from '../blur-view/blur-view.component';

const keepOpenSelectors = [
  'lib-blur-editor',
  'lib-blur-toolbar',
  'lib-blur-box',
  '#remove-button',
];

@Component({
  selector: 'lib-blur-editor',
  templateUrl: './blur-editor.component.html',
  styleUrls: ['./blur-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurEditorComponent extends BlurViewComponent implements OnInit {
  constructor(
    hostElm: ElementRef,
    public readonly ctrl: BlurEditorControllerService
  ) {
    super(hostElm);
  }

  ngOnInit() {
    this.clip = this.ctrl.clip.value;
    this.subscriptions.add(this.ctrl.effect.subscribe(this._effect));
    this.updateDimensions();
  }

  @HostListener('window:mousedown', ['$event'])
  onEditorClick(event: MouseEvent): void {
    const editorClicked = event.composedPath().some(
      (v: HTMLElement) =>
        (v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
        // check if element id indicates it is part of the tool
        (v.id && keepOpenSelectors.includes(`#${v.id}`))
    );
    if (!editorClicked) {
      // save edit and close editor
      this.ctrl.save();
      this.ctrl.close();
    }
  }

  onBoxClick(boxIdx: number, event: MouseEvent): void {
    this.ctrl.selectedBox.next(boxIdx);
    event.stopPropagation(); // don't trigger click handler on background (clears box selection)
  }

  protected _getVideoDims(): Size {
    return this._clip.srcDimensions;
  }
}
