/*!
 * Copyright 2020 Screencastify LLC
 */

import { Limits, Rectangle, Size, sty } from '@castify/models';

export class Point {
  constructor(public x: number, public y: number) {}

  /**
   * This function rotates the current point around a given centerpoint
   * @param center center point to rotate current point around
   * @param angle angle of rotation to rotate point
   * @returns new point with rotated co-ordinates
   */
  rotateClockwise(center: Point, angle: sty.Radians): Point {
    // Make angle negative to rotate clockwise
    const cosT = Math.cos(angle);
    const sinT = Math.sin(angle);

    // Translate point to orgin
    const originX = this.x - center.x;
    const originY = this.y - center.y;

    // Rotate point around origin
    const rotatedX = originX * cosT - originY * sinT;
    const rotatedY = originX * sinT + originY * cosT;

    // Translate the point back to where it was
    return new Point(rotatedX + center.x, rotatedY + center.y);
  }
}

export class RotatedRect {
  constructor(public fixedCorner: Point, public unfixedCorner: Point) {}

  /**
   * Grabs the point on the rectangle closest to the origin of our dom
   */
  get topLeft(): Point {
    return new Point(
      this.fixedCorner.x < this.unfixedCorner.x
        ? this.fixedCorner.x
        : this.unfixedCorner.x,
      this.fixedCorner.y < this.unfixedCorner.y
        ? this.fixedCorner.y
        : this.unfixedCorner.y
    );
  }

  /**
   * Grabs the point on the rectangle furthest from the origin of our dom
   */
  get bottomRight(): Point {
    return new Point(
      this.fixedCorner.x > this.unfixedCorner.x
        ? this.fixedCorner.x
        : this.unfixedCorner.x,
      this.fixedCorner.y > this.unfixedCorner.y
        ? this.fixedCorner.y
        : this.unfixedCorner.y
    );
  }

  /**
   * Gets the width of our unrotated rectangle
   */
  get width(): number {
    return this.bottomRight.x - this.topLeft.x;
  }

  /**
   * Gets the height of our unrotated rectangle
   */
  get height(): number {
    return this.bottomRight.y - this.topLeft.y;
  }

  /**
   * The center of the rectangle
   */
  get center(): Point {
    return new Point(
      (this.unfixedCorner.x + this.fixedCorner.x) / 2,
      (this.unfixedCorner.y + this.fixedCorner.y) / 2
    );
  }

  /**
   * This function takes a width and height and reduces the values within our rectangle to a more managable state.
   * @param widthChange width of the canvas for our rect to be reduced to
   * @param heightChange height of our canvas for our rect to be reduced to
   */
  normalizeValues(widthChange: number, heightChange: number) {
    this.fixedCorner.x /= widthChange;
    this.fixedCorner.y /= heightChange;
    this.unfixedCorner.x /= widthChange;
    this.unfixedCorner.y /= heightChange;
  }

  /**
   * This function creates a new rectangle with its points rotated around the centerpoint
   * @param theta intensity of our rotation
   * @returns Rotated version of this rectangle
   */
  rotate(theta: sty.Radians): RotatedRect {
    return new RotatedRect(
      this.fixedCorner.rotateClockwise(this.center, theta),
      this.unfixedCorner.rotateClockwise(this.center, theta)
    );
  }

  /**
   * This function takes the current rectangle and converts it into a unit rectangle, that way we can save it.
   * @param minDims Smallest possible dimensions for our new rect
   * @returns Rectangle ready for being persisted to our DB
   */
  convertToUnitRect(minDims: Size = new Size(0, 0)): Rectangle {
    // Adjust top-left corner
    const newTop = new Limits(-this.height / 2, 1 - this.height / 2).apply(
      this.topLeft.y
    );
    const newLeft = new Limits(-this.width / 2, 1 - this.width / 2).apply(
      this.topLeft.x
    );

    // Calculate and adjust box size according to limits
    const newWidth = new Limits(minDims.width, 1).apply(this.width);
    const newHeight = new Limits(minDims.height, 1).apply(this.height);

    return new Rectangle(newLeft, newTop, newWidth, newHeight);
  }
}
