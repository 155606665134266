/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { IInitAuthResult } from '@castify/edit-models';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UiApiService } from '../common/ui-api.service';
import { UserService } from './user.service';

export type UserLicense = IInitAuthResult;

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  readonly license = new BehaviorSubject<UserLicense | null>(null);

  /**
   * current license of user or null if no user signed in or license not yet fetched
   */
  get currentLicense(): UserLicense | null {
    return this.license.value;
  }

  constructor(private user: UserService, private uiApi: UiApiService) {
    // set license and credentials to null when user logs out.
    this.user.user
      .pipe(
        switchMap((user) => (user ? this.uiApi.authSyncLicense() : of(null)))
      )
      .subscribe((v) => this.license.next(v));
  }
}
