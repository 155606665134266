/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2019 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-clip-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./audio-clip-view.component";
import * as i3 from "../../streamer-client.service";
var styles_AudioClipViewComponent = [i0.styles];
var RenderType_AudioClipViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioClipViewComponent, data: {} });
export { RenderType_AudioClipViewComponent as RenderType_AudioClipViewComponent };
export function View_AudioClipViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _audioElm: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["audio", 1]], null, 0, "audio", [], null, null, null, null, null))], null, null); }
export function View_AudioClipViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-audio-clip-view", [], null, null, null, View_AudioClipViewComponent_0, RenderType_AudioClipViewComponent)), i1.ɵdid(1, 245760, null, 0, i2.AudioClipViewComponent, [i3.StreamerClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioClipViewComponentNgFactory = i1.ɵccf("lib-audio-clip-view", i2.AudioClipViewComponent, View_AudioClipViewComponent_Host_0, {}, {}, []);
export { AudioClipViewComponentNgFactory as AudioClipViewComponentNgFactory };
