/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { kEditorColors, kQuillDefaultTextColor, NonToggleableAttribute as nonToggleable, } from '../../quill-config';
/**
 * The three states for the color tab widget:
 * nothing shown, text colors shown, bg colors shown
 */
var ColorTabNames;
(function (ColorTabNames) {
    ColorTabNames["text"] = "text";
    ColorTabNames["background"] = "background";
    ColorTabNames["border"] = "border";
    ColorTabNames["none"] = "none";
})(ColorTabNames || (ColorTabNames = {}));
var AnnotationsSidebarColorsComponent = /** @class */ (function () {
    function AnnotationsSidebarColorsComponent(ctrl) {
        this.ctrl = ctrl;
        /**
         * Expose constants to the template
         */
        this.colorTabNames = ColorTabNames;
        this.editorColors = kEditorColors;
        /**
         * Emits which color-swatch tab is open
         */
        this.activeColorTab = new BehaviorSubject(ColorTabNames.none);
        /**
         * Attribute observables
         */
        this.color = this.ctrl
            .nonToggleableObsFactory(nonToggleable.color, true)
            .pipe(startWith(kQuillDefaultTextColor));
        this.bgColor = this.ctrl.activeBox.pipe(filter(function (box) { return !!box; }), map(function (box) { return box.backgroundColor; }));
        this.borderColor = this.ctrl.activeBox.pipe(filter(function (box) { return !!box; }), map(function (box) { return box.borderColor; }));
    }
    AnnotationsSidebarColorsComponent.prototype.ngOnInit = function () { };
    /**
     * Allows changing the active color swatch tab
     */
    AnnotationsSidebarColorsComponent.prototype.setActiveColorTab = function (tabName) {
        this.activeColorTab.next(tabName);
    };
    /**
     * Handle clicks on color tab buttons. If tab  is already open, close it;
     * otherwise open it.
     */
    AnnotationsSidebarColorsComponent.prototype.handleTabButtonClick = function (thisTabName) {
        if (this.activeColorTab.value === thisTabName) {
            this.activeColorTab.next(ColorTabNames.none);
        }
        else {
            this.activeColorTab.next(thisTabName);
        }
    };
    /**
     * Non-toggleable attribute setters
     */
    AnnotationsSidebarColorsComponent.prototype.setColor = function (color) {
        this.ctrl.triggerSettable(nonToggleable.color, color);
    };
    AnnotationsSidebarColorsComponent.prototype.setBackgroundColor = function (bgColor) {
        var _this = this;
        this.ctrl.editAnnotation(function (editor) {
            return editor.setBackgroundColor(_this.ctrl.selectedBoxId.value, bgColor);
        });
        // update last seen styles so this change can be copied to
        // new boxes
        this.ctrl.persistSelectedBoxToLastSeenStyles();
        // refocus quill after each interaction
        setTimeout(function () {
            _this.ctrl.activeQuill.value.focus();
        }, 0);
    };
    AnnotationsSidebarColorsComponent.prototype.setBorderColor = function (borderColor) {
        var _this = this;
        this.ctrl.editAnnotation(function (editor) {
            return editor.setBorderColor(_this.ctrl.selectedBoxId.value, borderColor);
        });
        // update last seen styles so this change can be copied to
        // new boxes
        this.ctrl.persistSelectedBoxToLastSeenStyles();
        // refocus quill after each interaction
        setTimeout(function () {
            _this.ctrl.activeQuill.value.focus();
        }, 0);
    };
    return AnnotationsSidebarColorsComponent;
}());
export { AnnotationsSidebarColorsComponent };
