/*!
 * Copyright 2019 Screencastify LLC
 */

import { FisProject } from '@castify/edit-models';
import { BehaviorSubject } from 'rxjs';

export class ProjectServiceMock {
  readonly project = new BehaviorSubject<FisProject | null>(null);
}
