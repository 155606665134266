/*!
 * Copyright 2020 Screencastify LLC
 */

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LicenseService } from 'lib-editor/lib/auth/license.service';

/**
 * This component displays the Screencastify logo, adjusting its phrasing
 * based on whether the user has a free account or a paid account.
 */
@Component({
  selector: 'lib-titlebar-logo',
  templateUrl: './titlebar-logo.component.html',
  styleUrls: ['./titlebar-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitlebarLogoComponent implements OnInit {
  constructor(public license: LicenseService) {}

  ngOnInit() {}
}
