/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
import { LicenseService } from 'lib-editor/lib/auth/license.service';
/**
 * This component displays the Screencastify logo, adjusting its phrasing
 * based on whether the user has a free account or a paid account.
 */
var TitlebarLogoComponent = /** @class */ (function () {
    function TitlebarLogoComponent(license) {
        this.license = license;
    }
    TitlebarLogoComponent.prototype.ngOnInit = function () { };
    return TitlebarLogoComponent;
}());
export { TitlebarLogoComponent };
