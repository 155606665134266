/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shadow-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shadow-box.component";
var styles_ShadowBoxComponent = [i0.styles];
var RenderType_ShadowBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShadowBoxComponent, data: {} });
export { RenderType_ShadowBoxComponent as RenderType_ShadowBoxComponent };
export function View_ShadowBoxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { shadowBox: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["shadowBox", 1]], null, 0, "div", [["id", "shadowBox"]], null, null, null, null, null))], null, null); }
export function View_ShadowBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-shadow-box", [], null, null, null, View_ShadowBoxComponent_0, RenderType_ShadowBoxComponent)), i1.ɵdid(1, 49152, null, 0, i2.ShadowBoxComponent, [], null, null)], null, null); }
var ShadowBoxComponentNgFactory = i1.ɵccf("lib-shadow-box", i2.ShadowBoxComponent, View_ShadowBoxComponent_Host_0, { cutout: "cutout" }, {}, []);
export { ShadowBoxComponentNgFactory as ShadowBoxComponentNgFactory };
