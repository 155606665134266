/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2019 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zoom-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-common/components/shadow-box/shadow-box.component.ngfactory";
import * as i3 from "../../../tool-common/components/shadow-box/shadow-box.component";
import * as i4 from "../../../tool-common/directives/drag-handle.directive";
import * as i5 from "./zoom-editor.component";
import * as i6 from "../../zoom-editor-controller.service";
var styles_ZoomEditorComponent = [i0.styles];
var RenderType_ZoomEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZoomEditorComponent, data: {} });
export { RenderType_ZoomEditorComponent as RenderType_ZoomEditorComponent };
export function View_ZoomEditorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { zoomBox: 0 }), i1.ɵqud(402653184, 2, { shadowBox: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "lib-shadow-box", [], null, null, null, i2.View_ShadowBoxComponent_0, i2.RenderType_ShadowBoxComponent)), i1.ɵdid(3, 49152, [[2, 4]], 0, i3.ShadowBoxComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["zoomBox", 1]], null, 9, "div", [["id", "zoomBox"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onMove($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "resizer top-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "resizer top-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "resizer bottom-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "resizer bottom-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" })], null, null); }
export function View_ZoomEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-zoom-editor", [], null, [["window", "resize"], ["window", "mousedown"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleWindowResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeEditor($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ZoomEditorComponent_0, RenderType_ZoomEditorComponent)), i1.ɵdid(1, 4374528, null, 0, i5.ZoomEditorComponent, [i6.ZoomEditorControllerService, i1.ElementRef], null, null)], null, null); }
var ZoomEditorComponentNgFactory = i1.ɵccf("lib-zoom-editor", i5.ZoomEditorComponent, View_ZoomEditorComponent_Host_0, {}, {}, []);
export { ZoomEditorComponentNgFactory as ZoomEditorComponentNgFactory };
