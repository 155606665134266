/*!
 * Copyright 2020 Screencastify LLC
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { filter, map } from 'rxjs/operators';
import { SideToolbarContentComponent } from '../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { AudioToolControllerService } from '../../audio-tool-controller.service';
var keepOpenSelectors = ['lib-audio-toolbar'];
var AudioToolbarComponent = /** @class */ (function () {
    function AudioToolbarComponent(ctrl) {
        var _this = this;
        this.ctrl = ctrl;
        /**
         * max gain in dB (~6.3x)
         */
        this.MAX_GAIN = 16;
        /**
         * min gain in dB (~0.001x)
         */
        this.MIN_GAIN = -61;
        this.closable = false;
        /**
         * Manually control slider's color. material's native slider color control
         * was removed with `-webkit-appearance: none;`
         * TODO: deduplicate into a shared component
         */
        this.sliderGradient = this.ctrl.gain.pipe(filter(function (gain) { return !Number.isNaN(gain); }), map(function (gain) {
            var leftColor = '#ff8282';
            var rightColor = '#fff';
            var fullRange = _this.MAX_GAIN - _this.MIN_GAIN;
            var val = gain - _this.MIN_GAIN;
            return {
                background: "linear-gradient(\n          to right,\n          " + leftColor + " 0%,\n          " + leftColor + " " + (val / fullRange) * 100 + "%,\n          " + rightColor + " " + (val / fullRange) * 100 + "%,\n          " + rightColor + " 100%)",
            };
        }));
        // HACK: touchscreen registers mousedown events too early,
        // triggering closeIfClickedOutside on opening
        setTimeout(function () { return (_this.closable = true); }, 0);
    }
    AudioToolbarComponent.prototype.closeToolbar = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._toolbarContainer) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toolbarContainer.close()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AudioToolbarComponent.prototype.closeEditor = function () {
        this.ctrl.save();
        this.ctrl.close();
    };
    AudioToolbarComponent.prototype.closeIfClickedOutside = function (event) {
        var toolClicked = event.composedPath().some(function (v) {
            return v &&
                // check if tag name indicates it is part of the tool
                ((v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
                    // check if element id indicates it is part of the tool
                    (v.id && keepOpenSelectors.includes("#" + v.id)));
        });
        if (!toolClicked && this.closable) {
            this.closeEditor();
        }
    };
    // type set to number before setting gain
    AudioToolbarComponent.prototype.setGain = function (gain) {
        this.ctrl.setGain(Number(gain));
    };
    return AudioToolbarComponent;
}());
export { AudioToolbarComponent };
