/*!
 * Copyright 2019 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { interval } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';

/**
 * Centralized service to track when seeks/scrubs are happening
 * Used to get an idea of how much random access seeks are requested on the system
 * and how frequently the user ask for per time interval
 */
@Injectable({
  providedIn: 'root',
})
export class SeekDataCollectorService {
  private _seekCount = 0;
  private CATEGORY = 'seeking/scrubbing';

  constructor(private _angulartics: Angulartics2GoogleAnalytics) {
    // tracks seek rate every 5 seconds
    // TODO: remove when done - it's very noisy
    interval(5 * 1000)
      .pipe(
        map(() => this._seekCount),
        pairwise()
      )
      .subscribe(([previousSeekCount, currentSeekCount]) => {
        // To avoid flooding our system, we only capture 10% of seek rate
        // analytics only tracks the first 500 events per session
        if (++this._seekCount && Math.random() < 0.1) {
          this._angulartics.eventTrack('seek rate (seeks/second)', {
            label: Math.round((currentSeekCount - previousSeekCount) / 5),
            category: this.CATEGORY,
            value: 1,
            noninteraction: true,
          });
        }
      });
  }

  /**
   * call this method each time a seek is happening in the app
   */
  count() {
    // To avoid flooding our system, we only capture 1% of seek events
    // analytics only tracks the first 500 events per session
    if (++this._seekCount && Math.random() < 0.01) {
      this._angulartics.eventTrack('count (sampled @ 1%)', {
        category: this.CATEGORY,
        value: 1,
        noninteraction: true,
      });
    }
  }
}
