/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = ["[_nghost-%COMP%] {\n  position: absolute;\n  width: 100%;\n  height: 100%; }\n\n#shadowBox[_ngcontent-%COMP%] {\n  pointer-events: none;\n  position: relative;\n  background-color: black;\n  opacity: 0.7;\n  width: 100%;\n  height: 100%; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvbW9kdWxlcy9lZGl0LXVpL3Byb2plY3RzL2xpYi1lZGl0b3Ivc3JjL2xpYi90b29sLWNvbW1vbi9jb21wb25lbnRzL3NoYWRvdy1ib3gvc2hhZG93LWJveC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGtCQUFrQjtFQUNsQixXQUFXO0VBQ1gsWUFBWSxFQUFBOztBQUdkO0VBQ0Usb0JBQW9CO0VBQ3BCLGtCQUFrQjtFQUNsQix1QkFBdUI7RUFDdkIsWUFBWTtFQUNaLFdBQVc7RUFDWCxZQUFZLEVBQUEiLCJmaWxlIjoicHJvamVjdHMvbGliLWVkaXRvci9zcmMvbGliL3Rvb2wtY29tbW9uL2NvbXBvbmVudHMvc2hhZG93LWJveC9zaGFkb3ctYm94LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG46aG9zdCB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuI3NoYWRvd0JveCB7XG4gIHBvaW50ZXItZXZlbnRzOiBub25lO1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGJhY2tncm91bmQtY29sb3I6IGJsYWNrO1xuICBvcGFjaXR5OiAwLjc7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDEwMCU7XG59XG4iXX0= */"];
export { styles as styles };
