/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LicenseService } from 'lib-editor/lib/auth/license.service';

/**
 * This component exists to encapsulate all of the titlebar logic
 */
@Component({
  selector: 'lib-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss'],
})
export class TitlebarComponent implements OnInit {
  @Output() openNav = new EventEmitter<void>();

  constructor(public license: LicenseService) {}

  ngOnInit() {}
}
