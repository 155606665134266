/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
var SpinnerModalComponent = /** @class */ (function () {
    function SpinnerModalComponent() {
    }
    SpinnerModalComponent.prototype.ngOnInit = function () { };
    return SpinnerModalComponent;
}());
export { SpinnerModalComponent };
