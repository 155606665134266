/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-local-progress-modal',
  templateUrl: './local-progress-modal.component.html',
  styleUrls: ['./local-progress-modal.component.scss'],
})
export class LocalProgressModalComponent implements OnInit {
  public _progress = new BehaviorSubject<number>(null);
  @Input() set progress(p: number) {
    this._progress.next(p);
  }

  constructor() {}

  @Output() closeEvent = new EventEmitter<void>();

  ngOnInit() {}

  /**
   * Emits an event to the parent which indicates the user has
   * requested the modal be closed
   */
  public onCloseClick(): void {
    this.closeEvent.emit();
  }
}
