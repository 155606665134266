/*!
 * Copyright 2020 Screencastify LLC
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, } from '@angular/core';
import { KeyboardService } from 'lib-editor/lib/keyboard-shortcuts/keyboard.service';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SideToolbarContentComponent } from '../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';
var BlurToolbarComponent = /** @class */ (function () {
    function BlurToolbarComponent(ctrl, keyboardShortcuts) {
        this.ctrl = ctrl;
        this.keyboardShortcuts = keyboardShortcuts;
        this.intensity = combineLatest([this.ctrl.effect, this.ctrl.selectedBox]).pipe(filter(function (_a) {
            var _b = __read(_a, 2), effect = _b[0], _ = _b[1];
            return !!effect && !!effect.boxes;
        }), filter(function (_a) {
            var _b = __read(_a, 2), effect = _b[0], sel = _b[1];
            return sel !== null && sel >= 0 && sel < effect.boxes.length;
        }), map(function (_a) {
            var _b = __read(_a, 2), effect = _b[0], sel = _b[1];
            return effect.boxes[sel].intensity;
        }));
        /**
         * Manually control slider's color. material's native slider color control
         * was removed with `-webkit-appearance: none;`
         */
        this.sliderGradient = this.intensity.pipe(map(function (value) {
            var leftColor = '#ff8282';
            var rightColor = '#fff';
            return {
                background: "linear-gradient(\n          to right,\n          " + leftColor + " 0%,\n          " + leftColor + " " + value * 100 + "%,\n          " + rightColor + " " + value * 100 + "%,\n          " + rightColor + " 100%)",
            };
        }));
    }
    BlurToolbarComponent.prototype.ngOnDestroy = function () {
        this.keyboardShortcuts.resumeAll();
    };
    BlurToolbarComponent.prototype.closeToolbar = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._toolbarContainer) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toolbarContainer.close()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BlurToolbarComponent.prototype.onAddClick = function () {
        this.ctrl.addBlurBox();
    };
    BlurToolbarComponent.prototype.onIntensityChange = function (value) {
        var boxIdx = this.ctrl.selectedBox.value;
        if (this._isValidBoxIdx(boxIdx)) {
            this.ctrl._editBlur(function (editor) { return editor.setIntensity(boxIdx, value); });
        }
    };
    /**
     * check if a box index represents a valid box selection
     */
    BlurToolbarComponent.prototype._isValidBoxIdx = function (boxIdx) {
        return (boxIdx !== null &&
            boxIdx >= 0 &&
            boxIdx < this.ctrl.effect.value.boxes.length);
    };
    BlurToolbarComponent.prototype.closeEditor = function () {
        this.ctrl.save();
        this.ctrl.close();
    };
    return BlurToolbarComponent;
}());
export { BlurToolbarComponent };
