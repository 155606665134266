/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component } from '@angular/core';
import { sty, VideoClip, VideoClipEffect } from '@castify/edit-models';
import { BehaviorSubject } from 'rxjs';
import { ClipViewComponent } from '../clip-view/clip-view.component';

@Component({
  template: '',
})
export class VideoEffectViewComponent<
  TEffect extends VideoClipEffect = VideoClipEffect
> extends ClipViewComponent<VideoClip> {
  set effect(v: TEffect) {
    this._effect.next(v);
  }
  readonly _effect = new BehaviorSubject<TEffect>(null);

  get startInScene(): sty.Milliseconds {
    return this._clip.startInScene + this._effect.value.startInClip;
  }
}
