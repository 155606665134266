var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject } from 'rxjs';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService } from '../timeline/timeline-state.service';
import { EditorControllerService } from './editor-controller.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../preview/preview-state.service";
var SingleClipEditorControllerService = /** @class */ (function (_super) {
    __extends(SingleClipEditorControllerService, _super);
    function SingleClipEditorControllerService(timelineState, previewState) {
        var _this = _super.call(this) || this;
        _this.timelineState = timelineState;
        _this.previewState = previewState;
        _this.clip = new BehaviorSubject(undefined);
        return _this;
    }
    SingleClipEditorControllerService.prototype.open = function () {
        this.timelineState.toolSidebarData.next({
            clip: this.clip.value,
            editor: this,
        });
        _super.prototype.open.call(this);
    };
    SingleClipEditorControllerService.prototype.save = function () {
        throw new Error('not implemented');
    };
    SingleClipEditorControllerService.prototype.close = function () {
        this.timelineState.toolSidebarData.next(null);
        this.timelineState.overrideScene.next(null);
        this.previewState.overrideScene.next(null);
        _super.prototype.close.call(this);
    };
    SingleClipEditorControllerService.ngInjectableDef = i0.defineInjectable({ factory: function SingleClipEditorControllerService_Factory() { return new SingleClipEditorControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.PreviewStateService)); }, token: SingleClipEditorControllerService, providedIn: "root" });
    return SingleClipEditorControllerService;
}(EditorControllerService));
export { SingleClipEditorControllerService };
