<div [ngSwitch]="activeModal | async">
  <lib-spinner-modal *ngSwitchCase="'spinner'"></lib-spinner-modal>
  <lib-local-progress-modal
    *ngSwitchCase="'progress'"
    [progress]="brain.progress | async"
    (closeEvent)="close()"
  ></lib-local-progress-modal>
  <lib-error-modal
    *ngSwitchCase="'error'"
    (closeEvent)="close()"
  ></lib-error-modal>
</div>
