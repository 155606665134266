/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';
import { HorizontalAlignment, VerticalAlignment } from '@castify/edit-models';
import { filter, map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import {
  kQuillDefaultAlignment,
  NonToggleableAttribute as nonToggleable,
  ToggleableAttribute as toggleable,
} from '../../quill-config';

@Component({
  selector: 'lib-annotations-sidebar-buttons',
  templateUrl: './annotations-sidebar-buttons.component.html',
  styleUrls: ['./annotations-sidebar-buttons.component.scss'],
})
export class AnnotationsSidebarButtonsComponent implements OnInit {
  /**
   * Expose constants to the template
   */
  verticalAlignment = VerticalAlignment;
  horizontalAlignment = HorizontalAlignment;

  /**
   * Attribute observables
   */
  bold = this.ctrl.toggleableObsFactory(toggleable.bold);
  italic = this.ctrl.toggleableObsFactory(toggleable.italic);
  underline = this.ctrl.toggleableObsFactory(toggleable.underline);
  verticalAlign = this.ctrl.activeBox.pipe(
    filter((box) => !!box),
    map((box) => box.verticalAlignment)
  );
  align = this.ctrl
    .nonToggleableObsFactory<string>(nonToggleable.align, false)
    .pipe(
      map((attr) => (attr ? attr : kQuillDefaultAlignment)),
      startWith(kQuillDefaultAlignment)
    );

  constructor(public ctrl: AnnotationsToolControllerService) {}

  ngOnInit() {}

  /**
   * Toggleable attribute setters
   */
  public toggleBold(): void {
    this.ctrl.triggerToggleable(toggleable.bold);
  }
  public toggleItalics(): void {
    this.ctrl.triggerToggleable(toggleable.italic);
  }
  public toggleUnderline(): void {
    this.ctrl.triggerToggleable(toggleable.underline);
  }

  /**
   * Non-toggleable attribute setters
   */
  public setJustification(justification: string): void {
    // pass in the emptry string for left
    // because quill treats `left` as default
    this.ctrl.triggerSettable(
      nonToggleable.align,
      justification === kQuillDefaultAlignment ? '' : justification
    );
  }
  public setVerticalAlignment(alignment: VerticalAlignment): void {
    this.ctrl.editAnnotation((editor) =>
      editor.setVerticalAlignment(this.ctrl.selectedBoxId.value, alignment)
    );

    // update last seen styles so this change can be copied to
    // new boxes
    this.ctrl.persistSelectedBoxToLastSeenStyles();

    // refocus quill after each interaction
    setTimeout(() => {
      this.ctrl.activeQuill.value.focus();
    }, 0);
  }
}
