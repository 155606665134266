/*!
 * Copyright 2018 Screencastify LLC
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit, } from '@angular/core';
import { AnnotationsClip, AudioClip, ClipModel2, SceneEditor2, VideoClip, } from '@castify/edit-models';
import { AnnotationsToolControllerService } from 'lib-editor/lib/annotations-tool/annotations-tool-controller.service';
import { BlurEditorControllerService } from 'lib-editor/lib/blur-editor/blur-editor-controller.service';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { UndoManagerService } from '../../../common/undo-manager.service';
import { CropEditorControllerService } from '../../../crop-editor/crop-editor-controller.service';
import { EditorManagerService } from '../../../preview/editor-manager.service';
import { PreviewStateService } from '../../../preview/preview-state.service';
import { EffectSelection, TimelineStateService, } from '../../../timeline/timeline-state.service';
import { ZoomEditorControllerService } from '../../../zoom-editor/zoom-editor-controller.service';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(undoManager, editorManager, previewState, timelineState, cropCtrl, zoomCtrl, blurCtrl, annotationsCtrl) {
        this.undoManager = undoManager;
        this.editorManager = editorManager;
        this.previewState = previewState;
        this.timelineState = timelineState;
        this.cropCtrl = cropCtrl;
        this.zoomCtrl = zoomCtrl;
        this.blurCtrl = blurCtrl;
        this.annotationsCtrl = annotationsCtrl;
        this.removeEnabled = new BehaviorSubject(false);
        this.cutEnabled = new BehaviorSubject(false);
        this.cropEnabled = new BehaviorSubject(false);
        this.annotationsEnabled = new BehaviorSubject(false);
        this.addClipEnabled = new BehaviorSubject(false); // TODOO: wire up to add clip button
        this.zoomEnabled = new BehaviorSubject(false);
        this.blurEnabled = new BehaviorSubject(false);
        this.playEnabled = new BehaviorSubject(false);
        this.playbackRunning = new Subject();
        this._log = Log.create('ToolbarComponent');
        this.subscriptions = new Subscription();
    }
    ToolbarComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        // removeEnabled
        this.subscriptions.add(this.timelineState.selection
            .pipe(map(function (selection) {
            return selection.some(function (c) { return _this.isEditableClip(c) || c instanceof EffectSelection; });
        }))
            .subscribe(this.removeEnabled));
        // cutEnabled
        this.subscriptions.add(combineLatest([
            this.cropCtrl.isActive,
            this.timelineState.selection,
            this.timelineState.playheadCursor,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 3), cropActive = _b[0], selection = _b[1], playhead = _b[2];
            if (cropActive)
                return false;
            var clip = undefined;
            if (selection.some(function (c) { return c instanceof EffectSelection; })) {
                clip = (selection.find(function (c) { return c instanceof EffectSelection; })).clip;
            }
            else {
                clip = selection.find(function (c) { return _this.isEditableClip(c); });
            }
            return (!!clip &&
                playhead > clip.startInScene &&
                playhead < clip.endInScene);
        }))
            .subscribe(this.cutEnabled));
        // cropEnabled
        this.subscriptions.add(combineLatest([this.cropCtrl.canActivate, this.timelineState.selection])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), cropCanActivate = _b[0], selection = _b[1];
            return cropCanActivate &&
                !selection.some(function (clip) { return clip instanceof AudioClip; });
        }))
            .subscribe(this.cropEnabled));
        // zoomEnabled
        this.subscriptions.add(combineLatest([
            this.zoomCtrl.canActivate.pipe(debounceTime(1)),
            this.cropCtrl.isActive,
            this.timelineState.selection,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 3), canActivate = _b[0], cropActive = _b[1], selection = _b[2];
            return canActivate &&
                !cropActive &&
                !selection.some(function (clip) { return clip instanceof AudioClip; });
        }))
            .subscribe(this.zoomEnabled));
        // blurEnabled
        this.subscriptions.add(combineLatest([
            this.blurCtrl.canActivate,
            this.cropCtrl.isActive,
            this.timelineState.selection,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 3), canActivate = _b[0], cropActive = _b[1], selection = _b[2];
            return canActivate &&
                !cropActive &&
                !selection.some(function (clip) { return clip instanceof AudioClip; });
        }))
            .subscribe(this.blurEnabled));
        // addClipEnabled
        this.subscriptions.add(combineLatest([this.cropCtrl.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 1), cropActive = _b[0];
            return !cropActive;
        }))
            .subscribe(this.addClipEnabled));
        // annotationsEnabled
        this.subscriptions.add(combineLatest([
            this.annotationsCtrl.canActivate,
            this.cropCtrl.isActive,
            this.timelineState.selection,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 3), canActivate = _b[0], cropActive = _b[1], selection = _b[2];
            return canActivate &&
                !cropActive &&
                !selection.some(function (clip) { return clip instanceof AudioClip; });
        }))
            .subscribe(this.annotationsEnabled));
        // playEnabled
        this.subscriptions.add(combineLatest([this.undoManager.scene, this.editorManager.activeEditor])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), scene = _b[0], editor = _b[1];
            return !!scene && !editor;
        }))
            .subscribe(this.playEnabled));
        // stop playback when playback gets disabled
        this.subscriptions.add(this.playEnabled
            .pipe(filter(function (v) { return !v; }))
            .subscribe(function () { return _this.previewState.pause(); }));
        this.subscriptions.add(this.previewState.shouldPlay
            .pipe(
        // HACK: for some reasons we're not getting the correct values by just subscribing
        //        to should play. Looks like values are out of order when value changes more then once
        //        within the same render cycle. This happens when the scene is seeked to the end and the
        //        user hits play.
        //        shouldPlay.value has the correct value.
        map(function () { return _this.previewState.shouldPlay.value; }))
            .subscribe(this.playbackRunning));
    };
    /**
     * Toggles the current play state of
     * our preview when the associated button is clicked.
     */
    ToolbarComponent.prototype.onPlayClick = function () {
        if (!!this.undoManager.scene.value &&
            !this.editorManager.activeEditor.value) {
            if (this.previewState.shouldPlay.value) {
                this.previewState.pause();
            }
            else {
                this.previewState.play();
            }
        }
    };
    /**
     * Removes selected effect or clip from the current preview
     */
    ToolbarComponent.prototype.onRemoveClick = function () {
        var _this = this;
        if (!this.removeEnabled.value)
            return;
        // If we're currently cropping a clip, stop cropping and move on
        if (this.cropCtrl.isActive.value) {
            this.cropCtrl.reset();
            return;
        }
        var scene = this.undoManager.scene.value;
        // Set the current type of selection we're working with
        var selection = this.timelineState.selection.value.find(function (c) { return _this.isEditableClip(c) || c instanceof EffectSelection; });
        // case for clicks on clips
        if (selection instanceof ClipModel2) {
            // case where annotations clips are selecte
            // and the annotations tool is open
            if (selection instanceof AnnotationsClip &&
                this.annotationsCtrl.isActive.value) {
                this.annotationsCtrl.deleteSelectedTextBox();
                // early return to avoid removing clips when boxes still remain
                if (this.annotationsCtrl.numBoxes > 0) {
                    return;
                }
            }
            // remove clip when it is in the scene
            if (scene.clips.byId(selection.id)) {
                new SceneEditor2(scene).removeClip(selection);
            }
            // handles case where it is not yet in the scene
            else {
                this.annotationsCtrl.close();
            }
            this._log.info('Delete clip from timeline');
            // Otherwise if we're working with an effect thats attached to a clip...
        }
        else if (selection.clip && selection.effect) {
            // If it's a blur effect, remove the currently selected box
            if (selection.effect.type === 'blur' && this.blurCtrl.isActive.value) {
                this.blurCtrl.deleteSelectedBox();
                if (this.blurCtrl.numBlurBoxes > 0) {
                    return;
                }
            }
            // Remove the effect from the clip
            new SceneEditor2(scene)
                .getClipEditor(selection.clip)
                .removeEffect(selection.effect);
        }
        else {
            throw new Error("Can not Remove this selection: " + selection);
        }
        // close all open editors to not be stuck
        if (!!this.editorManager.activeEditor.value) {
            this.editorManager.activeEditor.value.getController().close();
        }
        // remove deleted clip from selection
        this.timelineState.selection.next([]);
        this.undoManager.update(scene, 'remove');
    };
    /**
     * Separate the selected clip into two different clips based on
     * the position of the playhead cursor
     */
    ToolbarComponent.prototype.onCutClick = function () {
        var _this = this;
        if (!this.cutEnabled.value)
            return;
        var scene = this.undoManager.scene.value;
        // cut only selected clip
        var selection = this.timelineState.selection.value.find(function (c) { return c instanceof EffectSelection || _this.isEditableClip(c); });
        var clip = selection instanceof EffectSelection
            ? scene.clips.byId(selection.clip.id)
            : scene.clips.byId(selection.id);
        if (!clip)
            return;
        var cutPosition = this.timelineState.playheadCursor.value - clip.startInScene;
        var newClips = new SceneEditor2(scene).cutClip(clip, cutPosition);
        this.undoManager.update(scene, 'cut');
        // workflow tweak: select new clips to allow users to play back and cut again
        if (newClips) {
            var selection_1 = newClips.find(function (v) { return v.type === clip.type; });
            this.timelineState.selection.next([selection_1]);
        }
    };
    ToolbarComponent.prototype.onZoomClick = function () {
        if (!this.zoomEnabled.value)
            return;
        this.onEffectBtnClick('zoom');
    };
    ToolbarComponent.prototype.onBlurClick = function () {
        if (!this.blurCtrl.canActivate.value)
            return;
        this.onEffectBtnClick('blur');
    };
    ToolbarComponent.prototype.onCropClick = function () {
        if (!this.cropEnabled.value)
            return;
        this.cropCtrl.open();
    };
    ToolbarComponent.prototype.onTextClick = function () {
        if (!this.annotationsEnabled.value)
            return;
        this.annotationsCtrl.open();
    };
    /**
     * Abstract similar logic for applying effects to a video.
     * Effects are indicated by `type` and include `blur` and `zoom`.
     */
    ToolbarComponent.prototype.onEffectBtnClick = function (type) {
        var clipsAtPlayhead = __spread(this.undoManager.scene.value.clips.getAllAtPos(this.timelineState.playheadCursor.value));
        var videoAtPlayhead = (clipsAtPlayhead.filter(function (v) { return v instanceof VideoClip; }));
        if (videoAtPlayhead.length) {
            var playheadPosInClip = this.timelineState.playheadCursor.value -
                videoAtPlayhead[0].startInScene;
            var effects = __spread(videoAtPlayhead[0].effects
                .filterType([type])
                .getAllAtPos(playheadPosInClip));
            if (!!effects[0]) {
                this.timelineState.selection.next([
                    new EffectSelection({
                        clip: videoAtPlayhead[0],
                        effect: effects[0],
                    }),
                ]);
            }
        }
        this.editorManager.providers[type].getController().open();
    };
    ToolbarComponent.prototype.isEditableClip = function (selection) {
        return (selection instanceof ClipModel2 &&
            ['video', 'annotations', 'still'].includes(selection.type));
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
