/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Rectangle, Size } from '@castify/models';
import { ShadowBoxComponent } from 'lib-editor/lib/tool-common/components/shadow-box/shadow-box.component';
import { DragHandleEvent } from 'lib-editor/lib/tool-common/directives/drag-handle.directive';
import { applyPosition } from 'lib-editor/lib/tool-common/helpers';
import { Subscription } from 'rxjs';
import {
  IZoomBox,
  ZoomEditorControllerService,
} from '../../zoom-editor-controller.service';

const keepOpenSelectors = ['lib-zoom-editor', 'lib-zoom-toolbar'];

@Component({
  selector: 'lib-zoom-editor',
  templateUrl: './zoom-editor.component.html',
  styleUrls: ['./zoom-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('zoomBox') zoomBox: ElementRef<HTMLDivElement>;
  @ViewChild(ShadowBoxComponent) shadowBox: ShadowBoxComponent;

  protected _editStartBox: IZoomBox;
  protected subscriptions = new Subscription();

  constructor(
    public zoomEditorCtrl: ZoomEditorControllerService,
    protected hostElm: ElementRef
  ) {}

  ngAfterViewInit() {
    this.subscriptions.add(
      this.zoomEditorCtrl.zoomBox.subscribe((zoomBox) =>
        this.updateZoomBox(zoomBox)
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    this.updateZoomBox(this.zoomEditorCtrl.zoomBox.value);
  }

  protected updateZoomBox(zoomBox: IZoomBox): void {
    if (!this.shadowBox || !this.zoomBox || !this.zoomEditorCtrl.clip.value)
      return;
    const hostDims = Size.fromObj(
      this.hostElm.nativeElement.getBoundingClientRect()
    );
    const videoRect = this.getVideoRect();
    const zoomRect = new Rectangle( // zoom rectangle relative to the host element (= background)
      zoomBox.left * (videoRect.width / hostDims.width) +
        videoRect.left / hostDims.width,
      zoomBox.top * (videoRect.height / hostDims.height) +
        videoRect.top / hostDims.height,
      videoRect.width / hostDims.width / zoomBox.zoom,
      videoRect.height / hostDims.height / zoomBox.zoom
    );

    // update positions
    this.shadowBox.setCutout(zoomRect);
    applyPosition(this.zoomBox, zoomRect);
  }

  onEditStart(): void {
    this._editStartBox = this.zoomEditorCtrl.zoomBox.value;
  }

  onMove(event: DragHandleEvent): void {
    const videoRect = this.getVideoRect();
    const shiftX = event.shiftX / videoRect.width;
    const shiftY = event.shiftY / videoRect.height;

    this.zoomEditorCtrl.setZoomBox({
      zoom: this.zoomEditorCtrl.zoomBox.value.zoom,
      top: this._editStartBox.top + shiftY,
      left: this._editStartBox.left + shiftX,
    });
  }

  onResize(
    event: DragHandleEvent,
    topFixed: boolean,
    leftFixed: boolean
  ): void {
    const videoRect = this.getVideoRect();
    const shiftX = event.shiftX / videoRect.width;
    const shiftY = event.shiftY / videoRect.height;

    const right =
      this._editStartBox.left +
      1 / this._editStartBox.zoom +
      (leftFixed ? shiftX : 0);
    const left = this._editStartBox.left + (leftFixed ? 0 : shiftX);

    const bottom =
      this._editStartBox.top +
      1 / this._editStartBox.zoom +
      (topFixed ? shiftY : 0);
    const top = this._editStartBox.top + (topFixed ? 0 : shiftY);

    const zoomX = 1 / Math.max(right - left, Number.EPSILON); // EPSILON is the number closest to 0 we can divide by without error
    const zoomY = 1 / Math.max(bottom - top, Number.EPSILON);
    const zoom = this.zoomEditorCtrl.applyZoomLimits(Math.min(zoomX, zoomY));
    this.zoomEditorCtrl.setZoomBox({
      zoom,
      top: topFixed ? top : bottom - 1 / zoom,
      left: leftFixed ? left : right - 1 / zoom,
    });
  }

  @HostListener('window:mousedown', ['$event'])
  closeEditor(event: MouseEvent) {
    const toolClicked = event
      .composedPath()
      .some(
        (v: HTMLElement) =>
          v && v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())
      );
    if (!toolClicked) {
      this.zoomEditorCtrl.save();
      this.zoomEditorCtrl.close();
    }
  }

  /**
   * caclulate the videos dimensions and position relative to shadowBox (= background)
   */
  protected getVideoRect(): Rectangle {
    const hostDims = Size.fromObj(
      this.hostElm.nativeElement.getBoundingClientRect()
    );
    const videoDims = this.zoomEditorCtrl.clip.value.dimensions.scaleToFit(
      hostDims
    );
    const offsets = hostDims.sub(videoDims).div(2);
    return new Rectangle(
      offsets.width,
      offsets.height,
      videoDims.width,
      videoDims.height
    );
  }
}
