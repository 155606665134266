/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  animate,
  AnimationEvent,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncSubject } from 'rxjs';

@Component({
  selector: 'lib-tool-sidebar-content',
  templateUrl: './tool-sidebar-content.component.html',
  styleUrls: ['./tool-sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('open', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate(300, style({ transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('close', [
      transition('false => true', [
        animate(300, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SideToolbarContentComponent {
  closeAnimationDone = new AsyncSubject<void>();
  triggerCloseAnimation = new AsyncSubject<true>();

  constructor() {}

  onCloseAnimationStart(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onCloseAnimationDone(event: AnimationEvent): void {
    window.dispatchEvent(new Event('resize'));
    if (event.toState) {
      this.closeAnimationDone.next();
      this.closeAnimationDone.complete();
    }
  }

  onOpenAnimationDone(): void {
    window.dispatchEvent(new Event('resize'));
  }

  async close(): Promise<void> {
    this.triggerCloseAnimation.next(true);
    this.triggerCloseAnimation.complete();
    window.dispatchEvent(new Event('resize'));
    await this.closeAnimationDone.toPromise();
  }
}
