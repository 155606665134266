/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./error-modal.component";
var styles_ErrorModalComponent = [i0.styles];
var RenderType_ErrorModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorModalComponent, data: {} });
export { RenderType_ErrorModalComponent as RenderType_ErrorModalComponent };
export function View_ErrorModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["id", "title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["aria-label", "close button"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" Import failed due to a system error. Please try again to see if this was a temporary issue, otherwise contact support. "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onHelpClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Contact Support"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Try Again "])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 8, 0); var currVal_9 = "primary"; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 8).inline; var currVal_4 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 16).disabled || null); var currVal_6 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 19).disabled || null); var currVal_8 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_7, currVal_8); }); }
export function View_ErrorModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-error-modal", [], null, null, null, View_ErrorModalComponent_0, RenderType_ErrorModalComponent)), i1.ɵdid(1, 114688, null, 0, i10.ErrorModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorModalComponentNgFactory = i1.ɵccf("lib-error-modal", i10.ErrorModalComponent, View_ErrorModalComponent_Host_0, {}, { closeEvent: "closeEvent" }, []);
export { ErrorModalComponentNgFactory as ErrorModalComponentNgFactory };
