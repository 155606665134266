/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, Input } from '@angular/core';
import { Rectangle, Size, sty, TextBox } from '@castify/edit-models';
import { Limits } from '@castify/models';
import { DragHandleEvent } from 'lib-editor/lib/tool-common/directives/drag-handle.directive';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { Point, RotatedRect } from './rectangle';

export const kTextBoxMinDims = new Size(0.04, 0.04);

export enum DragHandleNames {
  northwest = 'northwest',
  northeast = 'northeast',
  southwest = 'southwest',
  southeast = 'southeast',
}

@Component({
  selector: 'lib-text-box-edit-border',
  templateUrl: './text-box-edit-border.component.html',
  styleUrls: ['./text-box-edit-border.component.scss'],
})
export class TextBoxEditBorderComponent {
  dragHandleNames = DragHandleNames;

  /**
   * The box index which this box corresponds to
   */
  @Input() boxId: sty.UUID;

  /**
   * Whether this border surrounds the box selected
   * for editing
   */
  _isSelected = this.ctrl.selectedBoxId.pipe(
    map((selectedBoxId) => {
      return selectedBoxId === this.boxId;
    })
  );

  /**
   * Starting point state for editing
   */
  private _editStartBox: TextBox;

  /**
   * The preview container; intended to help calculate
   * % changes when moving or resizing text boxes
   */
  @Input()
  set parentDims(box: DOMRect) {
    if (box) {
      this._parentDims.next(box);
    }
  }
  readonly _parentDims = new BehaviorSubject<DOMRect>(<DOMRect>{
    width: 0,
    height: 0,
  });

  constructor(public readonly ctrl: AnnotationsToolControllerService) {}

  /**
   * Intended to be called in template on start of drag
   */
  onMoveStart(): void {
    this._editStartBox = JSON.parse(
      JSON.stringify(this.ctrl.clip.value.getBoxById(this.boxId))
    );
  }

  /**
   * Intended to be called when drag ends
   */
  onMoveEnd(): void {
    this.ctrl.selectTextBoxById(this.boxId);
  }

  /**
   * Handles moving text boxes via handles
   */
  onMove(event: DragHandleEvent): void {
    const shiftX = event.shiftX / this._parentDims.value.width;
    const shiftY = event.shiftY / this._parentDims.value.height;

    this.ctrl.editAnnotation((editor) =>
      editor.moveBox(
        this.boxId,
        this._editStartBox.left + shiftX,
        this._editStartBox.top + shiftY
      )
    );
  }

  onClick(id: sty.UUID): void {
    this.ctrl.selectTextBoxById(id);
  }

  /**
   * resizes a unit rectangle by moving it from one corner. Parameters topFixed and leftFixed define which corner of the
   * rectangle can move freely. This corner is shifted (shiftX, shiftY) relative to the startRect.
   * @param topFixed toogles between top and bottom staying fixed
   * @param leftFixed analogous to topFixed
   * @param minDims sets a lower limit for dimensions of the returned rectangle
   */
  resizeAnnotationUnitRect(
    startRect: Rectangle,
    shiftX: sty.UnitValue,
    shiftY: sty.UnitValue,
    topFixed: boolean,
    leftFixed: boolean,
    minDims: Size = new Size(0, 0)
  ): Rectangle {
    // Adjust top-left corner
    const top = new Limits(-startRect.width, 1).apply(
      startRect.top + (topFixed ? 0 : shiftY)
    );
    const left = new Limits(-startRect.height, 1).apply(
      startRect.left + (leftFixed ? 0 : shiftX)
    );

    // Adjust bottom-right corner
    const bottom = new Limits(0, 1 + startRect.height).apply(
      startRect.top + startRect.height + (topFixed ? shiftY : 0)
    );
    const right = new Limits(0, 1 + startRect.width).apply(
      startRect.left + startRect.width + (leftFixed ? shiftX : 0)
    );

    // Calculate and adjust box size according to limits
    const width = new Limits(minDims.width, 1).apply(right - left);
    const height = new Limits(minDims.height, 1).apply(bottom - top);

    return new Rectangle(
      new Limits(-width, leftFixed ? 1 : right).apply(left),
      new Limits(-height, topFixed ? 1 : bottom).apply(top),
      width,
      height
    );
  }

  /**
   * Helper for getting startbox from saved data. Returns values in
   * pixels
   */
  private _rectFromStartBox(activeHandle: DragHandleNames): RotatedRect {
    if (!this._editStartBox) throw new Error('no start box saved');
    const w = this._parentDims.value.width;
    const h = this._parentDims.value.height;

    const north = this._editStartBox.top * h;
    const south = (this._editStartBox.top + this._editStartBox.height) * h;
    const west = this._editStartBox.left * w;
    const east = (this._editStartBox.left + this._editStartBox.width) * w;

    const fixedCorner = new Point(
      activeHandle.slice(5) === 'east' ? west : east,
      activeHandle.slice(0, 5) === 'north' ? south : north
    );
    const unfixedCorner = new Point(
      activeHandle.slice(5) === 'east' ? east : west,
      activeHandle.slice(0, 5) === 'north' ? north : south
    );

    return new RotatedRect(fixedCorner, unfixedCorner);
  }

  /**
   * Gets the rotation of the start box in radians
   */
  private _thetaInRadians(): sty.Radians {
    if (!this._editStartBox) throw new Error('no start box saved');
    return (Math.PI / 180) * this._editStartBox.rotation;
  }

  /**
   * Handles resizing text boxes via handles
   */
  onCornerResize(event: DragHandleEvent, activeHandle: DragHandleNames): void {
    // Grab the starting location before resizing
    const startBox = this._rectFromStartBox(activeHandle);

    // Find the points of our box after rotating it (what the user visually sees)
    const rotatedStartBox = startBox.rotate(this._thetaInRadians());

    // Figure out which corner to lock based on which corner is being dragged
    // Find the location of the users mouse (also the bottomRight corner of our new box post-resize)
    const mouseLocation = new Point(
      rotatedStartBox.unfixedCorner.x + event.shiftX,
      rotatedStartBox.unfixedCorner.y + event.shiftY
    );

    // Draw a box connecting our new mouses location and our previous rotated box
    const rotatedEndBox = new RotatedRect(
      rotatedStartBox.fixedCorner,
      mouseLocation
    );

    // Rotate our box in the opposite direction
    const endBox = rotatedEndBox.rotate(-this._thetaInRadians());

    // Convert our center point back into a rectangle
    endBox.normalizeValues(
      this._parentDims.value.width,
      this._parentDims.value.height
    );
    const resized = endBox.convertToUnitRect(kTextBoxMinDims);

    // Update the editor to reflect our selected items new size
    this.ctrl.editAnnotation((editor) => {
      editor.resizeBox(this.boxId, Size.fromObj(resized));
      editor.moveBox(this.boxId, resized.left, resized.top);
    });

    // ensure box is selected and that styles are persisted
    this.ctrl.selectTextBoxById(this.boxId);
  }

  /**
   * Intended to be called in template on start of drag
   */
  onRotateStart(event: DragHandleEvent): void {
    this.onMoveStart();
    this.ctrl.grayBoxRotation.next(this._editStartBox.rotation);
  }

  /**
   * Intended to be called when drag ends
   */
  onRotateEnd(event: DragHandleEvent): void {
    // Update the editor to reflect our selected items new size
    this.ctrl.editAnnotation((editor) => {
      editor.setRotation(this.boxId, this.ctrl.grayBoxRotation.value);
    });

    this.ctrl.persistSelectedBoxToLastSeenStyles();
    this.ctrl.grayBoxRotation.next(null);
    this.onMoveEnd();
  }

  /**
   * Rotates current rectangle around it's centerpoint given the mouses current location in relation to the centerpoint
   * @param event Current drag event
   */
  onRotate(event: DragHandleEvent) {
    const mouseLocation = new Point(
      (<any>event.event).pageX - this._parentDims.value.left,
      (<any>event.event).pageY - this._parentDims.value.top
    );

    // Grab the starting rect (argument doesn't matter, we just want the center point)
    const startRect = this._rectFromStartBox(<DragHandleNames>'northeast');

    // Find the angle of the users drag relative to the center
    const deltaX = mouseLocation.x - startRect.center.x;
    const deltaY = mouseLocation.y - startRect.center.y;
    let rotation = Math.atan2(deltaY, deltaX);

    // Normalize the data with what we're storing in the models
    rotation *= 180 / Math.PI;
    rotation += 90;

    // Snap the rotation back to origin if it gets close enough
    if (rotation >= -4 && rotation <= 4) rotation = 0;

    this.ctrl.grayBoxRotation.next(rotation);
  }
}
