var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FisProject, SceneModel2 } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../auth/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/user.service";
var ProjectService = /** @class */ (function () {
    function ProjectService(user) {
        var _this = this;
        this.user = user;
        this._log = Log.create('ProjectService');
        this.project = new BehaviorSubject(null);
        this.user.user.subscribe(function (signIn) {
            return _this._onUserChange(signIn ? signIn : null);
        });
    }
    /**
     * create an empty project for currently logged in user
     */
    ProjectService.prototype.loadNewProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userId, project_1, err_1, project;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userId = this.user.currentUser.uid;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!this.project.value) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.project.value.markAsDeleted()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, FisProject.root.getLatestByUser(userId)];
                    case 4:
                        project_1 = _a.sent();
                        if (!project_1) return [3 /*break*/, 6];
                        return [4 /*yield*/, project_1.markAsDeleted()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this._log.error(err_1);
                        return [3 /*break*/, 8];
                    case 8:
                        project = FisProject.create({
                            userId: userId,
                            scene: new SceneModel2(),
                        });
                        return [4 /*yield*/, project.save()];
                    case 9:
                        _a.sent();
                        this.project.next(project);
                        this._log.info('New project created');
                        return [2 /*return*/, project];
                }
            });
        });
    };
    ProjectService.prototype.loadLatestProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var project;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, FisProject.root.getLatestByUser(this.user.currentUser.uid)];
                    case 1:
                        project = _a.sent();
                        if (!project) return [3 /*break*/, 3];
                        this.project.next(project);
                        return [4 /*yield*/, project.updateLastUsed()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.loadNewProject()];
                    case 4:
                        project = _a.sent();
                        _a.label = 5;
                    case 5:
                        this._log.info('Latest project loaded');
                        return [2 /*return*/, project];
                }
            });
        });
    };
    ProjectService.prototype._onUserChange = function (user) {
        // unset project when user signs out
        if (!user) {
            if (this.project.value)
                this.project.next(null);
            return;
        }
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.UserService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
export { ProjectService };
