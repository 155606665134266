/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { DriveExportComponent } from '../export-modals/drive-export/drive-export.component';
import { ExportModule } from '../export-modals/export.module';
import { LocalExportComponent } from '../export-modals/local-export/local-export.component';
import { LibToolbarModule } from '../toolbar/lib-toolbar.module';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { TitlebarLicenseNoticeComponent } from './components/titlebar-license-notice/titlebar-license-notice.component';
import { TitlebarLogoComponent } from './components/titlebar-logo/titlebar-logo.component';
import { TitlebarComponent } from './components/titlebar/titlebar.component';

const exportDeclarations = [TitlebarComponent, TitlebarLogoComponent];

@NgModule({
  declarations: [
    ...exportDeclarations,
    TitlebarLicenseNoticeComponent,
    ExportButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LibToolbarModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    ExportModule,
  ],
  entryComponents: [LocalExportComponent, DriveExportComponent],
  exports: exportDeclarations,
})
export class LibTitlebarModule {}
