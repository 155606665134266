/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Log, Logger } from 'ng2-logger/browser';
import { of } from 'rxjs';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { KeyboardService } from '../../keyboard-shortcuts/keyboard.service';
import { LocalImportBrainService } from './local-import-brain.service';

type modalName = 'spinner' | 'progress' | 'error';

@Component({
  selector: 'lib-local-import',
  templateUrl: './local-import.component.html',
  styleUrls: ['./local-import.component.scss'],
  providers: [KeyboardService, LocalImportBrainService],
})
export class LocalImportComponent implements OnInit, OnDestroy {
  private _log: Logger<any> = Log.create('LocalImportComponent');
  private subscriptions = new Subscription();

  /**
   * Observable instance member which derives UI state from
   * the latest values of observables in the brain service
   *
   * Shows the spinner only while we're waiting for the project
   * to be ready (edge case), which is here represented by
   * `this.brain.readyToAskUserForFile`.
   */
  public activeModal: Observable<modalName> = combineLatest([
    this.brain.readyToAskUserForFile,
    this.brain._importInit.pipe(startWith(null)),
    this.brain._fileToUpload.pipe(startWith(null)),
    this.brain.progress.pipe(startWith(null)),
  ]).pipe(
    // show progress as soon as all have emitted
    map(([, , gotFile]) => {
      if (gotFile) return <modalName>'progress';
      else return 'spinner';
    }),
    /**
     * This overrides the above to show the error modal
     * if any of the observables throw
     */
    catchError<modalName, Observable<modalName>>((err: any) => {
      this._log.error(err);
      return of(<modalName>'error');
    }),
    distinctUntilChanged()
  );

  constructor(
    private dialogRef: MatDialogRef<LocalImportComponent>,
    private keyboardService: KeyboardService,
    private brain: LocalImportBrainService
  ) {}

  ngOnInit() {
    // Temporarily pause all editor keyboard shortcuts
    this.keyboardService.pauseAll();

    // start the brain service's subscriptions
    this.brain.init();

    // Open file dialog
    this.subscriptions.add(
      this.brain.readyToAskUserForFile.subscribe(() => this.openFileDialog())
    );

    // close modal on completion
    this.subscriptions.add(
      this.brain.userFileReadyForEditing.subscribe(() => this.close())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    // turn the keyboard shortcuts back on
    this.keyboardService.resumeAll();
  }

  /**
   * Opens a file dialogue and passes any results into the
   * brain's observables for kicking off uploads; catches a
   * cancel click in the file picker dialog and closes the
   * modal.
   **/
  public async openFileDialog(): Promise<void> {
    const filePickerOptions = {
      types: [
        {
          description: 'Videos',
          accept: {
            'video/*': [
              '.mp4',
              '.webm',
              '.mkv',
              '.flv',
              '.f4v',
              '.avi',
              '.mov',
              '.wmv',
              '.mpg',
              '.mpeg',
              '.m2v',
              '.m4v',
              '.3gp',
              '.3g2',
            ],
          },
        },
      ],
      // disallow selecting multiple files
      multiple: false,
      // disallow the user turning off the file extension
      // filter in the browser/OS's file picker, which protects
      // against uploading non-video files as video files
      excludeAcceptAllOption: true,
    };

    try {
      const files = await (<any>window).showOpenFilePicker(filePickerOptions);

      // pass the file on to the brain service
      if (files.length) {
        const file = await files[0].getFile();
        this.brain.setFile(file);

        // if we for some reason didn't get any files
        // back from the user, we close the modal
      } else {
        this.close();
      }
    } catch (err) {
      // close the modal on a cancel click
      if (err instanceof DOMException) {
        this.close();

        // MDN documentation on showOpenFilePicker's errors
        // is incomplete as of Feb 2021, so we leave this
        // clause to catch anything else we might encounter
      } else {
        this._log.error('unhandled filePicker error', err);
        this.close();
      }
    }
  }

  /**
   * Any of the modal subcomponents can call this to close
   * the MatDialogue modal.
   */
  close(): void {
    this.subscriptions.unsubscribe();
    this.dialogRef.close({ name: '' });
  }
}
