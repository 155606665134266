/*!
 * Copyright 2020 Screencastify LLC
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import 'zone.js/dist/zone-error'; // for easier debugging
import { environment as staging } from './environment.stag';
export var environment = __assign({}, staging, { watchURL: 'https://watch.dev1.screencastify.com/v/', auth: __assign({}, staging.auth, { signInUrl: null }), 
    // copy from 'web config' in firebase console / authentication tab
    firebaseConfig: {
        apiKey: 'AIzaSyAYAgCFoNbWa-bhEjDI2lb5cWyeD4-t4sY',
        authDomain: 'castify-edit-dev1.firebaseapp.com',
        databaseURL: 'https://castify-edit-dev1.firebaseio.com',
        projectId: 'castify-edit-dev1',
        storageBucket: 'castify-edit-dev1.appspot.com',
        messagingSenderId: '1058302864917',
    } });
