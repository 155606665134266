/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorModalComponent } from './common-modals/error-modal/error-modal.component';
import { SpinnerModalComponent } from './common-modals/spinner-modal/spinner-modal.component';
import { DriveImportProgressModalComponent } from './drive-import/drive-import-progress-modal/drive-import-progress-modal.component';
import { DriveImportComponent } from './drive-import/drive-import.component';
import { LocalImportComponent } from './local-import/local-import.component';
import { LocalProgressModalComponent } from './local-import/local-progress-modal/local-progress-modal.component';

@NgModule({
  declarations: [
    LocalProgressModalComponent,
    ErrorModalComponent,
    SpinnerModalComponent,
    LocalImportComponent,
    DriveImportComponent,
    DriveImportProgressModalComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
})
export class ImportModule {}
