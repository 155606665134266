
  <div id="color-tabs-container">
    <ul id="tab-bar" role="tablist">
      <li>
        <button
          mat-button
          type="button"
          role="tab"
          id="text-color-label"
          (click)="handleTabButtonClick(colorTabNames.text)"
          [ngClass]="{ selected: (activeColorTab | async) === colorTabNames.text }"
          [attr.aria-selected]="(activeColorTab | async) === colorTabNames.text"
          aria-controls="text-swatch-panel"
        >
          <span
            class="tab-swatch"
            [style.background-color]="color | async"
          ></span>
          <mat-icon aria-hidden="true">format_color_text</mat-icon>
        </button>
      </li>
      <li>
        <button
          mat-button
          type="button"
          role="tab"
          id="bg-color-label"
          (click)="handleTabButtonClick(colorTabNames.background)"
          [ngClass]="{ selected: (activeColorTab | async) === colorTabNames.background }"
          [attr.aria-selected]="(activeColorTab | async) === colorTabNames.background"
          aria-controls="background-swatch-panel"
        >
          <span
            class="tab-swatch"
            [style.background]="bgColor | async"
          >
            <mat-icon
              *ngIf="(bgColor | async) === 'none'"
              svgIcon="circle-slash"
              class="tab-button-no-bg-color-icon"
              aria-hidden="true"
            ></mat-icon>
          </span>
          <mat-icon aria-hidden="true">format_color_fill</mat-icon>
        </button>
      </li>
      <li>
        <button
          mat-button
          type="button"
          role="tab"
          id="border-color-label"
          (click)="handleTabButtonClick(colorTabNames.border)"
          [ngClass]="{ selected: (activeColorTab | async) === colorTabNames.border }"
          [attr.aria-selected]="(activeColorTab | async) === colorTabNames.border"
          aria-controls="border-swatch-panel"
        >
          <span
            class="tab-swatch"
            [style.background]="borderColor | async"
          >
            <mat-icon
              *ngIf="(borderColor | async) === 'none'"
              svgIcon="circle-slash"
              class="tab-button-no-border-color-icon"
              aria-hidden="true"
            ></mat-icon>
          </span>
          <mat-icon aria-hidden="true">border_style</mat-icon>
        </button>
      </li>
    </ul>
    <div
      *ngIf="(activeColorTab | async) === colorTabNames.text"
      role="tabpanel"
      id="text-swatch-panel"
      class="swatch-container"
    >
      <button
        *ngFor="let thisColor of editorColors"
        class="swatch"
        [style.background-color]="thisColor"
        type="button"
        (click)="setColor(thisColor)"
        [ngClass]="{
          selected: (color | async) === thisColor,
          white: thisColor === '#ffffff'
        }"
      ></button>
    </div>
    <div
      *ngIf="(activeColorTab | async) === colorTabNames.background"
      role="tabpanel"
      id="background-swatch-outer"
    >
      <div id="background-swatch-panel" class="swatch-container">
        <button
          *ngFor="let color of editorColors"
          class="swatch"
          [style.background-color]="color"
          type="button"
          (click)="setBackgroundColor(color)"
          [ngClass]="{
            selected: (bgColor | async) === color,
            white: color === '#ffffff'
          }"
        ></button>
        <button
        class="swatch circle-slash-button "
        type="button"
        style="background: none;"
        (click)="setBackgroundColor('none')"
        [ngClass]="{
          selected: (bgColor | async) === 'none'
        }"
      >
        <mat-icon
          svgIcon="circle-slash"
          class="no-bg-color-icon"
          aria-hidden="true"
        ></mat-icon>
      </button>
      </div>

    </div>

    <div
    *ngIf="(activeColorTab | async) === colorTabNames.border"
    role="tabpanel"
    id="border-swatch-outer"
  >
    <div id="border-swatch-panel" class="swatch-container">
      <button
        *ngFor="let color of editorColors"
        class="swatch"
        [style.background-color]="color"
        type="button"
        (click)="setBorderColor(color)"
        [ngClass]="{
          selected: (borderColor | async) === color,
          white: color === '#ffffff'
        }"
      ></button>
      <button
      class="swatch circle-slash-button "
      type="button"
      style="background: none;"
      (click)="setBorderColor('none')"
      [ngClass]="{
        selected: (borderColor | async) === 'none'
      }"
    >
      <mat-icon
        svgIcon="circle-slash"
        class="no-bg-color-icon"
        aria-hidden="true"
      ></mat-icon>
    </button>
    </div>

  </div>
  </div>
