var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { kLogLevelMapping, } from '@castify/edit-models';
import { promiseTools } from '@castify/models';
import { retryBackoff } from 'backoff-rxjs';
import { LoggerAdapter } from 'lib-editor';
import * as inspect from 'object-inspect';
import { of } from 'rxjs';
import { delay, filter, repeat, switchMap } from 'rxjs/operators';
import { RemoteLoggerClientService, } from './remote-logger-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../lib-editor/src/lib/common/logger-adapter";
import * as i2 from "./remote-logger-client.service";
export var LEVELS = Object.keys(kLogLevelMapping);
// NOTE: this is very short interval, so disable streaming via LoggerAdapter
// when appropriate (e.g. when there's no internet or when user isn't signed in)
var FLUSH_INTERVAL = 1000 * 5; // 5 seconds
// Max retry interval used in exponential backoff for retrying errors.
var kMaxRetryInterval = 120 * 1000;
export var STORAGE_KEY = 'remote_logs';
/**
 * Capture logs by patching over console. Periodically send a batch of logs data
 * to the backend. Additional metadata can be included on install. The colored styling
 * are stripped from the logs since they break the backend. It can be enabled/disabled
 * by the app at any point via LoggerAdapter
 * Note: Only starts capturing after install, so initial logs are not captured.
 * It's not a problem because not useful logs anyways
 *
 * TODO: implement size limits per flush
 */
var RemoteLoggerService = /** @class */ (function () {
    function RemoteLoggerService(_adapter, _remoteLoggerClient) {
        this._adapter = _adapter;
        this._remoteLoggerClient = _remoteLoggerClient;
        /**
         * Limit max number of log items stored based on number of items in logs.
         * Ideally this would be based on bytes/some more suitable data structure.
         * For now, just empty the buffer entirely once it exceeds the number of items
         * to prevent it from growing indefinitely (e.g. while offline)
         */
        // Exposed for unit-testing online.
        this._maxLogsInMemory = 100000; // 100k items = ~10MB @ 100 bytes/item
        // private - made public for testing only
        this._logs = [];
        // Chunk size, max lines per request. Needs to be <= what endpoint in
        // castify-stoarge allows. Exposed for easier unit testing only.
        this._maxLinesPerRequest = 10000;
        // Backup of original non-patched methods.
        this._origConsole = {};
    }
    /**
     * Colorized logs messes with winston logger on the backend.
     * See https://stackoverflow.com/q/51135092/7965622
     * Colorized log looks like this:
     * [
     *   '%c ServifyPublisher:userAccount  %c publishing service ',
     *   'background: #196D7F;color:white; border: 1px solid #196D7F; ',
     *   'border: 1px solid gray; ',
     *   ...any // additional arguments
     * ]
     */
    RemoteLoggerService.filterCssFromLog = function (params) {
        var substitutionsCount = 0;
        var message = params.shift();
        if (typeof message === 'string') {
            var cleanMessage = message.replace(/%c /g, function () {
                substitutionsCount++;
                return '';
            });
            var cleanParams = params.slice(substitutionsCount);
            return __spread([cleanMessage], cleanParams);
        }
        return __spread([message], params);
    };
    /**
     * Post logs to server and remove posted logs from cache
     * made public for testing only
     */
    RemoteLoggerService.prototype._flush = function () {
        return __awaiter(this, void 0, void 0, function () {
            var logs, payload, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._logs.length) {
                            return [2 /*return*/];
                        }
                        logs = __spread(this._logs.slice(0, this._maxLinesPerRequest));
                        payload = this._buildPayload(logs);
                        if (!
                        // Note: _flush errors are handled in the rxjs pipe chain making sure they
                        // don't recursively trigger logs in case of network issues.
                        this._adapter.loggedIn) 
                        // Note: _flush errors are handled in the rxjs pipe chain making sure they
                        // don't recursively trigger logs in case of network issues.
                        return [3 /*break*/, 2];
                        return [4 /*yield*/, this._remoteLoggerClient.postAuthLogs(payload)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._remoteLoggerClient.postUnauthLogs(payload)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        // Note: _flush errors are handled in the rxjs pipe chain making sure they
                        // don't recursively trigger logs in case of network issues.
                        _a;
                        // get latest logs to account for any that's been added while posting
                        this._shiftLogs(logs.length);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Call this on app boot-strapping, patches console.*() methods.
     */
    RemoteLoggerService.prototype.install = function (metadata) {
        this._metadata = metadata;
        this._patchConsole();
        this._installBatcher();
    };
    /**
     * Dispose / uninstall timers etc. Mainly intended for cleanup of during
     * testing.
     */
    RemoteLoggerService.prototype.uninstall = function () {
        var e_1, _a;
        this._subscription.unsubscribe();
        try {
            // Restore console.
            for (var LEVELS_1 = __values(LEVELS), LEVELS_1_1 = LEVELS_1.next(); !LEVELS_1_1.done; LEVELS_1_1 = LEVELS_1.next()) {
                var key = LEVELS_1_1.value;
                console[key] = this._origConsole[key];
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (LEVELS_1_1 && !LEVELS_1_1.done && (_a = LEVELS_1.return)) _a.call(LEVELS_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Cache logs as an array of logs
     * Do not use directly. only used to hook other loggers (e.g. ajs logger)
     */
    RemoteLoggerService.prototype._addLog = function (level, params) {
        params = RemoteLoggerService.filterCssFromLog(params);
        params = this._stringifyErrors(params);
        /**
         * Note: need to avoid holding references to arbitrary obejcts (potentially
         * indefinitely while offline), we stringify everything.
         * Holding references to original objects would prevent GC and could cause
         * memory leaks in some cases.
         */
        // Use slice to remove quotes.
        params = params.map(function (p) { return inspect(p, { indent: 2 }).slice(1, -1); });
        this._logs.push({
            level: level,
            params: params,
            timestamp: Date.now(),
        });
        // HACK: Flush entire buffer for now to avoid potential expotential memory consumption.
        if (this._logs.length > this._maxLogsInMemory)
            this._logs.length = 0;
    };
    /**
     * Builds payload with logs and necessary metadata
     */
    RemoteLoggerService.prototype._buildPayload = function (logs) {
        return {
            logs: logs,
            metadata: __assign({ userAgent: navigator.userAgent }, this._metadata),
        };
    };
    /**
     * Flushes logs every X time interval
     */
    RemoteLoggerService.prototype._installBatcher = function () {
        var _this = this;
        this._subscription = of(null)
            .pipe(filter(function () { return _this._logs.length > 0 && navigator.onLine; }), switchMap(function () { return _this._flush(); }), 
        // Use expontential backoff to retry in case of errors,
        // See here for docs: https://www.npmjs.com/package/backoff-rxjs#retrybackoff
        retryBackoff({
            initialInterval: FLUSH_INTERVAL,
            maxInterval: kMaxRetryInterval,
        }), 
        // Repeat chain after FLUSH_INTERVAL on success.
        delay(FLUSH_INTERVAL), repeat())
            // Note, actual request is triggered in switchMap for easier retry handling etc.
            .subscribe(function () { return null; });
    };
    /**
     * Patch console. Stores logs in local storage until flushed.
     */
    RemoteLoggerService.prototype._patchConsole = function () {
        var _this = this;
        var e_2, _a;
        var _loop_1 = function (key) {
            var tempVar = console[key];
            this_1._origConsole[key] = tempVar;
            console[key] = function () {
                var params = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    params[_i] = arguments[_i];
                }
                tempVar.apply(void 0, __spread(params));
                var newParams = _this._stringifyErrors(params);
                _this._addLog(key, newParams);
            };
        };
        var this_1 = this;
        try {
            for (var LEVELS_2 = __values(LEVELS), LEVELS_2_1 = LEVELS_2.next(); !LEVELS_2_1.done; LEVELS_2_1 = LEVELS_2.next()) {
                var key = LEVELS_2_1.value;
                _loop_1(key);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (LEVELS_2_1 && !LEVELS_2_1.done && (_a = LEVELS_2.return)) _a.call(LEVELS_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    /**
     * Removes X number of logs from the beginning of cached logs.
     */
    RemoteLoggerService.prototype._shiftLogs = function (numberToRemove) {
        var logs2 = this._logs;
        logs2.splice(0, numberToRemove);
    };
    /**
     * If the log contains an error, stringify the error with Object.getOwnPropertyNames
     * so its information can be read else it will be sent to remote as an empty object '{}'
     * The same with DomExceptions.
     */
    RemoteLoggerService.prototype._stringifyErrors = function (params) {
        return params.map(function (p) {
            if (p instanceof DOMException) {
                return "DOMException(" + p.name + "): " + p.message;
            }
            if (p instanceof Error) {
                return JSON.stringify(p, Object.getOwnPropertyNames(p));
            }
            return p;
        });
    };
    RemoteLoggerService.ngInjectableDef = i0.defineInjectable({ factory: function RemoteLoggerService_Factory() { return new RemoteLoggerService(i0.inject(i1.LoggerAdapter), i0.inject(i2.RemoteLoggerClientService)); }, token: RemoteLoggerService, providedIn: "root" });
    __decorate([
        promiseTools.serialize,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], RemoteLoggerService.prototype, "_flush", null);
    return RemoteLoggerService;
}());
export { RemoteLoggerService };
