/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { LibCommonModule } from '../common/lib-common.module';
import { UserGuideComponent } from './components/user-guide.component';

@NgModule({
  declarations: [UserGuideComponent],
  imports: [CommonModule, GuidedTourModule, LibCommonModule],
  providers: [GuidedTourService],
  exports: [UserGuideComponent],
})
export class LibUserGuideModule {}
