/*!
 * Copyright 2019 Screencastify LLC
 */
import { AfterViewInit, ElementRef, OnDestroy, } from '@angular/core';
import { Rectangle, Size } from '@castify/models';
import { ShadowBoxComponent } from 'lib-editor/lib/tool-common/components/shadow-box/shadow-box.component';
import { applyPosition } from 'lib-editor/lib/tool-common/helpers';
import { Subscription } from 'rxjs';
import { ZoomEditorControllerService, } from '../../zoom-editor-controller.service';
var keepOpenSelectors = ['lib-zoom-editor', 'lib-zoom-toolbar'];
var ZoomEditorComponent = /** @class */ (function () {
    function ZoomEditorComponent(zoomEditorCtrl, hostElm) {
        this.zoomEditorCtrl = zoomEditorCtrl;
        this.hostElm = hostElm;
        this.subscriptions = new Subscription();
    }
    ZoomEditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscriptions.add(this.zoomEditorCtrl.zoomBox.subscribe(function (zoomBox) {
            return _this.updateZoomBox(zoomBox);
        }));
    };
    ZoomEditorComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ZoomEditorComponent.prototype.handleWindowResize = function () {
        this.updateZoomBox(this.zoomEditorCtrl.zoomBox.value);
    };
    ZoomEditorComponent.prototype.updateZoomBox = function (zoomBox) {
        if (!this.shadowBox || !this.zoomBox || !this.zoomEditorCtrl.clip.value)
            return;
        var hostDims = Size.fromObj(this.hostElm.nativeElement.getBoundingClientRect());
        var videoRect = this.getVideoRect();
        var zoomRect = new Rectangle(// zoom rectangle relative to the host element (= background)
        zoomBox.left * (videoRect.width / hostDims.width) +
            videoRect.left / hostDims.width, zoomBox.top * (videoRect.height / hostDims.height) +
            videoRect.top / hostDims.height, videoRect.width / hostDims.width / zoomBox.zoom, videoRect.height / hostDims.height / zoomBox.zoom);
        // update positions
        this.shadowBox.setCutout(zoomRect);
        applyPosition(this.zoomBox, zoomRect);
    };
    ZoomEditorComponent.prototype.onEditStart = function () {
        this._editStartBox = this.zoomEditorCtrl.zoomBox.value;
    };
    ZoomEditorComponent.prototype.onMove = function (event) {
        var videoRect = this.getVideoRect();
        var shiftX = event.shiftX / videoRect.width;
        var shiftY = event.shiftY / videoRect.height;
        this.zoomEditorCtrl.setZoomBox({
            zoom: this.zoomEditorCtrl.zoomBox.value.zoom,
            top: this._editStartBox.top + shiftY,
            left: this._editStartBox.left + shiftX,
        });
    };
    ZoomEditorComponent.prototype.onResize = function (event, topFixed, leftFixed) {
        var videoRect = this.getVideoRect();
        var shiftX = event.shiftX / videoRect.width;
        var shiftY = event.shiftY / videoRect.height;
        var right = this._editStartBox.left +
            1 / this._editStartBox.zoom +
            (leftFixed ? shiftX : 0);
        var left = this._editStartBox.left + (leftFixed ? 0 : shiftX);
        var bottom = this._editStartBox.top +
            1 / this._editStartBox.zoom +
            (topFixed ? shiftY : 0);
        var top = this._editStartBox.top + (topFixed ? 0 : shiftY);
        var zoomX = 1 / Math.max(right - left, Number.EPSILON); // EPSILON is the number closest to 0 we can divide by without error
        var zoomY = 1 / Math.max(bottom - top, Number.EPSILON);
        var zoom = this.zoomEditorCtrl.applyZoomLimits(Math.min(zoomX, zoomY));
        this.zoomEditorCtrl.setZoomBox({
            zoom: zoom,
            top: topFixed ? top : bottom - 1 / zoom,
            left: leftFixed ? left : right - 1 / zoom,
        });
    };
    ZoomEditorComponent.prototype.closeEditor = function (event) {
        var toolClicked = event
            .composedPath()
            .some(function (v) {
            return v && v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase());
        });
        if (!toolClicked) {
            this.zoomEditorCtrl.save();
            this.zoomEditorCtrl.close();
        }
    };
    /**
     * caclulate the videos dimensions and position relative to shadowBox (= background)
     */
    ZoomEditorComponent.prototype.getVideoRect = function () {
        var hostDims = Size.fromObj(this.hostElm.nativeElement.getBoundingClientRect());
        var videoDims = this.zoomEditorCtrl.clip.value.dimensions.scaleToFit(hostDims);
        var offsets = hostDims.sub(videoDims).div(2);
        return new Rectangle(offsets.width, offsets.height, videoDims.width, videoDims.height);
    };
    return ZoomEditorComponent;
}());
export { ZoomEditorComponent };
