/*!
 * Copyright 2020 Screencastify LLC
 */
import { ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { BlurEffect, Size } from '@castify/edit-models';
import { Rectangle } from '@castify/models';
import { applyPosition, resizeUnitRect, } from 'lib-editor/lib/tool-common/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';
export var kBlurBoxMinDims = new Size(0.05, 0.05);
var BlurBoxComponent = /** @class */ (function () {
    function BlurBoxComponent(ctrl, hostElm) {
        this.ctrl = ctrl;
        this.hostElm = hostElm;
        this._blurBox = new BehaviorSubject(null);
        this._editable = new BehaviorSubject(false);
        this.subscriptions = new Subscription();
    }
    Object.defineProperty(BlurBoxComponent.prototype, "blurBox", {
        get: function () {
            return this._blurBox.value;
        },
        set: function (box) {
            this._blurBox.next(box);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BlurBoxComponent.prototype, "editable", {
        set: function (v) {
            this._editable.next(v);
        },
        enumerable: true,
        configurable: true
    });
    BlurBoxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this._blurBox.subscribe(function (v) { return _this.updateBlurBox(v); }));
    };
    BlurBoxComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    BlurBoxComponent.prototype.onEditStart = function () {
        this._editStartBox = JSON.parse(JSON.stringify(this._blurBox.value));
    };
    BlurBoxComponent.prototype.onMove = function (event) {
        var _this = this;
        var parentRect = this.hostElm.nativeElement.parentElement.getBoundingClientRect();
        var shiftX = event.shiftX / parentRect.width;
        var shiftY = event.shiftY / parentRect.height;
        this.ctrl._editBlur(function (editor) {
            return editor.moveBox(_this.ctrl.selectedBox.value, _this._editStartBox.left + shiftX, _this._editStartBox.top + shiftY);
        });
    };
    BlurBoxComponent.prototype.onResize = function (event, topFixed, leftFixed) {
        var _this = this;
        if (!this._editStartBox)
            return;
        var parentRect = this.hostElm.nativeElement.parentElement.getBoundingClientRect();
        var resized = resizeUnitRect(Rectangle.fromObj(this._editStartBox), event.shiftX / parentRect.width, event.shiftY / parentRect.height, topFixed, leftFixed, kBlurBoxMinDims);
        this.ctrl._editBlur(function (editor) {
            editor.resizeBox(_this.ctrl.selectedBox.value, Size.fromObj(resized));
            editor.moveBox(_this.ctrl.selectedBox.value, resized.left, resized.top);
        });
    };
    BlurBoxComponent.prototype.updateBlurBox = function (box) {
        // position/size the blur overlay
        applyPosition(this.hostElm, box);
        this.updateBlurIntensity(box);
    };
    BlurBoxComponent.prototype.updateBlurIntensity = function (box) {
        var hostElm = this.hostElm.nativeElement;
        var clipDisplayWidth = hostElm.offsetWidth / (box.width + Number.EPSILON);
        var intensityPx = BlurEffect.intensityToBlurRadius(box.intensity, clipDisplayWidth);
        hostElm.style['backdrop-filter'] = "blur(" + intensityPx + "px)";
    };
    BlurBoxComponent.prototype.onWindowResize = function () {
        this.updateBlurBox(this._blurBox.value);
    };
    return BlurBoxComponent;
}());
export { BlurBoxComponent };
