var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LoggerAdapter, UserService } from 'lib-editor';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../lib-editor/src/lib/auth/user.service";
/**
 * Allows the app to decide how to control logger. This helps decouple app from the logger.
 * Differences in implementation from app to app can implemented here.
 */
var EditLoggerAdapter = /** @class */ (function (_super) {
    __extends(EditLoggerAdapter, _super);
    function EditLoggerAdapter(_auth) {
        var _this = _super.call(this) || this;
        _this.authedEndPoint = '';
        _this.loggedIn = false;
        _this.unauthedEndPoint = '';
        // this part can be decoupled and shared among all apps once migrated/updated to monorepo
        // can be a function defined in the LoggerAdapter and called here
        _auth.user
            .pipe(map(function (u) { return !!u; }), distinctUntilChanged())
            .subscribe(function (isSignedIn) {
            _this.loggedIn = isSignedIn;
        });
        return _this;
    }
    EditLoggerAdapter.ngInjectableDef = i0.defineInjectable({ factory: function EditLoggerAdapter_Factory() { return new EditLoggerAdapter(i0.inject(i1.UserService)); }, token: EditLoggerAdapter, providedIn: "root" });
    return EditLoggerAdapter;
}(LoggerAdapter));
export { EditLoggerAdapter };
