/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';
import { StillClipToolbarComponent } from './components/still-clip-toolbar/still-clip-toolbar.component';

const kEntryComponents = [StillClipToolbarComponent];

@NgModule({
  declarations: [...kEntryComponents],
  imports: [CommonModule, MatButtonModule, LibToolSidebarModule],
  entryComponents: kEntryComponents,
})
export class LibStillClipToolModule {}
