<lib-tool-sidebar-content>
  <div id="toolbar-header">
    <mat-icon>lens_blur</mat-icon>
    <h2>Blur</h2>
  </div>

  <div id="toolbar-content">
    <h4 id="field-title">Intensity</h4>

    <input
      type="range"
      class="slider"
      min="0"
      max="1"
      step="0.01"
      [ngStyle]="sliderGradient | async"
      [value]="intensity | async"
      (input)="onIntensityChange($event.target.value)"
      (focus)="keyboardShortcuts.pauseAll()"
      (blur)="keyboardShortcuts.resumeAll()"
    />
    <div class="labels">
      <div>Low</div>
      <div>High</div>
    </div>

    <br />

    <button mat-button id="new-blur-button" (click)="onAddClick()">
      + New Blur
    </button>
  </div>

  <button id="done-button" mat-stroked-button (click)="closeEditor()">
    Done
  </button>
</lib-tool-sidebar-content>
