/*!
 * Copyright 2018 Screencastify LLC
 */
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
/**
 * Helper service to register shortcuts in a directive.
 * Automatically removes all shortcuts when destroyed.
 * Make sure to inject with @Self annotation.
 *
 * We currently wrap around angular2-hotkeys, but should probably dump that and use
 * Moustrap directly, since we are not using much its functionality anyway.
 *
 * Note that this is set up as a component-scoped/directive-scoped service
 */
var KeyboardService = /** @class */ (function () {
    function KeyboardService(_hotkeysService) {
        this._hotkeysService = _hotkeysService;
        this._hotKeysList = [];
        this._oldHotKeys = [];
    }
    KeyboardService.prototype.add = function (shortcut, callback) {
        var e_1, _a;
        var hotkeyObj = new Hotkey(shortcut.combo, function (event, combo) {
            return callback(event, combo) || false;
        }, undefined, shortcut.desc);
        try {
            for (var _b = __values(shortcut.combo), _c = _b.next(); !_c.done; _c = _b.next()) {
                var combo = _c.value;
                var oldHotkey = this._hotkeysService.get(combo);
                if (oldHotkey !== null) {
                    this._oldHotKeys.push(oldHotkey);
                    this._hotkeysService.remove(oldHotkey);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this._hotKeysList.push(hotkeyObj);
        this._hotkeysService.add(hotkeyObj);
    };
    /**
     * Pauses any active hotkeys (e.g. for when a modal is open which should
     * function as a focus trap)
     */
    KeyboardService.prototype.pauseAll = function () {
        // for why we copy the array here, see:
        // https://github.com/brtnshrdr/angular2-hotkeys/issues/101
        this.pausedHotkeys = __spread(this._hotkeysService.hotkeys);
        this._hotkeysService.pause(this.pausedHotkeys);
    };
    /**
     * Resumes any previously paused hotkeys.
     */
    KeyboardService.prototype.resumeAll = function () {
        this._hotkeysService.unpause(this.pausedHotkeys);
        this.pausedHotkeys = [];
    };
    KeyboardService.prototype.ngOnDestroy = function () {
        var e_2, _a, e_3, _b;
        try {
            for (var _c = __values(this._hotKeysList), _d = _c.next(); !_d.done; _d = _c.next()) {
                var hotkey = _d.value;
                this._hotkeysService.remove(hotkey);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            for (var _e = __values(this._hotKeysList), _f = _e.next(); !_f.done; _f = _e.next()) {
                var oldKey = _f.value;
                this._hotkeysService.add(oldKey);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    return KeyboardService;
}());
export { KeyboardService };
