/*!
 * Copyright 2019 Screencastify LLC
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { kMinClipDuration, } from '@castify/edit-models';
import { Limits } from '@castify/models';
import * as Hammer from 'hammerjs';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PreviewStateService } from '../../../preview/preview-state.service';
import { TimelineStateService } from '../../timeline-state.service';
import { TimelineOverlayComponent } from '../timeline-overlay/timeline-overlay.component';
// factor by which the deltaY value from scroll event is multiplied to determine zoom change
var kZoomSpeed = 1 / 250;
// value which is skipped when arrowkeys are pressed (this should be 40 minimally since thats one frame)
var kArrowTimeSkip = 40;
var TimelineComponent = /** @class */ (function () {
    function TimelineComponent(timelineState, previewState, hostElm) {
        var _this = this;
        this.timelineState = timelineState;
        this.previewState = previewState;
        this.hostElm = hostElm;
        this.clips = {
            videoAndAudio: new BehaviorSubject([]),
            annotations: new BehaviorSubject([]),
            still: new BehaviorSubject([]),
        };
        this.lastDeltaTime = 0;
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.timelineState.zoomFactor.subscribe(function () {
            return _this.updateDisplayDuration();
        }));
    }
    TimelineComponent.prototype.filterVideoAndAudioClips = function (scene) {
        var videoClips = scene.filterClipType(['video']).clips.items;
        return videoClips.map(function (videoClip) {
            var audioClip = (scene
                .filterClipType(['audio'])
                .clips.filterByInsertId(videoClip.insertId)[0]);
            return {
                video: videoClip,
                audio: audioClip,
            };
        });
    };
    TimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        var hm = new Hammer(this.hostElm.nativeElement, {});
        hm.add(new Hammer.Pan({
            event: 'twoFingersPan',
            pointers: 2,
            threshold: 0,
            direction: Hammer.DIRECTION_HORIZONTAL,
        }));
        hm.on('twoFingersPan', function (event) {
            _this.timelineState.scroll(_this.timelineState.startTime.value +
                _this.timelineState.pixelsToTime(event.velocity * (event.deltaTime - _this.lastDeltaTime)));
            _this.lastDeltaTime = event.deltaTime;
            if (event.isFinal)
                _this.lastDeltaTime = 0;
        });
        // split clip types
        this.subscriptions.add(this.timelineState.scene
            .pipe(filter(function (scene) { return !!scene; }), map(function (scene) { return _this.filterVideoAndAudioClips(scene); }))
            .subscribe(this.clips.videoAndAudio));
        this.subscriptions.add(this.timelineState.scene
            .pipe(filter(function (scene) { return !!scene; }), map(function (scene) { return scene.filterClipType(['annotations']).clips.items; }), distinctUntilChanged(), map(function (v) { return v.map(function (c) { return c.copy(); }); }))
            .subscribe(this.clips.annotations));
        this.subscriptions.add(this.timelineState.scene
            .pipe(filter(function (scene) { return !!scene; }), map(function (scene) { return scene.filterClipType(['still']).clips.items; }), distinctUntilChanged(), map(function (v) { return v.map(function (c) { return c.copy(); }); }))
            .subscribe(this.clips.still));
        // scale background grid
        this.subscriptions.add(this.timelineState.zoomFactor.subscribe(function (zoomFactor) {
            var pixelsPerSecond = 1000 * zoomFactor;
            _this.hostElm.nativeElement.style.setProperty('background-size', pixelsPerSecond + "px");
        }));
        this.subscriptions.add(this.timelineState.startTime.subscribe(function (startTime) {
            _this.hostElm.nativeElement.style.setProperty('background-position', _this.timelineState.timeToPixels(startTime) + "px");
        }));
        this.subscriptions.add(combineLatest([
            this.previewState.scene,
            this.timelineState.toolSidebarData,
        ])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), scene = _b[0], toolSidebarData = _b[1];
            return [
                scene,
                !!toolSidebarData,
                !!toolSidebarData ? toolSidebarData.clip : null,
            ];
        }))
            .subscribe(function (_a) {
            var _b = __read(_a, 3), scene = _b[0], active = _b[1], clip = _b[2];
            if (active) {
                _this.overlay.show();
                // max limit is set to last frame -1. Same reason as for last frame in PreviewComponent
                _this.previewState.seekLimits.next(new Limits(Math.max(0, clip.startInScene), clip.endInScene - kMinClipDuration - 1));
            }
            else {
                _this.overlay.hide();
                _this.previewState.seekLimits.next(new Limits(0, scene ? scene.duration : 0));
            }
        }));
        this.subscriptions.add(this.clips.annotations
            .pipe(map(function (clips) { return !!clips.length; }), distinctUntilChanged())
            .subscribe(function (hasText) {
            if (hasText) {
                _this.hostElm.nativeElement.classList.add('has-text');
                window.dispatchEvent(new Event('resize'));
            }
            else {
                _this.hostElm.nativeElement.classList.remove('has-text');
                window.dispatchEvent(new Event('resize'));
            }
        }));
    };
    TimelineComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.updateDisplayDuration(); }, 1);
    };
    Object.defineProperty(TimelineComponent.prototype, "playheadCursorOffset", {
        get: function () {
            var _this = this;
            return combineLatest([
                this.timelineState.startTime,
                this.timelineState.zoomFactor,
                this.timelineState.playheadCursor,
            ]).pipe(map(function (_a) {
                var _b = __read(_a, 3), startTime = _b[0], _ = _b[1], playheadPos = _b[2];
                return _this.timelineState.timeToPixels(playheadPos + startTime);
            }));
        },
        enumerable: true,
        configurable: true
    });
    TimelineComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(TimelineComponent.prototype, "scrubCursorOffset", {
        get: function () {
            var _this = this;
            return combineLatest([
                this.timelineState.startTime,
                this.timelineState.zoomFactor,
                this.timelineState.scrubCursor,
                this.timelineState.overrideCursor,
            ]).pipe(map(function (_a) {
                var _b = __read(_a, 4), startTime = _b[0], _ = _b[1], scrubPos = _b[2], overridePos = _b[3];
                if (scrubPos === null || overridePos !== null) {
                    return null;
                }
                else {
                    return _this.timelineState.timeToPixels(scrubPos + startTime);
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    TimelineComponent.prototype.updateDisplayDuration = function () {
        this.timelineState.displayDuration.next(this.timelineState.pixelsToTime(this.hostElm.nativeElement.offsetWidth));
    };
    /**
     * handle scrolling and zooming from scroll wheel
     */
    TimelineComponent.prototype.onWheel = function (event) {
        if (event.ctrlKey) {
            // zoom
            var oldZoomFactor = this.timelineState.zoomFactor.value;
            var oldMouseOffsetInScene = this.hostElm.nativeElement.scrollLeft + event.x;
            // apply zoom (updates millisPerPixel synchronously
            var zoomFactor = this.timelineState.zoomFactor.value;
            this.timelineState.setZoom(zoomFactor - zoomFactor * event.deltaY * kZoomSpeed);
            // adjust scroll to zoom around mouse position
            var zoomFactorChange = this.timelineState.zoomFactor.value / oldZoomFactor;
            // calculate leftmost point of the timeline that gets displayed (= scroll position)
            var scrollPixels = oldMouseOffsetInScene * zoomFactorChange - event.x;
            this.timelineState.scroll(this.timelineState.startTime.value -
                this.timelineState.pixelsToTime(scrollPixels));
            this.updateDisplayDuration();
        }
        else {
            // scroll
            // deltaX is for vertical scrolling on touchpads
            var scrollPixels = event.deltaX || event.deltaY;
            this.timelineState.scroll(this.timelineState.startTime.value -
                this.timelineState.pixelsToTime(scrollPixels));
        }
        return false; // don't propagate the event further to avoid zooming in on the page
    };
    TimelineComponent.prototype.onMouseMove = function (event) {
        if (!this.previewState.isPlaying.value &&
            !this.previewState.shouldPlay.value) {
            // scrub
            var cursorOffsetLeft = event.x + this.hostElm.nativeElement.scrollLeft;
            this.timelineState.scrub(this.timelineState.pixelsToTime(cursorOffsetLeft) -
                this.timelineState.startTime.value);
        }
    };
    TimelineComponent.prototype.onClick = function (event) {
        var continuePlaying = this.previewState.isPlaying.value && this.previewState.shouldPlay.value;
        this.previewState.pause();
        var seekPosition = this.timelineState.pixelsToTime(event.x + this.hostElm.nativeElement.scrollLeft) - this.timelineState.startTime.value;
        // seek to where the click occurred
        // update timeline
        this.timelineState.seek(seekPosition);
        // update preview
        this.previewState.seek(seekPosition);
        // clear selection if no clip was hit
        if (event.target === this.hostElm.nativeElement)
            this.timelineState.selection.next([]);
        if (continuePlaying) {
            this.previewState.play();
        }
    };
    TimelineComponent.prototype.onMouseLeave = function () {
        this.timelineState.endScrub();
    };
    TimelineComponent.prototype.seekFrame = function (frames) {
        if (frames === void 0) { frames = 1; }
        if (!this.previewState.shouldPlay.value &&
            !this.previewState.isPlaying.value) {
            var skip = frames * kArrowTimeSkip;
            var seekPosition = this.timelineState.playheadCursor.value + skip;
            this.timelineState.seek(seekPosition);
            this.previewState.seek(seekPosition);
        }
    };
    TimelineComponent.prototype.videoAudioTrackBy = function (_, videoAndAudio) {
        return (videoAndAudio.video.id +
            (videoAndAudio.audio ? videoAndAudio.audio.id : ''));
    };
    TimelineComponent.prototype.clipTrackBy = function (_, clip) {
        return clip.id;
    };
    return TimelineComponent;
}());
export { TimelineComponent };
