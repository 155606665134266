/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AnnotationsClip, TextBox } from '@castify/edit-models';
import { Size, sty } from '@castify/models';
import { ClipViewComponent } from 'lib-editor/lib/preview/components/clip-view/clip-view.component';
import { BehaviorSubject, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';

// must be changed manually in css if changed here
const kCanvasBaseWidth = 1600;
const kCanvasBaseHeight = 900;
const kCanvasBaseDimensions = new Size(kCanvasBaseWidth, kCanvasBaseHeight);

@Component({
  selector: 'lib-annotations-preview-view',
  templateUrl: './annotations-preview-view.component.html',
  styleUrls: ['./annotations-preview.component.scss'],
})
export class AnnotationsPreviewViewComponent
  extends ClipViewComponent<AnnotationsClip>
  implements OnDestroy, OnInit, AfterViewInit {
  /**
   * Overrides the setter on the parent class. Intended to be used in at
   * least three different ways: to pass in the clip by preview, in which
   * case data is passed once and only once during component's lifecycle;
   * by render-host when injecting data; by the Edit component whenever
   * data changes.
   */
  set clip(clip: AnnotationsClip) {
    // possible for clip to be null in edge cases
    // where clip has been deleted from timeline
    // but is still being rendered by preview
    if (clip) {
      this._clip = clip;
      this._textBoxes.next(clip.boxes);
    }
    // Hacky fix, see note in seek()
    this.allResizeEvents.next(null);
  }
  readonly _textBoxes = new BehaviorSubject<TextBox[]>([]);

  // tells preview that this is always playable
  readonly canPlay = new BehaviorSubject<boolean>(true);

  /**
   * These refs are intended to be consumed in observables
   * which help with resizing.
   */
  @ViewChild('bounds') protected bounds: ElementRef<HTMLDivElement>;
  @ViewChild('container') protected container: ElementRef<HTMLDivElement>;

  /**
   * Intended to emit whenever window resize takes place, but can also be
   * manually triggers to ensure resize happens at various points in lifecycle.
   */
  readonly allResizeEvents = new BehaviorSubject<null>(null);

  /**
   * Emits a ref to the container element whenever a resize takes place
   * but only when the component is visible
   */
  readonly containerOnResize = this.allResizeEvents.pipe(
    switchMap(() => of(this.container)),
    filter((container) => !!container && !!container.nativeElement),
    map((el) => el.nativeElement)
  );

  /**
   * Emits a ref to the bounds element whenever a resize takes place,
   * but only when the component is visible
   */
  readonly boundsOnResize = this.allResizeEvents.pipe(
    switchMap(() => of(this.bounds)),
    filter((bounds) => !!bounds && !!bounds.nativeElement),
    map((el) => el.nativeElement)
  );

  /**
   * Dimensions of the container's parent. Intended for use in markup
   * to pass container dimensions into child components
   */
  public readonly parentDims = this.boundsOnResize.pipe(
    map((boundsElem) => boundsElem.getBoundingClientRect()),
    distinctUntilChanged((prev, curr) => {
      return Object.keys(prev).every((key) => prev[key] === curr[key]);
    })
  );

  /**
   * The dimensions of the canvas when scaled down to the
   * current size of the window/parent container. Both intended
   * for markup use, but can be used elsewhere
   */
  readonly scaledDims = this.boundsOnResize.pipe(
    map((boundsElem) =>
      kCanvasBaseDimensions.scaleToFit(
        new Size(boundsElem.offsetWidth, boundsElem.offsetHeight)
      )
    )
  );
  public readonly scaledWidth = this.scaledDims.pipe(
    map((dims) => dims.width),
    distinctUntilChanged()
  );
  public readonly scaledHeight = this.scaledDims.pipe(
    map((dims) => dims.height),
    distinctUntilChanged()
  );

  /**
   * Derived state from the scaled height; intended for use in markup.
   */
  public readonly scaleFactor = this.scaledWidth.pipe(
    map((scaledWidth) => {
      return scaledWidth / kCanvasBaseWidth;
    }),
    startWith(1),
    // don't allow zero
    filter((x) => x !== 0),
    distinctUntilChanged()
  );

  /**
   * The inverse of the scale factor.
   *
   * Necessary because width and height are expressed as a percentage of the
   * container, and the text-containing element must have its width and height
   * up before the element is scaled to size via CSS transforms.
   */
  public readonly inverseScaleFactor = this.scaleFactor.pipe(map((x) => 1 / x));

  constructor(public readonly ctrl: AnnotationsToolControllerService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  play(): void {}

  pause(): void {}

  seek(pos: number): void {}

  get startInScene(): sty.Milliseconds {
    return this._clip.startInScene;
  }

  onDisplay(): void {
    this.allResizeEvents.next(null);
  }

  onHide(): void {}

  /**
   * resizes container and editor to fit the video element and
   * scales text so it appears fixed on the video
   */
  @HostListener('window:resize')
  resize(): void {
    this.allResizeEvents.next(null);
  }
}
