/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { PostLogMetadataDto, PostLogPayloadDto } from '@castify/edit-models';
import { UiApiService } from 'lib-editor';

/**
 * Optional metadata added each request.
 */
export type IPostLogMetadata = PostLogMetadataDto;
export type IPostLogPayload = PostLogPayloadDto;

/**
 * Http client for sending client side logs to backend
 */
@Injectable({
  providedIn: 'root',
})
export class RemoteLoggerClientService {
  constructor(private uiApi: UiApiService) {}

  public async postAuthLogs(payload: PostLogPayloadDto): Promise<void> {
    await this.uiApi.postAuthLogs(payload).toPromise();
  }

  public async postUnauthLogs(payload: PostLogPayloadDto): Promise<void> {
    await this.uiApi.postUnauthLogs(payload).toPromise();
  }
}
