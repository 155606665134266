/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  ClipModel2,
  CropTransform,
  Rectangle,
  SceneEditor2,
  VideoClip,
} from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../timeline/timeline-state.service';

const log = Log.create('CropEditorControllerService');
const kInitialCropRect = new Rectangle(0.1, 0.1, 0.8, 0.8);

@Injectable({
  providedIn: 'root',
})
export class CropEditorControllerService extends SingleClipEditorControllerService {
  readonly _cropRect = new BehaviorSubject<Rectangle | null>(null); // current crop rectangle in unit values
  protected sceneEditor: SceneEditor2;

  constructor(
    protected timelineState: TimelineStateService,
    protected previewState: PreviewStateService,
    private undoManager: UndoManagerService
  ) {
    super(timelineState, previewState);

    // update clip timeline from selection
    this.timelineState.selection
      .pipe(map((selection) => this._getTargetClip(selection)))
      .subscribe(this.clip);

    // sync canActivate
    combineLatest([this.clip, this.isActive])
      .pipe(map(([clip, isActive]) => !!clip && !isActive))
      .subscribe(this.canActivate);
  }

  open(): void {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening editor not fulfilled');
    if (!this.clip.value)
      throw new Error('Failed to open crop tool: no clip selected');

    this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
    const crop = this.clip.value.transform.crop;
    if (!!crop) {
      // load initial crop
      this._cropRect.next(this.cropToRect(crop, this.clip.value));
    } else {
      // add new crop
      this._setCropRect(kInitialCropRect);
    }

    // make preview display an uncropped version of the clip
    const previewScene = this.undoManager.scene.value
      .filterClipType(['video'])
      .copy();
    const previewClip = <VideoClip>previewScene.clips.byId(this.clip.value.id);
    previewClip.transform.crop = null;
    previewClip.effects = previewClip.effects.filterType(['blur']);
    this.previewState.overrideScene.next(previewScene);
    super.open();
  }

  reset(): void {
    this.sceneEditor.getClipEditor(this.clip.value).crop(null);
    this.save();
    this.close();
  }

  close() {
    super.close();
    this._cropRect.next(null);
    this.sceneEditor = null;
  }

  save(): void {
    if (
      this.sceneEditor &&
      this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash
    ) {
      log.info('save crop');
      this.undoManager.update(this.sceneEditor.scene);
    }
  }

  /**
   * manipulate crop rectangle.
   * @param newRect new crop rect in unit values
   */
  _setCropRect(rect: Rectangle): void {
    const clip = this.clip.value;
    const videoEditor = this.sceneEditor.getClipEditor(clip);
    const newCrop = videoEditor.crop(this.rectToCrop(rect, clip));
    this._cropRect.next(this.cropToRect(newCrop, clip));
  }

  protected rectToCrop(rect: Rectangle, clip: VideoClip): CropTransform {
    return new CropTransform({
      left: rect.left * clip.srcDimensions.width,
      width: rect.width * clip.srcDimensions.width,
      top: rect.top * clip.srcDimensions.height,
      height: rect.height * clip.srcDimensions.height,
    });
  }
  protected cropToRect(crop: CropTransform, clip: VideoClip): Rectangle {
    return new Rectangle(
      crop.left / clip.srcDimensions.width,
      crop.top / clip.srcDimensions.height,
      crop.width / clip.srcDimensions.width,
      crop.height / clip.srcDimensions.height
    );
  }

  protected _getTargetClip(
    timelineSelection: (ClipModel2 | EffectSelection)[]
  ): VideoClip | null {
    const clipCandidates = timelineSelection.filter(
      (clip) => clip instanceof VideoClip
    );
    return <VideoClip>clipCandidates[0] || null;
  }
}
