/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2019 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = ["audio[_ngcontent-%COMP%] {\n  display: none; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvbW9kdWxlcy9lZGl0LXVpL3Byb2plY3RzL2xpYi1lZGl0b3Ivc3JjL2xpYi9wcmV2aWV3L2NvbXBvbmVudHMvYXVkaW8tY2xpcC12aWV3L2F1ZGlvLWNsaXAtdmlldy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQztFQUNFLGFBQWEsRUFBQSIsImZpbGUiOiJwcm9qZWN0cy9saWItZWRpdG9yL3NyYy9saWIvcHJldmlldy9jb21wb25lbnRzL2F1ZGlvLWNsaXAtdmlldy9hdWRpby1jbGlwLXZpZXcuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIgYXVkaW8ge1xuICAgZGlzcGxheTogbm9uZTtcbiB9Il19 */"];
export { styles as styles };
