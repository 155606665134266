/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, HostListener, ViewChild } from '@angular/core';
import { SideToolbarContentComponent } from '../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { InjectableToolbarComponent } from '../../../tool-sidebar/components/tool-sidebar/tool-sidebar.component';
import { StillClipToolControllerService } from '../../still-clip-tool-controller.service';

const kColorChoices = [
  '000000',
  '575757',
  'B4AFAF',
  '00D1FF',
  '0066cc',
  '022069',
  'AF7302',
  'EA6503',
  'ff9900',
  'ffff00',
  '92FE00',
  '008a00',
  '096305',
  'e60000',
  'FF00D8',
  '9933ff',
  '440178',
  'ffffff',
];

const keepOpenSelectors = [
  'lib-still-video-clip',
  'lib-text-overlay',
  'lib-still-clip-toolbar',
];

@Component({
  selector: 'lib-still-clip-toolbar',
  templateUrl: './still-clip-toolbar.component.html',
  styleUrls: ['./still-clip-toolbar.component.scss'],
})
export class StillClipToolbarComponent implements InjectableToolbarComponent {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;
  private closable = false;

  colorChoices = kColorChoices;

  constructor(private ctrl: StillClipToolControllerService) {
    // HACK: touchscreen registers mousedown events too early,
    // triggering closeIfClickedOutside on opening
    setTimeout(() => (this.closable = true), 0);
  }

  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      await this._toolbarContainer.close();
    }
  }

  closeEditor(): void {
    this.ctrl.save();
    this.ctrl.close();
  }

  @HostListener('window:mousedown', ['$event'])
  closeIfClickedOutside(event: MouseEvent) {
    const toolClicked = event.composedPath().some(
      (v: HTMLElement) =>
        v &&
        // check if tag name indicates it is part of the tool
        ((v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
          // check if element id indicates it is part of the tool
          (v.id && keepOpenSelectors.includes(`#${v.id}`)))
    );
    if (!toolClicked && this.closable) {
      this.closeEditor();
    }
  }
}
