/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
import { AnnotationsToolControllerService } from 'lib-editor/lib/annotations-tool/annotations-tool-controller.service';
import { UndoManagerService } from 'lib-editor/lib/common/undo-manager.service';
import { CropEditorControllerService } from 'lib-editor/lib/crop-editor/crop-editor-controller.service';
import { ZoomEditorControllerService } from 'lib-editor/lib/zoom-editor/zoom-editor-controller.service';
var UndoControlsComponent = /** @class */ (function () {
    function UndoControlsComponent(undoManager, zoomCtrl, cropCtrl, annotationsCtrl) {
        this.undoManager = undoManager;
        this.zoomCtrl = zoomCtrl;
        this.cropCtrl = cropCtrl;
        this.annotationsCtrl = annotationsCtrl;
    }
    UndoControlsComponent.prototype.ngOnInit = function () { };
    UndoControlsComponent.prototype.onUndoClick = function () {
        if (!this.undoManager.canUndo.value)
            return;
        this._closeAllEditors();
        this.undoManager.undo();
    };
    UndoControlsComponent.prototype.onRedoClick = function () {
        if (!this.undoManager.canRedo.value)
            return;
        this._closeAllEditors();
        this.undoManager.redo();
    };
    UndoControlsComponent.prototype._closeAllEditors = function () {
        if (this.zoomCtrl.isActive.value)
            this.zoomCtrl.close();
        if (this.cropCtrl.isActive.value)
            this.cropCtrl.close();
        if (this.annotationsCtrl.isActive.value)
            this.annotationsCtrl.close();
    };
    return UndoControlsComponent;
}());
export { UndoControlsComponent };
