/*!
 * Copyright 2020 Screencastify LLC
 */
import { AudioToolbarComponent } from './components/audio-toolbar/audio-toolbar.component';
var kEntryComponents = [AudioToolbarComponent];
var LibAudioToolModule = /** @class */ (function () {
    function LibAudioToolModule() {
    }
    return LibAudioToolModule;
}());
export { LibAudioToolModule };
