/*!
 * Copyright 2020 Screencastify LLC
 */
import { KeysDirective } from './directives/shortcut.directive';
var exportDeclarations = [KeysDirective];
var LibKeyboardShortcutsModule = /** @class */ (function () {
    function LibKeyboardShortcutsModule() {
    }
    return LibKeyboardShortcutsModule;
}());
export { LibKeyboardShortcutsModule };
