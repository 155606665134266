/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { UserService } from 'lib-editor/lib/auth/user.service';
import { ProjectService } from 'lib-editor/lib/common/project.service';
import { ConfirmNewProjectComponent } from '../confirm-new-project/confirm-new-project.component';

@Component({
  selector: 'lib-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Output() closeNav = new EventEmitter<void>();

  constructor(
    private user: UserService,
    private project: ProjectService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  async onNewProjectClick(): Promise<void> {
    const dialog = this.dialog.open(ConfirmNewProjectComponent, {
      autoFocus: true,
    });
    if (await dialog.beforeClosed().toPromise()) {
      await this.project.loadNewProject();
      this.closeNav.emit();
    }
  }

  onMyVideosClick(): void {
    window.open(environment.myVideosURL);
  }

  onMyAccountClick(): void {
    window.open(environment.castifyAccountUrl);
  }

  onHelpClick(): void {
    window.open(environment.externalLinks.support);
  }

  async onSignOutClick(): Promise<void> {
    await this.user.signOut();
  }
}
