/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { LibCommonModule } from '../common/lib-common.module';
import { LibKeyboardShortcutsModule } from '../keyboard-shortcuts/keyboard-shortcuts.module';
import { ScrollbarComponent } from './components/scrollbar/scrollbar.component';
import { TimelineAnnotationsComponent } from './components/timeline-annotations/timeline-annotations.component';
import { TimelineBlurComponent } from './components/timeline-blur/timeline-blur.component';
import { TimelineClipComponent } from './components/timeline-clip/timeline-clip.component';
import { TimelineEffectComponent } from './components/timeline-effect/timeline-effect.component';
import { TimelineOverlayComponent } from './components/timeline-overlay/timeline-overlay.component';
import { TimelineRulerComponent } from './components/timeline-ruler/timeline-ruler.component';
import { TimelineStillClipComponent } from './components/timeline-still-clip/timeline-still-clip.component';
import { TimelineVideoAudioComponent } from './components/timeline-video-audio/timeline-video-audio.component';
import { TimelineVideoComponent } from './components/timeline-video/timeline-video.component';
import { TimelineZoomComponent } from './components/timeline-zoom/timeline-zoom.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { VideoThumbnailComponent } from './components/video-thumbnail/video-thumbnail.component';

const kExportComponents = [TimelineComponent];

@NgModule({
  declarations: [
    ...kExportComponents,
    TimelineVideoComponent,
    TimelineVideoAudioComponent,
    TimelineClipComponent,
    VideoThumbnailComponent,
    TimelineRulerComponent,
    TimelineOverlayComponent,
    TimelineZoomComponent,
    TimelineEffectComponent,
    ScrollbarComponent,
    TimelineBlurComponent,
    TimelineStillClipComponent,
    TimelineAnnotationsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    LibCommonModule,
    LibKeyboardShortcutsModule,
  ],
  exports: kExportComponents,
})
export class LibTimelineModule {}
