/*!
 * Copyright 2018 Screencastify LLC
 */
import { BlurEditorComponent } from './components/blur-editor/blur-editor.component';
import { BlurToolbarComponent } from './components/blur-toolbar/blur-toolbar.component';
import { BlurViewComponent } from './components/blur-view/blur-view.component';
var kEntryComponents = [
    BlurEditorComponent,
    BlurToolbarComponent,
    BlurViewComponent,
];
var LibBlurEditorModule = /** @class */ (function () {
    function LibBlurEditorModule() {
    }
    return LibBlurEditorModule;
}());
export { LibBlurEditorModule };
