/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { TextBox, VerticalAlignment } from '@castify/edit-models/';
import Quill from 'quill';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { makeQuillInstance } from '../../quill-config';

/**
 * This view component encapsulates Quill instantiation, Quill-related styles,
 * and some Quill lifecycle & user-interaction concerns. Source of data is
 * intended to be preview.component.ts (passed in via `.clip`)
 */
@Component({
  selector: 'lib-quill-view',
  templateUrl: './quill-container.component.html',
  styleUrls: ['./quill-container.component.scss'],
})
export class QuillContainerViewComponent implements OnDestroy, AfterViewInit {
  protected subscriptions = new Subscription();
  cursorStyle = 'pointer';
  overflow = 'hidden';

  /**
   * _textBox emits the data to render
   */
  @Input()
  set textBox(box: TextBox) {
    this._textBox.next(box);
  }
  get textBox(): TextBox {
    return this._textBox.value;
  }
  readonly _textBox = new BehaviorSubject<TextBox | null>(null);

  /**
   * Quill instance
   */
  quill: Quill = null;

  constructor(
    protected hostElm: ElementRef<HTMLDivElement>,
    public ctrl: AnnotationsToolControllerService
  ) {}

  ngAfterViewInit(): void {
    // Initialize Quill instance
    this.quill = makeQuillInstance(
      this.hostElm.nativeElement.querySelector('[quill-editor-element]'),
      true,
      () => {}
    );
    this.quill.setContents(this.textBox.delta, 'silent');

    // set up subscriptions to text box updates
    this.subscriptions.add(
      this._textBox.subscribe((box) => {
        this.setVerticalAlignment(box.verticalAlignment);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Quill renders contensts via flex box; will that flexbox
   * overflow on the top or on the bottom? We do this here
   * vs. the Angluar template because we need to alter a child of Quill's
   * root element not rendered in the template.
   */
  setVerticalAlignment(verticalAlignment: VerticalAlignment): void {
    const alignmentMap = {
      top: 'flex-start',
      center: 'center',
      bottom: 'flex-end',
    };
    const qlEditor = <HTMLDivElement>(
      this.hostElm.nativeElement.firstChild.firstChild
    );
    qlEditor.style.justifyContent = alignmentMap[verticalAlignment];
  }

  /**
   * Makes sure clicks don't make it up to drag handlers in the components
   * higher than this in the view, and opens the editor. Note this is not
   * intended to be attached to edit-mode quill components, and it is expected
   * it will be overridden.
   */
  onQuillMousedown(event: MouseEvent): void {
    event.stopPropagation();
    this.ctrl.open(this.textBox.id);
  }
}
