/*!
 * Copyright 2020 Screencastify LLC
 */
/**
 * Used as injection token for a service implementing it. We use an abstract
 * class instead of an interface / injectionToken for more convenient DI
 * wiring.
 * Copy of Record abstract class. TODO: dedupe once moved to shared code source.
 */
var LoggerAdapter = /** @class */ (function () {
    function LoggerAdapter() {
    }
    return LoggerAdapter;
}());
export { LoggerAdapter };
