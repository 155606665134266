/*!
 * Copyright 2020 Screencastify LLC
 */
import { Routes } from '@angular/router';
import { BrowserGuardComponent } from './components/browser-guard/browser-guard.component';
var routes = [
    {
        path: 'unsupported_browser',
        component: BrowserGuardComponent,
    },
];
var BrowserGuardRoutingModule = /** @class */ (function () {
    function BrowserGuardRoutingModule() {
    }
    return BrowserGuardRoutingModule;
}());
export { BrowserGuardRoutingModule };
