<lib-tool-sidebar-content>
  <div id="toolbar-header">
    <mat-icon>zoom_in</mat-icon>
    <h2>Zoom</h2>
  </div>

  <div id="toolbar-content">
    <h4 id="field-title">Transition Speed</h4>

    <div class="edit-options">
      <div class="input-container">
        <label for="buildIn">IN</label>
        <input
          type="number"
          id="buildIn"
          title=" "
          min="0"
          [max]="(buildInMax | async) / 1000"
          [step]="buildStepSize"
          [ngModel]="(ctrl.buildIn | async) / 1000"
          (ngModelChange)="setBuildIn($event)"
        />
      </div>

      <div class="input-container">
        <label for="buildOut">OUT</label>
        <input
          type="number"
          id="buildOut"
          title=" "
          min="0"
          [max]="(buildOutMax | async) / 1000"
          [step]="buildStepSize"
          [ngModel]="(ctrl.buildOut | async) / 1000"
          (ngModelChange)="setBuildOut($event)"
        />
      </div>
    </div>

    <div class="limitMessage" *ngIf="durationLimitHit | async">
      Transitions cannot be longer than clip
    </div>
  </div>

  <button id="done-button" mat-stroked-button (click)="closeEditor()">
    Done
  </button>
</lib-tool-sidebar-content>
