/*!
 * Copyright 2019 Screencastify LLC
 */
var ConstantDurationSegmentHelper = /** @class */ (function () {
    function ConstantDurationSegmentHelper(segmentDuration) {
        this.segmentDuration = segmentDuration;
    }
    /**
     * calculate index of a segment at a certain position (clip time)
     * This is the inverse function to this._segStartTime
     */
    ConstantDurationSegmentHelper.prototype.segIdxAtPos = function (clip, pos) {
        return Math.floor((clip.startInFile + Math.min(clip.duration - 1, pos)) /
            this.segmentDuration);
    };
    /**
     * calculate start pos (clip time) of segment identified by segIdx.
     * This is the inverse function to this._segIdxAtPos
     */
    ConstantDurationSegmentHelper.prototype.segStartPos = function (clip, segIdx) {
        var res = Math.max(0, segIdx * this.segmentDuration - clip.startInFile);
        return res;
    };
    /**
     * calculate seek time in segment from pos in clip (clip time)
     */
    ConstantDurationSegmentHelper.prototype.segSeekTimeFromClipPos = function (clip, segIdx, pos) {
        return pos + clip.startInFile - segIdx * this.segmentDuration;
    };
    return ConstantDurationSegmentHelper;
}());
export { ConstantDurationSegmentHelper };
