/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-placeholder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./text-placeholder.component";
var styles_TextPlaceholderComponent = [i0.styles];
var RenderType_TextPlaceholderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextPlaceholderComponent, data: {} });
export { RenderType_TextPlaceholderComponent as RenderType_TextPlaceholderComponent };
function View_TextPlaceholderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "placeholder-container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { alignItems: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "placeholder"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(7, { textAlign: 0 }), (_l()(), i1.ɵted(-1, null, ["Write something..."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.verticalAlignment))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.horizontalAlignment))); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TextPlaceholderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TextPlaceholderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.shouldRender)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextPlaceholderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-text-placeholder", [], null, null, null, View_TextPlaceholderComponent_0, RenderType_TextPlaceholderComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextPlaceholderComponent, [], null, null)], null, null); }
var TextPlaceholderComponentNgFactory = i1.ɵccf("lib-text-placeholder", i3.TextPlaceholderComponent, View_TextPlaceholderComponent_Host_0, { textBox: "textBox" }, {}, []);
export { TextPlaceholderComponentNgFactory as TextPlaceholderComponentNgFactory };
