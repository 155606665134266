/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { SideToolbarContentComponent } from './components/tool-sidebar-content/tool-sidebar-content.component';
import { SideToolbarComponent } from './components/tool-sidebar/tool-sidebar.component';

const exportDeclarations = [SideToolbarComponent, SideToolbarContentComponent];

@NgModule({
  declarations: [...exportDeclarations],
  imports: [CommonModule, MatButtonModule],
  exports: exportDeclarations,
})
export class LibToolSidebarModule {}
