/*!
 * Copyright 2018 Screencastify LLC
 */
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, ViewContainerRef, } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MatTooltip, } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { keyboardShortcuts } from '../keyboard-shortcuts';
import { KeyboardService } from '../keyboard.service';
/**
 * Directive to add a keyboard shortcut and a tooltip to a button.
 * Triggers existing click event when keyboard event is fired.
 */
var KeysDirective = /** @class */ (function () {
    function KeysDirective(_keyboardService, _overlay, _elementRef, _scrollDispatcher, _viewContainerRef, _ngZone, _aria, _focusMonitor, _platform, _dir, scroll) {
        this._keyboardService = _keyboardService;
        this.click = new EventEmitter();
        this.log = Log.create('KeysDirective');
        // TODO: get rid of this hack once angular2 supports adding directives dynamically natively
        this.MatTooltip = new MatTooltip(_overlay, _elementRef, _scrollDispatcher, _viewContainerRef, _ngZone, _platform, _aria, _focusMonitor, scroll, _dir, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY());
    }
    KeysDirective.prototype.ngOnInit = function () {
        var _this = this;
        var shortcut = keyboardShortcuts[this.libShortcut];
        if (!shortcut) {
            this.log.error('unknown shortcut type: ', this.libShortcut);
            return;
        }
        this._keyboardService.add(shortcut, function (ev) {
            _this.click.emit(ev);
        });
        this.MatTooltip.message = shortcut.descWithCombo();
        this.MatTooltip.tooltipClass = ['big-tooltip'];
    };
    KeysDirective.prototype.ngOnDestroy = function () {
        this.MatTooltip.ngOnDestroy();
    };
    return KeysDirective;
}());
export { KeysDirective };
