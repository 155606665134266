/*!
 * Copyright 2020 Screencastify LLC
 */
import { EventEmitter, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
var ErrorModalComponent = /** @class */ (function () {
    function ErrorModalComponent() {
        this.closeEvent = new EventEmitter();
    }
    ErrorModalComponent.prototype.ngOnInit = function () { };
    /**
     * Opens the support page in a new tab
     */
    ErrorModalComponent.prototype.onHelpClick = function () {
        window.open(environment.externalLinks.support);
    };
    /**
     * Emits an event to the parent which indicates the user has
     * requested the modal be closed
     */
    ErrorModalComponent.prototype.onCloseClick = function () {
        this.closeEvent.emit();
    };
    return ErrorModalComponent;
}());
export { ErrorModalComponent };
