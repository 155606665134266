/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { LoggerAdapter, UserService } from 'lib-editor';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * Allows the app to decide how to control logger. This helps decouple app from the logger.
 * Differences in implementation from app to app can implemented here.
 */
@Injectable({
  providedIn: 'root',
})
export class EditLoggerAdapter extends LoggerAdapter {
  public authedEndPoint = '';
  public loggedIn = false;
  public unauthedEndPoint = '';

  constructor(_auth: UserService) {
    super();
    // this part can be decoupled and shared among all apps once migrated/updated to monorepo
    // can be a function defined in the LoggerAdapter and called here
    _auth.user
      .pipe(
        map((u) => !!u),
        distinctUntilChanged()
      )
      .subscribe((isSignedIn) => {
        this.loggedIn = isSignedIn;
      });
  }
}
