/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';
import { AnnotationsToolControllerService } from 'lib-editor/lib/annotations-tool/annotations-tool-controller.service';
import { UndoManagerService } from 'lib-editor/lib/common/undo-manager.service';
import { CropEditorControllerService } from 'lib-editor/lib/crop-editor/crop-editor-controller.service';
import { ZoomEditorControllerService } from 'lib-editor/lib/zoom-editor/zoom-editor-controller.service';

@Component({
  selector: 'lib-undo-controls',
  templateUrl: './undo-controls.component.html',
  styleUrls: ['./undo-controls.component.scss'],
})
export class UndoControlsComponent implements OnInit {
  constructor(
    public undoManager: UndoManagerService,
    private zoomCtrl: ZoomEditorControllerService,
    private cropCtrl: CropEditorControllerService,
    private annotationsCtrl: AnnotationsToolControllerService
  ) {}

  ngOnInit() {}

  onUndoClick(): void {
    if (!this.undoManager.canUndo.value) return;
    this._closeAllEditors();
    this.undoManager.undo();
  }

  onRedoClick(): void {
    if (!this.undoManager.canRedo.value) return;
    this._closeAllEditors();
    this.undoManager.redo();
  }

  protected _closeAllEditors(): void {
    if (this.zoomCtrl.isActive.value) this.zoomCtrl.close();
    if (this.cropCtrl.isActive.value) this.cropCtrl.close();
    if (this.annotationsCtrl.isActive.value) this.annotationsCtrl.close();
  }
}
