/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  AnnotationsClip,
  DeltaOperation,
  QuillAttributes,
  SceneEditor2,
  sty,
  TextBox,
  VerticalAlignment,
} from '@castify/edit-models';
import { AnnotationsClipMutator } from '@castify/edit-models/lib/scene/annotations-clip-mutator';
import { Log, Logger } from 'ng2-logger/browser';
import {
  BehaviorSubject,
  combineLatest,
  fromEventPattern,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService } from '../timeline/timeline-state.service';
import {
  kMaxTextBoxes,
  kMaxTextBoxLength,
  NonToggleableAttribute,
  ToggleableAttribute,
} from './quill-config';
import {
  kAnnotationsClipDefaultDuration,
  kNewTextBoxDefaultFactory,
} from './text-box-defaults';

interface LastSeenStyle {
  quillAttributes: QuillAttributes;
  verticalAlignment: VerticalAlignment;
  backgroundColor: string;
  borderColor: string;
  top: sty.UnitValue;
  left: sty.UnitValue;
  width: sty.UnitValue;
  height: sty.UnitValue;
  rotation: sty.Degrees;
}

// Describes type of a Quill method return
interface RangeStatic {
  index: number;
  length: number;
}

// When multiple formats are selected quill emits something that looks like
// this: values can be arrays of e.g. strings as well as just strings
type OneOrMultiple<T> = {
  [P in keyof T]: T[P] | Array<T[P]>;
};
type MultiFormatQuillAttributes = OneOrMultiple<QuillAttributes>;

// These rough types were built by looking at how Quill is used in this file as
// of this commit, and *are not* derived from Quill's actual source or
// documentation. They exist just to get a bit of extra safety and will
// likely need to be updated when quill usage changes; be sure to check Quill's
// runtime behavior when updating these for correctness. This effectively just
// centralizes all coercion to one place (vs distributing it throughout
// the quill code)
interface Quill {
  getLength: () => number;
  focus: () => void;
  getSelection: () => RangeStatic;
  setSelection: (index: number, length?: number, source?: string) => void;
  format: (key: string, val: string | number) => void;
  getContents: () => { ops: DeltaOperation[] };
  getFormat: (range: RangeStatic) => MultiFormatQuillAttributes;
  deleteText: (start: number, end: number) => void;
  // data is discarded, no need for types here
  on: (eventName: string, handler: () => any) => void;
  off: (eventName: string, handler: () => any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class AnnotationsToolControllerService extends SingleClipEditorControllerService<AnnotationsClip> {
  private _log: Logger<any> = Log.create('AnnotationsToolControllerService');
  /*****************************************************
   *                Utility members
   *****************************************************/
  subscriptions = new Subscription();
  protected sceneEditor: SceneEditor2;

  /*****************************************************
   *    These members contain mutable component state
   *        from which other state is derived
   *****************************************************/

  // the active quill instance if any
  readonly activeQuill = new BehaviorSubject<Quill | null>(null);

  // Idx of selcted box
  // External calls of its .next() should use setSelectedIndex method
  readonly selectedBoxId = new BehaviorSubject<sty.UUID | null>(null);
  public isRotationActive = new BehaviorSubject<boolean>(false);
  public grayBoxRotation = new BehaviorSubject<sty.Degrees | null>(null);

  // the "last seen" cursor and box styles
  // intended to facilitate "copying" styles
  // to new text boxes, but also by methods intended
  // to be consumed by the sidebar
  lastSeenStyles: LastSeenStyle = {
    quillAttributes: {},
    verticalAlignment: VerticalAlignment.top,
    backgroundColor: 'none',
    borderColor: 'none',
    top: 0.4,
    left: 0.4,
    width: 0.2,
    height: 0.2,
    rotation: 0,
  };

  // Manually triggers polling quill for selection/attribute
  // states, triggering updates to all sidebar state observbles.
  // Intended to allow manually trigger querying quill
  // selection states when the user interacts with sidebar
  // controls, but likely also emitted to elsewhere.
  readonly triggerQuillPoll = new BehaviorSubject<null>(null);

  /*****************************************************
   *        These observable members describe
   *        the derivation of dependent state
   *****************************************************/

  // the selected box itself
  readonly activeBox = combineLatest(
    this.clip.pipe(
      // possible for clip to be null in edge cases
      // where clip has been deleted from timeline
      // but is still being rendered by preview
      filter((clip) => !!clip),
      map((clip) => clip.boxes)
    ),
    this.selectedBoxId.pipe(filter((id) => !!id))
  ).pipe(
    map(([_, selectedBoxId]) => this.clip.value.getBoxById(selectedBoxId)),
    distinctUntilChanged()
  );

  // how many boxes are there
  get numBoxes() {
    return this.clip.value.boxes.length;
  }

  // Quill instance which updates only when changing the selected text box
  public readonly quillInstance = this.activeQuill.pipe(
    filter((quill) => !!quill),
    distinctUntilChanged()
  );

  // Merges a stream of Quill events with user-generated
  // sidebar interaction events
  readonly quillTextAndSelectionChange = this.quillInstance.pipe(
    switchMap((quill) =>
      combineLatest([
        fromEventPattern(
          (handler) => {
            quill.on('editor-change', handler);
          },
          (handler) => {
            quill.off('editor-change', handler);
          }
        ),
        this.triggerQuillPoll,
      ])
    ),
    map(() => null),
    shareReplay()
  );

  // Fires only when quill state changes
  // does not include selection updates
  readonly quillTextChanges = this.quillInstance.pipe(
    switchMap((quill) =>
      fromEventPattern(
        (handler) => {
          quill.on('text-change', handler);
        },
        (handler) => {
          quill.off('text-change', handler);
        }
      )
    ),
    map(() => null)
  );

  // Emits length of the text according to quill whenever this changes
  readonly quillTextLength = this.quillTextChanges.pipe(
    switchMap(() => of(this.activeQuill.value.getLength())),
    distinctUntilChanged()
  );

  // Emits only when the user deletes all text in a box via any
  // means (delete, backspace, paste, etc.). Length 1 means
  // the box contains only a newline char (appears empty)
  readonly quillTextEmptyEvents = this.quillTextLength.pipe(
    startWith(0),
    pairwise(),
    filter(([last, current]) => last >= 1 && current === 1),
    map(() => null)
  );

  // Emits an object describing the style of the cursor or of a text selection
  // if there is one every time the selection, the cursor, the selected text,
  // or active quill instance changes
  //
  // Blocked from emitting when the text length has dropped to 1, meaning the
  // text box is empty, which mitigating an edge case in which Quill "forgets"
  // all formatting state when the user deletes all of the text (length "1" means
  // there's only a single newline char)
  readonly quillSelectedAttributes = combineLatest([
    this.quillInstance,
    this.quillTextAndSelectionChange,
  ]).pipe(
    // need to explicitly call getSelection again so we can filter the null
    // values sometimes returned (inexplicably) as the selection
    map(([quill]) => {
      return <[Quill, RangeStatic]>[quill, quill.getSelection()];
    }),
    filter(([, selection]) => !!selection),
    map(([quill, selection]) => quill.getFormat(selection)),
    map((rawAttribs) => this.normalizeQuillAttributes(rawAttribs))
  );

  /***************************************
   *          CONSTRUCTOR
   ***************************************/

  constructor(
    public timelineState: TimelineStateService,
    private undoManager: UndoManagerService,
    public previewState: PreviewStateService
  ) {
    super(timelineState, previewState);

    // Permit tool activation whenever timeline isn't empty
    this.undoManager.scene
      .pipe(
        map((scene) => scene && scene.clips && scene.clips.length > 0),
        distinctUntilChanged()
      )
      .subscribe(this.canActivate);

    // Persist delta changes in the active box to the models
    this.subscriptions.add(
      this.quillTextChanges.subscribe(() => this.saveDeltaToSelectedBoxModel())
    );

    // Persist last-seen styles. Intended to help with system to copy
    // last-seen styles to new text boxes, but also the system which
    // prevents Quill from forgetting cursor formatting when deleting
    // all of the text in a text box.
    this.subscriptions.add(
      this.quillSelectedAttributes
        .pipe(
          filter(() => {
            return this.activeQuill.value.getLength() > 1;
          })
        )
        .subscribe((attributes) => {
          this.updateLastSeenStyles(attributes);
        })
    );

    // Whenever the user clears the text box, update the cursor
    // to reflect the last-seen cursor style prior to the clear.
    // Fixes an issue in which Quill forgets last-seen cursor styles.
    this.subscriptions.add(
      this.quillTextEmptyEvents.subscribe(() => {
        this.setQuillCursorStyles(this.lastSeenStyles.quillAttributes);
      })
    );

    // deletes text in excess of max length
    this.subscriptions.add(
      this.quillTextLength.subscribe((length) => {
        this.deleteTextInExcessOfLimit(length);
      })
    );
  }

  /*****************************************************
   *        Tool lifecycle methods
   *****************************************************/

  /**
   * Opens the tool; always uses the playhead position to select a clip,
   * creating a clip if one does not already exist. Can optionally be passed
   * the index of a text box that should receive focus when the tool is
   * opened (intended for use when opening the tool via clicks on text boxes).
   */
  open(boxId?: sty.UUID): void {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening editor not fulfilled');
    this.previewState.pause();
    this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());

    // select annotations clip at playhead, creating a clip if one does not
    // already exist, before opening the clip with the tool
    let annotationsClipAtPlayhead = this._getAnnotationsClipAtPlayhead();
    if (!annotationsClipAtPlayhead) {
      // create clip with one text box, copying in persisted box-level styles
      annotationsClipAtPlayhead = this.makeNewAnnotationsClipUsingPersistedStyles();
    }
    this.clip.next(annotationsClipAtPlayhead);
    this.timelineState.selection.next([annotationsClipAtPlayhead]);

    // select textbox at given index if provided and update the
    // sidebar's snapshot of Quill's format attributes

    this.selectTextBoxById(
      boxId !== undefined
        ? this.clip.value.getBoxById(boxId).id
        : this.clip.value.getFrontBox().id
    );

    // set override scene
    this.timelineState.overrideScene.next(this.sceneEditor.scene);

    super.open();
  }

  /**
   * Make new annotations clip, applies any last-used box-level styles
   * saved in this controller, overriding defaults.
   */
  makeNewAnnotationsClipUsingPersistedStyles(): AnnotationsClip {
    // Needed because we cannot control when Quill sets the cursor and triggers
    // persisted style updates, which may trigger observablesi in this class
    // that update persited styles
    const copyOfPersistedQuillAttribs = JSON.parse(
      JSON.stringify(this.lastSeenStyles.quillAttributes)
    );

    // generate new clip in our copy of the scene
    const newClip = this.sceneEditor.addAnnotationsClip(
      this.previewState.playhead.value,
      kAnnotationsClipDefaultDuration,
      [this.overrideBoxStylesWithLastSeen(kNewTextBoxDefaultFactory())]
    );

    // set cursor styles of the new textbox
    this.setQuillCursorStyles(copyOfPersistedQuillAttribs);

    return newClip;
  }

  /**
   * Get the annotations clip at the playhead if there is one
   */
  _getAnnotationsClipAtPlayhead(): AnnotationsClip | null {
    const timelineSelection = [
      ...this.timelineState.scene.value.clips.getAllAtPos(
        // This handles the edge case of the playhead being
        // all the way at the end of the timeline, in which
        // no annotations clip is returned even if one is present.
        // Subtracting one makes sure we get the clip.
        Math.max(this.previewState.playhead.value - 1, 0)
      ),
    ];
    const clipCandidates = timelineSelection.filter(
      (clip) => clip instanceof AnnotationsClip
    );
    return <AnnotationsClip>clipCandidates[0] || null;
  }

  /**
   * Closes the editor; intended to be called in editor manager
   * when editors switch but also when user manually closes
   * editor via buttons.
   */
  close() {
    this.clip.next(null);
    this.activeQuill.next(null);
    this.selectedBoxId.next(null);
    super.close();
  }

  /**
   * Enables "soft" style persistence, intended to permit copying
   * last-seen styles to new text boxes within single user/browser sessions.
   * Not involved in persisting text box state to models or DB.
   *
   * Quill attributes must be passed in while non-quill attributes
   * can be pulled out inside the methods, as the former are observable
   * while the latter are not, in this design.
   */
  updateLastSeenStyles(attributes: QuillAttributes): void {
    const selectedTextBoxId = this.selectedBoxId.value;

    // object copy for safety
    this.lastSeenStyles.quillAttributes = JSON.parse(
      JSON.stringify(attributes)
    );

    // Non-quill attributes will always be correct and safe to update
    const selectedTextBox = this.clip.value.getBoxById(selectedTextBoxId);
    this.lastSeenStyles.backgroundColor = selectedTextBox.backgroundColor;
    this.lastSeenStyles.verticalAlignment = selectedTextBox.verticalAlignment;
    this.lastSeenStyles.rotation = selectedTextBox.rotation;
  }

  /*****************************************************
   *         These methods concern altering &
   *     persisting alterations to text box data
   *****************************************************/

  /**
   * Intended to be called when the editor is closed. Makes sure scene
   * is updated with delta from active instance.
   */
  save() {
    if (this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
      this._log.info('Annotations saved');
      this.undoManager.update(this.sceneEditor.scene);
    }
  }

  /**
   * Permits access to annotations mutator to allow scene data
   * to be changed
   */
  editAnnotation(editFn: (editor: AnnotationsClipMutator) => void): void {
    const editor = this.sceneEditor.editAnnotationsClip(this.clip.value);
    editFn(editor);
    this.clip.next(editor.annotationClip.copy());
  }

  /**
   * Saves changed deltas for active quill to the model
   * corresponding to the selected text box. Intended to
   * be run when Quill emits change events.
   */
  saveDeltaToSelectedBoxModel(): void {
    const selectedTextBoxId = this.selectedBoxId.value;
    const delta = this.activeQuill.value.getContents().ops;

    this.editAnnotation((mutator) => {
      mutator.setQuillDelta(selectedTextBoxId, delta);
    });

    // push to timeline
    this.timelineState.overrideScene.next(this.sceneEditor.scene);
  }

  /**
   * Intended to be called after changes to non-quill
   * text box properties to make sure they can be copied
   * to new boxes (e.g. after move, bg change etc.)
   */
  persistSelectedBoxToLastSeenStyles(): void {
    const selectedTextBoxId = this.selectedBoxId.value;
    const {
      top,
      left,
      width,
      height,
      verticalAlignment,
      backgroundColor,
      borderColor,
      rotation,
    } = this.clip.value.getBoxById(selectedTextBoxId);
    this.lastSeenStyles = {
      ...this.lastSeenStyles,
      top,
      left,
      width,
      height,
      verticalAlignment,
      backgroundColor,
      borderColor,
      rotation,
    };
  }

  deleteTextInExcessOfLimit(length: number) {
    if (length > kMaxTextBoxLength) {
      this.activeQuill.value.deleteText(kMaxTextBoxLength, length);
    }
  }

  /*****************************************************
   *      These methods handle text box creation,
   *         destruction, selection
   *****************************************************/

  /**
   * Intended to add new text boxes when the sidebar's
   * New Text Box button is clicked
   */
  addAnnotationsBox(newTextBox: TextBox): void {
    const newBox = {
      ...newTextBox,
    };

    // If box overlaps box already placed, move it slightly and retry
    this.clip.value.boxes.forEach((box) => {
      if (box.top === newBox.top && box.left === newBox.left) {
        newBox.top -= 0.05;
        newBox.left += 0.05;

        if (newBox.top <= 0 || newBox.left >= 1 - newBox.width) {
          newBox.top = 1 - newBox.height;
          newBox.left = 0;
        }
      }
    });

    this.editAnnotation((editor) => editor.addBox(newBox));
    this.selectTextBoxById(this.clip.value.getFrontBox().id);
    this._log.info('Add new annotation box');

    // focus quill and select all text
    // setTimeout needed because Quill is weird
    setTimeout(() => {
      if (this.activeQuill.value) {
        this.activeQuill.value.focus();
        const length = this.activeQuill.value.getLength();
        this.activeQuill.value.setSelection(0, length, 'api');
      }
    }, 0);
  }

  /**
   * Take some quill attributes and apply them
   * to the cursor
   */
  setQuillCursorStyles(attributes: QuillAttributes): void {
    setTimeout(() => {
      if (this.activeQuill.value) {
        Object.entries(attributes).forEach(([key, val]) => {
          this.activeQuill.value.format(key, val);
        });
      }
    }, 0);
  }

  /**
   * Combine last-seen box-level styles with another box's properties.
   * Intended to help with applying persisted style state to a new box.
   */
  overrideBoxStylesWithLastSeen(textBox: TextBox): TextBox {
    const {
      backgroundColor,
      borderColor,
      verticalAlignment,
      top,
      left,
      width,
      height,
      rotation,
    } = this.lastSeenStyles;

    return {
      ...textBox,
      backgroundColor,
      borderColor,
      verticalAlignment,
      top,
      left,
      width,
      height,
      rotation,
    };
  }

  /**
   * Adds a new annotations box that applies the persisted styles
   */
  addNewBoxUsingPersistedStyles(): void {
    // early return if we have already hit cap
    if (this.clip.value.boxes.length >= kMaxTextBoxes) return;

    // Needed because we cannot control when Quill sets the cursor and triggers
    // persisted style updates, which may trigger observablesi in this class
    // that update persited styles
    const copyOfPersistedQuillAttribs = JSON.parse(
      JSON.stringify(this.lastSeenStyles.quillAttributes)
    );

    // actually create the new box
    this.addAnnotationsBox(
      this.overrideBoxStylesWithLastSeen(kNewTextBoxDefaultFactory())
    );

    // set quill cursor styles
    this.setQuillCursorStyles(copyOfPersistedQuillAttribs);
  }

  /**
   * Removes the currently selected text box from the clip
   */
  deleteSelectedTextBox() {
    // If we have more than one box, swap selection to next logical box
    const boxToDelete = this.selectedBoxId.value;

    if (this.numBoxes > 1) {
      const boxBehindCurrentBox = this.clip.value.getBoxBelow(
        this.selectedBoxId.value
      );
      this.selectedBoxId.next(boxBehindCurrentBox.id);
    }

    // Remove the selected box
    this.editAnnotation((editor) => editor.removeBox(boxToDelete));
    if (this.numBoxes !== 0) this.putCursorAtEnd();
  }

  moveTextBoxToFront() {
    this.editAnnotation((editor) =>
      editor.moveBoxToFront(this.selectedBoxId.value)
    );
    this.putCursorAtEnd();
    this._log.info('Moved Text-Box to Front');
  }

  moveTextBoxToBack() {
    this.editAnnotation((editor) =>
      editor.moveBoxToBack(this.selectedBoxId.value)
    );
    this.putCursorAtEnd();
    this._log.info('Moved Text-Box to Back');
  }

  /**
   * Intended to be used for selecting a text box (e.g. on a click).
   * Mirrors quill delta from the active quill instance to the scene
   * to ensure it can be persisted later.
   */
  selectTextBoxById(id: sty.UUID) {
    this.selectedBoxId.next(id);
    this.putCursorAtEnd();
    this.persistSelectedBoxToLastSeenStyles();
  }

  /**
   * Puts the cursor at the end of the active text box
   */
  putCursorAtEnd() {
    setTimeout(() => {
      if (this.activeQuill.value) {
        const cursorIndex = this.activeQuill.value.getLength();
        this.activeQuill.value.setSelection(cursorIndex);
      }
    }, 0);
  }

  /*****************************************************
   *         These methods concern observing and
   *          changing Quill attribute state
   *****************************************************/

  /**
   * Grabs the first style when there are multiple styles. Necessary because
   * Quill can output arrays when multiple styles are selected, but only for
   * some properties.
   */
  private normalizeQuillAttributes(
    attrib: MultiFormatQuillAttributes
  ): QuillAttributes {
    return Object.entries(attrib).reduce((acc, [key, val]) => {
      acc[key] = Array.isArray(val) ? val[0] : val;
      return acc;
    }, {});
  }

  /**
   * Handle a request from the user to toggle one of the
   * toggleable Quill attributes. Decides whether to apply or
   * disable the attribute based on current state of
   * the Quill cursor or selection.
   */
  triggerToggleable(attrib: ToggleableAttribute): void {
    const quill = this.activeQuill.value;
    if (quill) {
      if (this.lastSeenStyles.quillAttributes[attrib]) quill.format(attrib, 0);
      else quill.format(attrib, 1);

      // force observables to update
      this.triggerQuillPoll.next(null);

      // refocus quill after each interaction
      setTimeout(() => {
        if (this.activeQuill.value) {
          this.activeQuill.value.focus();
        }
      }, 0);
    }
  }

  /**
   * Handle a request from the user to toggle one of the
   * non-toggleable Quill attributes. Always passes values passed in
   * to Quill (which hopefully has them whitelisted).
   */
  triggerSettable(attributeName: NonToggleableAttribute, value: string): void {
    const quill = this.activeQuill.value;
    if (quill) {
      quill.format(attributeName, value);

      // force observables to update
      this.triggerQuillPoll.next(null);

      // refocus quill after each interaction
      setTimeout(() => {
        if (this.activeQuill.value) {
          this.activeQuill.value.focus();
        }
      }, 0);
    }
  }

  /**
   * Make an observable for a toggleable attribute
   */
  toggleableObsFactory(attribName: ToggleableAttribute): Observable<boolean> {
    return this.quillSelectedAttributes.pipe(
      map((attr) => !!attr[attribName]),
      // will get many repeat values here due to nature of Quill
      // event stream; prevent unnecessary DOM updates
      distinctUntilChanged()
    );
  }

  /**
   * Make an observable of specified type for a non-toggleable attribute.
   * Configurable to not allow undefined values through
   */
  nonToggleableObsFactory<T>(
    attribName: NonToggleableAttribute,
    filterFalsey: boolean
  ): Observable<T> {
    return this.quillSelectedAttributes.pipe(
      map((attribs) => <T>(<unknown>attribs[attribName])),
      // can be undefined, filter this out if asked to do so
      filter((attrib) => (filterFalsey ? !!attrib : true)),
      // can be an array if multiple styles selcted.
      // we use only the first
      // will get many repeat values here due to nature of Quill
      // event stream; prevent unnecessary DOM updates
      distinctUntilChanged()
    );
  }
}
