/*!
 * Copyright 2019 Screencastify LLC
 */

import { FileClipModel2 } from '@castify/edit-models';
import { sty } from '@castify/models';

export class ConstantDurationSegmentHelper {
  constructor(readonly segmentDuration: sty.Milliseconds) {}

  /**
   * calculate index of a segment at a certain position (clip time)
   * This is the inverse function to this._segStartTime
   */
  segIdxAtPos(clip: FileClipModel2, pos: sty.Milliseconds): number {
    return Math.floor(
      (clip.startInFile + Math.min(clip.duration - 1, pos)) /
        this.segmentDuration
    );
  }

  /**
   * calculate start pos (clip time) of segment identified by segIdx.
   * This is the inverse function to this._segIdxAtPos
   */
  segStartPos(clip: FileClipModel2, segIdx: number): sty.Milliseconds {
    const res = Math.max(0, segIdx * this.segmentDuration - clip.startInFile);
    return res;
  }

  /**
   * calculate seek time in segment from pos in clip (clip time)
   */
  segSeekTimeFromClipPos(
    clip: FileClipModel2,
    segIdx: number,
    pos: sty.Milliseconds
  ): sty.Milliseconds {
    return pos + clip.startInFile - segIdx * this.segmentDuration;
  }
}
