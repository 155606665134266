/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = ["\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJwcm9qZWN0cy9saWItZWRpdG9yL3NyYy9saWIvaW1wb3J0LW1vZGFscy9sb2NhbC1pbXBvcnQvbG9jYWwtaW1wb3J0LmNvbXBvbmVudC5zY3NzIn0= */"];
export { styles as styles };
