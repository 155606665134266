/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  AudioClip,
  ClipModel2,
  SceneEditor2,
  sty,
  VideoClip,
} from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../timeline/timeline-state.service';

const log = Log.create('AudioToolControllerService');

/**
 * Not really using SingleClipEditorControllerService.clips
 */
@Injectable({
  providedIn: 'root',
})
export class AudioToolControllerService extends SingleClipEditorControllerService<VideoClip> {
  public readonly gain = new BehaviorSubject<number>(null);
  protected sceneEditor: SceneEditor2;
  private _audioClip = new BehaviorSubject<AudioClip>(null);

  constructor(
    protected timelineState: TimelineStateService,
    protected previewState: PreviewStateService,
    private undoManager: UndoManagerService
  ) {
    super(timelineState, previewState);

    // update clip timeline from selection
    this.timelineState.selection
      .pipe(map((selection) => this._getSelectedClips(selection)))
      .subscribe(({ audioClip, videoClip }) => {
        this._audioClip.next(audioClip);
        // NOTE: using videoClip as this.clip because timelineState uses it for
        // highlighting selected clips, consider renaming this to parentClip or rootClip
        // see logic in parent
        this.clip.next(videoClip);
      });

    // sync canActivate
    combineLatest([this._audioClip, this.isActive])
      .pipe(map(([clip, isActive]) => !!clip && !isActive))
      .subscribe(this.canActivate);
  }

  public open(): void {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening editor not fulfilled');
    if (!this._audioClip.value)
      throw new Error('Failed to open audio tool: no clip selected');

    this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
    this.gain.next(this._audioClip.value.gain);

    super.open();
  }

  public close() {
    super.close();
    this.gain.next(null);
    this.sceneEditor = null;
    // select the video when editor sidebar closes
    const videoClip: VideoClip | null = this.timelineState.selection.value.find(
      (v) => v instanceof VideoClip
    ) as VideoClip | null;
    this.timelineState.selection.next([videoClip]);
  }

  public save(): void {
    if (
      this.sceneEditor &&
      this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash
    ) {
      log.info(`save audio gain: ${this.gain.value}`);
      this.undoManager.update(this.sceneEditor.scene);
    }
  }

  /**
   * manipulate gain
   */
  public setGain(gain: sty.Decibels): void {
    const clip = this._audioClip.value;
    const clipEditor = this.sceneEditor.getClipEditor(clip);
    clipEditor.setGain(gain);
    this.gain.next(gain);
    this._updateIntermediateScene();
  }

  private _updateIntermediateScene(): void {
    // NOTE: using scene.copy() to change reference since async pipe does not
    // trigger change detection where there's no reference change.
    // see `[clip]="_clip | async"` see “Marking things for check” in
    // https://blog.thoughtram.io/angular/2017/02/27/three-things-you-didnt-know-about-the-async-pipe.html
    this.timelineState.overrideScene.next(this.sceneEditor.scene.copy());
  }

  /**
   * Audio's clip and video clip
   */
  private _getSelectedClips(
    timelineSelection: (ClipModel2 | EffectSelection)[]
  ): { audioClip: AudioClip | null; videoClip: VideoClip | null } {
    let audioClip = null;
    let videoClip = null;
    // get audioClip
    audioClip = <AudioClip>timelineSelection.find((clip) => {
        return clip instanceof AudioClip;
      }) || null;

    if (!audioClip) return { audioClip, videoClip };
    // get videoClip
    const sceneModel = this.timelineState.scene.value.filter(
      (clip) =>
        clip instanceof VideoClip && clip.insertId === audioClip.insertId
    );
    videoClip = <VideoClip>(
      sceneModel.clips.items.find((clip) => clip instanceof VideoClip)
    );
    return { audioClip, videoClip };
  }
}
