var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { CropTransform, Rectangle, SceneEditor2, VideoClip, } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService, } from '../timeline/timeline-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../preview/preview-state.service";
import * as i3 from "../common/undo-manager.service";
var log = Log.create('CropEditorControllerService');
var kInitialCropRect = new Rectangle(0.1, 0.1, 0.8, 0.8);
var CropEditorControllerService = /** @class */ (function (_super) {
    __extends(CropEditorControllerService, _super);
    function CropEditorControllerService(timelineState, previewState, undoManager) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.previewState = previewState;
        _this.undoManager = undoManager;
        _this._cropRect = new BehaviorSubject(null); // current crop rectangle in unit values
        // update clip timeline from selection
        _this.timelineState.selection
            .pipe(map(function (selection) { return _this._getTargetClip(selection); }))
            .subscribe(_this.clip);
        // sync canActivate
        combineLatest([_this.clip, _this.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), clip = _b[0], isActive = _b[1];
            return !!clip && !isActive;
        }))
            .subscribe(_this.canActivate);
        return _this;
    }
    CropEditorControllerService.prototype.open = function () {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening editor not fulfilled');
        if (!this.clip.value)
            throw new Error('Failed to open crop tool: no clip selected');
        this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
        var crop = this.clip.value.transform.crop;
        if (!!crop) {
            // load initial crop
            this._cropRect.next(this.cropToRect(crop, this.clip.value));
        }
        else {
            // add new crop
            this._setCropRect(kInitialCropRect);
        }
        // make preview display an uncropped version of the clip
        var previewScene = this.undoManager.scene.value
            .filterClipType(['video'])
            .copy();
        var previewClip = previewScene.clips.byId(this.clip.value.id);
        previewClip.transform.crop = null;
        previewClip.effects = previewClip.effects.filterType(['blur']);
        this.previewState.overrideScene.next(previewScene);
        _super.prototype.open.call(this);
    };
    CropEditorControllerService.prototype.reset = function () {
        this.sceneEditor.getClipEditor(this.clip.value).crop(null);
        this.save();
        this.close();
    };
    CropEditorControllerService.prototype.close = function () {
        _super.prototype.close.call(this);
        this._cropRect.next(null);
        this.sceneEditor = null;
    };
    CropEditorControllerService.prototype.save = function () {
        if (this.sceneEditor &&
            this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
            log.info('save crop');
            this.undoManager.update(this.sceneEditor.scene);
        }
    };
    /**
     * manipulate crop rectangle.
     * @param newRect new crop rect in unit values
     */
    CropEditorControllerService.prototype._setCropRect = function (rect) {
        var clip = this.clip.value;
        var videoEditor = this.sceneEditor.getClipEditor(clip);
        var newCrop = videoEditor.crop(this.rectToCrop(rect, clip));
        this._cropRect.next(this.cropToRect(newCrop, clip));
    };
    CropEditorControllerService.prototype.rectToCrop = function (rect, clip) {
        return new CropTransform({
            left: rect.left * clip.srcDimensions.width,
            width: rect.width * clip.srcDimensions.width,
            top: rect.top * clip.srcDimensions.height,
            height: rect.height * clip.srcDimensions.height,
        });
    };
    CropEditorControllerService.prototype.cropToRect = function (crop, clip) {
        return new Rectangle(crop.left / clip.srcDimensions.width, crop.top / clip.srcDimensions.height, crop.width / clip.srcDimensions.width, crop.height / clip.srcDimensions.height);
    };
    CropEditorControllerService.prototype._getTargetClip = function (timelineSelection) {
        var clipCandidates = timelineSelection.filter(function (clip) { return clip instanceof VideoClip; });
        return clipCandidates[0] || null;
    };
    CropEditorControllerService.ngInjectableDef = i0.defineInjectable({ factory: function CropEditorControllerService_Factory() { return new CropEditorControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.PreviewStateService), i0.inject(i3.UndoManagerService)); }, token: CropEditorControllerService, providedIn: "root" });
    return CropEditorControllerService;
}(SingleClipEditorControllerService));
export { CropEditorControllerService };
