/*!
 * Copyright 2020 Screencastify LLC
 */
import { MoveIconComponent } from './components/move-icon/move-icon.component';
import { ShadowBoxComponent } from './components/shadow-box/shadow-box.component';
import { DragHandleDirective } from './directives/drag-handle.directive';
var kExportComponents = [
    MoveIconComponent,
    DragHandleDirective,
    ShadowBoxComponent,
];
var LibToolCommonModule = /** @class */ (function () {
    function LibToolCommonModule() {
    }
    return LibToolCommonModule;
}());
export { LibToolCommonModule };
