/*!
 * Copyright 2020 Screencastify LLC
 */
import { Rectangle, Size } from '@castify/edit-models';
import { Limits } from '@castify/models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { Point, RotatedRect } from './rectangle';
export var kTextBoxMinDims = new Size(0.04, 0.04);
export var DragHandleNames;
(function (DragHandleNames) {
    DragHandleNames["northwest"] = "northwest";
    DragHandleNames["northeast"] = "northeast";
    DragHandleNames["southwest"] = "southwest";
    DragHandleNames["southeast"] = "southeast";
})(DragHandleNames || (DragHandleNames = {}));
var TextBoxEditBorderComponent = /** @class */ (function () {
    function TextBoxEditBorderComponent(ctrl) {
        var _this = this;
        this.ctrl = ctrl;
        this.dragHandleNames = DragHandleNames;
        /**
         * Whether this border surrounds the box selected
         * for editing
         */
        this._isSelected = this.ctrl.selectedBoxId.pipe(map(function (selectedBoxId) {
            return selectedBoxId === _this.boxId;
        }));
        this._parentDims = new BehaviorSubject({
            width: 0,
            height: 0,
        });
    }
    Object.defineProperty(TextBoxEditBorderComponent.prototype, "parentDims", {
        /**
         * The preview container; intended to help calculate
         * % changes when moving or resizing text boxes
         */
        set: function (box) {
            if (box) {
                this._parentDims.next(box);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Intended to be called in template on start of drag
     */
    TextBoxEditBorderComponent.prototype.onMoveStart = function () {
        this._editStartBox = JSON.parse(JSON.stringify(this.ctrl.clip.value.getBoxById(this.boxId)));
    };
    /**
     * Intended to be called when drag ends
     */
    TextBoxEditBorderComponent.prototype.onMoveEnd = function () {
        this.ctrl.selectTextBoxById(this.boxId);
    };
    /**
     * Handles moving text boxes via handles
     */
    TextBoxEditBorderComponent.prototype.onMove = function (event) {
        var _this = this;
        var shiftX = event.shiftX / this._parentDims.value.width;
        var shiftY = event.shiftY / this._parentDims.value.height;
        this.ctrl.editAnnotation(function (editor) {
            return editor.moveBox(_this.boxId, _this._editStartBox.left + shiftX, _this._editStartBox.top + shiftY);
        });
    };
    TextBoxEditBorderComponent.prototype.onClick = function (id) {
        this.ctrl.selectTextBoxById(id);
    };
    /**
     * resizes a unit rectangle by moving it from one corner. Parameters topFixed and leftFixed define which corner of the
     * rectangle can move freely. This corner is shifted (shiftX, shiftY) relative to the startRect.
     * @param topFixed toogles between top and bottom staying fixed
     * @param leftFixed analogous to topFixed
     * @param minDims sets a lower limit for dimensions of the returned rectangle
     */
    TextBoxEditBorderComponent.prototype.resizeAnnotationUnitRect = function (startRect, shiftX, shiftY, topFixed, leftFixed, minDims) {
        if (minDims === void 0) { minDims = new Size(0, 0); }
        // Adjust top-left corner
        var top = new Limits(-startRect.width, 1).apply(startRect.top + (topFixed ? 0 : shiftY));
        var left = new Limits(-startRect.height, 1).apply(startRect.left + (leftFixed ? 0 : shiftX));
        // Adjust bottom-right corner
        var bottom = new Limits(0, 1 + startRect.height).apply(startRect.top + startRect.height + (topFixed ? shiftY : 0));
        var right = new Limits(0, 1 + startRect.width).apply(startRect.left + startRect.width + (leftFixed ? shiftX : 0));
        // Calculate and adjust box size according to limits
        var width = new Limits(minDims.width, 1).apply(right - left);
        var height = new Limits(minDims.height, 1).apply(bottom - top);
        return new Rectangle(new Limits(-width, leftFixed ? 1 : right).apply(left), new Limits(-height, topFixed ? 1 : bottom).apply(top), width, height);
    };
    /**
     * Helper for getting startbox from saved data. Returns values in
     * pixels
     */
    TextBoxEditBorderComponent.prototype._rectFromStartBox = function (activeHandle) {
        if (!this._editStartBox)
            throw new Error('no start box saved');
        var w = this._parentDims.value.width;
        var h = this._parentDims.value.height;
        var north = this._editStartBox.top * h;
        var south = (this._editStartBox.top + this._editStartBox.height) * h;
        var west = this._editStartBox.left * w;
        var east = (this._editStartBox.left + this._editStartBox.width) * w;
        var fixedCorner = new Point(activeHandle.slice(5) === 'east' ? west : east, activeHandle.slice(0, 5) === 'north' ? south : north);
        var unfixedCorner = new Point(activeHandle.slice(5) === 'east' ? east : west, activeHandle.slice(0, 5) === 'north' ? north : south);
        return new RotatedRect(fixedCorner, unfixedCorner);
    };
    /**
     * Gets the rotation of the start box in radians
     */
    TextBoxEditBorderComponent.prototype._thetaInRadians = function () {
        if (!this._editStartBox)
            throw new Error('no start box saved');
        return (Math.PI / 180) * this._editStartBox.rotation;
    };
    /**
     * Handles resizing text boxes via handles
     */
    TextBoxEditBorderComponent.prototype.onCornerResize = function (event, activeHandle) {
        var _this = this;
        // Grab the starting location before resizing
        var startBox = this._rectFromStartBox(activeHandle);
        // Find the points of our box after rotating it (what the user visually sees)
        var rotatedStartBox = startBox.rotate(this._thetaInRadians());
        // Figure out which corner to lock based on which corner is being dragged
        // Find the location of the users mouse (also the bottomRight corner of our new box post-resize)
        var mouseLocation = new Point(rotatedStartBox.unfixedCorner.x + event.shiftX, rotatedStartBox.unfixedCorner.y + event.shiftY);
        // Draw a box connecting our new mouses location and our previous rotated box
        var rotatedEndBox = new RotatedRect(rotatedStartBox.fixedCorner, mouseLocation);
        // Rotate our box in the opposite direction
        var endBox = rotatedEndBox.rotate(-this._thetaInRadians());
        // Convert our center point back into a rectangle
        endBox.normalizeValues(this._parentDims.value.width, this._parentDims.value.height);
        var resized = endBox.convertToUnitRect(kTextBoxMinDims);
        // Update the editor to reflect our selected items new size
        this.ctrl.editAnnotation(function (editor) {
            editor.resizeBox(_this.boxId, Size.fromObj(resized));
            editor.moveBox(_this.boxId, resized.left, resized.top);
        });
        // ensure box is selected and that styles are persisted
        this.ctrl.selectTextBoxById(this.boxId);
    };
    /**
     * Intended to be called in template on start of drag
     */
    TextBoxEditBorderComponent.prototype.onRotateStart = function (event) {
        this.onMoveStart();
        this.ctrl.grayBoxRotation.next(this._editStartBox.rotation);
    };
    /**
     * Intended to be called when drag ends
     */
    TextBoxEditBorderComponent.prototype.onRotateEnd = function (event) {
        var _this = this;
        // Update the editor to reflect our selected items new size
        this.ctrl.editAnnotation(function (editor) {
            editor.setRotation(_this.boxId, _this.ctrl.grayBoxRotation.value);
        });
        this.ctrl.persistSelectedBoxToLastSeenStyles();
        this.ctrl.grayBoxRotation.next(null);
        this.onMoveEnd();
    };
    /**
     * Rotates current rectangle around it's centerpoint given the mouses current location in relation to the centerpoint
     * @param event Current drag event
     */
    TextBoxEditBorderComponent.prototype.onRotate = function (event) {
        var mouseLocation = new Point(event.event.pageX - this._parentDims.value.left, event.event.pageY - this._parentDims.value.top);
        // Grab the starting rect (argument doesn't matter, we just want the center point)
        var startRect = this._rectFromStartBox('northeast');
        // Find the angle of the users drag relative to the center
        var deltaX = mouseLocation.x - startRect.center.x;
        var deltaY = mouseLocation.y - startRect.center.y;
        var rotation = Math.atan2(deltaY, deltaX);
        // Normalize the data with what we're storing in the models
        rotation *= 180 / Math.PI;
        rotation += 90;
        // Snap the rotation back to origin if it gets close enough
        if (rotation >= -4 && rotation <= 4)
            rotation = 0;
        this.ctrl.grayBoxRotation.next(rotation);
    };
    return TextBoxEditBorderComponent;
}());
export { TextBoxEditBorderComponent };
