/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
var ConfirmNewProjectComponent = /** @class */ (function () {
    function ConfirmNewProjectComponent() {
    }
    ConfirmNewProjectComponent.prototype.ngOnInit = function () { };
    return ConfirmNewProjectComponent;
}());
export { ConfirmNewProjectComponent };
