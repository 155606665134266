/*!
 * Copyright 2020 Screencastify LLC
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, SignInComponent } from 'lib-editor';
import { BrowserGuard } from './browser-guard/browser-guard';
import { BrowserGuardRoutingModule } from './browser-guard/browser-guard-routing.module';

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent,
  },
  {
    path: 'edit',
    canActivate: [BrowserGuard, AuthGuard],
    loadChildren: './edit/edit.module#EditModule',
  },
  {
    path: 'open',
    canActivate: [BrowserGuard, AuthGuard],
    loadChildren: './open/open.module#OpenModule',
  },
  {
    path: '**',
    redirectTo: '/edit',
  },
];

@NgModule({
  imports: [BrowserGuardRoutingModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
