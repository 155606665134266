/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BlurBox, BlurEffect, Size } from '@castify/edit-models';
import { Rectangle } from '@castify/models';
import { DragHandleEvent } from 'lib-editor/lib/tool-common/directives/drag-handle.directive';
import {
  applyPosition,
  resizeUnitRect,
} from 'lib-editor/lib/tool-common/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';

export const kBlurBoxMinDims = new Size(0.05, 0.05);

@Component({
  selector: 'lib-blur-box',
  templateUrl: './blur-box.component.html',
  styleUrls: ['./blur-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurBoxComponent implements OnInit, OnDestroy {
  @Input()
  set blurBox(box: BlurBox) {
    this._blurBox.next(box);
  }
  get blurBox(): BlurBox {
    return this._blurBox.value;
  }
  readonly _blurBox = new BehaviorSubject<BlurBox>(null);

  @Input()
  set editable(v: boolean) {
    this._editable.next(v);
  }
  readonly _editable = new BehaviorSubject<boolean>(false);

  private _editStartBox: BlurBox;
  private subscriptions = new Subscription();

  constructor(
    public ctrl: BlurEditorControllerService,
    private hostElm: ElementRef<HTMLDivElement>
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this._blurBox.subscribe((v) => this.updateBlurBox(v))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onEditStart(): void {
    this._editStartBox = JSON.parse(JSON.stringify(this._blurBox.value));
  }

  onMove(event: DragHandleEvent): void {
    const parentRect = this.hostElm.nativeElement.parentElement.getBoundingClientRect();
    const shiftX = event.shiftX / parentRect.width;
    const shiftY = event.shiftY / parentRect.height;

    this.ctrl._editBlur((editor) =>
      editor.moveBox(
        this.ctrl.selectedBox.value,
        this._editStartBox.left + shiftX,
        this._editStartBox.top + shiftY
      )
    );
  }

  onResize(
    event: DragHandleEvent,
    topFixed: boolean,
    leftFixed: boolean
  ): void {
    if (!this._editStartBox) return;
    const parentRect = this.hostElm.nativeElement.parentElement.getBoundingClientRect();
    const resized = resizeUnitRect(
      Rectangle.fromObj(this._editStartBox),
      event.shiftX / parentRect.width,
      event.shiftY / parentRect.height,
      topFixed,
      leftFixed,
      kBlurBoxMinDims
    );
    this.ctrl._editBlur((editor) => {
      editor.resizeBox(this.ctrl.selectedBox.value, Size.fromObj(resized));
      editor.moveBox(this.ctrl.selectedBox.value, resized.left, resized.top);
    });
  }

  updateBlurBox(box: BlurBox): void {
    // position/size the blur overlay
    applyPosition(this.hostElm, box);
    this.updateBlurIntensity(box);
  }

  updateBlurIntensity(box: BlurBox): void {
    const hostElm = this.hostElm.nativeElement;
    const clipDisplayWidth = hostElm.offsetWidth / (box.width + Number.EPSILON);
    const intensityPx = BlurEffect.intensityToBlurRadius(
      box.intensity,
      clipDisplayWidth
    );
    hostElm.style['backdrop-filter'] = `blur(${intensityPx}px)`;
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateBlurBox(this._blurBox.value);
  }
}
