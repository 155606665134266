/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { ClipModel2, VideoClip } from '@castify/edit-models';
import { BehaviorSubject } from 'rxjs';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService } from '../timeline/timeline-state.service';
import { EditorControllerService } from './editor-controller.service';

export interface IToolSidebarData {
  // the clip in which the selection is happening in, used for lifting parent in the UI
  //  - effect/audio's parent clip
  //  - different from TimelineState.selection
  clip: ClipModel2;
  editor: SingleClipEditorControllerService<ClipModel2>;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Implements single clip mode as part of the editor.
 */
export class SingleClipEditorControllerService<
  TClip extends ClipModel2 = VideoClip
> extends EditorControllerService {
  clip = new BehaviorSubject<TClip | null>(undefined);

  constructor(
    protected timelineState: TimelineStateService,
    protected previewState: PreviewStateService
  ) {
    super();
  }

  open(): void {
    this.timelineState.toolSidebarData.next({
      clip: this.clip.value,
      editor: this,
    });
    super.open();
  }

  save(): void {
    throw new Error('not implemented');
  }

  close(): void {
    this.timelineState.toolSidebarData.next(null);
    this.timelineState.overrideScene.next(null);
    this.previewState.overrideScene.next(null);
    super.close();
  }
}
