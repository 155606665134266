/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { ClipModel2, SceneEditor2, StillClip } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../timeline/timeline-state.service';

@Injectable({
  providedIn: 'root',
})
export class StillClipToolControllerService extends SingleClipEditorControllerService<StillClip> {
  private logger = Log.create('StillClipToolControllerService');

  readonly backgroundColor = new BehaviorSubject<string | null>(null);
  protected sceneEditor: SceneEditor2;

  constructor(
    protected timelineState: TimelineStateService,
    protected previewState: PreviewStateService,
    private undoManager: UndoManagerService
  ) {
    super(timelineState, previewState);

    // update clip timeline from selection
    this.timelineState.selection
      .pipe(map((selection) => this._getTargetClip(selection)))
      .subscribe(this.clip);

    // sync canActivate
    combineLatest([this.clip, this.isActive])
      .pipe(map(([clip, isActive]) => !!clip && !isActive))
      .subscribe(this.canActivate);
  }

  open(): void {
    if (!this.canActivate.value)
      throw new Error('preconditions for opening editor not fulfilled');
    if (!this.clip.value)
      throw new Error('Failed to open crop tool: no clip selected');

    this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
    this.backgroundColor.next(this.clip.value.backgroundColor);

    super.open();
  }

  close() {
    super.close();
    this.backgroundColor.next(null);
    this.sceneEditor = null;
  }

  save(): void {
    if (
      this.sceneEditor &&
      this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash
    ) {
      this.logger.info('save still clip');
      this.undoManager.update(this.sceneEditor.scene);
    }
  }

  /**
   * manipulate background color.
   */
  public setBackgroundColor(color: string): void {
    const clip = this.clip.value;
    const clipEditor = this.sceneEditor.getClipEditor(clip);
    const newColor = clipEditor.setBackgroundColor(color);
    this.backgroundColor.next(newColor);
    this._updateIntermediateScene();
  }

  _updateIntermediateScene(): void {
    this.timelineState.overrideScene.next(this.sceneEditor.scene);
    this.previewState.overrideScene.next(this.sceneEditor.scene);
  }

  protected _getTargetClip(
    timelineSelection: (ClipModel2 | EffectSelection)[]
  ): StillClip | null {
    return (
      <StillClip>timelineSelection.find((clip) => clip instanceof StillClip) ||
      null
    );
  }
}
