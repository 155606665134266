/*!
 * Copyright 2019 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { LibCommonModule } from '../common/lib-common.module';
import { LibToolCommonModule } from '../tool-common/lib-tools-common.module';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';
import { ZoomEditorComponent } from './components/zoom-editor/zoom-editor.component';
import { ZoomToolbarComponent } from './components/zoom-toolbar/zoom-toolbar.component';

const kEntryComponents = [ZoomToolbarComponent, ZoomEditorComponent];

@NgModule({
  declarations: [...kEntryComponents],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatSliderModule,
    MatButtonModule,
    LibCommonModule,
    LibToolCommonModule,
    LibToolSidebarModule,
  ],
  exports: [...kEntryComponents],
  entryComponents: kEntryComponents,
})
export class LibZoomEditorModule {}
