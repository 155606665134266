/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatSliderModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { LibCommonModule } from '../common/lib-common.module';
import { DriveImportComponent } from '../import-modals/drive-import/drive-import.component';
import { ImportModule } from '../import-modals/import.module';
import { LocalImportComponent } from '../import-modals/local-import/local-import.component';
import { LibKeyboardShortcutsModule } from '../keyboard-shortcuts/keyboard-shortcuts.module';
import { ImportButtonComponent } from './components/add-media-button/add-media-button.component';
import { TimeIndicatorComponent } from './components/time-indicator/time-indicator.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UndoControlsComponent } from './components/undo-controls/undo-controls.component';
import { ZoomSliderComponent } from './components/zoom-slider/zoom-slider.component';
import { MinuteSecondsPipe } from './minute-seconds.pipe';

const exportDeclarations = [ToolbarComponent, UndoControlsComponent];

@NgModule({
  declarations: [
    ...exportDeclarations,
    ZoomSliderComponent,
    MinuteSecondsPipe,
    TimeIndicatorComponent,
    ImportButtonComponent,
  ],
  imports: [
    CommonModule,
    LibCommonModule,
    MatMenuModule,
    MatButtonModule,
    FormsModule,
    MatDialogModule,
    MatSliderModule,
    MatTooltipModule,
    MatIconModule,
    MatToolbarModule,
    LibKeyboardShortcutsModule,
    ImportModule,
  ],
  entryComponents: [LocalImportComponent, DriveImportComponent],
  exports: exportDeclarations,
})
export class LibToolbarModule {}
