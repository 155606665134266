<div class="container">
  <h2 matDialogTitle>Processing video...</h2>
  <mat-dialog-content>
    <mat-progress-bar
      [mode]="
        (_progress | async) > 0 && (_progress | async) <= 1
          ? 'determinate'
          : 'indeterminate'
      "
      [value]="(_progress | async) * 100"
    >
    </mat-progress-bar>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button (click)="onCloseClick()" cdkFocusInitial>Cancel</button>
  </mat-dialog-actions>
</div>
