/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quill-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./quill-container-edit.component";
import * as i4 from "../../annotations-tool-controller.service";
var styles_QuillContainerEditComponent = [i0.styles];
var RenderType_QuillContainerEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuillContainerEditComponent, data: {} });
export { RenderType_QuillContainerEditComponent as RenderType_QuillContainerEditComponent };
export function View_QuillContainerEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "quill-container"], ["quill-editor-element", ""]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onQuillMousedown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { cursor: 0, overflow: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.cursorStyle, _co.overflow); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QuillContainerEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-quill-edit", [], null, null, null, View_QuillContainerEditComponent_0, RenderType_QuillContainerEditComponent)), i1.ɵdid(1, 4374528, null, 0, i3.QuillContainerEditComponent, [i1.ElementRef, i4.AnnotationsToolControllerService], null, null)], null, null); }
var QuillContainerEditComponentNgFactory = i1.ɵccf("lib-quill-edit", i3.QuillContainerEditComponent, View_QuillContainerEditComponent_Host_0, { textBox: "textBox" }, {}, []);
export { QuillContainerEditComponentNgFactory as QuillContainerEditComponentNgFactory };
