var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fbWrapper } from '@castify/models';
import { environment } from 'environments/environment';
import * as firebase from 'firebase';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { GapiLoaderService } from '../common/gapi-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/gapi-loader.service";
var log = Log.create('UserService');
/**
 * provides user and license information
 */
var UserService = /** @class */ (function () {
    function UserService(gapiLoader) {
        var _this = this;
        this.gapiLoader = gapiLoader;
        this.fbAuth = fbWrapper().auth();
        this.user = new BehaviorSubject(undefined);
        // provide observable for firebase user
        this.fbAuth.onAuthStateChanged(function (user) {
            if (user !== _this.user.value)
                _this.user.next(user);
        });
    }
    Object.defineProperty(UserService.prototype, "currentUser", {
        /**
         * current signed in user or null if no user signed in
         */
        get: function () {
            return this.user.value || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isSignedIn", {
        /**
         * true when a user issigned in
         */
        get: function () {
            return !!this.user.value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sign in with a gapi access token. This is
     */
    UserService.prototype.signInWithGoogleAccessToken = function (accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var cred;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log.info('signing in with gapi access token');
                        cred = firebase.auth.GoogleAuthProvider.credential(null, accessToken);
                        return [4 /*yield*/, this.fbAuth.signInWithCredential(cred)];
                    case 1:
                        _a.sent();
                        this.fbAuth
                            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                            .then(function () { return log.info('updated auth persistence'); })
                            .catch(function (err) { return log.error('failed to set persistence', err); });
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.signInFromCache = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isSignedIn)
                            return [2 /*return*/, true];
                        // wait cached firebase session
                        return [4 /*yield*/, this.user
                                .pipe(filter(function (u) { return u !== undefined; }), // undefined indicates that firebase auth cache has not been read yet
                            first())
                                .toPromise()];
                    case 1:
                        // wait cached firebase session
                        _a.sent();
                        return [2 /*return*/, this.isSignedIn];
                }
            });
        });
    };
    /**
     * Obtain a gapi auth response for the currently signed in user (firebase user)
     * without prompting the user.
     * This works, because the dashboard authenticates against gapi. That auth state is
     * then simply read when we auth silently.
     * gapi also caches the auth state, so that the returned promise resolves immediately
     * if a previously received access_token is not expired.
     */
    UserService.prototype.gapiAuth = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isSignedIn)
                            throw new Error('not signed in');
                        return [4 /*yield*/, this.gapiLoader.loadAuth2()];
                    case 1:
                        _a.sent(); // ensure auth api is loaded
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                gapi.auth2.authorize({
                                    client_id: environment.auth.clientId,
                                    scope: environment.auth.scopes.join(' '),
                                    prompt: 'none',
                                    login_hint: UserService.getGoogleUid(_this.currentUser),
                                }, function (resp) {
                                    if (resp.error) {
                                        reject(resp.error);
                                    }
                                    else {
                                        resolve(resp);
                                    }
                                });
                            })];
                    case 2: // ensure auth api is loaded
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * dev signin with email and passowrd.
     * NOTE: this will produce a crippeled auth state, because we can only authenticate firebase using email+password,
     *  but nor gapi. So access to google drive will not be possible / only possible from backends, given that a
     *  valid offline access code/refresh token is present in datastore
     */
    UserService.prototype._signInFirebaseWithPassword = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fbAuth.signInWithEmailAndPassword(email, password)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fbAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Signs out the current user and redirects to dashboard to select new account
     */
    UserService.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fbAuth.signOut()];
                    case 1:
                        _a.sent();
                        // redirect to dashboard sign in endpoint
                        this.initSignIn('', true);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.initSignIn = function (returnPath, selectAccount) {
        if (selectAccount === void 0) { selectAccount = false; }
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                if (environment.auth.signInUrl) {
                    url = new URL(environment.auth.signInUrl);
                    url.searchParams.set('returnPath', "/signin?returnUrl=" + encodeURIComponent(returnPath));
                    // signal dashboard that users should select a new account
                    if (selectAccount)
                        url.searchParams.set('selectAccount', '');
                    // navigate to dashboard
                    log.info('navigating to dashboard signin:', url.toString());
                    window.location.replace(url.toString());
                }
                else {
                    window.location.replace("/signin?returnUrl=" + encodeURIComponent(returnPath));
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * @returns google UID of the user
     */
    UserService.getGoogleUid = function (user) {
        return user.providerData.find(function (profile) { return profile.providerId === 'google.com'; }).uid;
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.GapiLoaderService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
