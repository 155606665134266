/*!
 * Copyright 2020 Screencastify LLC
 */

import { SceneModel2 } from '@castify/edit-models';
import { Limits, mod, sty } from '@castify/models';
import { Log } from 'ng2-logger/browser';
import * as uuid from 'uuid/v4';

const log = Log.create('UndoStore');

/**
 * key of local storage undo buffer
 */
export const kUndoStorageKey = '_undo';

export interface UndoState {
  scene: SceneModel2;
  id: sty.UUID;
}

interface UndoStorageMetadata {
  undoIdx: number;
  redoIdx: number;
  currentIdx: number;
}

/**
 * Undo/Redo state storage that is limited to a certain capacity.
 * This implements a ring buffer of capacity size that contains a linear buffer of states.
 * The linear buffer is delimited by redoIdx (start) and undoIdx (end) and can grow up to capacity size.
 * When possible the buffer will get synced to local storage.
 */
export class UndoStore {
  protected undoIdx = 0; // marks end of linear buffer
  protected redoIdx = 0; // marks start of linear buffer
  protected currentIdx = 0;

  // current size of the linear buffer (number of states available for undo/redo+current)
  protected get length(): number {
    return this._distance(this.redoIdx, this.undoIdx);
  }
  /**
   * current number of undo states stored. Note that the current state is counted as an undo state.
   * This means that you can make (undoLength - 1) undos
   */
  get undoLength(): number {
    return this._distance(this.currentIdx, this.undoIdx);
  }
  /**
   * current number of redo states stored. You can do redoLength redos
   */
  get redoLength(): number {
    return this._distance(this.redoIdx, this.currentIdx);
  }
  // calculate distance in the ring buffer
  protected _distance(start: number, end: number): number {
    return start >= end
      ? start - end // no wrap around at the end of the linear buffer (ring buffer)
      : this.capacity - end + start; // wrap around
  }

  protected states: UndoState[];
  readonly storage: Storage = window.localStorage;

  constructor(initialState: UndoState | null, readonly capacity: number) {
    this.states = Array(capacity).fill(null);
    this._init(initialState);
  }

  pushState(scene: SceneModel2): UndoState {
    const state = { id: uuid(), scene };
    // push independent copy of state to ensure no outside reference can alter the undo state
    this._pushState(this._copyState(state));
    return state;
  }

  protected _pushState(state: UndoState): void {
    // adjust pointers, ensure redoIdx >= currentIdx >= undoIdx (in ring buffer sense)
    if (this.length + 1 >= this.capacity)
      this.undoIdx = mod(this.redoIdx + 2, this.capacity);
    this.currentIdx = mod(this.currentIdx + 1, this.capacity);
    this.redoIdx = this.currentIdx;

    // update state in memory
    this.states[this.currentIdx] = state;
    // sync local storage (optional, local storage might eb disabled)
    try {
      this._writeState(this.currentIdx, state);
      this._writeMeta();
    } catch (err) {
      log.warn('failed to write local storage');
      this._clearStorage();
    }
  }

  undo(): UndoState | null {
    if (!this.undoLength) throw new RangeError('undo');
    this.currentIdx = mod(this.currentIdx - 1, this.capacity);

    this.states[this.currentIdx] = {
      ...this.states[this.currentIdx],
      id: uuid(), // replace uuid to make sure no invalid redo branch is taken from this state (on another machine)
    };
    const state = this.states[this.currentIdx];
    try {
      this._writeState(this.currentIdx, state);
      this._writeMeta();
    } catch {}

    // return independent copy of scene to make sure the saved state is not altered
    return state && state.id && state.scene ? this._copyState(state) : null;
  }

  redo(): UndoState | null {
    if (!this.redoLength) throw new RangeError('redo');
    this.currentIdx = mod(this.currentIdx + 1, this.capacity);
    try {
      this._writeMeta();
    } catch {}
    const state = this.states[this.currentIdx];

    // return independent copy of scene to make sure the saved state is not altered
    return state && state.id && state.scene ? this._copyState(state) : null;
  }

  reset(): void {
    this.undoIdx = 0;
    this.redoIdx = 0;
    this.currentIdx = 0;
    try {
      this._writeMeta();
    } catch {}
  }

  protected _init(state: UndoState): void {
    if (!state) return;

    const meta = this._readMeta();
    if (meta && meta.currentIdx) {
      try {
        // this can throw an error when a Scene is in local storage that is incompatible with the current scene model
        const storageState = this._readState(meta.currentIdx);
        if (storageState && storageState.id && storageState.id === state.id) {
          // can continue existing undo history from local storage
          for (let i = 0; i < this.capacity; i++) {
            this.states[i] = this._readState(i);
          }
          const idxLimits = new Limits(0, this.capacity - 1);
          this.undoIdx = idxLimits.apply(Math.round(meta.undoIdx || 0));
          this.redoIdx = idxLimits.apply(Math.round(meta.redoIdx || 0));
          this.currentIdx = idxLimits.apply(Math.round(meta.currentIdx || 0));
          return;
        }
      } catch (error) {
        log.error(error, 'Handled: undo store cleared');
        this._clearStorage();
        this.reset();
      }
    }

    // push initial state
    this._pushState(state);
  }

  protected _readState(idx: number): UndoState | null {
    const obj = JSON.parse(
      this.storage.getItem([kUndoStorageKey, idx].join('_'))
    );
    if (obj && obj.id && obj.scene) {
      return {
        id: obj.id,
        scene: new SceneModel2(obj.scene),
      };
    } else {
      return null;
    }
  }
  protected _writeState(idx: number, state: UndoState): void {
    this.storage.setItem(
      [kUndoStorageKey, idx].join('_'),
      JSON.stringify(state)
    );
  }
  protected _readMeta(): UndoStorageMetadata {
    return JSON.parse(
      this.storage.getItem([kUndoStorageKey, 'meta'].join('_'))
    );
  }
  protected _writeMeta(): void {
    const meta: UndoStorageMetadata = {
      currentIdx: this.currentIdx,
      undoIdx: this.undoIdx,
      redoIdx: this.redoIdx,
    };
    this.storage.setItem(
      [kUndoStorageKey, 'meta'].join('_'),
      JSON.stringify(meta)
    );
  }
  protected _clearStorage(): void {
    this.storage.removeItem([kUndoStorageKey, 'meta'].join('_'));
    for (let i = 0; i < this.capacity; i++) {
      this.storage.removeItem([kUndoStorageKey, i].join('_'));
    }
  }

  protected _copyState(state: UndoState): UndoState {
    return {
      id: state.id,
      scene: state.scene ? state.scene.copy() : null,
    };
  }
}
