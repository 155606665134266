/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss'],
})
export class SpinnerModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
