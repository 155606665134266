/*!
 * Copyright 2020 Screencastify LLC
 */
import { StillClipToolbarComponent } from './components/still-clip-toolbar/still-clip-toolbar.component';
var kEntryComponents = [StillClipToolbarComponent];
var LibStillClipToolModule = /** @class */ (function () {
    function LibStillClipToolModule() {
    }
    return LibStillClipToolModule;
}());
export { LibStillClipToolModule };
