<mat-toolbar color="primary">
  <span>Unsupported Browser</span>
</mat-toolbar>
<div class="content">
  <p class="mat-body-2">
    Sorry, your browser is not supported yet.
  </p>
  <p>
    Only up-to-date versions of <a href="https://www.google.com/chrome/">Google Chrome</a>Support for more
    browsers coming soon.
  </p>
</div>
