/*!
 * Copyright 2020 Screencastify LLC
 */
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var LocalProgressModalComponent = /** @class */ (function () {
    function LocalProgressModalComponent() {
        this._progress = new BehaviorSubject(null);
        this.closeEvent = new EventEmitter();
    }
    Object.defineProperty(LocalProgressModalComponent.prototype, "progress", {
        set: function (p) {
            this._progress.next(p);
        },
        enumerable: true,
        configurable: true
    });
    LocalProgressModalComponent.prototype.ngOnInit = function () { };
    /**
     * Emits an event to the parent which indicates the user has
     * requested the modal be closed
     */
    LocalProgressModalComponent.prototype.onCloseClick = function () {
        this.closeEvent.emit();
    };
    return LocalProgressModalComponent;
}());
export { LocalProgressModalComponent };
