/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudioClip, Range, VideoClip } from '@castify/edit-models';
import { environment } from 'environments/environment';
import {
  concat,
  from,
  Observable,
  of,
  throwError,
  throwError as observableError,
} from 'rxjs';
import {
  catchError,
  delay,
  ignoreElements,
  map,
  retryWhen,
  switchMap,
} from 'rxjs/operators';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class StreamerClientService {
  protected _queueStartByFile: { [keyof: string]: number } = {};

  constructor(
    protected readonly userService: UserService,
    protected readonly http: HttpClient
  ) {}

  /**
   * makes sure the api processes upcoming requests with priority.
   * All previously issued requests are assumed to be obsolete but will be retried if still subscribed to
   */
  async flushVideoQueue(fileId: string): Promise<void> {
    this._queueStartByFile[fileId] = Date.now();
  }

  /**
   * fetch a video segment from the api.
   * Request will be retried when api ejects it from the queue, and will be aborted on unsubscribe
   */
  fetchVideo(clip: VideoClip, segIdx: number): Observable<ArrayBuffer> {
    return this._fetchHandleApiNotOpen(
      clip.userFileId,
      this._retryWhenResponseEmpty(this._doFetchVideo(clip, segIdx))
    );
  }

  protected _doFetchVideo(
    clip: VideoClip,
    segIdx: number
  ): Observable<ArrayBuffer> {
    // build request
    return from(this.userService.currentUser.getIdToken()).pipe(
      switchMap((authToken) =>
        this.http.get(this._getVideoUrl(clip.userFileId, segIdx), {
          responseType: 'arraybuffer',
          withCredentials: true, // send cookies for cors request
          headers: new HttpHeaders({
            'client-time': `${Date.now()}`,
            'queue-start': `${this._queueStartByFile[clip.userFileId] || 0}`,
            authorization: `Bearer ${authToken}`,
          }),
          params: this._getClipQueryParams(clip, segIdx),
        })
      )
    );
  }
  protected _getClipQueryParams(clip: VideoClip, segIdx: number): HttpParams {
    let params = new HttpParams();
    if (!clip.transform) return params;
    // crop
    if (clip.transform.crop) {
      const crop = clip.transform.crop;
      params = params.set('crop', crop.toQueryString());
    }
    // effects
    if (clip.effects) {
      const segDuration = environment.video.segmentDuration;
      const segStart = segIdx * segDuration;
      const effects = clip.effects.getAllInRange(
        segStart - clip.startInFile,
        segDuration,
        { exclusive: true }
      );
      effects.forEach((effect) => {
        // translate startInClip to file time for better caching
        const queryEffect = effect.copy();
        queryEffect.startInClip += clip.startInFile;
        // add effect to request
        switch (effect.type) {
          case 'zoom':
            params = params.append(
              effect.type,
              queryEffect.toQueryString(
                new Range(segStart, segStart + segDuration)
              )
            );
            break;
          default:
            break;
        }
      });
    }
    return params;
  }

  /**
   * fetch audio from the api.
   * Behaves similar to fetchVideo
   */
  fetchAudio(clip: AudioClip, segIdx: number): Observable<ArrayBuffer> {
    return this._fetchHandleApiNotOpen(
      clip.userFileId,
      this._retryWhenResponseEmpty(this._doFetchAudio(clip, segIdx))
    );
  }
  protected _doFetchAudio(
    clip: AudioClip,
    segIdx: number
  ): Observable<ArrayBuffer> {
    return from(this.userService.currentUser.getIdToken()).pipe(
      switchMap((authToken) =>
        this.http.get(this._getAudioUrl(clip.userFileId, segIdx), {
          responseType: 'arraybuffer',
          withCredentials: true,
          headers: new HttpHeaders({
            authorization: `Bearer ${authToken}`,
          }),
        })
      )
    );
  }

  protected _retryWhenResponseEmpty(
    fetchObs: Observable<ArrayBuffer>
  ): Observable<ArrayBuffer> {
    return fetchObs.pipe(
      map((v) => {
        if (!v.byteLength) throw new HttpErrorResponse({ status: 202 });
        return v;
      }),
      retryWhen((errObs) =>
        errObs.pipe(
          switchMap((err) => {
            if (err && err.status === 202)
              return of(202).pipe(
                delay(environment.preview.emptyApiResponseRetryDelay)
              );
            return throwError(err);
          })
        )
      )
    );
  }

  protected _fetchHandleApiNotOpen(
    fileId: string,
    fetchObs: Observable<ArrayBuffer>
  ): Observable<ArrayBuffer> {
    let retryCount = 0;
    const maxRetries = 1; // TODO: read from config
    return fetchObs.pipe(
      catchError((err, caught) => {
        if (
          err instanceof HttpErrorResponse &&
          (err.status === 400 || err.status === 401)
        ) {
          // open and retry request
          return concat(this._openApi(fileId).pipe(ignoreElements()), caught);
        }
        return observableError(err);
      }),
      retryWhen((errObs) =>
        errObs.pipe(
          switchMap((err) => {
            if (
              ++retryCount > maxRetries || // limit number of retry attempts
              (err && err.status === 404)
            ) {
              // don't repeat when receiving 404
              return throwError(err);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }
  protected _openApi(fileId: string): Observable<Object> {
    return from(this.userService.currentUser.getIdToken()).pipe(
      switchMap((authToken) =>
        this.http.post(
          this._getOpenUrl(fileId),
          {},
          {
            responseType: 'json',
            withCredentials: true,
            headers: new HttpHeaders({
              authorization: `Bearer ${authToken}`,
            }),
          }
        )
      )
    );
  }

  _getBaseUrl(fileId: string): string {
    return [environment.preview.apiUrl, fileId].join('/');
  }
  _getOpenUrl(fileId: string): string {
    return [this._getBaseUrl(fileId), 'open'].join('/');
  }
  _getVideoUrl(fileId: string, segIdx: number): string {
    return [this._getBaseUrl(fileId), `v${segIdx}`].join('/');
  }
  _getAudioUrl(fileId: string, segIdx: number): string {
    return [this._getBaseUrl(fileId), `a${segIdx}`].join('/');
  }
}
