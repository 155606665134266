/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ElementRef, OnDestroy, } from '@angular/core';
import { Rectangle, Size, } from '@castify/edit-models';
import { VideoEffectViewComponent } from 'lib-editor/lib/preview/components/video-effect-view/video-effect-view.component';
import { applyPosition } from 'lib-editor/lib/tool-common/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
var BlurViewComponent = /** @class */ (function (_super) {
    __extends(BlurViewComponent, _super);
    function BlurViewComponent(hostElm) {
        var _this = _super.call(this) || this;
        _this.hostElm = hostElm;
        _this._blurBoxes = new BehaviorSubject([]);
        _this.canPlay = new BehaviorSubject(true);
        _this.subscriptions = new Subscription();
        return _this;
    }
    BlurViewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    BlurViewComponent.prototype.play = function () {
        // empty implementation:
        // playback relies on sync being called periodically. We don't need to track aplayback state
    };
    BlurViewComponent.prototype.pause = function () {
        // empty implementation: see this.play()
    };
    BlurViewComponent.prototype.seek = function (pos) {
        if (!this._clip || !this._effect.value)
            return;
        var posInClip = pos + this._effect.value.startInClip;
        var rect = new Rectangle(0, 0, 1, 1);
        var crop = (this._clip.transform || {}).crop;
        if (crop) {
            var srcDims = this._clip.srcDimensions;
            rect.width *= srcDims.width / Math.max(crop.width, 1);
            rect.height *= srcDims.height / Math.max(crop.height, 1);
            rect.left -= crop.left / Math.max(crop.width, 1);
            rect.top -= crop.top / Math.max(crop.height, 1);
        }
        var zoom = (__spread(this._clip.effects.getAllAtPos(posInClip)).find(function (v) { return v.type === 'zoom'; }));
        if (zoom) {
            var posInZoom = posInClip - zoom.startInClip;
            var buildFactor = Math.min(posInZoom / (zoom.buildIn + Number.EPSILON), 1) -
                Math.max((posInZoom - zoom.duration) / (zoom.buildOut + Number.EPSILON) + 1, 0);
            var scale = (zoom.zoom - 1) * buildFactor + 1;
            rect.width *= scale;
            rect.height *= scale;
            rect.left *= scale;
            rect.left -= zoom.left * buildFactor * zoom.zoom;
            rect.top *= scale;
            rect.top -= zoom.top * buildFactor * zoom.zoom;
        }
        applyPosition(this.container, rect);
    };
    BlurViewComponent.prototype.sync = function (refPos) {
        this.seek(refPos);
        this._currentTime = refPos;
        return this.currentTime - refPos;
    };
    BlurViewComponent.prototype.onDisplay = function () {
        this.updateDimensions();
        this.boxesSubscription = this._effect
            .pipe(map(function (v) { return v.boxes; }))
            .subscribe(this._blurBoxes);
        this.subscriptions.add(this.boxesSubscription);
        _super.prototype.onDisplay.call(this);
    };
    BlurViewComponent.prototype.onHide = function () {
        if (this.boxesSubscription)
            this.boxesSubscription.unsubscribe();
    };
    BlurViewComponent.prototype.trackByIndex = function (idx, _) {
        return idx;
    };
    BlurViewComponent.prototype.updateDimensions = function () {
        if (!this.bounds)
            return;
        var hostDims = Size.fromObj(this.hostElm.nativeElement.getBoundingClientRect());
        var videoDims = this._getVideoDims().scaleToFit(hostDims);
        applyPosition(this.bounds, {
            width: videoDims.width / hostDims.width,
            height: videoDims.height / hostDims.height,
        });
    };
    BlurViewComponent.prototype._getVideoDims = function () {
        return this._clip.dimensions;
    };
    return BlurViewComponent;
}(VideoEffectViewComponent));
export { BlurViewComponent };
