/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2018 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crop-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-common/components/shadow-box/shadow-box.component.ngfactory";
import * as i3 from "../../../tool-common/components/shadow-box/shadow-box.component";
import * as i4 from "../../../tool-common/directives/drag-handle.directive";
import * as i5 from "./crop-editor.component";
import * as i6 from "../../crop-editor-controller.service";
var styles_CropEditorComponent = [i0.styles];
var RenderType_CropEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropEditorComponent, data: {} });
export { RenderType_CropEditorComponent as RenderType_CropEditorComponent };
export function View_CropEditorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { bounds: 0 }), i1.ɵqud(402653184, 2, { resizeBox: 0 }), i1.ɵqud(402653184, 3, { shadowBox: 0 }), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["bounds", 1]], null, 12, "div", [["id", "bounds"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "lib-shadow-box", [], null, null, null, i2.View_ShadowBoxComponent_0, i2.RenderType_ShadowBoxComponent)), i1.ɵdid(5, 49152, [[3, 4]], 0, i3.ShadowBoxComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["resizeBox", 1]], null, 9, "div", [["id", "resizeBox"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onMove($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "resizer top-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "resizer top-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "resizer bottom-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "resizer bottom-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" })], null, null); }
export function View_CropEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-crop-editor", [], null, [["window", "mousedown"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeEditor($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleWindowResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CropEditorComponent_0, RenderType_CropEditorComponent)), i1.ɵdid(1, 245760, null, 0, i5.CropEditorComponent, [i6.CropEditorControllerService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CropEditorComponentNgFactory = i1.ɵccf("lib-crop-editor", i5.CropEditorComponent, View_CropEditorComponent_Host_0, {}, {}, []);
export { CropEditorComponentNgFactory as CropEditorComponentNgFactory };
