/*!
 * Copyright 2019 Screencastify LLC
 */

export class PIDController {
  protected setpoint: number;
  protected integrator: number;
  protected derivator: number;

  constructor(
    readonly kP: number,
    readonly kI: number,
    readonly kD: number,
    readonly minIntegrator = -1000,
    readonly maxIntegrator = 1000
  ) {
    this.set(0);
  }

  set(setpoint: number): this {
    this.setpoint = setpoint;
    this.integrator = 0;
    this.derivator = 0;
    return this;
  }

  /**
   * Calculate PID output value for given input value (process variable)
   */
  update(currentValue: number): number {
    const error = this.setpoint - currentValue;

    const p = this.kP * error;
    const d = this.kD * (error - this.derivator);
    this.derivator = error;

    this.integrator = Math.max(
      Math.min(this.integrator + error, this.maxIntegrator),
      this.minIntegrator
    );

    const i = this.integrator * this.kI;
    return p + i + d;
  }
}
