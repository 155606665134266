/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit, } from '@angular/core';
import { BlurEditorControllerService } from '../../blur-editor-controller.service';
import { BlurViewComponent } from '../blur-view/blur-view.component';
var keepOpenSelectors = [
    'lib-blur-editor',
    'lib-blur-toolbar',
    'lib-blur-box',
    '#remove-button',
];
var BlurEditorComponent = /** @class */ (function (_super) {
    __extends(BlurEditorComponent, _super);
    function BlurEditorComponent(hostElm, ctrl) {
        var _this = _super.call(this, hostElm) || this;
        _this.ctrl = ctrl;
        return _this;
    }
    BlurEditorComponent.prototype.ngOnInit = function () {
        this.clip = this.ctrl.clip.value;
        this.subscriptions.add(this.ctrl.effect.subscribe(this._effect));
        this.updateDimensions();
    };
    BlurEditorComponent.prototype.onEditorClick = function (event) {
        var editorClicked = event.composedPath().some(function (v) {
            return (v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
                // check if element id indicates it is part of the tool
                (v.id && keepOpenSelectors.includes("#" + v.id));
        });
        if (!editorClicked) {
            // save edit and close editor
            this.ctrl.save();
            this.ctrl.close();
        }
    };
    BlurEditorComponent.prototype.onBoxClick = function (boxIdx, event) {
        this.ctrl.selectedBox.next(boxIdx);
        event.stopPropagation(); // don't trigger click handler on background (clears box selection)
    };
    BlurEditorComponent.prototype._getVideoDims = function () {
        return this._clip.srcDimensions;
    };
    return BlurEditorComponent;
}(BlurViewComponent));
export { BlurEditorComponent };
