/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
import { HorizontalAlignment, VerticalAlignment } from '@castify/edit-models';
import { filter, map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { kQuillDefaultAlignment, NonToggleableAttribute as nonToggleable, ToggleableAttribute as toggleable, } from '../../quill-config';
var AnnotationsSidebarButtonsComponent = /** @class */ (function () {
    function AnnotationsSidebarButtonsComponent(ctrl) {
        this.ctrl = ctrl;
        /**
         * Expose constants to the template
         */
        this.verticalAlignment = VerticalAlignment;
        this.horizontalAlignment = HorizontalAlignment;
        /**
         * Attribute observables
         */
        this.bold = this.ctrl.toggleableObsFactory(toggleable.bold);
        this.italic = this.ctrl.toggleableObsFactory(toggleable.italic);
        this.underline = this.ctrl.toggleableObsFactory(toggleable.underline);
        this.verticalAlign = this.ctrl.activeBox.pipe(filter(function (box) { return !!box; }), map(function (box) { return box.verticalAlignment; }));
        this.align = this.ctrl
            .nonToggleableObsFactory(nonToggleable.align, false)
            .pipe(map(function (attr) { return (attr ? attr : kQuillDefaultAlignment); }), startWith(kQuillDefaultAlignment));
    }
    AnnotationsSidebarButtonsComponent.prototype.ngOnInit = function () { };
    /**
     * Toggleable attribute setters
     */
    AnnotationsSidebarButtonsComponent.prototype.toggleBold = function () {
        this.ctrl.triggerToggleable(toggleable.bold);
    };
    AnnotationsSidebarButtonsComponent.prototype.toggleItalics = function () {
        this.ctrl.triggerToggleable(toggleable.italic);
    };
    AnnotationsSidebarButtonsComponent.prototype.toggleUnderline = function () {
        this.ctrl.triggerToggleable(toggleable.underline);
    };
    /**
     * Non-toggleable attribute setters
     */
    AnnotationsSidebarButtonsComponent.prototype.setJustification = function (justification) {
        // pass in the emptry string for left
        // because quill treats `left` as default
        this.ctrl.triggerSettable(nonToggleable.align, justification === kQuillDefaultAlignment ? '' : justification);
    };
    AnnotationsSidebarButtonsComponent.prototype.setVerticalAlignment = function (alignment) {
        var _this = this;
        this.ctrl.editAnnotation(function (editor) {
            return editor.setVerticalAlignment(_this.ctrl.selectedBoxId.value, alignment);
        });
        // update last seen styles so this change can be copied to
        // new boxes
        this.ctrl.persistSelectedBoxToLastSeenStyles();
        // refocus quill after each interaction
        setTimeout(function () {
            _this.ctrl.activeQuill.value.focus();
        }, 0);
    };
    return AnnotationsSidebarButtonsComponent;
}());
export { AnnotationsSidebarButtonsComponent };
