/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatIconRegistry,
  MatInputModule,
  MatMenuModule,
  MatSelectModule,
} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { LibToolCommonModule } from '../tool-common/lib-tools-common.module';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';

import { AnnotationsPreviewEditComponent } from './components/annotations-preview/annotations-preview-edit.component';
import { AnnotationsPreviewViewComponent } from './components/annotations-preview/annotations-preview-view.component';
import { AnnotationsSidebarButtonsComponent } from './components/annotations-sidebar-buttons/annotations-sidebar-buttons.component';
import { AnnotationsSidebarColorsComponent } from './components/annotations-sidebar-colors/annotations-sidebar-colors.component';
import { AnnotationsSidebarFontComponent } from './components/annotations-sidebar-font/annotations-sidebar-font.component';
import { AnnotationsSidebarComponent } from './components/annotations-sidebar/annotations-sidebar.component';
import { QuillContainerEditComponent } from './components/quill-container/quill-container-edit.component';
import { QuillContainerViewComponent } from './components/quill-container/quill-container-view.component';
import { TextBoxEditBorderComponent } from './components/text-box-edit-border/text-box-edit-border.component';
import { TextBoxEditRotateBorderComponent } from './components/text-box-edit-border/text-box-edit-rotate-border.component';
import { TextPlaceholderComponent } from './components/text-placeholder/text-placeholder.component';
import { sidebarSVGs } from './sidebar-svgs';

const kEntryComponents = [
  AnnotationsPreviewViewComponent,
  AnnotationsPreviewEditComponent,
  QuillContainerEditComponent,
  QuillContainerViewComponent,
  AnnotationsSidebarComponent,
];

@NgModule({
  declarations: [
    ...kEntryComponents,
    QuillContainerEditComponent,
    TextPlaceholderComponent,
    TextBoxEditBorderComponent,
    TextBoxEditRotateBorderComponent,
    AnnotationsSidebarButtonsComponent,
    AnnotationsSidebarFontComponent,
    AnnotationsSidebarColorsComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    LibToolSidebarModule,
    LibToolCommonModule,
    LibToolSidebarModule,
  ],
  exports: [...kEntryComponents],
  entryComponents: kEntryComponents,
})
export class LibAnnotationsToolModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {
    /**
     * Registers custom SVGs with the Material Icon module with
     * selectors we can reference in markup
     */
    Object.entries(sidebarSVGs).forEach(([svgSelector, svgMarkup]) => {
      this.matIconRegistry.addSvgIconLiteral(
        svgSelector,
        this.domSanitzer.bypassSecurityTrustHtml(svgMarkup)
      );
    });
  }
}
