/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatSliderModule,
} from '@angular/material';
import { LibCommonModule } from '../common/lib-common.module';
import { LibToolCommonModule } from '../tool-common/lib-tools-common.module';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';
import { BlurBoxComponent } from './components/blur-box/blur-box.component';
import { BlurEditorComponent } from './components/blur-editor/blur-editor.component';
import { BlurToolbarComponent } from './components/blur-toolbar/blur-toolbar.component';
import { BlurViewComponent } from './components/blur-view/blur-view.component';

const kEntryComponents = [
  BlurEditorComponent,
  BlurToolbarComponent,
  BlurViewComponent,
];

@NgModule({
  declarations: [...kEntryComponents, BlurBoxComponent],
  imports: [
    CommonModule,
    MatSliderModule,
    MatIconModule,
    MatButtonModule,
    LibCommonModule,
    LibToolCommonModule,
    LibToolSidebarModule,
  ],
  entryComponents: kEntryComponents,
})
export class LibBlurEditorModule {}
