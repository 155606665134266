/*!
 * Copyright 2020 Screencastify LLC
 */
import { ElementRef, } from '@angular/core';
import { Rectangle } from '@castify/models';
var ShadowBoxComponent = /** @class */ (function () {
    function ShadowBoxComponent() {
    }
    Object.defineProperty(ShadowBoxComponent.prototype, "cutout", {
        set: function (rect) {
            this.setCutout(rect);
        },
        enumerable: true,
        configurable: true
    });
    ShadowBoxComponent.prototype.setCutout = function (rect) {
        if (!this.shadowBox) {
            return;
        }
        // set clipping path for shadow box (defines an inner cutout)
        this.shadowBox.nativeElement.style.clipPath = "polygon(\n      0% 0%,\n      0% 100%,\n      " + rect.left * 100 + "% 100%,\n      " + rect.left * 100 + "% " + rect.top * 100 + "%,\n      " + rect.right * 100 + "% " + rect.top * 100 + "%,\n      " + rect.right * 100 + "% " + rect.bottom * 100 + "%,\n      " + rect.left * 100 + "% " + rect.bottom * 100 + "%,\n      " + rect.left * 100 + "% 100%,\n      100% 100%,\n      100% 0%\n    )";
    };
    return ShadowBoxComponent;
}());
export { ShadowBoxComponent };
