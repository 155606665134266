/*!
 * Copyright 2018 Screencastify LLC
 */
import { CropEditorComponent } from './components/crop-editor/crop-editor.component';
import { CropToolbarComponent } from './components/crop-toolbar/crop-toolbar.component';
var entryComponents = [CropEditorComponent, CropToolbarComponent];
var LibCropEditorModule = /** @class */ (function () {
    function LibCropEditorModule() {
    }
    return LibCropEditorModule;
}());
export { LibCropEditorModule };
