var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Limits } from '@castify/models';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UndoManagerService } from '../common/undo-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/undo-manager.service";
var PreviewStateService = /** @class */ (function () {
    function PreviewStateService(undoManager) {
        var _this = this;
        this.undoManager = undoManager;
        // set by user (through undo manager): The scene that is currently reproduced by preview
        this.scene = new BehaviorSubject(undefined);
        // set by preview: the actual playhead position. This gets periodical updates (not necessarily on every frame)
        this.playhead = new BehaviorSubject(0);
        // set by user: position that the preview should seek to
        this.seekPos = new Subject();
        // set by preview: indicates whether preview is actually playing
        this.isPlaying = new BehaviorSubject(false);
        // set by user: indicates whether playback is requested
        this.shouldPlay = new BehaviorSubject(false);
        // set by timeline indicates the seekable scene bounds
        this.seekLimits = new BehaviorSubject(new Limits(0, this.scene.value ? this.scene.value.duration : 0));
        // set this to temporarily ignore updates from undo manager
        this.overrideScene = new BehaviorSubject(null);
        combineLatest(this.undoManager.scene, this.overrideScene)
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), scene = _b[0], override = _b[1];
            return override || scene;
        }), map(function (scene) { return scene.copy().removeRedundantCuts(); }))
            .subscribe(function (scene) {
            // make sure playhead is in scene
            _this.seek(new Limits(0, scene.duration).apply(_this.playhead.value));
            _this.scene.next(scene);
        });
    }
    /**
     * start playback from current playhead position
     */
    PreviewStateService.prototype.play = function () {
        this.shouldPlay.next(true);
    };
    /**
     * stop at current position
     */
    PreviewStateService.prototype.pause = function () {
        this.shouldPlay.next(false);
    };
    /**
     * seek to pos in scene
     *
     * @returns actual new seek pos
     */
    PreviewStateService.prototype.seek = function (pos) {
        var seekPos = this.applySeekLimits(pos);
        this.seekPos.next(seekPos);
        return seekPos;
    };
    PreviewStateService.prototype.applySeekLimits = function (pos) {
        var sceneBounds = this.seekLimits.value;
        return sceneBounds.apply(pos);
    };
    /**
     * set playhead position (should only be used from preview component)
     */
    PreviewStateService.prototype.setPlayhead = function (pos) {
        this.playhead.next(pos);
    };
    PreviewStateService.ngInjectableDef = i0.defineInjectable({ factory: function PreviewStateService_Factory() { return new PreviewStateService(i0.inject(i1.UndoManagerService)); }, token: PreviewStateService, providedIn: "root" });
    return PreviewStateService;
}());
export { PreviewStateService };
