/*!
 * Copyright 2019 Screencastify LLC
 */
var PIDController = /** @class */ (function () {
    function PIDController(kP, kI, kD, minIntegrator, maxIntegrator) {
        if (minIntegrator === void 0) { minIntegrator = -1000; }
        if (maxIntegrator === void 0) { maxIntegrator = 1000; }
        this.kP = kP;
        this.kI = kI;
        this.kD = kD;
        this.minIntegrator = minIntegrator;
        this.maxIntegrator = maxIntegrator;
        this.set(0);
    }
    PIDController.prototype.set = function (setpoint) {
        this.setpoint = setpoint;
        this.integrator = 0;
        this.derivator = 0;
        return this;
    };
    /**
     * Calculate PID output value for given input value (process variable)
     */
    PIDController.prototype.update = function (currentValue) {
        var error = this.setpoint - currentValue;
        var p = this.kP * error;
        var d = this.kD * (error - this.derivator);
        this.derivator = error;
        this.integrator = Math.max(Math.min(this.integrator + error, this.maxIntegrator), this.minIntegrator);
        var i = this.integrator * this.kI;
        return p + i + d;
    };
    return PIDController;
}());
export { PIDController };
