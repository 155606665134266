<div
  class="ng-content"
  [ngStyle]="{ zIndex: (_isSelected | async) ? '+2' : '+0', pointerEvents: 'none'}"
>
  <ng-content></ng-content>
</div>
<div class="gray-rotate-handle"
  *ngIf="showGreyBox | async"
>
  <div
    class="rotate-knob"
  ></div>
</div>
<div id="draggable-border">
  <div
    *ngIf="showGreyBox | async"
    class="rotate-border"
    (dragStart)="onMoveStart()"
    (dragMove)="onMove($event)"
    (dragEnd)="onMoveEnd()"
    libDragHandle
  ></div>
</div>
