/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { GapiLoaderService } from '../../../common/gapi-loader.service';
import { UserService } from '../../user.service';

const log = Log.create('SignInComponent');

@Component({
  selector: 'lib-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  hasError = new BehaviorSubject<boolean>(false);

  constructor(
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private gapiLoader: GapiLoaderService
  ) {}

  ngOnInit() {
    if (environment.e2e) {
      this.e2eSignIn();
    } else {
      this.signIn();
    }
  }

  private async e2eSignIn(): Promise<void> {
    // wait for firebase cached user:
    await this.user.user
      .pipe(
        filter((v) => v !== undefined),
        first()
      )
      .toPromise();
    if (!this.user.isSignedIn) {
      const pass = window.prompt('e2e_signin');
      await this.user._signInFirebaseWithPassword(environment.e2e.email, pass);
    }
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    await this.router.navigateByUrl(returnUrl);
  }

  private async signIn(): Promise<void> {
    try {
      const token = !!environment.auth.signInUrl
        ? this.getAccessTokenFromCookie()
        : await this.getAccessTokenViaPopup();
      if (!token || !token.length)
        throw new Error('unable to authenticate without a token');
      await this.user.signInWithGoogleAccessToken(token);

      const returnUrl =
        this.route.snapshot.queryParamMap.get('returnUrl') || '/';
      await this.router.navigateByUrl(returnUrl);
    } catch (err) {
      log.error(err);
      this.hasError.next(true);
    }
  }

  private getAccessTokenFromCookie(): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; castifyAuthToken=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : null;
  }

  private async getAccessTokenViaPopup(): Promise<string | null> {
    await this.gapiLoader.loadAuth2();
    const authResp = await new Promise<gapi.auth2.AuthorizeResponse>(
      (resolve, reject) => {
        gapi.auth2.authorize(
          {
            client_id: environment.auth.clientId,
            scope: environment.auth.scopes.join(' '),
            prompt: 'select_account',
          },
          (resp) => {
            if (resp.error) {
              reject(resp.error);
            } else {
              resolve(resp);
            }
          }
        );
      }
    );
    return authResp.access_token;
  }

  switchAccount(): void {
    this.user.signOut();
  }
}
