/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { TextBoxEditBorderComponent } from './text-box-edit-border.component';
@Component({
  selector: 'lib-text-box-edit-rotate-border',
  templateUrl: './text-box-edit-rotate-border.component.html',
  styleUrls: ['./text-box-edit-border.component.scss'],
})
export class TextBoxEditRotateBorderComponent extends TextBoxEditBorderComponent {
  constructor(public readonly ctrl: AnnotationsToolControllerService) {
    super(ctrl);
  }

  showGreyBox = combineLatest([
    this.ctrl.grayBoxRotation,
    this._isSelected,
  ]).pipe(
    map(([rotation, isSelected]) => {
      if (rotation === null) return false;
      return !!isSelected;
    })
  );
}
