/*!
 * Copyright 2018 Screencastify LLC
 */
import { OnInit } from '@angular/core';
var BrowserGuardComponent = /** @class */ (function () {
    function BrowserGuardComponent() {
    }
    BrowserGuardComponent.prototype.ngOnInit = function () { };
    return BrowserGuardComponent;
}());
export { BrowserGuardComponent };
