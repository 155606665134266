/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  ILogLevelMapping,
  kLogLevelMapping,
  LogInfoDto,
} from '@castify/edit-models';
import { promiseTools } from '@castify/models';
import { retryBackoff } from 'backoff-rxjs';
import { LoggerAdapter } from 'lib-editor';
import * as inspect from 'object-inspect';
import { of, Subscription } from 'rxjs';
import { delay, filter, repeat, switchMap } from 'rxjs/operators';
import {
  IPostLogMetadata,
  IPostLogPayload,
  RemoteLoggerClientService,
} from './remote-logger-client.service';

export const LEVELS = <(keyof ILogLevelMapping)[]>Object.keys(kLogLevelMapping);

// NOTE: this is very short interval, so disable streaming via LoggerAdapter
// when appropriate (e.g. when there's no internet or when user isn't signed in)
const FLUSH_INTERVAL = 1000 * 5; // 5 seconds

// Max retry interval used in exponential backoff for retrying errors.
const kMaxRetryInterval = 120 * 1000;

export const STORAGE_KEY = 'remote_logs';

export type ILogInfo = LogInfoDto;

// leave this type def for now for smoother migration to mono repo
export type LogParams = any[];

/**
 * Capture logs by patching over console. Periodically send a batch of logs data
 * to the backend. Additional metadata can be included on install. The colored styling
 * are stripped from the logs since they break the backend. It can be enabled/disabled
 * by the app at any point via LoggerAdapter
 * Note: Only starts capturing after install, so initial logs are not captured.
 * It's not a problem because not useful logs anyways
 *
 * TODO: implement size limits per flush
 */
@Injectable({
  providedIn: 'root',
})
export class RemoteLoggerService {
  /**
   * Limit max number of log items stored based on number of items in logs.
   * Ideally this would be based on bytes/some more suitable data structure.
   * For now, just empty the buffer entirely once it exceeds the number of items
   * to prevent it from growing indefinitely (e.g. while offline)
   */
  // Exposed for unit-testing online.
  _maxLogsInMemory = 100000; // 100k items = ~10MB @ 100 bytes/item
  // private - made public for testing only
  public _logs: ILogInfo[] = [];

  // Chunk size, max lines per request. Needs to be <= what endpoint in
  // castify-stoarge allows. Exposed for easier unit testing only.
  _maxLinesPerRequest = 10000;
  // additional metadata set by the app.
  private _metadata: IPostLogMetadata;
  // Backup of original non-patched methods.
  private _origConsole: { [k in keyof Console]?: any } = {};
  private _subscription: Subscription;

  constructor(
    private _adapter: LoggerAdapter,
    private _remoteLoggerClient: RemoteLoggerClientService
  ) {}
  /**
   * Colorized logs messes with winston logger on the backend.
   * See https://stackoverflow.com/q/51135092/7965622
   * Colorized log looks like this:
   * [
   *   '%c ServifyPublisher:userAccount  %c publishing service ',
   *   'background: #196D7F;color:white; border: 1px solid #196D7F; ',
   *   'border: 1px solid gray; ',
   *   ...any // additional arguments
   * ]
   */
  static filterCssFromLog(params: LogParams): LogParams {
    let substitutionsCount = 0;
    const message = params.shift();
    if (typeof message === 'string') {
      const cleanMessage = message.replace(/%c /g, () => {
        substitutionsCount++;
        return '';
      });
      const cleanParams = params.slice(substitutionsCount);
      return [cleanMessage, ...cleanParams];
    }
    return [message, ...params];
  }
  /**
   * Post logs to server and remove posted logs from cache
   * made public for testing only
   */
  @promiseTools.serialize
  public async _flush(): Promise<void> {
    if (!this._logs.length) {
      return;
    }
    const logs = [...this._logs.slice(0, this._maxLinesPerRequest)];
    const payload = this._buildPayload(logs);
    // Note: _flush errors are handled in the rxjs pipe chain making sure they
    // don't recursively trigger logs in case of network issues.
    this._adapter.loggedIn
      ? await this._remoteLoggerClient.postAuthLogs(payload)
      : await this._remoteLoggerClient.postUnauthLogs(payload);
    // get latest logs to account for any that's been added while posting
    this._shiftLogs(logs.length);
  }

  /**
   * Call this on app boot-strapping, patches console.*() methods.
   */
  public install(metadata?: IPostLogMetadata) {
    this._metadata = metadata;
    this._patchConsole();
    this._installBatcher();
  }

  /**
   * Dispose / uninstall timers etc. Mainly intended for cleanup of during
   * testing.
   */
  public uninstall() {
    this._subscription.unsubscribe();
    // Restore console.
    for (const key of LEVELS) {
      console[key] = this._origConsole[key];
    }
  }

  /**
   * Cache logs as an array of logs
   * Do not use directly. only used to hook other loggers (e.g. ajs logger)
   */
  private _addLog(level: keyof ILogLevelMapping, params: LogParams) {
    params = RemoteLoggerService.filterCssFromLog(params);
    params = this._stringifyErrors(params);
    /**
     * Note: need to avoid holding references to arbitrary obejcts (potentially
     * indefinitely while offline), we stringify everything.
     * Holding references to original objects would prevent GC and could cause
     * memory leaks in some cases.
     */
    // Use slice to remove quotes.
    params = params.map((p) => inspect(p, { indent: 2 }).slice(1, -1));
    this._logs.push({
      level,
      params,
      timestamp: Date.now(),
    });
    // HACK: Flush entire buffer for now to avoid potential expotential memory consumption.
    if (this._logs.length > this._maxLogsInMemory) this._logs.length = 0;
  }

  /**
   * Builds payload with logs and necessary metadata
   */
  private _buildPayload(logs: ILogInfo[]): IPostLogPayload {
    return {
      logs,
      metadata: {
        userAgent: navigator.userAgent,
        ...this._metadata,
      },
    };
  }

  /**
   * Flushes logs every X time interval
   */
  private _installBatcher() {
    this._subscription = of(null)
      .pipe(
        filter(() => this._logs.length > 0 && navigator.onLine),
        switchMap(() => this._flush()),
        // Use expontential backoff to retry in case of errors,
        // See here for docs: https://www.npmjs.com/package/backoff-rxjs#retrybackoff
        retryBackoff({
          initialInterval: FLUSH_INTERVAL,
          maxInterval: kMaxRetryInterval,
        }),
        // Repeat chain after FLUSH_INTERVAL on success.
        delay(FLUSH_INTERVAL),
        repeat()
      )
      // Note, actual request is triggered in switchMap for easier retry handling etc.
      .subscribe(() => null);
  }

  /**
   * Patch console. Stores logs in local storage until flushed.
   */
  private _patchConsole() {
    for (const key of LEVELS) {
      const tempVar = console[key];
      this._origConsole[key] = tempVar;
      console[key] = (...params: LogParams) => {
        tempVar(...params);
        const newParams = this._stringifyErrors(params);
        this._addLog(key, newParams);
      };
    }
  }

  /**
   * Removes X number of logs from the beginning of cached logs.
   */
  private _shiftLogs(numberToRemove: number): void {
    const logs2 = this._logs;
    logs2.splice(0, numberToRemove);
  }

  /**
   * If the log contains an error, stringify the error with Object.getOwnPropertyNames
   * so its information can be read else it will be sent to remote as an empty object '{}'
   * The same with DomExceptions.
   */
  private _stringifyErrors(params: LogParams) {
    return params.map((p) => {
      if (p instanceof DOMException) {
        return `DOMException(${p.name}): ${p.message}`;
      }
      if (p instanceof Error) {
        return JSON.stringify(p, Object.getOwnPropertyNames(p));
      }
      return p;
    }) as LogParams;
  }
}
