/*!
 * Copyright 2018 Screencastify LLC
 */
import { EditorLogoComponent } from './components/editor-logo/editor-logo.component';
var exportDeclarations = [EditorLogoComponent];
var LibCommonModule = /** @class */ (function () {
    function LibCommonModule() {
    }
    return LibCommonModule;
}());
export { LibCommonModule };
