<lib-tool-sidebar-content>
  <div id="toolbar-header">
    <mat-icon>music_video</mat-icon>
    <h2>Audio</h2>
  </div>

  <div id="toolbar-content">
    <h4 id="field-title">Volume</h4>
    <div class="volume-slider-container">
      <!-- marks where 0dB gain is at -->
      <div
        class="marker-container"
        matTooltip="Original Volume"
        matTooltipClass="medium-tooltip"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
      >
        <div class="vertical-marker"></div>
      </div>
      <input
        type="range"
        class="slider"
        [min]="MIN_GAIN"
        [max]="MAX_GAIN"
        step="0.1"
        [ngStyle]="sliderGradient | async"
        [value]="ctrl.gain | async"
        (input)="setGain($event.target.value)"
      />
      <div class="labels">
        <mat-icon>volume_off</mat-icon>
        <mat-icon>volume_up</mat-icon>
      </div>
    </div>
  </div>

  <button id="done-button" mat-stroked-button (click)="closeEditor()">
    Done
  </button>
</lib-tool-sidebar-content>
