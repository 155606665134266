/*!
 * Copyright 2018 Screencastify LLC
 */

import { configureFirebase } from '@castify/edit-models';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';

let app;

function initFirebase() {
  return firebase.initializeApp(environment.firebaseConfig);
}

function getApp() {
  if (!app) app = initFirebase();
  return app;
}

configureFirebase(() => [firebase, getApp()]);
