/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'edit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(angulartics: Angulartics2GoogleAnalytics) {
    angulartics.settings.anonymizeIp = true;
    angulartics.startTracking();
  }
}
