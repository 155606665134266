/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, ElementRef } from '@angular/core';
import { StillClip } from '@castify/edit-models';
import { sty } from '@castify/models';
import { ClipViewComponent } from '../clip-view/clip-view.component';

@Component({
  selector: 'lib-still-clip-view',
  templateUrl: './still-clip-view.component.html',
  styleUrls: ['./still-clip-view.component.scss'],
})
export class StillClipViewComponent extends ClipViewComponent<StillClip> {
  protected _playStartTime: sty.Timestamp = Date.now();
  protected _seekPos: sty.Milliseconds = 0;

  get currentTime(): sty.Milliseconds {
    return (
      this._seekPos + (this.paused.value ? 0 : Date.now() - this._playStartTime)
    );
  }

  get startInScene(): sty.Milliseconds {
    return this._clip.startInScene;
  }

  constructor(protected hostElm: ElementRef<HTMLElement>) {
    super();
    this.canPlay.next(true);
  }

  play(): void {
    this._playStartTime = Date.now();
    this.paused.next(false);
    return;
  }

  pause(): void {
    this.seek(this.currentTime);
    this.paused.next(true);
    return;
  }

  seek(pos: sty.Milliseconds): void {
    this._seekPos = pos;
    this._playStartTime = Date.now();
    return;
  }

  sync(refPos: sty.Milliseconds | null): sty.Milliseconds {
    if (Number.isFinite(refPos)) {
      // clip is not the sync master, update current position to match sync reference
      this._seekPos = refPos;
      this._playStartTime = Date.now();
    }
    return 0;
  }

  onDisplay(): void {
    if (!this.hostElm || !this._clip) return;
    this.hostElm.nativeElement.style.backgroundColor = `#${this._clip.backgroundColor}`;
  }
}
