/*!
 * Copyright 2020 Screencastify LLC
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-drive-import-progress-modal',
  templateUrl: './drive-import-progress-modal.component.html',
  styleUrls: ['./drive-import-progress-modal.component.scss'],
})
export class DriveImportProgressModalComponent implements OnInit {
  public _progress = new BehaviorSubject<number>(null);
  @Input() set progress(p: number) {
    this._progress.next(p);
  }

  public _importCount = new BehaviorSubject<number>(null);
  @Input() set importCount(p: number) {
    this._importCount.next(p);
  }

  public _complete = new BehaviorSubject<number>(null);
  @Input() set complete(p: number) {
    this._complete.next(p);
  }

  constructor() {}

  @Output() closeEvent = new EventEmitter<void>();

  ngOnInit() {}

  /**
   * Emits an event to the parent which indicates the user has
   * requested the modal be closed
   */
  public onCloseClick(): void {
    this.closeEvent.emit();
  }
}
