/*!
 * Copyright 2018 Screencastify LLC
 */
import { AudioClipViewComponent } from './components/audio-clip-view/audio-clip-view.component';
import { StillClipViewComponent } from './components/still-clip-view/still-clip-view.component';
import { VideoClipViewComponent } from './components/video-clip-view/video-clip-view.component';
var kEntryComponents = [
    VideoClipViewComponent,
    AudioClipViewComponent,
    StillClipViewComponent,
];
var LibPreviewModule = /** @class */ (function () {
    function LibPreviewModule() {
    }
    return LibPreviewModule;
}());
export { LibPreviewModule };
