/*!
 * Copyright 2020 Screencastify LLC
 */
import { Routes } from '@angular/router';
import { AuthGuard, SignInComponent } from 'lib-editor';
import { BrowserGuard } from './browser-guard/browser-guard';
var routes = [
    {
        path: 'signin',
        component: SignInComponent,
    },
    {
        path: 'edit',
        canActivate: [BrowserGuard, AuthGuard],
        loadChildren: './edit/edit.module#EditModule',
    },
    {
        path: 'open',
        canActivate: [BrowserGuard, AuthGuard],
        loadChildren: './open/open.module#OpenModule',
    },
    {
        path: '**',
        redirectTo: '/edit',
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
