/*!
 * Copyright 2020 Screencastify LLC
 */

import { TextBox, VerticalAlignment } from '@castify/edit-models';
import { nanoid } from 'nanoid';
export const kAnnotationsClipDefaultDuration = 4000;

/**
 * This factory exists to return a new object every time
 * this new text box constant is used. This protects against
 * pass-by-refernence bugs.
 */
export function kNewTextBoxDefaultFactory(): TextBox {
  return {
    id: nanoid(),
    top: 0.4,
    left: 0.4,
    width: 0.2,
    height: 0.2,
    verticalAlignment: VerticalAlignment.top,
    backgroundColor: 'none',
    rotation: 0,
    borderColor: 'none',
    delta: [],
    touched: false,
  };
}
