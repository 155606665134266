/*!
 * Copyright 2019 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HttpClient } from '@angular/common/http';
import { ElementRef, OnDestroy, OnInit, Renderer2, } from '@angular/core';
import { environment } from 'environments/environment';
import { Log } from 'ng2-logger/browser';
import { AsyncSubject, BehaviorSubject, combineLatest, Observable, Subject, Subscription, } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, retry, switchMap, } from 'rxjs/operators';
import { SeekDataCollectorService } from '../../seek-data-collector.service';
import { StreamerClientService } from '../../streamer-client.service';
import { PIDController } from '../../util/pid-controller';
import { ClipViewComponent } from '../clip-view/clip-view.component';
import { ConstantDurationSegmentHelper } from '../constant-duration-segment-helper';
// TODO: get that value from the api
var kSegmentDuration = 1000;
// determine mime string: https://gist.github.com/jimkang/f23ce12c359c7465e83f
// const mimeString = 'video/mp4; codecs="avc1.F4001E"'; // for local test videos
var kVideoMimeString = 'video/mp4; codecs="avc1.64000D"'; // TODO: move to environment
var VideoClipViewComponent = /** @class */ (function (_super) {
    __extends(VideoClipViewComponent, _super);
    function VideoClipViewComponent(_seekDataCollector, renderer, streamerClient, http) {
        var _this = _super.call(this) || this;
        _this._seekDataCollector = _seekDataCollector;
        _this.renderer = renderer;
        _this.streamerClient = streamerClient;
        _this.http = http;
        _this._videoSelector = true; // stores which of the two video elements is currently displayed
        _this._log = Log.create('VideoClipViewComponent');
        // every new item will cause switch to specified segment and seek to that position in the segment.
        // also this._displaySegment will get updates as soon as the new segment is ready for playback
        _this._seekTo = new Subject();
        _this._seekBusy = new BehaviorSubject(false);
        _this._displaySegment = new BehaviorSubject(null);
        _this._shouldPlay = false;
        _this._hasBeenWarmedUp = false;
        // buffer for segments that are relevant for current playback indexed by segment id
        // NOTE: manage active segments through _getSegment and _discardSegmentsNotInRange
        _this._bufferedSegments = {};
        _this.subscriptions = new Subscription();
        _this.segHelper = new ConstantDurationSegmentHelper(environment.video.segmentDuration);
        // use scripts in _dev/tune_pid/ to determine pid parameters
        _this._syncPID = new PIDController(6 / 1000, 0.5 / 1000, 0.2 / 1000).set(0);
        return _this;
    }
    VideoClipViewComponent.prototype._getVideoElm = function (videoSelector) {
        return videoSelector
            ? this._videoT.nativeElement
            : this._videoF.nativeElement;
    };
    Object.defineProperty(VideoClipViewComponent.prototype, "currentTime", {
        get: function () {
            var currentSeg = this._displaySegment.value;
            if (!currentSeg)
                return 0;
            var timeInSegment = this._getVideoElm(this._videoSelector).currentTime * 1000;
            var res = Math.min(this._clip.duration, currentSeg.id * environment.video.segmentDuration +
                timeInSegment -
                this._clip.startInFile);
            return res;
        },
        enumerable: true,
        configurable: true
    });
    VideoClipViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        // generate canPlay observable for playback case
        // NOTE: when playback is running seek is either performed from the segment
        //       before or displaySegment is updated to be the same as the seeking segment. In those cases we want
        //       to wait with propagating state as seeking to another segment will cause the playback to change state to false.
        //       During playback the state should only be set to false in the worst case when we cannot switch segments
        var playbackCanPlay = combineLatest([
            this._seekTo,
            this._displaySegment,
        ]).pipe(map(function (_a) {
            var _b = __read(_a, 2), seekSeg = _b[0], displaySeg = _b[1];
            return [seekSeg.newSeg, displaySeg];
        }), filter(function (_a) {
            var _b = __read(_a, 2), newSeg = _b[0], displaySeg = _b[1];
            return !!displaySeg &&
                (displaySeg.id + 1 === newSeg.id || displaySeg.id === newSeg.id);
        }), map(function (_a) {
            var _b = __read(_a, 2), newSeg = _b[0], displaySeg = _b[1];
            return displaySeg.id === newSeg.id;
        }), distinctUntilChanged(), debounceTime(50), // debounce to not have a glitch on every segment change
        distinctUntilChanged());
        // generate canPlay for general case without playback
        // NOTE: when we seek from any other segment than the previous we want to propagate the state as fast as possible
        //       If state propagation is stalled and we switch clips during a seek we can briefly see the previously loaded
        //       position due to the lag in state propagation. If we seek in the same clip the state could propagate lazily
        //       OR instantaneously since we will see the previously loaded segment either way. Here we propagate as fast as
        //       possible in both cases
        var generalCanPlay = combineLatest([
            this._seekTo,
            this._displaySegment,
        ]).pipe(map(function (_a) {
            var _b = __read(_a, 2), seekSeg = _b[0], displaySeg = _b[1];
            return [seekSeg.newSeg, displaySeg];
        }), filter(function (_a) {
            var _b = __read(_a, 2), newSeg = _b[0], displaySeg = _b[1];
            return !displaySeg || displaySeg.id + 1 !== newSeg.id;
        }), map(function (_a) {
            var _b = __read(_a, 2), newSeg = _b[0], displaySeg = _b[1];
            return !!displaySeg && displaySeg.id === newSeg.id;
        }), distinctUntilChanged());
        // combine can plays only when both are true the state will be playable
        this.subscriptions.add(combineLatest([playbackCanPlay, generalCanPlay])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), playback = _b[0], general = _b[1];
            return playback && general;
        }))
            .subscribe(this.canPlay));
        // dispatch calls to _switchVideoSeg
        this.subscriptions.add(this._seekTo
            .pipe(
        // on emit: aborts any seek that might still be in progress and starts emitted seek
        switchMap(function (args) {
            return _this._switchVideoSeg(args.newSeg, args.seekPosInSegment, args.preloadSeg);
        }))
            .subscribe());
        // start playback when requested after seek completes
        this.subscriptions.add(this.paused
            .pipe(distinctUntilChanged(), filter(function (v) { return !v; }), // filter paused = false
        switchMap(function () {
            return _this._seekBusy.pipe(filter(function (v) { return !v; }), // filter busy = false
            first());
        }))
            .subscribe(function () { return _this._play(_this.currentTime); }));
    };
    VideoClipViewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(VideoClipViewComponent.prototype, "startInScene", {
        get: function () {
            return this._clip.startInScene;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoClipViewComponent.prototype, "syncPriority", {
        get: function () {
            return this._clip.syncPriority;
        },
        enumerable: true,
        configurable: true
    });
    VideoClipViewComponent.prototype.play = function () {
        if (this.paused.value)
            this.paused.next(false);
    };
    /**
     * play from position (clip time), this method schedules a recursive call to play the next segment
     */
    VideoClipViewComponent.prototype._play = function (pos) {
        var _this = this;
        // check if end is reached
        if (pos >= this._clip.duration) {
            // stop playback
            this.pause();
            return;
        }
        // remove old segment end handler
        this._segmentOnPauseHandler = undefined;
        var segIdx = this.segHelper.segIdxAtPos(this._clip, pos);
        var nextSegStartTime = this.segHelper.segStartPos(this._clip, segIdx + 1);
        // use reliable ended event listener to elm:
        //   ended event does not fire when file is not played to (the original files) end.
        //   So ended does not fire when an end time is set on a clip
        this._segmentOnPauseHandler = function () {
            if (!_this.paused.value) {
                _this._play(nextSegStartTime);
            }
        };
        // signal segments that they should play
        this._shouldPlay = true;
        this._seekTo.next({
            newSeg: this._bufferSegment(segIdx),
            seekPosInSegment: this.segHelper.segSeekTimeFromClipPos(this._clip, segIdx, pos),
            preloadSeg: function () { return _this._bufferSegment(segIdx + 1); },
        });
    };
    VideoClipViewComponent.prototype.pause = function () {
        this._segmentOnPauseHandler = undefined;
        this._shouldPlay = false;
        this._getVideoElm(this._videoSelector).pause();
        // update paused value
        if (!this.paused.value)
            this.paused.next(true);
    };
    VideoClipViewComponent.prototype.seek = function (pos) {
        if (this.paused.value) {
            // flush streamer queue to get next segment as fast as possible
            this.streamerClient.flushVideoQueue(this._clip.userFileId);
            // get segment at pos
            var segIdx = this.segHelper.segIdxAtPos(this._clip, pos);
            var seg = this._bufferSegment(segIdx);
            this._unbufferSegmentsNotInRange(segIdx - 1, segIdx + 1);
            // seg is null when seeking outside of clip range
            if (!seg) {
                this._log.error('seeking out of clip range');
                return;
            }
            // switch to segment
            this._seekTo.next({
                newSeg: seg,
                seekPosInSegment: this.segHelper.segSeekTimeFromClipPos(this._clip, segIdx, pos),
                preloadSeg: function () { return null; },
            });
        }
        else {
            this._log.error('Cannot seek during playback');
            return;
        }
    };
    /**
     * sync playback to other view. This should update internal playbackRate to
     * approach refPos (assuming refPos plays at constant rate).
     *
     * @param refPos  value of null indicates that clip is syncRef and should approach playbackRate=1
     * @returns current error to actual playheadPos (currentTime - refPos)
     */
    VideoClipViewComponent.prototype.sync = function (refPos) {
        // refPos=null indicates this view is sync master. Offset is 0 is this case
        var syncOffset = refPos === null ? 0 : this.currentTime - refPos;
        // adapt playback rate
        var pidOutput = this._syncPID.update(syncOffset);
        // limit to interval (0.5, 1,5)
        var playbackRate = 1 + Math.min(0.5, Math.max(-0.5, pidOutput));
        this._videoT.nativeElement.playbackRate = playbackRate;
        this._videoF.nativeElement.playbackRate = playbackRate;
        return syncOffset;
    };
    /**
     * Buffer segments within time interval [tStart, tStart+duration]
     */
    VideoClipViewComponent.prototype.buffer = function (tStart, duration) {
        // buffer segments
        for (var segIdx = this.segHelper.segIdxAtPos(this._clip, tStart); segIdx <= this.segHelper.segIdxAtPos(this._clip, tStart + duration); segIdx++) {
            this._bufferSegment(segIdx);
        }
        // free memory
        this._unbufferSegmentsNotInRange(Math.max(0, this.segHelper.segIdxAtPos(this._clip, tStart - 1 * environment.video.segmentDuration)), this.segHelper.segIdxAtPos(this._clip, tStart + duration + 2 * environment.video.segmentDuration));
        if (!this._hasBeenWarmedUp) {
            // get earliest instance of bufferedSegment
            var earliestSeg = Object.keys(this._bufferedSegments)
                .map(function (segIdx) { return Number(segIdx); })
                .reduce(function (minSegIdx, currSegIdx) { return Math.min(minSegIdx, currSegIdx); }, Infinity);
            // seek to start of earliest segment
            // NOTE: we seek to the earliest buffered segment. We have to seek to a segment that was loaded from preview
            //       since we would crash playback otherwise. This ensures that the segment we seek to is (1) loaded and
            //       (2) the earliest in queue. If this happens to be segment 0 we have a lazy init, otherwise preview will
            //       handle the seeking.
            this.seek((earliestSeg === Infinity
                ? 0
                : earliestSeg -
                    Math.floor(this._clip.startInFile / kSegmentDuration)) *
                kSegmentDuration);
            this._hasBeenWarmedUp = true;
        }
    };
    VideoClipViewComponent.prototype._bufferSegment = function (segIdx) {
        // return null if segment is not in the clip
        if (segIdx < this.segHelper.segIdxAtPos(this._clip, 0) ||
            segIdx > this.segHelper.segIdxAtPos(this._clip, this._clip.duration - 1))
            return null;
        // if segment is in buffer: just return the instance
        if (this._bufferedSegments[segIdx])
            return this._bufferedSegments[segIdx];
        // We want to monitor events when the users scrubs or seeks. This only happens when the video is paused.
        // Even with the video is playing, to seek, the video pauses for an instant and then seeks.
        if (this.paused.value) {
            this._seekDataCollector.count();
        }
        // create new segment
        var segment = this._buildNewSegment(segIdx, this.streamerClient.fetchVideo(this._clip, segIdx));
        // add to buffer
        this._bufferedSegments[segment.id] = segment;
        return segment;
    };
    VideoClipViewComponent.prototype._buildNewSegment = function (segIdx, segmentRequest) {
        var _this = this;
        // calculate timing
        var segStartInFile = segIdx * environment.video.segmentDuration; // file time at which the segment starts
        var startInSegment = Math.max(0, this._clip.startInFile - segStartInFile);
        var endInSegment = Math.min(environment.video.segmentDuration, this._clip.endInFile - segStartInFile);
        // build segment
        var buffer = new AsyncSubject();
        var seg = {
            id: segIdx,
            buffer: buffer,
            bufferSubscription: this.subscriptions.add(segmentRequest
                .pipe(catchError(function (err) {
                if (err && err.status === 404) {
                    // load black segment instead
                    return _this.http
                        .get('/assets/black.mp4', { responseType: 'arraybuffer' })
                        .pipe(retry(1));
                }
                throw err;
            }))
                .subscribe(buffer)),
        };
        if (!!startInSegment)
            seg.startInSegment = startInSegment;
        if (endInSegment !== environment.video.segmentDuration)
            seg.endInSegment = endInSegment;
        return seg;
    };
    /**
     * remove segments that are not relevant for playback
     */
    VideoClipViewComponent.prototype._unbufferSegmentsNotInRange = function (startSegIdx, endSegIdx) {
        var _this = this;
        Object.entries(this._bufferedSegments)
            .map(function (_a) {
            var _b = __read(_a, 2), segIdx = _b[0], seg = _b[1];
            return [Number(segIdx), seg];
        })
            .filter(function (_a) {
            var _b = __read(_a, 2), segIdx = _b[0], _ = _b[1];
            return segIdx < startSegIdx || segIdx > endSegIdx;
        })
            .forEach(function (_a) {
            var _b = __read(_a, 2), segIdx = _b[0], seg = _b[1];
            // cancel pending requests
            seg.bufferSubscription.unsubscribe();
            delete _this._bufferedSegments[segIdx];
        });
    };
    ////////////////////////////////////
    // manage current segment
    VideoClipViewComponent.prototype._getMediaSourceObjectUrl = function (seg) {
        var srcObj = new MediaSource();
        var srcUrl = URL.createObjectURL(srcObj);
        function addBufferedSegmentData() {
            URL.revokeObjectURL(srcUrl);
            // hack: somehow this sometimes gets called without the MediaSource being actually open
            if (srcObj.readyState !== 'open')
                return;
            var srcBuffer = srcObj.addSourceBuffer(kVideoMimeString);
            srcObj.duration = environment.video.segmentDuration / 1000;
            // signal end of stream as soon as buffer has finished updating
            srcBuffer.addEventListener('updateend', function () {
                if (srcObj.readyState === 'open')
                    srcObj.endOfStream();
            }, { once: true });
            // push data to the buffer
            seg.buffer.pipe(filter(function (v) { return !!v; })).subscribe(function (data) {
                // MediaSource might have been removed from video element in the meantime: only append if source is still open
                if (srcObj.readyState === 'open')
                    srcBuffer.appendBuffer(data);
            });
        }
        srcObj.addEventListener('sourceopen', function () { return addBufferedSegmentData(); }, {
            once: true,
        });
        return srcUrl;
    };
    VideoClipViewComponent.prototype._switchVideoSeg = function (newSeg, seekPosInSegment, preloadSeg) {
        var _this = this;
        return new Observable(function (observer) {
            _this._seekBusy.next(true);
            var currVid = _this._getVideoElm(_this._videoSelector);
            // changes the video elements source to point to seg (initiates load)
            // returns true if source had to be changed at all, otherwise false
            var attachSource = (function (elm, seg) {
                // change source only if not already set
                if (seg && seg.id === elm['segIdx'])
                    return false;
                elm.src = seg ? _this._getMediaSourceObjectUrl(seg) : '';
                elm.load();
                elm['segIdx'] = seg ? seg.id : undefined; // save segment id that is currently loaded by the video elements
                return true;
            }).bind(_this);
            var hideOldVideo = (function () {
                currVid.pause();
                currVid.classList.add('hidden');
                // preload segment
                attachSource(currVid, preloadSeg());
                _this._seekBusy.next(false);
                observer.complete();
            }).bind(_this);
            var switchToNewVideo = (function () {
                // switch onPauseListener
                currVid.onpause = undefined;
                nextVid.onpause = function () {
                    if (_this._segmentOnPauseHandler)
                        _this._segmentOnPauseHandler();
                };
                // show new video
                if (_this._shouldPlay)
                    // TODO: returns a promise. Catch this here
                    nextVid.play();
                nextVid.classList.remove('hidden');
                // schedule removal of old video
                setTimeout(hideOldVideo.bind(_this), 1);
                // toggle video elements
                _this._videoSelector = !_this._videoSelector;
                _this._displaySegment.next(newSeg);
            }).bind(_this);
            var nextVid = _this._getVideoElm(!_this._videoSelector);
            // switch segment only if newSegment is not loaded on currVid
            if (newSeg.id === currVid['segIdx']) {
                // don't switch, only adjust time:
                currVid.currentTime = seekPosInSegment / 1000;
                if (_this._shouldPlay)
                    // TODO: returns a promise. Catch this here
                    currVid.play();
                // preload segment
                attachSource(nextVid, preloadSeg());
                _this._seekBusy.next(false);
                observer.complete();
            }
            else {
                // schedule switch to nextVid:
                var sourceChanged = attachSource(nextVid, newSeg);
                var seekChangedPosition = nextVid.currentTime !== seekPosInSegment / 1000;
                // seek to correct position
                nextVid.currentTime = seekPosInSegment / 1000;
                // optimize timing: seek and wait for canplay event only when needed (video is usually preloaded)
                if (!sourceChanged &&
                    nextVid.readyState >= nextVid.HAVE_FUTURE_DATA && // ready to play
                    !seekChangedPosition) {
                    // not actually seeked to new position
                    switchToNewVideo();
                }
                else {
                    // canplay fires after every seek (when currentTime gets updated even if the value does not change)
                    //    because we always seek, this is ensured to fire
                    nextVid.addEventListener('canplay', switchToNewVideo, { once: true });
                }
            }
            function abort() {
                nextVid.removeEventListener('canplay', switchToNewVideo);
            }
            return abort.bind(_this);
        });
    };
    return VideoClipViewComponent;
}(ClipViewComponent));
export { VideoClipViewComponent };
