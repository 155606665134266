/*!
 * Copyright 2020 Screencastify LLC
 */

import 'zone.js/dist/zone-error'; // for easier debugging
import { environment as staging } from './environment.stag';

export const environment = {
  ...staging,
  watchURL: 'https://watch.dev1.screencastify.com/v/',
  auth: {
    ...staging.auth,
    signInUrl: null,
  },
  // copy from 'web config' in firebase console / authentication tab
  firebaseConfig: {
    apiKey: 'AIzaSyAYAgCFoNbWa-bhEjDI2lb5cWyeD4-t4sY',
    authDomain: 'castify-edit-dev1.firebaseapp.com',
    databaseURL: 'https://castify-edit-dev1.firebaseio.com',
    projectId: 'castify-edit-dev1',
    storageBucket: 'castify-edit-dev1.appspot.com',
    messagingSenderId: '1058302864917',
  },
};
