import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UiApiService } from '../common/ui-api.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "../common/ui-api.service";
var LicenseService = /** @class */ (function () {
    function LicenseService(user, uiApi) {
        var _this = this;
        this.user = user;
        this.uiApi = uiApi;
        this.license = new BehaviorSubject(null);
        // set license and credentials to null when user logs out.
        this.user.user
            .pipe(switchMap(function (user) { return (user ? _this.uiApi.authSyncLicense() : of(null)); }))
            .subscribe(function (v) { return _this.license.next(v); });
    }
    Object.defineProperty(LicenseService.prototype, "currentLicense", {
        /**
         * current license of user or null if no user signed in or license not yet fetched
         */
        get: function () {
            return this.license.value;
        },
        enumerable: true,
        configurable: true
    });
    LicenseService.ngInjectableDef = i0.defineInjectable({ factory: function LicenseService_Factory() { return new LicenseService(i0.inject(i1.UserService), i0.inject(i2.UiApiService)); }, token: LicenseService, providedIn: "root" });
    return LicenseService;
}());
export { LicenseService };
