<lib-tool-sidebar-content>
  <div id="toolbar-header">
    <img id="title-clips-icon" src="assets/title-clips-icon-white.svg" />
    <h2>Title</h2>
  </div>

  <div id="toolbar-content">
    <h4 id="field-title">Background</h4>
    <div id="swatch-container">
      <button
        *ngFor="let color of colorChoices"
        class="swatch"
        [ngClass]="{
          selected: (ctrl.backgroundColor | async) === color,
          white: color === 'ffffff'
        }"
        [style.background-color]="'#' + color"
        type="button"
        (click)="ctrl.setBackgroundColor(color)"
      ></button>
    </div>
  </div>

  <button id="done-button" mat-stroked-button (click)="closeEditor()">
    Done
  </button>
</lib-tool-sidebar-content>
