<lib-tool-sidebar-content (click)="preventDeselection($event)">
  <div id="toolbar-header">
    <mat-icon>title</mat-icon>
    <h2>Text</h2>
  </div>
  <div id="scroll-container">
    <div id="toolbar-content">
      <lib-annotations-sidebar-buttons></lib-annotations-sidebar-buttons>
      <lib-annotations-sidebar-font></lib-annotations-sidebar-font>
      <lib-annotations-sidebar-colors></lib-annotations-sidebar-colors>

      <div id="zPositionButtons">
        <button
          mat-button
          type="button"
          (click)="onMoveToFrontClick()"
          class="zPositionButton"
          [ngClass]="{grayed: (isBoxFront | async)}"
          >
          <div class="zButtonContents">
            <mat-icon
              svgIcon="move-up"
              aria-label="move to front"
              id="move-up-icon"
            ></mat-icon>
            <p>Move to <br/>Front</p>
          </div>
        </button>
        <button
          mat-button
          type="button"
          (click)="onMoveToBackClick()"
          class="zPositionButton"
          [ngClass]="{grayed: (isBoxBack | async)}"
          >
          <div class="zButtonContents">
            <mat-icon
              svgIcon="move-up"
              aria-label="move to back"
              id="move-down-icon"
            ></mat-icon>
            <p>Move to <br/>Back</p>
          </div>
        </button>
      </div>

    </div>
  </div>

  <div id="footer-buttons">
    <button mat-button id="new-text-button" (click)="onAddClick()" [disabled]="!!(ctrl.clip | async) && (ctrl.clip | async).boxes.length >= kMaxTextBoxes">
      + New Text Box
    </button>
    <button id="done-button" mat-stroked-button (click)="closeToolbar()">
      Done
    </button>
  </div>
</lib-tool-sidebar-content>
