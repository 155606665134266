/*!
 * Copyright 2018 Screencastify LLC
 */

import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Self,
  ViewContainerRef,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
} from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { keyboardShortcuts } from '../keyboard-shortcuts';
import { KeyboardService } from '../keyboard.service';

/**
 * Directive to add a keyboard shortcut and a tooltip to a button.
 * Triggers existing click event when keyboard event is fired.
 */
@Directive({
  selector: '[libShortcut]',
  providers: [KeyboardService],
})
export class KeysDirective implements OnInit, OnDestroy {
  @Input() libShortcut: string;
  @Output() click = new EventEmitter<KeyboardEvent>();
  log = Log.create('KeysDirective');
  @HostBinding('attr.matTooltip') MatTooltip: MatTooltip;

  constructor(
    @Self() private _keyboardService: KeyboardService,
    _overlay: Overlay,
    _elementRef: ElementRef,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _aria: AriaDescriber,
    _focusMonitor: FocusMonitor,
    _platform: Platform,
    _dir: Directionality,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scroll
  ) {
    // TODO: get rid of this hack once angular2 supports adding directives dynamically natively
    this.MatTooltip = new MatTooltip(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _aria,
      _focusMonitor,
      scroll,
      _dir,
      MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY()
    );
  }

  ngOnInit() {
    const shortcut = keyboardShortcuts[this.libShortcut];
    if (!shortcut) {
      this.log.error('unknown shortcut type: ', this.libShortcut);
      return;
    }
    this._keyboardService.add(shortcut, (ev) => {
      this.click.emit(ev);
    });
    this.MatTooltip.message = shortcut.descWithCombo();
    this.MatTooltip.tooltipClass = ['big-tooltip'];
  }

  ngOnDestroy() {
    this.MatTooltip.ngOnDestroy();
  }
}
