<div #bounds id="bounds" (mousedown)="preventBackgroundClicks($event)">
  <div
    #container
    id="container"
    [ngStyle]="{
        width: (scaledWidth | async) + 'px',
        height: (scaledHeight | async) + 'px'
    }"
  >
    <div
      *ngFor="
        let textBox of _textBoxes | async;
        index as idx;
        trackBy: trackByIndex
      "
      [ngStyle]="{
        top: textBox.top * 100 + '%',
        left: textBox.left * 100 + '%',
        width: textBox.width * 100 + '%',
        height: textBox.height * 100 + '%',
        transform: 'rotate(' + textBox.rotation + 'deg)',
        transformOrigin: 'center center',
        zIndex: 2000 + idx * 10
      }"
      class="annotations-box-positioning"
    >
      <lib-text-box-edit-border [parentDims]="parentDims | async" [boxId]="textBox.id">
        <div
          [ngStyle]="{
            width: 100 * (inverseScaleFactor | async) + '%',
            height: 100 * (inverseScaleFactor | async) + '%',
            transform: 'scale(' + (scaleFactor | async) + ')',
            transformOrigin: 'top left',
            background: textBox.backgroundColor,
            border: '6px solid ' + (textBox.borderColor === 'none' ? 'transparent' : textBox.borderColor),
            boxSizing: 'border-box'
          }"
        >
          <lib-quill-view *ngIf="(ctrl.selectedBoxId | async) !== textBox.id" [textBox]="textBox"></lib-quill-view>
          <lib-quill-edit *ngIf="(ctrl.selectedBoxId | async) === textBox.id" [textBox]="textBox"></lib-quill-edit>
          <lib-text-placeholder [textBox]="textBox"></lib-text-placeholder>
        </div>
      </lib-text-box-edit-border>
    </div>
  </div>
  <div
    *ngFor="
      let textBox of _textBoxes | async;
      index as idx;
      trackBy: trackByIndex
    "
    [ngStyle]="{
      top: textBox.top * 100 + '%',
      left: textBox.left * 100 + '%',
      width: textBox.width * 100 + '%',
      height: textBox.height * 100 + '%',
      transform: 'rotate(' + (grayBorderRotation | async) + 'deg)',
      transformOrigin: 'center center',
      zIndex: 2000 + idx * 10,
      pointerEvents: 'none'
    }"
    class="annotations-box-positioning"
  >
    <lib-text-box-edit-rotate-border [parentDims]="parentDims | async" [boxId]="textBox.id">
      <div></div>
    </lib-text-box-edit-rotate-border>
  </div>
</div>
