/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { VerticalAlignment } from '@castify/edit-models';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { AnnotationsPreviewViewComponent } from './annotations-preview-view.component';
var keepOpenSelectors = [
    '.cdk-overlay-backdrop',
    'lib-annotations-preview-edit',
    'lib-annotations-sidebar',
    '.mat-menu-content',
    '#remove-button',
];
var AnnotationsPreviewEditComponent = /** @class */ (function (_super) {
    __extends(AnnotationsPreviewEditComponent, _super);
    function AnnotationsPreviewEditComponent(ctrl) {
        var _this = _super.call(this, ctrl) || this;
        _this.ctrl = ctrl;
        _this.subscriptions = new Subscription();
        // pass constants into template
        _this.verticalAlignment = VerticalAlignment;
        _this.grayBorderRotation = combineLatest(_this.ctrl.activeBox, _this.ctrl.grayBoxRotation).pipe(map(function (_a) {
            var _b = __read(_a, 2), activeBox = _b[0], grayBoxRotation = _b[1];
            return grayBoxRotation === null ? activeBox.rotation : grayBoxRotation;
        }));
        return _this;
    }
    AnnotationsPreviewEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get clip updates from the controller into this component.
        this.subscriptions.add(this.ctrl.clip.subscribe(function (clip) { return (_this.clip = clip); }));
    };
    AnnotationsPreviewEditComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    AnnotationsPreviewEditComponent.prototype.onEditorClick = function (event) {
        var editorElements = keepOpenSelectors
            .map(function (selector) { return document.querySelectorAll(selector); })
            .map(function (list) { return Array.from(list); })
            .reduce(function (acc, el) { return acc.concat(el); }, []);
        var editorClicked = event
            .composedPath()
            .some(function (path) { return editorElements.includes(path); });
        if (!editorClicked) {
            // save edit and close editor
            this.ctrl.save();
            this.ctrl.close();
        }
    };
    /**
     * Identity function to help the Angular maintain
     * referential equivalence for components accross re-renders
     */
    AnnotationsPreviewEditComponent.prototype.trackByIndex = function (_, item) {
        return item.id;
    };
    /**
     * Background clicks when editor is open do not defocus quill. This makes
     * sure that misclicks do not cause user to lose text selection state
     * or cursor position
     */
    AnnotationsPreviewEditComponent.prototype.preventBackgroundClicks = function (event) {
        event.preventDefault();
    };
    return AnnotationsPreviewEditComponent;
}(AnnotationsPreviewViewComponent));
export { AnnotationsPreviewEditComponent };
