/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AudioClip } from '@castify/edit-models';
import { sty } from '@castify/models';
import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  EMPTY,
  empty as observableEmpty,
  fromEvent as observableFromEvent,
  merge as observableMerge,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { StreamerClientService } from '../../streamer-client.service';
import { ClipViewComponent } from '../clip-view/clip-view.component';
import { ConstantDurationSegmentHelper } from '../constant-duration-segment-helper';

const kSegmentDuration: sty.Milliseconds = 10000;
const kAudioMimeString = 'audio/webm; codecs="opus"'; // TODO: move to environment

@Component({
  selector: 'lib-audio-clip-view',
  templateUrl: './audio-clip-view.component.html',
  styleUrls: ['./audio-clip-view.component.scss'],
})
export class AudioClipViewComponent
  extends ClipViewComponent
  implements OnInit, OnDestroy {
  @ViewChild('audio') private _audioElm: ElementRef<HTMLAudioElement>;
  protected _srcObject = new MediaSource();
  protected _srcBuffer: SourceBuffer;
  protected _srcHeaderLoaded = new AsyncSubject<null>();
  protected _loadHeader = new Subject<void>();
  protected _appendBuffer = new Subject<ArrayBuffer>();
  protected _bufferedRanges = new BehaviorSubject<
    { start: number; end: number }[]
  >([]);
  private _fetchSubscriptions: Subscription[] = [];
  private _seekTo = new Subject<sty.Milliseconds>();
  private _audioElmBusy = new BehaviorSubject<boolean>(false);
  private _seekBusy = new BehaviorSubject<boolean>(false);
  private _gainNode: GainNode;
  private _audioContext: AudioContext;
  // the difference between threshold of hearing and conversation
  private _MIN_GAIN: sty.Decibels = -60;

  protected _clip: AudioClip;
  get currentTime(): sty.Milliseconds {
    // TODO: when segment loads, timeupdate sometimes goes to last seekable position
    //          can be reproduced by blocking a single segment (just return for segIdx==1 in _bufferSegment)
    return (
      this._audioElm.nativeElement.currentTime * 1000 - this._clip.startInFile
    );
  }

  protected subscriptions = new Subscription();
  protected segHelper = new ConstantDurationSegmentHelper(kSegmentDuration);

  constructor(protected readonly streamerClient: StreamerClientService) {
    super();
  }

  /**
   * Set gain node
   * Using gain node over audio.volume to can go over 100% volume
   * No need subscribe to clip and to update gain since component
   * re-renders after tool sidebar closes
   */
  private _setupGainNode() {
    this._audioContext = new (window as any).AudioContext();
    const source = this._audioContext.createMediaElementSource(
      this._audioElm.nativeElement
    );
    this._gainNode = this._audioContext.createGain();
    source.connect(this._gainNode);
    this._gainNode.connect(this._audioContext.destination);
    this._gainNode.gain.value = this.fromDecibelToRatio(this._clip.gain);
  }

  private fromDecibelToRatio(decibel: sty.Decibels): number {
    if (decibel < this._MIN_GAIN) return 0;
    return Math.pow(10, decibel / 20);
  }

  ngOnInit() {
    this._setupGainNode();
    // add handler for appending new source buffers
    const bufferUpdated = new Subject();
    this.subscriptions.add(
      this._appendBuffer
        .pipe(
          // append one buffer at atime
          concatMap((buffer) => {
            const updateend = observableFromEvent(
              this._srcBuffer,
              'updateend'
            ).pipe(first());
            this._srcBuffer.appendBuffer(buffer);
            // wait for updateend event
            return updateend;
          })
        )
        .subscribe(bufferUpdated)
    );

    // add seek handler, delay seek until the position in buffered
    this.subscriptions.add(
      this._seekTo
        .pipe(
          tap(() => this._seekBusy.next(true)), // seekBusy.next(false) is called in _doSeek()
          // generate observable that emits seekPos once when ready to seek
          switchMap((seekPos) =>
            observableMerge(
              observableOf(undefined), // provide an initial trigger
              bufferUpdated
            ).pipe(
              filter(() => this._isPositionBuffered(seekPos)),
              map(() => seekPos),
              first()
            )
          )
        )
        .subscribe((v) => this._doSeek(v))
    );

    // handle play/pause
    const isPlaying = new BehaviorSubject<boolean>(false);
    this.subscriptions.add(
      this.paused
        .pipe(
          map((v) => !v),
          // control when play and pause should be called:
          switchMap((shouldPlay) =>
            shouldPlay
              ? // shouldPlay == true -> play as soon as seeking is not busy
                this._seekBusy.pipe(
                  map((v) => !v),
                  takeWhile((v) => !v, true)
                )
              : // shouldPlay == false -> pause as soon as _audioElmBusy becomes false (indicating that the audio element can take commands)
                this._audioElmBusy.pipe(takeWhile((v) => !!v, true))
          ),
          distinctUntilChanged()
        )
        .subscribe(isPlaying)
    );
    this.subscriptions.add(
      isPlaying.subscribe((shouldPlay) => this._handlePlay(shouldPlay))
    );

    // update canPlay
    // audio only goes into canPLay=false state when actually trying to start playback on an unbuffered
    // position. Audio does not need to be loaded for seeking.
    this.canPlay.next(true);

    // this reflects the state of the audio clip. Generally audio is almost always playable
    const playable = observableMerge(
      observableFromEvent(this._audioElm.nativeElement, 'canplay').pipe(
        map(() => true)
      ),
      observableFromEvent(this._audioElm.nativeElement, 'stalled').pipe(
        map(() => false)
      )
    ).pipe(distinctUntilChanged());

    // The addition of _seekBusy is desireable to keep the playhead at a correct position at all times.
    // if omitted we could see jumping when the state of audio-clip-view is not ready even though
    // audio is in fact playable.
    this.subscriptions.add(
      combineLatest([this._seekBusy, playable])
        .pipe(map(([seekBusy, playable]) => !seekBusy && playable))
        .subscribe(this.canPlay)
    );

    // attach source object
    this._audioElm.nativeElement.src = URL.createObjectURL(this._srcObject);
    this._audioElm.nativeElement.load();

    this.subscriptions.add(
      combineLatest([
        this._loadHeader,
        observableFromEvent(this._srcObject, 'sourceopen'),
      ])
        .pipe(
          filter(([_, sourceopen]) => !!sourceopen),
          filter(() => !!this.streamerClient), // might get called after the clip has been destroyed
          concatMap(() =>
            this.streamerClient.fetchAudio(this._clip, -1).pipe(
              // ignore errors and rely on buffering to retry on next seek
              catchError(() => observableEmpty())
            )
          ),
          first()
        )
        .subscribe((buffer) => {
          URL.revokeObjectURL(this._audioElm.nativeElement.src);
          this._srcBuffer = this._srcObject.addSourceBuffer(kAudioMimeString);
          // set duration
          this._srcObject.duration = this._clip.duration / 1000;
          this._appendBuffer.next(buffer);
          // resolve srcHeaderLoaded
          this._srcHeaderLoaded.next(null);
          this._srcHeaderLoaded.complete();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._cancelFetchSubscriptionsNotInRange(-Infinity, -Infinity);
  }

  get startInScene(): sty.Milliseconds {
    return this._clip.startInScene;
  }

  get syncPriority(): number {
    return this._clip.syncPriority;
  }

  play(): void {
    this.paused.next(false);
  }
  pause(): void {
    this.paused.next(true);
  }
  private _handlePlay(shouldPlay: boolean): void {
    if (shouldPlay) {
      this._audioElmBusy.next(true);
      this._audioContext.resume();
      this._audioElm.nativeElement
        .play()
        .then(() => this._audioElmBusy.next(false))
        .catch(() => this._audioElmBusy.next(false));
    } else {
      this._audioElm.nativeElement.pause();
    }
  }

  seek(pos: sty.Milliseconds): void {
    this._seekTo.next(pos);
  }
  private _doSeek(pos: sty.Milliseconds): void {
    this._bufferSegment(this.segHelper.segIdxAtPos(this._clip, pos));
    this._audioElm.nativeElement.currentTime =
      (pos + this._clip.startInFile) / 1000;
    this._seekBusy.next(false);
  }

  sync(refPos: sty.Milliseconds | null): sty.Milliseconds {
    if (refPos !== null) {
      // adapting playback speed sounds bad, syncing requires additional thoughts.
      // Right now only one audio clip should be in the scene at any given position, so this is not required
      throw new Error('not implemented');
    }
    return 0;
  }

  buffer(tStart: sty.Milliseconds, duration: sty.Milliseconds): void {
    // trigger header load
    this._loadHeader.next();
    // buffer segments
    for (
      let segIdx = this.segHelper.segIdxAtPos(this._clip, tStart);
      segIdx <= this.segHelper.segIdxAtPos(this._clip, tStart + duration);
      segIdx++
    ) {
      this._bufferSegment(segIdx);
    }
    // cancel all pending subscriptions for segments that are not in buffer range
    this._cancelFetchSubscriptionsNotInRange(
      Math.max(
        0,
        this.segHelper.segIdxAtPos(this._clip, tStart - 0.4 * kSegmentDuration)
      ),
      this.segHelper.segIdxAtPos(
        this._clip,
        tStart + duration + 1.4 * kSegmentDuration
      )
    );
  }

  protected _bufferSegment(segIdx: number): void {
    // only load data if segment is in clip
    if (
      segIdx < this.segHelper.segIdxAtPos(this._clip, 0) ||
      segIdx > this.segHelper.segIdxAtPos(this._clip, this._clip.duration - 1)
    )
      return;
    // skip loading if already loaded
    if (this._fetchSubscriptions[segIdx]) return;
    // cancel all requests for thi segment might have been started previously
    if (this._fetchSubscriptions[segIdx])
      this._fetchSubscriptions[segIdx].unsubscribe();
    // start fetch but wait for srcHeaderLoaded to resolve before appending buffer
    this._fetchSubscriptions[segIdx] = combineLatest([
      this.streamerClient.fetchAudio(this._clip, segIdx).pipe(
        // ignore errors - will be retried on next buffer
        catchError(() => EMPTY)
      ),
      this._srcHeaderLoaded,
    ])
      .pipe(
        filter(([buffer, _]) => !!buffer),
        map(([buffer, _]) => buffer)
      )
      .subscribe((buffer) => this._appendBuffer.next(buffer));
  }

  protected _cancelFetchSubscriptionsNotInRange(
    startSegIdx: number,
    endSegIdx: number
  ): void {
    this._fetchSubscriptions.forEach((subscription, segIdx, arr) => {
      if (subscription && (segIdx < startSegIdx || segIdx > endSegIdx)) {
        subscription.unsubscribe();
        arr[segIdx] = null;
      }
    });
  }

  /**
   * check if buffered at pos (in clip time)
   */
  protected _isPositionBuffered(pos: sty.Milliseconds): boolean {
    const fileSeekTime = pos + this._clip.startInFile;
    const buffered = this._audioElm.nativeElement.buffered;
    for (let i = 0; i < buffered.length; i++) {
      if (
        buffered.start(i) * 1000 <= fileSeekTime &&
        buffered.end(i) * 1000 >= fileSeekTime
      )
        return true;
    }
    return false;
  }
}
