var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AsyncSubject, from } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GapiLoaderService } from 'lib-editor/lib/common/gapi-loader.service';
import { Log } from 'ng2-logger/browser';
import { UserService } from '../auth/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/user.service";
import * as i2 from "./gapi-loader.service";
var kDiscoveryDocs = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];
var log = Log.create('GDriveService');
// TODO: this class does not seem to manage any state -> this probably doesn't need to be a service
var GDriveService = /** @class */ (function () {
    function GDriveService(user, _gapiLoader) {
        var _this = this;
        this.user = user;
        this._gapiLoader = _gapiLoader;
        this._gapi = new AsyncSubject();
        // load client as soon as possible
        from(this.user.gapiAuth())
            .pipe(filter(function (auth) { return !!auth; }), switchMap(function (auth) {
            return _this._gapiLoader.loadClient(auth.access_token, kDiscoveryDocs);
        }), first())
            .subscribe(this._gapi);
    }
    /**
     * Find folder or create it, if it does not exist yet.
     * @returns id of folder.
     */
    GDriveService.prototype.getScreencastifyFolder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var folderId, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!environment.e2e) return [3 /*break*/, 1];
                        _a = environment.e2e.driveSCFolderId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.findScreencastifyFolder()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        folderId = _a;
                        if (!!folderId) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.createScreencastifyFolder()];
                    case 4:
                        folderId = _b.sent();
                        _b.label = 5;
                    case 5: return [2 /*return*/, folderId];
                }
            });
        });
    };
    /**
     * tries to find the screencastify upload folder in users google drive
     * @returns file id of folder or null if fodler not found
     */
    GDriveService.prototype.findScreencastifyFolder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var query, gapi, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "mimeType='application/vnd.google-apps.folder' and " +
                            'trashed=false and ' +
                            "appProperties has { key='isScreencastifyUploadFolder' and value='true' }";
                        return [4 /*yield*/, this._gapi.toPromise()];
                    case 1:
                        gapi = _a.sent();
                        return [4 /*yield*/, gapi.client.drive.files.list({
                                q: query,
                                // fields: 'files(id, name, trashed, properties)',   // debug: get more information on files
                                fields: 'files(id)',
                                pageSize: 1,
                            })];
                    case 2:
                        resp = _a.sent();
                        return [2 /*return*/, resp.result.files.length ? resp.result.files[0].id : null];
                }
            });
        });
    };
    /**
     * creates a screencastify upload folder on the users google drive
     * @returns file id of new folder
     */
    GDriveService.prototype.createScreencastifyFolder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log.i('creating Screencastify drive folder');
                        return [4 /*yield*/, this._gapi.toPromise()];
                    case 1: return [4 /*yield*/, (_a.sent()).client.drive.files.create({
                            resource: {
                                name: 'Screencastify',
                                mimeType: 'application/vnd.google-apps.folder',
                                description: 'Screencastify uses this folder to store Screencasts that you upload. ' +
                                    'You may rename or move it, it will upload to this folder.',
                                appProperties: [
                                    {
                                        isScreencastifyUploadFolder: 'true',
                                    },
                                ],
                            },
                            fields: 'id',
                        })];
                    case 2:
                        resp = _a.sent();
                        return [2 /*return*/, resp.result.id];
                }
            });
        });
    };
    /**
     * Get link to view file in drive UI.
     * @param fileId drive file id.
     * @returns url to view file in Drive UI.
     */
    GDriveService.prototype.getLink = function (fileId) {
        return "https://drive.google.com/file/d/" + fileId + "/view";
    };
    /**
     * Parse drive JSON state parameter
     * @param unparsedState json-serialized state string
     * @return null on failure.
     * (see https://developers.google.com/drive/v3/web/integrate-open)
     */
    GDriveService.prototype.parseOpenFromDriveData = function (unparsedState) {
        var state;
        try {
            state = JSON.parse(unparsedState);
        }
        catch (_) {
            return null;
        }
        if (!state)
            return null;
        // validate types
        if (typeof state.userId !== 'string' ||
            typeof state.ids !== 'object' ||
            !state.ids.length)
            return null;
        if (state.ids.some(function (v) { return typeof v !== 'string'; }))
            return null;
        return {
            userId: state.userId,
            ids: state.ids,
            resourceKeys: state.resourceKeys || {},
        };
    };
    GDriveService.prototype.listRecordings = function (maxResults) {
        if (maxResults === void 0) { maxResults = 1000; }
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = 'trashed = false and ' +
                            "appProperties has { key='isScreencastifySyncFile' " +
                            "and value='true'}";
                        return [4 /*yield*/, this._gapi.toPromise()];
                    case 1: return [4 /*yield*/, (_a.sent()).client.drive.files.list({
                            pageSize: 100,
                            q: query,
                            fields: 'files(name, thumbnailLink, id, appProperties, createdTime, md5Checksum)',
                            orderBy: 'createdTime desc',
                        })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.result.files];
                }
            });
        });
    };
    GDriveService.ngInjectableDef = i0.defineInjectable({ factory: function GDriveService_Factory() { return new GDriveService(i0.inject(i1.UserService), i0.inject(i2.GapiLoaderService)); }, token: GDriveService, providedIn: "root" });
    return GDriveService;
}());
export { GDriveService };
