/*!
 * Copyright 2018 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AnnotationsClip,
  AudioClip,
  ClipModel2,
  SceneEditor2,
  VideoClip,
} from '@castify/edit-models';
import { AnnotationsToolControllerService } from 'lib-editor/lib/annotations-tool/annotations-tool-controller.service';
import { BlurEditorControllerService } from 'lib-editor/lib/blur-editor/blur-editor-controller.service';
import { Log, Logger } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { UndoManagerService } from '../../../common/undo-manager.service';
import { CropEditorControllerService } from '../../../crop-editor/crop-editor-controller.service';
import { EditorManagerService } from '../../../preview/editor-manager.service';
import { PreviewStateService } from '../../../preview/preview-state.service';
import {
  EffectSelection,
  TimelineStateService,
} from '../../../timeline/timeline-state.service';
import { ZoomEditorControllerService } from '../../../zoom-editor/zoom-editor-controller.service';

@Component({
  selector: 'lib-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // NOTE: automatic change detection disabled
})
export class ToolbarComponent implements OnInit, OnDestroy {
  readonly removeEnabled = new BehaviorSubject<boolean>(false);
  readonly cutEnabled = new BehaviorSubject<boolean>(false);
  readonly cropEnabled = new BehaviorSubject<boolean>(false);
  readonly annotationsEnabled = new BehaviorSubject<boolean>(false);
  readonly addClipEnabled = new BehaviorSubject<boolean>(false); // TODOO: wire up to add clip button
  readonly zoomEnabled = new BehaviorSubject<boolean>(false);
  readonly blurEnabled = new BehaviorSubject<boolean>(false);
  readonly playEnabled = new BehaviorSubject<boolean>(false);

  readonly playbackRunning = new Subject<boolean>();
  private _log: Logger<any> = Log.create('ToolbarComponent');
  private subscriptions = new Subscription();

  constructor(
    public undoManager: UndoManagerService,
    public editorManager: EditorManagerService,
    public previewState: PreviewStateService,
    public timelineState: TimelineStateService,
    public cropCtrl: CropEditorControllerService,
    public zoomCtrl: ZoomEditorControllerService,
    public blurCtrl: BlurEditorControllerService,
    public annotationsCtrl: AnnotationsToolControllerService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    // removeEnabled
    this.subscriptions.add(
      this.timelineState.selection
        .pipe(
          map((selection) =>
            selection.some(
              (c) => this.isEditableClip(c) || c instanceof EffectSelection
            )
          )
        )
        .subscribe(this.removeEnabled)
    );

    // cutEnabled
    this.subscriptions.add(
      combineLatest([
        this.cropCtrl.isActive,
        this.timelineState.selection,
        this.timelineState.playheadCursor,
      ])
        .pipe(
          map(([cropActive, selection, playhead]) => {
            if (cropActive) return false;

            let clip: ClipModel2 = undefined;
            if (selection.some((c) => c instanceof EffectSelection)) {
              clip = (<EffectSelection>(
                selection.find((c) => c instanceof EffectSelection)
              )).clip;
            } else {
              clip = <ClipModel2>selection.find((c) => this.isEditableClip(c));
            }
            return (
              !!clip &&
              playhead > clip.startInScene &&
              playhead < clip.endInScene
            );
          })
        )
        .subscribe(this.cutEnabled)
    );

    // cropEnabled
    this.subscriptions.add(
      combineLatest([this.cropCtrl.canActivate, this.timelineState.selection])
        .pipe(
          map(
            ([cropCanActivate, selection]) =>
              cropCanActivate &&
              !selection.some((clip) => clip instanceof AudioClip)
          )
        )
        .subscribe(this.cropEnabled)
    );

    // zoomEnabled
    this.subscriptions.add(
      combineLatest([
        this.zoomCtrl.canActivate.pipe(debounceTime(1)),
        this.cropCtrl.isActive,
        this.timelineState.selection,
      ])
        .pipe(
          map(
            ([canActivate, cropActive, selection]) =>
              canActivate &&
              !cropActive &&
              !selection.some((clip) => clip instanceof AudioClip)
          )
        )
        .subscribe(this.zoomEnabled)
    );

    // blurEnabled
    this.subscriptions.add(
      combineLatest([
        this.blurCtrl.canActivate,
        this.cropCtrl.isActive,
        this.timelineState.selection,
      ])
        .pipe(
          map(
            ([canActivate, cropActive, selection]) =>
              canActivate &&
              !cropActive &&
              !selection.some((clip) => clip instanceof AudioClip)
          )
        )
        .subscribe(this.blurEnabled)
    );

    // addClipEnabled
    this.subscriptions.add(
      combineLatest([this.cropCtrl.isActive])
        .pipe(map(([cropActive]) => !cropActive))
        .subscribe(this.addClipEnabled)
    );

    // annotationsEnabled
    this.subscriptions.add(
      combineLatest([
        this.annotationsCtrl.canActivate,
        this.cropCtrl.isActive,
        this.timelineState.selection,
      ])
        .pipe(
          map(
            ([canActivate, cropActive, selection]) =>
              canActivate &&
              !cropActive &&
              !selection.some((clip) => clip instanceof AudioClip)
          )
        )
        .subscribe(this.annotationsEnabled)
    );

    // playEnabled
    this.subscriptions.add(
      combineLatest([this.undoManager.scene, this.editorManager.activeEditor])
        .pipe(map(([scene, editor]) => !!scene && !editor))
        .subscribe(this.playEnabled)
    );

    // stop playback when playback gets disabled
    this.subscriptions.add(
      this.playEnabled
        .pipe(filter((v) => !v))
        .subscribe(() => this.previewState.pause())
    );

    this.subscriptions.add(
      this.previewState.shouldPlay
        .pipe(
          // HACK: for some reasons we're not getting the correct values by just subscribing
          //        to should play. Looks like values are out of order when value changes more then once
          //        within the same render cycle. This happens when the scene is seeked to the end and the
          //        user hits play.
          //        shouldPlay.value has the correct value.
          map(() => this.previewState.shouldPlay.value)
        )
        .subscribe(this.playbackRunning)
    );
  }

  /**
   * Toggles the current play state of
   * our preview when the associated button is clicked.
   */
  onPlayClick(): void {
    if (
      !!this.undoManager.scene.value &&
      !this.editorManager.activeEditor.value
    ) {
      if (this.previewState.shouldPlay.value) {
        this.previewState.pause();
      } else {
        this.previewState.play();
      }
    }
  }

  /**
   * Removes selected effect or clip from the current preview
   */
  onRemoveClick(): void {
    if (!this.removeEnabled.value) return;

    // If we're currently cropping a clip, stop cropping and move on
    if (this.cropCtrl.isActive.value) {
      this.cropCtrl.reset();
      return;
    }

    const scene = this.undoManager.scene.value;
    // Set the current type of selection we're working with
    const selection = this.timelineState.selection.value.find(
      (c) => this.isEditableClip(c) || c instanceof EffectSelection
    );

    // case for clicks on clips
    if (selection instanceof ClipModel2) {
      // case where annotations clips are selecte
      // and the annotations tool is open
      if (
        selection instanceof AnnotationsClip &&
        this.annotationsCtrl.isActive.value
      ) {
        this.annotationsCtrl.deleteSelectedTextBox();
        // early return to avoid removing clips when boxes still remain
        if (this.annotationsCtrl.numBoxes > 0) {
          return;
        }
      }
      // remove clip when it is in the scene
      if (scene.clips.byId(selection.id)) {
        new SceneEditor2(scene).removeClip(selection);
      }
      // handles case where it is not yet in the scene
      else {
        this.annotationsCtrl.close();
      }
      this._log.info('Delete clip from timeline');
      // Otherwise if we're working with an effect thats attached to a clip...
    } else if (selection.clip && selection.effect) {
      // If it's a blur effect, remove the currently selected box
      if (selection.effect.type === 'blur' && this.blurCtrl.isActive.value) {
        this.blurCtrl.deleteSelectedBox();
        if (this.blurCtrl.numBlurBoxes > 0) {
          return;
        }
      }

      // Remove the effect from the clip
      new SceneEditor2(scene)
        .getClipEditor(selection.clip)
        .removeEffect(selection.effect);
    } else {
      throw new Error(`Can not Remove this selection: ${selection}`);
    }

    // close all open editors to not be stuck
    if (!!this.editorManager.activeEditor.value) {
      this.editorManager.activeEditor.value.getController().close();
    }

    // remove deleted clip from selection
    this.timelineState.selection.next([]);
    this.undoManager.update(scene, 'remove');
  }

  /**
   * Separate the selected clip into two different clips based on
   * the position of the playhead cursor
   */
  onCutClick(): void {
    if (!this.cutEnabled.value) return;
    const scene = this.undoManager.scene.value;
    // cut only selected clip
    const selection = this.timelineState.selection.value.find(
      (c) => c instanceof EffectSelection || this.isEditableClip(c)
    );
    const clip =
      selection instanceof EffectSelection
        ? scene.clips.byId(selection.clip.id)
        : scene.clips.byId(selection.id);
    if (!clip) return;
    const cutPosition =
      this.timelineState.playheadCursor.value - clip.startInScene;
    const newClips = new SceneEditor2(scene).cutClip(clip, cutPosition);
    this.undoManager.update(scene, 'cut');
    // workflow tweak: select new clips to allow users to play back and cut again
    if (newClips) {
      const selection = newClips.find((v) => v.type === clip.type);
      this.timelineState.selection.next([selection]);
    }
  }

  onZoomClick(): void {
    if (!this.zoomEnabled.value) return;
    this.onEffectBtnClick('zoom');
  }

  onBlurClick(): void {
    if (!this.blurCtrl.canActivate.value) return;
    this.onEffectBtnClick('blur');
  }

  onCropClick(): void {
    if (!this.cropEnabled.value) return;
    this.cropCtrl.open();
  }

  onTextClick(): void {
    if (!this.annotationsEnabled.value) return;
    this.annotationsCtrl.open();
  }

  /**
   * Abstract similar logic for applying effects to a video.
   * Effects are indicated by `type` and include `blur` and `zoom`.
   */
  protected onEffectBtnClick(type: string): void {
    const clipsAtPlayhead = [
      ...this.undoManager.scene.value.clips.getAllAtPos(
        this.timelineState.playheadCursor.value
      ),
    ];

    const videoAtPlayhead = <VideoClip[]>(
      clipsAtPlayhead.filter((v) => v instanceof VideoClip)
    );

    if (videoAtPlayhead.length) {
      const playheadPosInClip =
        this.timelineState.playheadCursor.value -
        videoAtPlayhead[0].startInScene;

      const effects = [
        ...videoAtPlayhead[0].effects
          .filterType([type])
          .getAllAtPos(playheadPosInClip),
      ];

      if (!!effects[0]) {
        this.timelineState.selection.next([
          new EffectSelection({
            clip: videoAtPlayhead[0],
            effect: effects[0],
          }),
        ]);
      }
    }

    this.editorManager.providers[type].getController().open();
  }

  protected isEditableClip(selection: ClipModel2 | EffectSelection): boolean {
    return (
      selection instanceof ClipModel2 &&
      ['video', 'annotations', 'still'].includes(selection.type)
    );
  }
}
