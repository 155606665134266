/*!
 * Copyright 2020 Screencastify LLC
 */
import { FisUserFile } from '@castify/edit-models';
import { defer, of } from 'rxjs';
import { endWith, last, map, shareReplay, startWith, switchMap, takeWhile, } from 'rxjs/operators';
var DriveImporter = /** @class */ (function () {
    function DriveImporter(_uiApiService, projectId, driveImportFile) {
        var _this = this;
        this._uiApiService = _uiApiService;
        this.projectId = projectId;
        this.driveImportFile = driveImportFile;
        /**
         * Kicks off import requests
         */
        this._gotUserFileId = defer(function () { return of(_this.driveImportFile); }).pipe(
        // kick off import via the api
        switchMap(function (driveImportFile) {
            return _this._importFile(_this.projectId, driveImportFile);
        }), 
        // grab the user file id from the api response
        map(function (apiResponse) { return apiResponse.file.id; }), 
        // multicast
        shareReplay());
        /**
         * Emits userFile when we have it
         */
        this._userFileProgress = defer(function () { return _this._gotUserFileId; }).pipe(
        // subscribe to progress updates from the task
        // and complete on task completion
        map(function (fisUserFileId) { return _this._getUserFileById(fisUserFileId); }), switchMap(function (userFile) {
            return userFile
                .onProgress()
                .pipe(takeWhile(function (file) { return !file.mediaInfo || !file.sourceFileLoaded; }, true));
        }));
        /**
         * Observable of progress updates
         */
        this.progress = defer(function () { return _this._userFileProgress; }).pipe(map(function (fisUserFile) { return fisUserFile.progress; }), startWith(0), endWith(1));
        /**
         * Emits underlying userFile on completion for use in project
         * Can also be used to check for completion of import for other reasons
         */
        this.complete = defer(function () { return _this._userFileProgress.pipe(last()); });
    }
    /**
     * Kicks off an import via the edit API
     */
    DriveImporter.prototype._importFile = function (projectId, driveImportFile) {
        return this._uiApiService.importGoogleDrive({
            projectId: projectId,
            driveFileId: driveImportFile.id,
            resourceKey: driveImportFile.resourceKey,
        });
    };
    /**
     * Gets userFile from firestore by ID
     * (exists to facilitate unit testing)
     */
    DriveImporter.prototype._getUserFileById = function (fisUserFileId) {
        return FisUserFile.byId(fisUserFileId);
    };
    return DriveImporter;
}());
export { DriveImporter };
