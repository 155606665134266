/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable, OnDestroy } from '@angular/core';
import { FisUserFile, SceneEditor2 } from '@castify/edit-models';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'environments/environment';
import { StatsHelperService } from 'lib-editor/lib/common/stats-helper.service';
import { Log, Logger } from 'ng2-logger/browser';
import { combineLatest, defer, from, Subscription } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { FisMediaInfo } from '../../../../../../../models/lib';
import { DrivePickerService } from '../../common/drive-picker.service';
import { ProjectService } from '../../common/project.service';
import { UiApiService } from '../../common/ui-api.service';
import { UndoManagerService } from '../../common/undo-manager.service';
import { TimelineStateService } from '../../timeline/timeline-state.service';
import { DriveImporter } from './drive-importer';

export interface DriveImportFile {
  id: string;
  resourceKey?: string;
}

@Injectable()
export class DriveImportBrainService implements OnDestroy {
  private _log: Logger<any> = Log.create('DriveImportBrainService');
  private subscriptions = new Subscription();

  /**
   * Allows DriveImporter injection for testing
   */
  protected _DriveImporter = DriveImporter;

  /**
   * Emits when we're ready to ask the user for files
   */
  readonly readyToAskUserForFile = defer(() => this._project.project).pipe(
    filter((project) => !!project),
    // prevents drive picker from opening again after starting a new project
    first()
  );

  /**
   * Emits once the service knows what files should be uploaded
   */
  gotDriveFiles = defer(() => this.readyToAskUserForFile).pipe(
    switchMap(() => this.askUserForFiles()),
    shareReplay()
  );

  /**
   * Emits if an import flow is cancelled by closing the picker,
   * (represented by the parent observable emitting null)
   */
  flowCancelled = defer(() => this.gotDriveFiles).pipe(
    first(),
    filter((fileIds) => !fileIds)
  );

  /**
   * The number of files the user is importing
   */
  importCount = defer(() => this.gotDriveFiles).pipe(
    first(),
    filter((fileIds) => !!fileIds),
    map((fileIds) => fileIds.length)
  );

  /**
   * Emits file import instances, completes when all have been created
   */
  _driveImporters = defer(() =>
    combineLatest([
      this._project.project.pipe(
        filter((v) => !!v),
        first()
      ),
      // go from array to observable
      this.gotDriveFiles.pipe(
        first(),
        filter((fileIds) => !!fileIds),
        switchMap((idsArray) => from(idsArray))
      ),
    ])
  ).pipe(
    map(
      ([project, importFile]) =>
        new this._DriveImporter(this._uiApi, project.id, importFile)
    ),
    tap((driveFileId) =>
      this._log.info('Import from Google Drive %s', driveFileId)
    ),
    shareReplay()
  );

  /**
   * Averages progress of all imports
   */
  progress = defer(() =>
    combineLatest([
      this._driveImporters.pipe(
        toArray(),
        map((driveImporters) =>
          driveImporters.map((driveImporter) => driveImporter.progress)
        ),
        switchMap((progressObservables) => combineLatest(progressObservables))
      ),
      this.importCount,
    ])
  ).pipe(
    map(
      ([progressArray, importCount]) =>
        progressArray.reduce((acc, p) => acc + p, 0) / importCount
    )
  );

  /**
   * Emits how many files have completed
   * and completes when all have completed
   */
  complete = defer(() => this._driveImporters).pipe(
    // Counts the number of completions
    // (these 2 lines same as mergeScan but more readable)
    mergeMap((driveImporter) => driveImporter.complete.pipe(map(() => 1))),
    scan((acc, curr) => acc + curr, 0),

    // make sure it isn't null initially
    startWith(0),
    shareReplay()
  );

  /**
   * Emits each file as it becomes ready for editing
   */
  userFilesReadyForEditing = defer(() => this._driveImporters).pipe(
    mergeMap((driveImporter) => driveImporter.complete)
  );

  constructor(
    private _uiApi: UiApiService,
    private _project: ProjectService,
    private _undoManager: UndoManagerService,
    private _timelineState: TimelineStateService,
    private _drivePicker: DrivePickerService,
    private _angulartics: Angulartics2GoogleAnalytics,
    private _statsHelper: StatsHelperService
  ) {}

  init() {
    // Add clips to the timeline when import is complete
    this.subscriptions.add(
      this.userFilesReadyForEditing.subscribe((userFile) =>
        this._addFileToTimeline(userFile)
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Opens the drive file picker.
   * `string[]` when the user picked files; `null` when they cancel.
   * Note that this may throw if there are auth issues with gdrive;
   * errors should be caught/handled in called
   */
  async askUserForFiles(): Promise<DriveImportFile[] | null> {
    const result = environment.e2e
      ? environment.e2e.drivePickerFiles
      : await this._drivePicker.openPicker();
    return result
      ? result.map((file) => ({ id: file.id, resourceKey: file.resourceKey }))
      : null;
  }

  /**
   * Adds a file to the timeline
   */
  _addFileToTimeline(file: FisUserFile): void {
    const scene = this._undoManager.scene.value;
    const newClips = new SceneEditor2(scene).addClipsFromFile(file);
    this._undoManager.update(scene);
    this._timelineState.selection.next(newClips);
    this._timelineState.scroll(
      -newClips[0].startInScene + this._timelineState.displayDuration.value / 10
    );
    this._log.data('File added to timeline %o', file.mediaInfo);
    if (file.mediaInfo) {
      this._trackMediaInfo(file.mediaInfo);
    }
  }

  private _trackMediaInfo(mediaInfo: FisMediaInfo) {
    // track duration
    if (mediaInfo.duration) {
      this._angulartics.eventTrack('drive - duration', {
        label: this._statsHelper.getDurationBucket(mediaInfo.duration),
        category: 'import',
        value: mediaInfo.duration,
        noninteraction: true,
      });
    }
    // track size
    const bounds = this._statsHelper.getValueBucket(mediaInfo.size);
    this._angulartics.eventTrack('drive - size', {
      label: `between ${bounds[0]}bytes-${bounds[1]}bytes`,
      category: 'import',
      value: mediaInfo.size,
      noninteraction: false, // count as user interaction
    });
    // track codecs
    if (mediaInfo.video) {
      this._angulartics.eventTrack('drive - video codec', {
        label: mediaInfo.video.codec,
        category: 'import',
        noninteraction: false, // count as user interaction
      });
    }
    if (mediaInfo.audio) {
      this._angulartics.eventTrack('drive - audio codec', {
        label: mediaInfo.audio.codec,
        category: 'import',
        noninteraction: false, // count as user interaction
      });
    }
  }
}
