/*!
 * Copyright 2020 Screencastify LLC
 */

import 'hammerjs';
import './fb-wrapper-config';
import './polyfills.ts';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap(AppModule) {
  return platformBrowserDynamic().bootstrapModule(AppModule);
}
bootstrap(AppModule);
