/*!
 * Copyright 2020 Screencastify LLC
 */
import { ElementRef, OnInit } from '@angular/core';
import { Limits, Size } from '@castify/models';
var MoveIconComponent = /** @class */ (function () {
    function MoveIconComponent(hostElm) {
        this.hostElm = hostElm;
    }
    MoveIconComponent.prototype.ngOnInit = function () {
        this.resize();
    };
    MoveIconComponent.prototype.resize = function () {
        if (!this.hostElm || !this.moveIcon)
            return;
        var maxSize = 0.7; // fraction of height (or with) of the parent element that the icon can take
        var hostDims = Size.fromObj(this.hostElm.nativeElement.parentElement.getBoundingClientRect());
        var iconDims = new Size(this.moveIcon.nativeElement.offsetWidth, this.moveIcon.nativeElement.offsetHeight);
        var scale = iconDims.scaleToFit(hostDims.mul(maxSize)).width / iconDims.width; // doesn't matter if we're using width or height
        this.moveIcon.nativeElement.style.transform = "scale(" + new Limits(0, 1).apply(scale) + ")";
    };
    return MoveIconComponent;
}());
export { MoveIconComponent };
