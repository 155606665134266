/*!
 * Copyright 2020 Screencastify LLC
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HttpClient, HttpEventType } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { FisUserFile, SceneEditor2, } from '@castify/edit-models';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { StatsHelperService } from 'lib-editor/lib/common/stats-helper.service';
import { Log } from 'ng2-logger/browser';
import { AsyncSubject, combineLatest, defer, Subscription, } from 'rxjs';
import { distinctUntilChanged, endWith, filter, first, last, map, shareReplay, startWith, switchMap, takeWhile, } from 'rxjs/operators';
import { ProjectService } from '../../common/project.service';
import { UiApiService } from '../../common/ui-api.service';
import { UndoManagerService } from '../../common/undo-manager.service';
import { TimelineStateService } from '../../timeline/timeline-state.service';
/**
 * When files are uploaded and a progress bar is shown to the user,
 * what percentage of the progress bar represents upload progress
 * (vs. import progress). Import progress weight will be 1 minus this value
 */
export var kUploadToImport = 0.8;
var LocalImportBrainService = /** @class */ (function () {
    function LocalImportBrainService(_uiApi, _project, _http, _undoManager, _angulartics, _statsHelper, _timelineState) {
        var _this = this;
        this._uiApi = _uiApi;
        this._project = _project;
        this._http = _http;
        this._undoManager = _undoManager;
        this._angulartics = _angulartics;
        this._statsHelper = _statsHelper;
        this._timelineState = _timelineState;
        this._log = Log.create('LocalImportBrainService');
        this.subscriptions = new Subscription();
        /**
         * Emits when we're ready to ask the user for a file
         */
        this.readyToAskUserForFile = defer(function () { return _this._project.project; }).pipe(filter(function (project) { return !!project; }));
        /**
         * Emits once the service knows what file should be uploaded
         */
        this._fileToUpload = new AsyncSubject();
        /**
         * Triggers the api call to initialize an upload
         */
        this._importInit = defer(function () {
            return combineLatest([_this._fileToUpload.pipe(last()), _this._project.project]);
        }).pipe(filter(function (_a) {
            var _b = __read(_a, 2), file = _b[0], project = _b[1];
            return !!file && !!project;
        }), map(function (_a) {
            var _b = __read(_a, 2), project = _b[1];
            return project;
        }), switchMap(function (project) { return _this._doInitializeUpload(project.id); }), shareReplay());
        /**
         * Uploads a file, transforming the response to a number
         * between 0 and 1 representing progress, completing
         * when the upload has finished
         */
        this._uploadProgress = defer(function () {
            return combineLatest([
                _this._fileToUpload.pipe(last()),
                _this._importInit.pipe(map(function (initResp) { return initResp.uploadUrl; })),
            ]);
        }).pipe(
        // kick off a put request, get back observable HttpEvents
        switchMap(function (_a) {
            var _b = __read(_a, 2), file = _b[0], uploadUrl = _b[1];
            return _this._uploadFile(file, uploadUrl);
        }), 
        // Completes the observable when we get a response event
        takeWhile(function (event) { return event && event.type !== HttpEventType.Response; }, true), 
        // Discard all but UploadProgress events
        filter(function (event) { return event && event.type === HttpEventType.UploadProgress; }), 
        // Transform to number describing progress
        map(function (event) { return event.loaded / event.total; }), 
        // Progress always starts with zero and ends with 1
        startWith(0), endWith(1), distinctUntilChanged(), shareReplay());
        /**
         * Triggers the api call to start importing the uploaded file
         *
         * Must be subscribed externally to kick off import on upload completion
         */
        this._importStart = defer(function () {
            return combineLatest([_this._importInit, _this._uploadProgress.pipe(last())]);
        }).pipe(filter(function (_a) {
            var _b = __read(_a, 2), uploadProgress = _b[1];
            return !!uploadProgress;
        }), switchMap(function (_a) {
            var _b = __read(_a, 1), uploadInitResp = _b[0];
            return _this._startImport(uploadInitResp);
        }), shareReplay());
        /**
         * Tracks progress of the UserFile that is being imported.
         * Completes when user file ready for editing
         */
        this._userFileProgress = defer(function () { return _this._importInit; }).pipe(map(function (uploadInitResp) { return uploadInitResp.file; }), switchMap(function (file) { return FisUserFile.byId(file.id).onProgress(); }), 
        // complete when ready for editing
        takeWhile(function (file) { return !file.sourceFileLoaded || !file.mediaInfo; }, true), shareReplay());
        /**
         * Emits accumulated progress for upload + import
         */
        this.progress = defer(function () {
            return combineLatest([
                _this._uploadProgress,
                _this._userFileProgress.pipe(filter(function (v) { return !!v; }), map(function (userFile) { return userFile.progress; })),
            ]);
        }).pipe(map(function (_a) {
            var _b = __read(_a, 2), uploadProgess = _b[0], importProgress = _b[1];
            return _this._calculateOverallProgress(uploadProgess, importProgress);
        }), shareReplay());
        /**
         * Emits user file when it's ready to be added to the scene
         *
         * Must be subscribed to externally; subscription should call _addFileToTimeline
         */
        this.userFileReadyForEditing = defer(function () { return _this._userFileProgress; }).pipe(last());
    }
    LocalImportBrainService.prototype.init = function () {
        var _this = this;
        // Start the import once file has been uploaded
        this.subscriptions.add(this._importStart.subscribe());
        // Add the clip to the timeline & close the modal
        // when import is complete and it is ready for editing
        this.subscriptions.add(this.userFileReadyForEditing.subscribe(function (userFile) {
            _this._addFileToTimeline(userFile);
        }));
    };
    LocalImportBrainService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * Use this to input any file that should be uploaded from local
     * disk and imported
     */
    LocalImportBrainService.prototype.setFile = function (file) {
        this._fileToUpload.next(file);
        this._fileToUpload.complete();
    };
    /**
     * Use to PUT a file to a url and report on progress
     */
    LocalImportBrainService.prototype._uploadFile = function (file, uploadUrl) {
        this._log.data('Upload from local machine %o', file);
        return this._http.put(uploadUrl, file, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            reportProgress: true,
            observe: 'events',
        });
    };
    /**
     * Kicks off an import via the api
     */
    LocalImportBrainService.prototype._startImport = function (uploadInitResp) {
        return this._uiApi.uploadImport({ userFileId: uploadInitResp.file.id });
    };
    /**
     * Adds a file to the timeline
     */
    LocalImportBrainService.prototype._addFileToTimeline = function (file) {
        var scene = this._undoManager.scene.value;
        var newClips = new SceneEditor2(scene).addClipsFromFile(file);
        this._undoManager.update(scene);
        this._timelineState.selection.next(newClips);
        this._timelineState.scroll(-newClips[0].startInScene + this._timelineState.displayDuration.value / 10);
        this._log.data('File added to timeline %o', file.mediaInfo);
        if (file.mediaInfo) {
            this._trackMediaInfo(file.mediaInfo);
        }
    };
    LocalImportBrainService.prototype._trackMediaInfo = function (mediaInfo) {
        // track duration
        if (mediaInfo.duration) {
            this._angulartics.eventTrack('local - duration', {
                label: this._statsHelper.getDurationBucket(mediaInfo.duration),
                category: 'import',
                value: mediaInfo.duration,
                noninteraction: true,
            });
        }
        // track size
        var bounds = this._statsHelper.getValueBucket(mediaInfo.size);
        this._angulartics.eventTrack('local - size', {
            label: "between " + bounds[0] + "bytes-" + bounds[1] + "bytes",
            category: 'import',
            value: mediaInfo.size,
            noninteraction: false,
        });
        // track codecs
        if (mediaInfo.video) {
            this._angulartics.eventTrack('local - video codec', {
                label: mediaInfo.video.codec,
                category: 'import',
                noninteraction: false,
            });
        }
        if (mediaInfo.audio) {
            this._angulartics.eventTrack('local - audio codec', {
                label: mediaInfo.audio.codec,
                category: 'import',
                noninteraction: false,
            });
        }
    };
    /**
     * Initializes an upload, preparing a GCS URL
     * to which a PUT request can be made
     */
    LocalImportBrainService.prototype._doInitializeUpload = function (projectId) {
        return this._uiApi.initUpload({ projectId: projectId }).pipe(first());
    };
    /**
     * Calculate an overal progress value from the progres of upload and import
     */
    LocalImportBrainService.prototype._calculateOverallProgress = function (uploadProgess, importProgress) {
        return (uploadProgess * kUploadToImport + importProgress * (1 - kUploadToImport));
    };
    return LocalImportBrainService;
}());
export { LocalImportBrainService };
