/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blur-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-common/directives/drag-handle.directive";
import * as i3 from "@angular/common";
import * as i4 from "./blur-box.component";
import * as i5 from "../../blur-editor-controller.service";
var styles_BlurBoxComponent = [i0.styles];
var RenderType_BlurBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlurBoxComponent, data: {} });
export { RenderType_BlurBoxComponent as RenderType_BlurBoxComponent };
function View_BlurBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "unselectedBox"]], null, null, null, null, null))], null, null); }
function View_BlurBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["editorBox", 1]], null, 9, "div", [["id", "editorBox"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onMove($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "resizer top-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "resizer top-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, false, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "resizer bottom-right"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "resizer bottom-left"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onEditStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onResize($event, true, false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove" })], null, null); }
export function View_BlurBoxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_BlurBoxComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BlurBoxComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co._editable)) && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.ctrl.isActive))); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co._editable)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BlurBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-blur-box", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BlurBoxComponent_0, RenderType_BlurBoxComponent)), i1.ɵdid(1, 245760, null, 0, i4.BlurBoxComponent, [i5.BlurEditorControllerService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlurBoxComponentNgFactory = i1.ɵccf("lib-blur-box", i4.BlurBoxComponent, View_BlurBoxComponent_Host_0, { blurBox: "blurBox", editable: "editable" }, {}, []);
export { BlurBoxComponentNgFactory as BlurBoxComponentNgFactory };
