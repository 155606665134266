/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = ["[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 0 16px;\n  font-weight: 400; }\n\nimg[_ngcontent-%COMP%] {\n  height: 28px;\n  margin-right: 4px; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvbW9kdWxlcy9lZGl0LXVpL3Byb2plY3RzL2xpYi1lZGl0b3Ivc3JjL2xpYi9jb21tb24vY29tcG9uZW50cy9lZGl0b3ItbG9nby9lZGl0b3ItbG9nby5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsbUJBQW1CO0VBQ25CLGNBQWM7RUFDZCxnQkFBZ0IsRUFBQTs7QUFHbEI7RUFDRSxZQUFZO0VBQ1osaUJBQWlCLEVBQUEiLCJmaWxlIjoicHJvamVjdHMvbGliLWVkaXRvci9zcmMvbGliL2NvbW1vbi9jb21wb25lbnRzL2VkaXRvci1sb2dvL2VkaXRvci1sb2dvLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG46aG9zdCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIG1hcmdpbjogMCAxNnB4O1xuICBmb250LXdlaWdodDogNDAwO1xufVxuXG5pbWcge1xuICBoZWlnaHQ6IDI4cHg7XG4gIG1hcmdpbi1yaWdodDogNHB4O1xufVxuIl19 */"];
export { styles as styles };
