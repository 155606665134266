/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HotkeyModule } from 'angular2-hotkeys';
import { KeysDirective } from './directives/shortcut.directive';
import { KeyboardService } from './keyboard.service';

const exportDeclarations = [KeysDirective];

@NgModule({
  declarations: [...exportDeclarations],
  imports: [CommonModule, HotkeyModule.forRoot()],
  providers: [KeyboardService],
  exports: exportDeclarations,
})
export class LibKeyboardShortcutsModule {}
