/*!
 * Copyright 2018 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-browser-guard',
  templateUrl: './browser-guard.component.html',
  styleUrls: ['./browser-guard.component.scss'],
})
export class BrowserGuardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
