/*!
 * Copyright 2020 Screencastify LLC
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserGuardComponent } from './components/browser-guard/browser-guard.component';

const routes: Routes = [
  {
    path: 'unsupported_browser',
    component: BrowserGuardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrowserGuardRoutingModule {}
