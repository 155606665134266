/*!
 * Copyright 2020 Screencastify LLC
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MatIconRegistry, } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AnnotationsPreviewEditComponent } from './components/annotations-preview/annotations-preview-edit.component';
import { AnnotationsPreviewViewComponent } from './components/annotations-preview/annotations-preview-view.component';
import { AnnotationsSidebarComponent } from './components/annotations-sidebar/annotations-sidebar.component';
import { QuillContainerEditComponent } from './components/quill-container/quill-container-edit.component';
import { QuillContainerViewComponent } from './components/quill-container/quill-container-view.component';
import { sidebarSVGs } from './sidebar-svgs';
var kEntryComponents = [
    AnnotationsPreviewViewComponent,
    AnnotationsPreviewEditComponent,
    QuillContainerEditComponent,
    QuillContainerViewComponent,
    AnnotationsSidebarComponent,
];
var LibAnnotationsToolModule = /** @class */ (function () {
    function LibAnnotationsToolModule(matIconRegistry, domSanitzer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitzer = domSanitzer;
        /**
         * Registers custom SVGs with the Material Icon module with
         * selectors we can reference in markup
         */
        Object.entries(sidebarSVGs).forEach(function (_a) {
            var _b = __read(_a, 2), svgSelector = _b[0], svgMarkup = _b[1];
            _this.matIconRegistry.addSvgIconLiteral(svgSelector, _this.domSanitzer.bypassSecurityTrustHtml(svgMarkup));
        });
    }
    return LibAnnotationsToolModule;
}());
export { LibAnnotationsToolModule };
