import { SceneModel2 } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ProjectService } from './project.service';
import { UndoStore } from './undo-store';
import * as i0 from "@angular/core";
import * as i1 from "./project.service";
var log = Log.create('UndoManagerService');
var kUndoHistorySize = 16;
var UndoManagerService = /** @class */ (function () {
    function UndoManagerService(projectService) {
        var _this = this;
        this.projectService = projectService;
        this.scene = new BehaviorSubject(new SceneModel2());
        this.canUndo = new BehaviorSubject(false);
        this.canRedo = new BehaviorSubject(false);
        /**
         * true when database update is pending.
         */
        this.busy = new BehaviorSubject(false);
        this._undoStore = null;
        this.projectService.project
            .pipe(distinctUntilChanged())
            .subscribe(function (v) { return _this._onProjectChange(v); });
    }
    /**
     * restore last state from the database, will update this.scene
     */
    UndoManagerService.prototype.undo = function () {
        var project = this.projectService.project.value;
        if (!project)
            throw new Error('no project set');
        var state = this._undoStore.undo();
        this._syncUndoRedoState(project, state);
    };
    /**
     * similar to undo() but moves forward in history
     */
    UndoManagerService.prototype.redo = function () {
        var project = this.projectService.project.value;
        if (!project)
            throw new Error('no project set');
        var state = this._undoStore.redo();
        this._syncUndoRedoState(project, state);
    };
    UndoManagerService.prototype._syncUndoRedoState = function (project, state) {
        if (state) {
            this._updateCanUndoRedo();
            this._wrapBusy(project.update({ scene: state.scene, stateId: state.id }));
            this.scene.next(state.scene);
        }
        else {
            // reset undo store in case the history state cannot be read
            this._undoStore.reset();
            this._undoStore.pushState(project.scene);
            this._updateCanUndoRedo();
        }
    };
    /**
     * updates the scene, persists it to the database and adds an entry to the undo/redo history
     * @param scene the new scene
     * @param action the action message which operation is used on the Scene
     */
    UndoManagerService.prototype.update = function (scene, action) {
        if (action === void 0) { action = ''; }
        var project = this.projectService.project.value;
        if (!project)
            throw new Error('no project set');
        // make independent copy of the scene to ensure change detection gets triggered
        var state = this._undoStore.pushState(scene ? scene.copy() : null);
        this._syncUndoRedoState(project, state);
    };
    UndoManagerService.prototype._onProjectChange = function (project) {
        if (!project)
            return;
        if (project.scene) {
            var initState = { scene: project.scene, id: project.stateId };
            this._undoStore = new UndoStore(initState, kUndoHistorySize);
            this._updateCanUndoRedo();
            this.scene.next(project.scene);
        }
        else {
            this._undoStore = new UndoStore(null, kUndoHistorySize);
            this.update(new SceneModel2());
        }
    };
    UndoManagerService.prototype._updateCanUndoRedo = function () {
        if (this._undoStore) {
            this.canUndo.next(this._undoStore.undoLength > 1);
            this.canRedo.next(this._undoStore.redoLength > 0);
        }
        else {
            this.canUndo.next(false);
            this.canRedo.next(false);
        }
    };
    UndoManagerService.prototype._wrapBusy = function (promise) {
        var _this = this;
        this.busy.next(true);
        promise
            .then(function () { return _this.busy.next(false); })
            .catch(function (err) {
            _this.busy.next(false);
            log.error(err);
        });
    };
    UndoManagerService.ngInjectableDef = i0.defineInjectable({ factory: function UndoManagerService_Factory() { return new UndoManagerService(i0.inject(i1.ProjectService)); }, token: UndoManagerService, providedIn: "root" });
    return UndoManagerService;
}());
export { UndoManagerService };
