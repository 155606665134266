/*!
 * Copyright 2020 Screencastify LLC
 */
import { AfterViewInit, ElementRef, OnDestroy, } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { makeQuillInstance } from '../../quill-config';
/**
 * This view component encapsulates Quill instantiation, Quill-related styles,
 * and some Quill lifecycle & user-interaction concerns. Source of data is
 * intended to be preview.component.ts (passed in via `.clip`)
 */
var QuillContainerViewComponent = /** @class */ (function () {
    function QuillContainerViewComponent(hostElm, ctrl) {
        this.hostElm = hostElm;
        this.ctrl = ctrl;
        this.subscriptions = new Subscription();
        this.cursorStyle = 'pointer';
        this.overflow = 'hidden';
        this._textBox = new BehaviorSubject(null);
        /**
         * Quill instance
         */
        this.quill = null;
    }
    Object.defineProperty(QuillContainerViewComponent.prototype, "textBox", {
        get: function () {
            return this._textBox.value;
        },
        /**
         * _textBox emits the data to render
         */
        set: function (box) {
            this._textBox.next(box);
        },
        enumerable: true,
        configurable: true
    });
    QuillContainerViewComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Initialize Quill instance
        this.quill = makeQuillInstance(this.hostElm.nativeElement.querySelector('[quill-editor-element]'), true, function () { });
        this.quill.setContents(this.textBox.delta, 'silent');
        // set up subscriptions to text box updates
        this.subscriptions.add(this._textBox.subscribe(function (box) {
            _this.setVerticalAlignment(box.verticalAlignment);
        }));
    };
    QuillContainerViewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * Quill renders contensts via flex box; will that flexbox
     * overflow on the top or on the bottom? We do this here
     * vs. the Angluar template because we need to alter a child of Quill's
     * root element not rendered in the template.
     */
    QuillContainerViewComponent.prototype.setVerticalAlignment = function (verticalAlignment) {
        var alignmentMap = {
            top: 'flex-start',
            center: 'center',
            bottom: 'flex-end',
        };
        var qlEditor = (this.hostElm.nativeElement.firstChild.firstChild);
        qlEditor.style.justifyContent = alignmentMap[verticalAlignment];
    };
    /**
     * Makes sure clicks don't make it up to drag handlers in the components
     * higher than this in the view, and opens the editor. Note this is not
     * intended to be attached to edit-mode quill components, and it is expected
     * it will be overridden.
     */
    QuillContainerViewComponent.prototype.onQuillMousedown = function (event) {
        event.stopPropagation();
        this.ctrl.open(this.textBox.id);
    };
    return QuillContainerViewComponent;
}());
export { QuillContainerViewComponent };
