/*!
 * Copyright 2020 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Rectangle } from '@castify/models';

@Component({
  selector: 'lib-shadow-box',
  templateUrl: './shadow-box.component.html',
  styleUrls: ['./shadow-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShadowBoxComponent {
  @ViewChild('shadowBox') shadowBox: ElementRef<HTMLDListElement>;
  @Input() set cutout(rect: Rectangle) {
    this.setCutout(rect);
  }

  constructor() {}

  setCutout(rect: Rectangle): void {
    if (!this.shadowBox) {
      return;
    }
    // set clipping path for shadow box (defines an inner cutout)
    this.shadowBox.nativeElement.style.clipPath = `polygon(
      0% 0%,
      0% 100%,
      ${rect.left * 100}% 100%,
      ${rect.left * 100}% ${rect.top * 100}%,
      ${rect.right * 100}% ${rect.top * 100}%,
      ${rect.right * 100}% ${rect.bottom * 100}%,
      ${rect.left * 100}% ${rect.bottom * 100}%,
      ${rect.left * 100}% 100%,
      100% 100%,
      100% 0%
    )`;
  }
}
