/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Limits, sty } from '@castify/models';
import { SideToolbarContentComponent } from 'lib-editor/lib/tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { ZoomEditorControllerService } from 'lib-editor/lib/zoom-editor/zoom-editor-controller.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-zoom-toolbar',
  templateUrl: './zoom-toolbar.component.html',
  styleUrls: ['./zoom-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomToolbarComponent implements OnInit, OnDestroy {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;
  readonly buildStepSize = 0.1;
  durationLimitHit = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();

  constructor(public ctrl: ZoomEditorControllerService) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([this.ctrl.buildIn, this.ctrl.buildOut])
        .pipe(
          map(
            ([buildIn, buildOut]) =>
              buildIn >= this.calcBuildInMax(buildOut) ||
              buildOut >= this.calcBuildOutMax(buildIn)
          )
        )
        .subscribe(this.durationLimitHit)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      await this._toolbarContainer.close();
    }
  }

  // buildIn logic
  readonly buildInMax: Observable<sty.Milliseconds> = this.ctrl.buildOut.pipe(
    map((buildOut) => this.calcBuildInMax(buildOut))
  );
  calcBuildInMax(buildOut: sty.Milliseconds): sty.Milliseconds {
    const maxBuildIn = this.ctrl.effectDuration - buildOut;
    return Math.round(
      Math.floor(maxBuildIn / 1000 / this.buildStepSize) *
        this.buildStepSize *
        1000
    );
  }
  setBuildIn(value: sty.Seconds): void {
    const limits = new Limits(0, this.calcBuildInMax(this.ctrl.buildOut.value));
    this.ctrl.buildIn.next(limits.apply(value * 1000));
  }

  // buildOut logic
  readonly buildOutMax: Observable<sty.Milliseconds> = this.ctrl.buildIn.pipe(
    map((buildIn) => this.calcBuildOutMax(buildIn))
  );
  calcBuildOutMax(buildIn: sty.Milliseconds): sty.Milliseconds {
    const maxBuildOut = this.ctrl.effectDuration - buildIn;
    return Math.round(
      Math.floor(maxBuildOut / 1000 / this.buildStepSize) *
        this.buildStepSize *
        1000
    );
  }
  setBuildOut(value: sty.Seconds): void {
    const limits = new Limits(0, this.calcBuildOutMax(this.ctrl.buildIn.value));
    this.ctrl.buildOut.next(limits.apply(value * 1000));
  }

  /**
   * closes the blur editor
   */
  closeEditor(): void {
    this.ctrl.save();
    this.ctrl.close();
  }
}
