/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component.ngfactory";
import * as i3 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component";
import * as i4 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "@angular/common";
import * as i14 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i15 from "@angular/material/button";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "./audio-toolbar.component";
import * as i18 from "../../audio-tool-controller.service";
var styles_AudioToolbarComponent = [i0.styles];
var RenderType_AudioToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioToolbarComponent, data: {} });
export { RenderType_AudioToolbarComponent as RenderType_AudioToolbarComponent };
export function View_AudioToolbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _toolbarContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 28, "lib-tool-sidebar-content", [], null, null, null, i2.View_SideToolbarContentComponent_0, i2.RenderType_SideToolbarContentComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.SideToolbarContentComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["id", "toolbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["music_video"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Audio"])), (_l()(), i1.ɵeld(9, 0, null, 0, 17, "div", [["id", "toolbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h4", [["id", "field-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Volume"])), (_l()(), i1.ɵeld(12, 0, null, null, 14, "div", [["class", "volume-slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 2, "div", [["class", "marker-container"], ["matTooltip", "Original Volume"], ["matTooltipClass", "medium-tooltip"], ["matTooltipPosition", "above"], ["matTooltipShowDelay", "500"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i10.AriaDescriber, i10.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { position: [0, "position"], showDelay: [1, "showDelay"], message: [2, "message"], tooltipClass: [3, "tooltipClass"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "vertical-marker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "input", [["class", "slider"], ["step", "0.1"], ["type", "range"]], [[8, "min", 0], [8, "max", 0], [8, "value", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setGain($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 278528, null, 0, i13.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "labels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(22, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["volume_off"])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(25, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["volume_up"])), (_l()(), i1.ɵeld(27, 0, null, 0, 2, "button", [["id", "done-button"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatButton_0, i14.RenderType_MatButton)), i1.ɵdid(28, 180224, null, 0, i15.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Done "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_2 = "above"; var currVal_3 = "500"; var currVal_4 = "Original Volume"; var currVal_5 = "medium-tooltip"; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_9 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.sliderGradient)); _ck(_v, 17, 0, currVal_9); _ck(_v, 22, 0); _ck(_v, 25, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).inline; var currVal_1 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_6 = _co.MIN_GAIN; var currVal_7 = _co.MAX_GAIN; var currVal_8 = i1.ɵunv(_v, 16, 2, i1.ɵnov(_v, 19).transform(_co.ctrl.gain)); _ck(_v, 16, 0, currVal_6, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 22).inline; var currVal_11 = (((i1.ɵnov(_v, 22).color !== "primary") && (i1.ɵnov(_v, 22).color !== "accent")) && (i1.ɵnov(_v, 22).color !== "warn")); _ck(_v, 21, 0, currVal_10, currVal_11); var currVal_12 = i1.ɵnov(_v, 25).inline; var currVal_13 = (((i1.ɵnov(_v, 25).color !== "primary") && (i1.ɵnov(_v, 25).color !== "accent")) && (i1.ɵnov(_v, 25).color !== "warn")); _ck(_v, 24, 0, currVal_12, currVal_13); var currVal_14 = (i1.ɵnov(_v, 28).disabled || null); var currVal_15 = (i1.ɵnov(_v, 28)._animationMode === "NoopAnimations"); _ck(_v, 27, 0, currVal_14, currVal_15); }); }
export function View_AudioToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-audio-toolbar", [], null, [["window", "mousedown"]], function (_v, en, $event) { var ad = true; if (("window:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeIfClickedOutside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AudioToolbarComponent_0, RenderType_AudioToolbarComponent)), i1.ɵdid(1, 49152, null, 0, i17.AudioToolbarComponent, [i18.AudioToolControllerService], null, null)], null, null); }
var AudioToolbarComponentNgFactory = i1.ɵccf("lib-audio-toolbar", i17.AudioToolbarComponent, View_AudioToolbarComponent_Host_0, {}, {}, []);
export { AudioToolbarComponentNgFactory as AudioToolbarComponentNgFactory };
