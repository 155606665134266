var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { environment } from 'environments/environment';
import { UserService } from '../auth/user.service';
import { GapiLoaderService } from './gapi-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/user.service";
import * as i2 from "./gapi-loader.service";
var DrivePickerService = /** @class */ (function () {
    function DrivePickerService(user, _gapiLoader) {
        this.user = user;
        this._gapiLoader = _gapiLoader;
        this._mimeTypes = [
            'video/mp4',
            'video/webm',
            'video/mkv',
            'video/flv',
            'video/f4v',
            'video/avi',
            'video/mov',
            'video/wmv',
            'video/mpg',
            'video/mpeg',
            'video/m2v',
            'video/m4v',
            'video/3gp',
            'video/3g2',
            'video/quicktime',
            'video/x-msvideo',
            'video/x-matrosk',
        ];
        this._shareClient = null;
    }
    /**
     * Show drive sharing settings dialog
     * @param ids file ids to change sharing settings for.
     */
    DrivePickerService.prototype.openShareDialog = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var gapi_1, client, authToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this._shareClient) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._gapiLoader.loadShareLibs()];
                    case 1:
                        _a.sent();
                        gapi_1 = window['gapi'];
                        this._shareClient = new gapi_1.drive.share.ShareClient(environment.auth.driveAppId);
                        _a.label = 2;
                    case 2:
                        client = this._shareClient;
                        return [4 /*yield*/, this.user.gapiAuth()];
                    case 3:
                        authToken = (_a.sent()).access_token;
                        client.setOAuthToken(authToken);
                        client.setItemIds(ids);
                        client.showSettingsDialog();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Open drive file picker dialog.
     */
    DrivePickerService.prototype.openPicker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var google, view, authToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._gapiLoader.loadPickerLibs()];
                    case 1:
                        _a.sent();
                        google = window['google'];
                        view = new google.picker.DocsView();
                        view.setMimeTypes(this._mimeTypes.join(','));
                        view.setIncludeFolders(true);
                        view.setOwnedByMe(true);
                        return [4 /*yield*/, this.user.gapiAuth()];
                    case 2:
                        authToken = (_a.sent()).access_token;
                        return [2 /*return*/, new Promise(function (resolve) {
                                var picker = new google.picker.PickerBuilder()
                                    // .enableFeature(google.picker.Feature.NAV_HIDDEN)
                                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                                    .setTitle('Select videos to import')
                                    .setAppId(environment.auth.driveAppId)
                                    .setOAuthToken(authToken)
                                    .addView(view)
                                    .addView(google.picker.ViewId.DOCS_VIDEOS)
                                    // .addView(new google.picker.DocsUploadView())
                                    .setCallback(function (result) {
                                    var picker = google.picker;
                                    if (result[picker.Response.ACTION] === picker.Action.PICKED) {
                                        resolve(result[picker.Response.DOCUMENTS]);
                                    }
                                    else if (result[picker.Response.ACTION] === picker.Action.CANCEL) {
                                        resolve(null);
                                    }
                                })
                                    .build();
                                picker.setVisible(true);
                            })];
                }
            });
        });
    };
    DrivePickerService.ngInjectableDef = i0.defineInjectable({ factory: function DrivePickerService_Factory() { return new DrivePickerService(i0.inject(i1.UserService), i0.inject(i2.GapiLoaderService)); }, token: DrivePickerService, providedIn: "root" });
    return DrivePickerService;
}());
export { DrivePickerService };
