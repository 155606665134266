/*!
 * Copyright 2020 Screencastify LLC
 */

import { Subscription } from 'rxjs';

export enum TrimSide {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface Trim {
  value: number;
  side: TrimSide;
  scrollSubscription: Subscription;
}
