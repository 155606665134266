/*!
 * Copyright 2020 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { Size } from '@castify/models';
import { ClipViewComponent } from 'lib-editor/lib/preview/components/clip-view/clip-view.component';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
// must be changed manually in css if changed here
var kCanvasBaseWidth = 1600;
var kCanvasBaseHeight = 900;
var kCanvasBaseDimensions = new Size(kCanvasBaseWidth, kCanvasBaseHeight);
var AnnotationsPreviewViewComponent = /** @class */ (function (_super) {
    __extends(AnnotationsPreviewViewComponent, _super);
    function AnnotationsPreviewViewComponent(ctrl) {
        var _this = _super.call(this) || this;
        _this.ctrl = ctrl;
        _this._textBoxes = new BehaviorSubject([]);
        // tells preview that this is always playable
        _this.canPlay = new BehaviorSubject(true);
        /**
         * Intended to emit whenever window resize takes place, but can also be
         * manually triggers to ensure resize happens at various points in lifecycle.
         */
        _this.allResizeEvents = new BehaviorSubject(null);
        /**
         * Emits a ref to the container element whenever a resize takes place
         * but only when the component is visible
         */
        _this.containerOnResize = _this.allResizeEvents.pipe(switchMap(function () { return of(_this.container); }), filter(function (container) { return !!container && !!container.nativeElement; }), map(function (el) { return el.nativeElement; }));
        /**
         * Emits a ref to the bounds element whenever a resize takes place,
         * but only when the component is visible
         */
        _this.boundsOnResize = _this.allResizeEvents.pipe(switchMap(function () { return of(_this.bounds); }), filter(function (bounds) { return !!bounds && !!bounds.nativeElement; }), map(function (el) { return el.nativeElement; }));
        /**
         * Dimensions of the container's parent. Intended for use in markup
         * to pass container dimensions into child components
         */
        _this.parentDims = _this.boundsOnResize.pipe(map(function (boundsElem) { return boundsElem.getBoundingClientRect(); }), distinctUntilChanged(function (prev, curr) {
            return Object.keys(prev).every(function (key) { return prev[key] === curr[key]; });
        }));
        /**
         * The dimensions of the canvas when scaled down to the
         * current size of the window/parent container. Both intended
         * for markup use, but can be used elsewhere
         */
        _this.scaledDims = _this.boundsOnResize.pipe(map(function (boundsElem) {
            return kCanvasBaseDimensions.scaleToFit(new Size(boundsElem.offsetWidth, boundsElem.offsetHeight));
        }));
        _this.scaledWidth = _this.scaledDims.pipe(map(function (dims) { return dims.width; }), distinctUntilChanged());
        _this.scaledHeight = _this.scaledDims.pipe(map(function (dims) { return dims.height; }), distinctUntilChanged());
        /**
         * Derived state from the scaled height; intended for use in markup.
         */
        _this.scaleFactor = _this.scaledWidth.pipe(map(function (scaledWidth) {
            return scaledWidth / kCanvasBaseWidth;
        }), startWith(1), 
        // don't allow zero
        filter(function (x) { return x !== 0; }), distinctUntilChanged());
        /**
         * The inverse of the scale factor.
         *
         * Necessary because width and height are expressed as a percentage of the
         * container, and the text-containing element must have its width and height
         * up before the element is scaled to size via CSS transforms.
         */
        _this.inverseScaleFactor = _this.scaleFactor.pipe(map(function (x) { return 1 / x; }));
        return _this;
    }
    Object.defineProperty(AnnotationsPreviewViewComponent.prototype, "clip", {
        /**
         * Overrides the setter on the parent class. Intended to be used in at
         * least three different ways: to pass in the clip by preview, in which
         * case data is passed once and only once during component's lifecycle;
         * by render-host when injecting data; by the Edit component whenever
         * data changes.
         */
        set: function (clip) {
            // possible for clip to be null in edge cases
            // where clip has been deleted from timeline
            // but is still being rendered by preview
            if (clip) {
                this._clip = clip;
                this._textBoxes.next(clip.boxes);
            }
            // Hacky fix, see note in seek()
            this.allResizeEvents.next(null);
        },
        enumerable: true,
        configurable: true
    });
    AnnotationsPreviewViewComponent.prototype.ngOnInit = function () { };
    AnnotationsPreviewViewComponent.prototype.ngAfterViewInit = function () { };
    AnnotationsPreviewViewComponent.prototype.ngOnDestroy = function () { };
    AnnotationsPreviewViewComponent.prototype.play = function () { };
    AnnotationsPreviewViewComponent.prototype.pause = function () { };
    AnnotationsPreviewViewComponent.prototype.seek = function (pos) { };
    Object.defineProperty(AnnotationsPreviewViewComponent.prototype, "startInScene", {
        get: function () {
            return this._clip.startInScene;
        },
        enumerable: true,
        configurable: true
    });
    AnnotationsPreviewViewComponent.prototype.onDisplay = function () {
        this.allResizeEvents.next(null);
    };
    AnnotationsPreviewViewComponent.prototype.onHide = function () { };
    /**
     * resizes container and editor to fit the video element and
     * scales text so it appears fixed on the video
     */
    AnnotationsPreviewViewComponent.prototype.resize = function () {
        this.allResizeEvents.next(null);
    };
    return AnnotationsPreviewViewComponent;
}(ClipViewComponent));
export { AnnotationsPreviewViewComponent };
