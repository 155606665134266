<div [ngSwitch]="activeModal | async">
  <lib-spinner-modal *ngSwitchCase="'spinner'"></lib-spinner-modal>
  <lib-drive-import-progress-modal
    *ngSwitchCase="'progress'"
    [progress]="brain.progress | async"
    [complete]="brain.complete | async"
    [importCount]="brain.importCount | async"
    (closeEvent)="close()"
  ></lib-drive-import-progress-modal>
  <lib-error-modal
    *ngSwitchCase="'error'"
    (closeEvent)="close()"
  ></lib-error-modal>
</div>
