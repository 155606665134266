var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SceneEditor2, StillClip } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService, } from '../timeline/timeline-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../preview/preview-state.service";
import * as i3 from "../common/undo-manager.service";
var StillClipToolControllerService = /** @class */ (function (_super) {
    __extends(StillClipToolControllerService, _super);
    function StillClipToolControllerService(timelineState, previewState, undoManager) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.previewState = previewState;
        _this.undoManager = undoManager;
        _this.logger = Log.create('StillClipToolControllerService');
        _this.backgroundColor = new BehaviorSubject(null);
        // update clip timeline from selection
        _this.timelineState.selection
            .pipe(map(function (selection) { return _this._getTargetClip(selection); }))
            .subscribe(_this.clip);
        // sync canActivate
        combineLatest([_this.clip, _this.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), clip = _b[0], isActive = _b[1];
            return !!clip && !isActive;
        }))
            .subscribe(_this.canActivate);
        return _this;
    }
    StillClipToolControllerService.prototype.open = function () {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening editor not fulfilled');
        if (!this.clip.value)
            throw new Error('Failed to open crop tool: no clip selected');
        this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
        this.backgroundColor.next(this.clip.value.backgroundColor);
        _super.prototype.open.call(this);
    };
    StillClipToolControllerService.prototype.close = function () {
        _super.prototype.close.call(this);
        this.backgroundColor.next(null);
        this.sceneEditor = null;
    };
    StillClipToolControllerService.prototype.save = function () {
        if (this.sceneEditor &&
            this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
            this.logger.info('save still clip');
            this.undoManager.update(this.sceneEditor.scene);
        }
    };
    /**
     * manipulate background color.
     */
    StillClipToolControllerService.prototype.setBackgroundColor = function (color) {
        var clip = this.clip.value;
        var clipEditor = this.sceneEditor.getClipEditor(clip);
        var newColor = clipEditor.setBackgroundColor(color);
        this.backgroundColor.next(newColor);
        this._updateIntermediateScene();
    };
    StillClipToolControllerService.prototype._updateIntermediateScene = function () {
        this.timelineState.overrideScene.next(this.sceneEditor.scene);
        this.previewState.overrideScene.next(this.sceneEditor.scene);
    };
    StillClipToolControllerService.prototype._getTargetClip = function (timelineSelection) {
        return (timelineSelection.find(function (clip) { return clip instanceof StillClip; }) ||
            null);
    };
    StillClipToolControllerService.ngInjectableDef = i0.defineInjectable({ factory: function StillClipToolControllerService_Factory() { return new StillClipToolControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.PreviewStateService), i0.inject(i3.UndoManagerService)); }, token: StillClipToolControllerService, providedIn: "root" });
    return StillClipToolControllerService;
}(SingleClipEditorControllerService));
export { StillClipToolControllerService };
