/*!
 * Copyright 2020 Screencastify LLC
 */
import { environment } from 'environments/environment';
import { RemoteLoggerService } from './remote-logger/remote-logger.service';
var AppModule = /** @class */ (function () {
    function AppModule(_remoteLogger) {
        this._remoteLogger = _remoteLogger;
        this._remoteLogger.install({
            appName: 'EDIT_UI',
            buildId: environment.version,
        });
    }
    return AppModule;
}());
export { AppModule };
