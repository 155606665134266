/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of as observableOf } from 'rxjs';

/**
 * Only allow with Chrome for now.
 */
@Injectable()
export class BrowserGuard implements CanActivate {
  constructor(private _router: Router) {}

  /**
   * Extract major chrome version from user agent string.
   * @return major chrome Version or 0 if it cannot be determined.
   */
  getChromeVersion(): number {
    return Number(
      (/Chrome\/(\d+)/.exec(window.navigator.userAgent) || [undefined, 0])[1]
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isChrome = !!window['chrome'] && this.getChromeVersion() >= 60;
    if (!isChrome) {
      this._router.navigate(['/unsupported_browser'], {
        skipLocationChange: true,
      });
    }
    return observableOf(isChrome);
  }
}
