var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AnnotationsClip, VideoClip, } from '@castify/edit-models';
import { kQuillDefaultFontName, kQuillDefaultFontSize, kQuillDefaultTextColor, } from '../annotations-tool/quill-config';
import * as i0 from "@angular/core";
var StatsHelperService = /** @class */ (function () {
    function StatsHelperService() {
    }
    /**
     * Generate a string map of count of each type
     */
    StatsHelperService.prototype.countClipTypes = function (scene) {
        var typesCount = {};
        scene.clips.items.forEach(function (clip) {
            if (!typesCount[clip.type])
                typesCount[clip.type] = 0;
            typesCount[clip.type]++;
        });
        typesCount.total = scene.clips.items.length;
        return typesCount;
    };
    /**
     * Count the total number of a certain effect used in the project
     */
    StatsHelperService.prototype.countEffects = function (scene, type) {
        return scene.clips.items
            .map(function (clip) {
            var count = 0;
            if (clip instanceof VideoClip) {
                clip.effects.items.forEach(function (effect) {
                    if (effect.type === type)
                        count++;
                });
            }
            return count;
        })
            .reduce(function (accumulator, reducer) { return accumulator + reducer; }, 0);
    };
    /**
     * Count the total number of a certain effect used in the project
     */
    StatsHelperService.prototype.countCrops = function (scene) {
        return scene.clips.items
            .map(function (clip) {
            var count = 0;
            if (clip instanceof VideoClip && !!clip.transform.crop) {
                count++;
            }
            return count;
        })
            .reduce(function (accumulator, reducer) { return accumulator + reducer; }, 0);
    };
    /**
     * Gets two fibanocci numbers that the value is in between
     * e.g. (11) => [8, 13]
     */
    StatsHelperService.prototype.getValueBucket = function (value) {
        var lower = 0;
        var upper = 1;
        var tempUpper = upper;
        // bucket based on fibanocci sequence because it can scale up infinitely
        while (value > upper) {
            tempUpper = upper;
            upper += lower;
            lower = tempUpper;
        }
        return [lower, upper];
    };
    /**
     * Similar to getValueBucket but converts it to seconds, minutes, or hours
     */
    StatsHelperService.prototype.getDurationBucket = function (duration) {
        var _a = __read(this.getValueBucket(duration / 1000), 2), lower = _a[0], upper = _a[1];
        var bounds = [lower, upper].map(function (v) {
            if (v > 3600) {
                return Math.round((v / 3600) * 100) / 100 + "hours";
            }
            else if (v > 60) {
                return Math.round((v / 60) * 100) / 100 + "minutes";
            }
            else {
                return v + "seconds";
            }
        });
        return "between " + bounds[0] + "-" + bounds[1];
    };
    /**
     * Returns an array representing the number of formats in each text box.
     * NOTE: this doesn't account for alignment change because alignment is a separate delta
     */
    StatsHelperService.prototype.countFormats = function (scene) {
        var _this = this;
        var formatCountPerBox = [];
        scene.clips.items.forEach(function (clip) {
            if (clip instanceof AnnotationsClip) {
                clip.boxes.map(function (box) {
                    var formats = new Set();
                    box.delta.forEach(function (delta) {
                        // copy to avoid mutation
                        var d = JSON.parse(JSON.stringify(delta));
                        // the alignment are assigned on the next line, causing duplicate counts
                        if (d.insert === '\n')
                            return;
                        _this._setDefaults(d);
                        var attributes = Object.entries(d.attributes);
                        attributes.sort(function (a, b) { return (a[0] > b[0] ? -1 : 1); });
                        formats.add(JSON.stringify(attributes));
                    });
                    formatCountPerBox.push(formats.size);
                });
            }
        });
        return formatCountPerBox;
    };
    /**
     * set delta operation defaults so when they are blank it coincidentally matches values set by user
     * {} === {color: kQuillDefaultTextColor, font: kQuillDefaultFontName, size: kQuillDefaultFontSize}
     */
    StatsHelperService.prototype._setDefaults = function (delta) {
        delta.attributes = delta.attributes || {};
        delta.attributes.color = delta.attributes.color || kQuillDefaultTextColor;
        delta.attributes.font = delta.attributes.font || kQuillDefaultFontName;
        delta.attributes.size =
            delta.attributes.size || kQuillDefaultFontSize.toString() + 'px';
    };
    StatsHelperService.ngInjectableDef = i0.defineInjectable({ factory: function StatsHelperService_Factory() { return new StatsHelperService(); }, token: StatsHelperService, providedIn: "root" });
    return StatsHelperService;
}());
export { StatsHelperService };
