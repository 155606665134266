/*!
 * Copyright 2019 Screencastify LLC
 */
import { BehaviorSubject } from 'rxjs';
var ClipViewComponent = /** @class */ (function () {
    function ClipViewComponent() {
        this._currentTime = 0;
        // indicates playback state. Implementations should make sure this fires whenever pausing for any reason.
        this.paused = new BehaviorSubject(true);
        // indicates whether enough data is buffered
        this.canPlay = new BehaviorSubject(false);
    }
    Object.defineProperty(ClipViewComponent.prototype, "clip", {
        set: function (clip) {
            this._clip = clip;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClipViewComponent.prototype, "currentTime", {
        // not using Subject because HTMLMediaElement has nothing like an "currentTimeChange" event.
        get: function () {
            return this._currentTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClipViewComponent.prototype, "syncPriority", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClipViewComponent.prototype, "startInScene", {
        get: function () {
            throw new Error('not implemented');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * start playback
     */
    ClipViewComponent.prototype.play = function () {
        throw new Error('not implemented');
    };
    /**
     * pause at current position
     */
    ClipViewComponent.prototype.pause = function () {
        throw new Error('not implemented');
    };
    /**
     * perform precise seek to pos
     */
    ClipViewComponent.prototype.seek = function (pos) {
        throw new Error('not implemented');
    };
    /**
     * start displaying
     */
    ClipViewComponent.prototype.onDisplay = function () {
        return;
    };
    /**
     * end displaying
     */
    ClipViewComponent.prototype.onHide = function () {
        return;
    };
    /**
     * sync playback to other view. This should update internal playbackRate to
     * approach refPos (assuming refPos plays at constant rate).
     *
     * @param refPos  value of null indicates that clip is syncRef and should approach playbackRate=1
     * @returns current error to actual playheadPos (currentTime - refPos)
     */
    ClipViewComponent.prototype.sync = function (refPos) {
        this._currentTime = refPos;
        return this.currentTime - refPos;
    };
    /**
     * Buffer segments within time interval [tStart, tStart+duration]
     */
    ClipViewComponent.prototype.buffer = function (tStart, duration) {
        return;
    };
    return ClipViewComponent;
}());
export { ClipViewComponent };
