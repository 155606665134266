/*!
 * Copyright 2020 Screencastify LLC
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment as base } from './environment.base';
export var environment = __assign({}, base, { castifyOpenUrl: 'chrome-extension://hodjlilmbhaajegmnpfcpoehlbnlfcne/app.html#/files/', castifyAccountUrl: 'https://app.staging.screencastify.com/account', watchURL: 'https://watch.staging.screencastify.com/v/', myVideosURL: 'https://app.staging.screencastify.com/videos/', 
    // NOTE: auth is handled by screencastify-staging project, because that is where the client api is hosted
    auth: __assign({}, base.auth, { signInUrl: 'https://app.staging.screencastify.com/editor-auth', clientId: '741471751866-vlfbvujeo7de30i720k40lu5gqcpkmj8.apps.googleusercontent.com', driveAppId: '741471751866', apiURL: 'https://screencastify-staging.appspot.com/api/client_auth' }), firebaseConfig: {
        apiKey: 'AIzaSyCRL9ct_efTzLV7xsiwwSJbHqvO_8plOGQ',
        authDomain: 'castify-edit-staging.firebaseapp.com',
        databaseURL: 'https://castify-edit-staging.firebaseio.com',
        projectId: 'castify-edit-staging',
        storageBucket: 'castify-edit-staging.appspot.com',
        messagingSenderId: '505548110384',
    }, stackdriverApiKey: 'AIzaSyDV75yAEEZw7BiD-685uukmiGZhI_jyQ3k' });
