/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { MoveIconComponent } from './components/move-icon/move-icon.component';
import { ShadowBoxComponent } from './components/shadow-box/shadow-box.component';
import { DragHandleDirective } from './directives/drag-handle.directive';

const kExportComponents = [
  MoveIconComponent,
  DragHandleDirective,
  ShadowBoxComponent,
];

@NgModule({
  declarations: [...kExportComponents],
  imports: [CommonModule, MatIconModule],
  exports: kExportComponents,
})
export class LibToolCommonModule {}
