/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material';
import { BrowserGuard } from './browser-guard';
import { BrowserGuardRoutingModule } from './browser-guard-routing.module';
import { BrowserGuardComponent } from './components/browser-guard/browser-guard.component';

@NgModule({
  imports: [CommonModule, BrowserGuardRoutingModule, MatToolbarModule],
  exports: [],
  providers: [BrowserGuard],
  declarations: [BrowserGuardComponent],
})
export class BrowserGuardModule {}
