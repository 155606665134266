/*!
 * Copyright 2018 Screencastify LLC
 */
/**
 * Global registry for all keyboard shortcuts.
 */
// Human readable name for mod key, same as in MouseTrap
var kModHumanName = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? 'cmd'
    : 'ctrl';
var KeyboardShortcut = /** @class */ (function () {
    function KeyboardShortcut(combo, desc) {
        if (desc === void 0) { desc = ''; }
        this.desc = desc;
        if (typeof combo === 'string')
            this.combo = [combo];
        else
            this.combo = combo;
    }
    Object.defineProperty(KeyboardShortcut.prototype, "primaryCombo", {
        get: function () {
            return this.combo[0];
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Keyboard combo as human-readable string for showing in tooltips etc.
     * Returns capitalized combo string like: Ctrl+Z, Del...
     */
    KeyboardShortcut.prototype.humanCombo = function () {
        return this.primaryCombo
            .replace('mod', kModHumanName)
            .split('+')
            .map(function (key) { return key[0].toUpperCase() + key.slice(1); })
            .join('+');
    };
    /**
     * Description with combo in brakets
     */
    KeyboardShortcut.prototype.descWithCombo = function () {
        return this.desc + " (" + this.humanCombo() + ")";
    };
    return KeyboardShortcut;
}());
export { KeyboardShortcut };
export var keyboardShortcuts = {
    cut: new KeyboardShortcut('mod+b', 'Cut'),
    del: new KeyboardShortcut(['del', 'backspace'], 'Delete'),
    undo: new KeyboardShortcut('mod+z', 'Undo'),
    zoom: new KeyboardShortcut('mod+shift+z', 'Zoom'),
    redo: new KeyboardShortcut(['mod+y', 'mod+shift-z'], 'Redo'),
    crop: new KeyboardShortcut('mod+shift+c', 'Crop'),
    add: new KeyboardShortcut('mod+a', 'Add clip'),
    text: new KeyboardShortcut('mod+o', 'Add text'),
    space: new KeyboardShortcut('space', 'Play/Pause'),
    blur: new KeyboardShortcut('mod+shift+b', 'Blur'),
    frame_forward: new KeyboardShortcut('right', 'Step one frame forward'),
    frame_back: new KeyboardShortcut('left', 'Step one frame backward'),
    crop_apply: new KeyboardShortcut(['enter', 'esc'], 'Apply crop'),
    back: new KeyboardShortcut(['esc'], 'Go back'),
};
