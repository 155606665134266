<lib-tool-sidebar-content>
  <div id="toolbar-header">
    <mat-icon>crop</mat-icon>
    <h2>Crop Clip</h2>
  </div>

  <div id="toolbar-content"></div>

  <button id="done-button" mat-stroked-button (click)="closeEditor()">
    Done
  </button>
</lib-tool-sidebar-content>
