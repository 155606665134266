var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SceneEditor2, VideoClip, ZoomEffect, } from '@castify/edit-models';
import { Limits } from '@castify/models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { EffectSelection, TimelineStateService, } from '../timeline/timeline-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../common/undo-manager.service";
import * as i3 from "../preview/preview-state.service";
var log = Log.create('ZoomEditorControllerService');
var ZoomEditorControllerService = /** @class */ (function (_super) {
    __extends(ZoomEditorControllerService, _super);
    function ZoomEditorControllerService(timelineState, undoManager, previewState) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.undoManager = undoManager;
        _this.previewState = previewState;
        _this.zoomBox = new BehaviorSubject({ left: 0, top: 0, zoom: 1 });
        _this.buildIn = new BehaviorSubject(null);
        _this.buildOut = new BehaviorSubject(null);
        _this._zoomSelection = new BehaviorSubject(undefined);
        // filter selection for zoom effect selected
        _this.timelineState.selection
            .pipe(map(function (sel) { return _this.filterZoomSelection(sel); }))
            .subscribe(_this._zoomSelection);
        // sync clip from selection
        _this.timelineState.selection
            .pipe(map(function (sel) {
            var zoomSelection = _this.filterZoomSelection(sel);
            if (!!zoomSelection)
                return zoomSelection.clip;
            var clip = sel.find(function (s) { return s instanceof VideoClip; });
            if (clip)
                return clip;
            return null;
        }))
            .subscribe(_this.clip);
        // sync canActivate
        combineLatest([_this.clip, _this.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), clip = _b[0], isActive = _b[1];
            return !!clip && !isActive;
        }))
            .subscribe(_this.canActivate);
        return _this;
    }
    Object.defineProperty(ZoomEditorControllerService.prototype, "effectDuration", {
        get: function () {
            if (!this._editingData)
                return undefined;
            return this._editingData.effect.duration;
        },
        enumerable: true,
        configurable: true
    });
    ZoomEditorControllerService.prototype.applyZoomLimits = function (zoom) {
        return new Limits(1, 10).apply(zoom);
    };
    ZoomEditorControllerService.prototype.setZoomBox = function (box) {
        // TODO: this should be done in VideoEditor
        // enforce limits
        box.zoom = this.applyZoomLimits(box.zoom);
        box.top = new Limits(0, 1 - 1 / box.zoom).apply(box.top);
        box.left = new Limits(0, 1 - 1 / box.zoom).apply(box.left);
        // update zoom box
        this.zoomBox.next(box);
    };
    ZoomEditorControllerService.prototype.open = function () {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening zoom editor not fulfilled');
        var clip = this.clip.value;
        if (!clip)
            throw new Error('Failed to open zoom editor: no clip selected');
        // determine value for this._editingData
        if (!!this._zoomSelection.value) {
            // edit existing clip
            var selection = this._zoomSelection.value;
            this._editingData = {
                clip: selection.clip,
                effect: selection.effect,
                playhead: this.previewState.playhead.value,
            };
        }
        else {
            var scene_1 = this.undoManager.scene.value;
            // add new clip
            var newEffect = new SceneEditor2(scene_1)
                .getClipEditor(clip)
                .addZoom(this.previewState.playhead.value - clip.startInScene, 4000, {
                left: 0.25,
                top: 0.25,
                zoom: 2,
                buildIn: 500,
                buildOut: 500,
            });
            // save clip
            if (!!newEffect) {
                this.undoManager.update(scene_1, 'add zoom');
            }
            else {
                throw Error('zoom could not be added');
            }
            // update properties
            this._editingData = {
                clip: clip,
                playhead: this.previewState.playhead.value,
                effect: newEffect,
            };
            this.timelineState.selection.next([
                new EffectSelection({ clip: clip, effect: newEffect }),
            ]);
        }
        // set override scene
        var scene = this.undoManager.scene.value.filterClipType(['video']).copy(); // remove text clips
        var overrideClip = scene.clips.byId(this._editingData.clip.id);
        overrideClip.effects = overrideClip.effects.filterType(['blur']);
        this.previewState.overrideScene.next(scene);
        // init editor
        this.zoomBox.next({
            left: this._editingData.effect.left,
            top: this._editingData.effect.top,
            zoom: this._editingData.effect.zoom,
        });
        this.buildIn.next(this._editingData.effect.buildIn);
        this.buildOut.next(this._editingData.effect.buildOut);
        _super.prototype.open.call(this);
    };
    ZoomEditorControllerService.prototype.close = function () {
        _super.prototype.close.call(this);
        this._editingData = undefined;
    };
    ZoomEditorControllerService.prototype.save = function () {
        if (!this._editingData)
            return;
        var zoomChanges = {
            left: this.zoomBox.value.left,
            top: this.zoomBox.value.top,
            zoom: this.zoomBox.value.zoom,
            buildIn: this.buildIn.value,
            buildOut: this.buildOut.value,
        };
        var scene = this.undoManager.scene.value;
        // manipulate existing effect
        if (zoomChanges.buildIn !== this._editingData.effect.buildIn ||
            zoomChanges.buildOut !== this._editingData.effect.buildOut ||
            zoomChanges.left !== this._editingData.effect.left ||
            zoomChanges.top !== this._editingData.effect.top ||
            zoomChanges.zoom !== this._editingData.effect.zoom) {
            new SceneEditor2(scene)
                .getClipEditor(this._editingData.clip)
                .changeZoom(this._editingData.effect, zoomChanges);
            this.undoManager.update(scene, 'change zoom');
            log.info('zoom saved');
        }
    };
    ZoomEditorControllerService.prototype.filterZoomSelection = function (selection) {
        return ((selection.find(function (s) {
            return s instanceof EffectSelection &&
                !!s.clip &&
                s.effect instanceof ZoomEffect;
        })) || null);
    };
    Object.defineProperty(ZoomEditorControllerService.prototype, "_effectStart", {
        get: function () {
            return !!this._editingData
                ? this._editingData.playhead -
                    this._editingData.clip.startInFile -
                    this._editingData.clip.startInFile
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    ZoomEditorControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ZoomEditorControllerService_Factory() { return new ZoomEditorControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.UndoManagerService), i0.inject(i3.PreviewStateService)); }, token: ZoomEditorControllerService, providedIn: "root" });
    return ZoomEditorControllerService;
}(SingleClipEditorControllerService));
export { ZoomEditorControllerService };
