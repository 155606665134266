/*!
 * Copyright 2018 Screencastify LLC
 */
import { Router, } from '@angular/router';
import { of as observableOf } from 'rxjs';
/**
 * Only allow with Chrome for now.
 */
var BrowserGuard = /** @class */ (function () {
    function BrowserGuard(_router) {
        this._router = _router;
    }
    /**
     * Extract major chrome version from user agent string.
     * @return major chrome Version or 0 if it cannot be determined.
     */
    BrowserGuard.prototype.getChromeVersion = function () {
        return Number((/Chrome\/(\d+)/.exec(window.navigator.userAgent) || [undefined, 0])[1]);
    };
    BrowserGuard.prototype.canActivate = function (route, state) {
        var isChrome = !!window['chrome'] && this.getChromeVersion() >= 60;
        if (!isChrome) {
            this._router.navigate(['/unsupported_browser'], {
                skipLocationChange: true,
            });
        }
        return observableOf(isChrome);
    };
    return BrowserGuard;
}());
export { BrowserGuard };
