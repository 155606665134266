/*!
 * Copyright 2020 Screencastify LLC
 */

import { ElementRef } from '@angular/core';
import { unitLimit } from '@castify/models';
import { Limits, Rectangle, Size, sty } from '@castify/models';

/**
 * apply a rectangle defined in unit values to an html element by setting it's
 * width, height, top and left properties to corresponding percentage values
 */
export function applyPosition(
  elm: ElementRef<HTMLElement>,
  pos: {
    width?: sty.UnitValue;
    height?: sty.UnitValue;
    top?: sty.UnitValue;
    left?: sty.UnitValue;
  }
): void {
  const style = elm.nativeElement.style;
  if (pos.width || pos.width === 0) {
    style.width = `${pos.width * 100}%`;
  }
  if (pos.height || pos.height === 0) {
    style.height = `${pos.height * 100}%`;
  }
  if (pos.top || pos.top === 0) {
    style.top = `${pos.top * 100}%`;
  }
  if (pos.left || pos.left === 0) {
    style.left = `${pos.left * 100}%`;
  }
}

/**
 * resizes a unit rectangle by moving it from one corner. Parameters topFixed and leftFixed define which corner of the
 * rectangle can move freely. This corner is shifted (shiftX, shiftY) relative to the startRect.
 * @param topFixed toogles between top and bottom staying fixed
 * @param leftFixed analogous to topFixed
 * @param minDims sets a lower limit for dimensions of the returned rectangle
 */
export function resizeUnitRect(
  startRect: Rectangle,
  shiftX: sty.UnitValue,
  shiftY: sty.UnitValue,
  topFixed: boolean,
  leftFixed: boolean,
  minDims: Size = new Size(0, 0)
): Rectangle {
  const right = unitLimit.apply(
    startRect.left + startRect.width + (leftFixed ? shiftX : 0)
  );
  const left = unitLimit.apply(startRect.left + (leftFixed ? 0 : shiftX));
  const width = new Limits(minDims.width, 1).apply(right - left);

  const bottom = unitLimit.apply(
    startRect.top + startRect.height + (topFixed ? shiftY : 0)
  );
  const top = unitLimit.apply(startRect.top + (topFixed ? 0 : shiftY));
  const height = new Limits(minDims.height, 1).apply(bottom - top);

  return new Rectangle(
    new Limits(0, leftFixed ? 1 : right - width).apply(left),
    new Limits(0, topFixed ? 1 : bottom - height).apply(top),
    width,
    height
  );
}
