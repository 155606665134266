/*!
 * Copyright 2020 Screencastify LLC
 */

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { environment } from 'environments/environment';
import {
  LibAuthModule,
  LibPreviewModule,
  LibTimelineModule,
  LibToolbarModule,
  LibToolSidebarModule,
  LoggerAdapter,
} from 'lib-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserGuardModule } from './browser-guard/browser-guard.module';
import { EditLoggerAdapter } from './remote-logger/edit-logger-adapter';
import { RemoteLoggerService } from './remote-logger/remote-logger.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    BrowserGuardModule,
    LibAuthModule,

    // HACK: these modules from lib-ediotr are neeed to have editor-tools available in the root injector (rendering dynamic components
    //    fails without that). we should be able to just remove these once we're running anguler 9 according to
    //    this: https://github.com/angular/angular/issues/14324
    LibPreviewModule,
    LibTimelineModule,
    LibToolbarModule,
    LibToolSidebarModule,
  ],
  providers: [
    {
      provide: LoggerAdapter,
      useClass: EditLoggerAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private _remoteLogger: RemoteLoggerService) {
    this._remoteLogger.install({
      appName: 'EDIT_UI',
      buildId: environment.version,
    });
  }
}
