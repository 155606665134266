/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import {
  kEditorColors,
  kQuillDefaultTextColor,
  NonToggleableAttribute as nonToggleable,
} from '../../quill-config';

/**
 * The three states for the color tab widget:
 * nothing shown, text colors shown, bg colors shown
 */
enum ColorTabNames {
  text = 'text',
  background = 'background',
  border = 'border',
  none = 'none',
}

@Component({
  selector: 'lib-annotations-sidebar-colors',
  templateUrl: './annotations-sidebar-colors.component.html',
  styleUrls: ['./annotations-sidebar-colors.component.scss'],
})
export class AnnotationsSidebarColorsComponent implements OnInit {
  /**
   * Expose constants to the template
   */
  colorTabNames = ColorTabNames;
  editorColors = kEditorColors;

  /**
   * Emits which color-swatch tab is open
   */
  activeColorTab = new BehaviorSubject<ColorTabNames>(ColorTabNames.none);

  /**
   * Attribute observables
   */
  color = this.ctrl
    .nonToggleableObsFactory<string>(nonToggleable.color, true)
    .pipe(startWith(kQuillDefaultTextColor));
  bgColor = this.ctrl.activeBox.pipe(
    filter((box) => !!box),
    map((box) => box.backgroundColor)
  );
  borderColor = this.ctrl.activeBox.pipe(
    filter((box) => !!box),
    map((box) => box.borderColor)
  );

  constructor(private ctrl: AnnotationsToolControllerService) {}

  ngOnInit() {}

  /**
   * Allows changing the active color swatch tab
   */
  public setActiveColorTab(tabName: string): void {
    this.activeColorTab.next(<ColorTabNames>tabName);
  }

  /**
   * Handle clicks on color tab buttons. If tab  is already open, close it;
   * otherwise open it.
   */
  public handleTabButtonClick(thisTabName: ColorTabNames): void {
    if (this.activeColorTab.value === thisTabName) {
      this.activeColorTab.next(ColorTabNames.none);
    } else {
      this.activeColorTab.next(thisTabName);
    }
  }

  /**
   * Non-toggleable attribute setters
   */
  public setColor(color: string): void {
    this.ctrl.triggerSettable(nonToggleable.color, color);
  }

  public setBackgroundColor(bgColor: string): void {
    this.ctrl.editAnnotation((editor) =>
      editor.setBackgroundColor(this.ctrl.selectedBoxId.value, bgColor)
    );

    // update last seen styles so this change can be copied to
    // new boxes
    this.ctrl.persistSelectedBoxToLastSeenStyles();

    // refocus quill after each interaction
    setTimeout(() => {
      this.ctrl.activeQuill.value.focus();
    }, 0);
  }

  public setBorderColor(borderColor: string): void {
    this.ctrl.editAnnotation((editor) =>
      editor.setBorderColor(this.ctrl.selectedBoxId.value, borderColor)
    );

    // update last seen styles so this change can be copied to
    // new boxes
    this.ctrl.persistSelectedBoxToLastSeenStyles();

    // refocus quill after each interaction
    setTimeout(() => {
      this.ctrl.activeQuill.value.focus();
    }, 0);
  }
}
