<div
  id="container"
  @open
  [@close]="(triggerCloseAnimation | async) || false"
  (@open.start)="onOpenAnimationDone()"
  (@close.done)="onCloseAnimationDone($event)"
  (@close.start)="onCloseAnimationStart()"
>
  <ng-content></ng-content>
</div>
