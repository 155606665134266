/*!
 * Copyright 2018 Screencastify LLC
 */
import { TimelineComponent } from './components/timeline/timeline.component';
var kExportComponents = [TimelineComponent];
var LibTimelineModule = /** @class */ (function () {
    function LibTimelineModule() {
    }
    return LibTimelineModule;
}());
export { LibTimelineModule };
