/*!
 * Copyright 2019 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { FisProject, SceneModel2 } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private _log = Log.create('ProjectService');
  readonly project = new BehaviorSubject<FisProject | null>(null);

  constructor(private user: UserService) {
    this.user.user.subscribe((signIn) =>
      this._onUserChange(signIn ? signIn : null)
    );
  }

  /**
   * create an empty project for currently logged in user
   */
  async loadNewProject(): Promise<FisProject> {
    const userId = this.user.currentUser.uid;

    // try to mark current project as deleted
    try {
      if (this.project.value) {
        await this.project.value.markAsDeleted();
      } else {
        const project = await FisProject.root.getLatestByUser(userId);
        if (project) await project.markAsDeleted();
      }
    } catch (err) {
      this._log.error(err);
    }

    const project = FisProject.create<FisProject>({
      userId,
      scene: new SceneModel2(),
    });
    await project.save();
    this.project.next(project);
    this._log.info('New project created');
    return project;
  }

  async loadLatestProject(): Promise<FisProject | null> {
    let project = await FisProject.root.getLatestByUser(
      this.user.currentUser.uid
    );

    if (project) {
      this.project.next(project);
      await project.updateLastUsed();
    } else {
      project = await this.loadNewProject();
    }
    this._log.info('Latest project loaded');
    return project;
  }

  protected _onUserChange(user?: firebase.User): void {
    // unset project when user signs out
    if (!user) {
      if (this.project.value) this.project.next(null);
      return;
    }
  }
}
