/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, ViewChild } from '@angular/core';
import { SideToolbarContentComponent } from 'lib-editor/lib/tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { InjectableToolbarComponent } from 'lib-editor/lib/tool-sidebar/components/tool-sidebar/tool-sidebar.component';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { kMaxTextBoxes } from '../../quill-config';

@Component({
  selector: 'lib-annotations-sidebar',
  templateUrl: './annotations-sidebar.component.html',
  styleUrls: ['./annotations-sidebar.component.scss'],
})
export class AnnotationsSidebarComponent implements InjectableToolbarComponent {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;
  kMaxTextBoxes = kMaxTextBoxes;

  isBoxFront = combineLatest([this.ctrl.clip, this.ctrl.activeBox]).pipe(
    filter(([clip]) => !!clip),
    map(([clip, box]) => {
      return box.id === clip.getFrontBox().id;
    }),
    distinctUntilChanged()
  );

  isBoxBack = combineLatest([this.ctrl.clip, this.ctrl.activeBox]).pipe(
    filter(([clip]) => !!clip),
    map(([clip, box]) => {
      return box.id === clip.getBackBox().id;
    }),
    distinctUntilChanged()
  );

  constructor(public ctrl: AnnotationsToolControllerService) {}

  /**
   * Closes the toolbar. Intended to be called in template.
   */
  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      this.ctrl.save();
      await this._toolbarContainer.close();
      this.ctrl.close();
    }
  }

  /**
   * Adds a new text box. Intended to be called in template.
   */
  public onAddClick(): void {
    this.ctrl.addNewBoxUsingPersistedStyles();
  }

  /**
   * Moves the selected text box in front of other text boxes.
   */
  public onMoveToFrontClick(): void {
    this.ctrl.moveTextBoxToFront();
  }

  /**
   * Moves the selected text box behind other text boxes.
   */
  public onMoveToBackClick(): void {
    this.ctrl.moveTextBoxToBack();
  }

  /**
   * Intended for use on background div for sidebar. Makes sure that misclicks
   * do not result in closing the editor element
   */
  preventDeselection(event: MouseEvent): void {
    event.preventDefault();
  }
}
