/*!
 * Copyright 2020 Screencastify LLC
 */
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { kQuillDefaultAlignment } from '../../quill-config';
var TextPlaceholderComponent = /** @class */ (function () {
    function TextPlaceholderComponent() {
        var _this = this;
        this._textBox = new BehaviorSubject(null);
        this.shouldRender = this._textBox.pipe(map(function (textBox) { return !textBox.touched; }), distinctUntilChanged());
        this.verticalAlignment = this._textBox.pipe(map(function (textBox) { return _this.getVerticalAlignment(textBox); }), distinctUntilChanged());
        this.horizontalAlignment = this._textBox.pipe(map(function (textBox) { return _this.getHorizontalAlignment(textBox); }), distinctUntilChanged());
    }
    Object.defineProperty(TextPlaceholderComponent.prototype, "textBox", {
        // the data used to decide whether/where to render a placeholder
        set: function (box) {
            this._textBox.next(box);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Maps from the vertical alignments to flexbox
     * style strings. Intended to help with placeholder
     * positioning in the templates.
     */
    TextPlaceholderComponent.prototype.getVerticalAlignment = function (textBox) {
        var alignmentMap = {
            top: 'flex-start',
            center: 'center',
            bottom: 'flex-end',
        };
        return alignmentMap[textBox.verticalAlignment];
    };
    /**
     *
     */
    TextPlaceholderComponent.prototype.getHorizontalAlignment = function (textBox) {
        return textBox &&
            textBox.delta &&
            textBox.delta[0] &&
            textBox.delta[0].attributes &&
            textBox.delta[0].attributes.align
            ? textBox.delta[0].attributes.align
            : kQuillDefaultAlignment;
    };
    return TextPlaceholderComponent;
}());
export { TextPlaceholderComponent };
