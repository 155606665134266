/*!
 * Copyright 2020 Screencastify LLC
 */
import { SideToolbarContentComponent } from './components/tool-sidebar-content/tool-sidebar-content.component';
import { SideToolbarComponent } from './components/tool-sidebar/tool-sidebar.component';
var exportDeclarations = [SideToolbarComponent, SideToolbarContentComponent];
var LibToolSidebarModule = /** @class */ (function () {
    function LibToolSidebarModule() {
    }
    return LibToolSidebarModule;
}());
export { LibToolSidebarModule };
