/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-editor-logo',
  templateUrl: './editor-logo.component.html',
  styleUrls: ['./editor-logo.component.scss'],
})
export class EditorLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
