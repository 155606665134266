/*!
 * Copyright 2020 Screencastify LLC
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SceneModel2 } from '@castify/edit-models';
import { Limits, mod } from '@castify/models';
import { Log } from 'ng2-logger/browser';
import * as uuid from 'uuid/v4';
var log = Log.create('UndoStore');
/**
 * key of local storage undo buffer
 */
export var kUndoStorageKey = '_undo';
/**
 * Undo/Redo state storage that is limited to a certain capacity.
 * This implements a ring buffer of capacity size that contains a linear buffer of states.
 * The linear buffer is delimited by redoIdx (start) and undoIdx (end) and can grow up to capacity size.
 * When possible the buffer will get synced to local storage.
 */
var UndoStore = /** @class */ (function () {
    function UndoStore(initialState, capacity) {
        this.capacity = capacity;
        this.undoIdx = 0; // marks end of linear buffer
        this.redoIdx = 0; // marks start of linear buffer
        this.currentIdx = 0;
        this.storage = window.localStorage;
        this.states = Array(capacity).fill(null);
        this._init(initialState);
    }
    Object.defineProperty(UndoStore.prototype, "length", {
        // current size of the linear buffer (number of states available for undo/redo+current)
        get: function () {
            return this._distance(this.redoIdx, this.undoIdx);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UndoStore.prototype, "undoLength", {
        /**
         * current number of undo states stored. Note that the current state is counted as an undo state.
         * This means that you can make (undoLength - 1) undos
         */
        get: function () {
            return this._distance(this.currentIdx, this.undoIdx);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UndoStore.prototype, "redoLength", {
        /**
         * current number of redo states stored. You can do redoLength redos
         */
        get: function () {
            return this._distance(this.redoIdx, this.currentIdx);
        },
        enumerable: true,
        configurable: true
    });
    // calculate distance in the ring buffer
    UndoStore.prototype._distance = function (start, end) {
        return start >= end
            ? start - end // no wrap around at the end of the linear buffer (ring buffer)
            : this.capacity - end + start; // wrap around
    };
    UndoStore.prototype.pushState = function (scene) {
        var state = { id: uuid(), scene: scene };
        // push independent copy of state to ensure no outside reference can alter the undo state
        this._pushState(this._copyState(state));
        return state;
    };
    UndoStore.prototype._pushState = function (state) {
        // adjust pointers, ensure redoIdx >= currentIdx >= undoIdx (in ring buffer sense)
        if (this.length + 1 >= this.capacity)
            this.undoIdx = mod(this.redoIdx + 2, this.capacity);
        this.currentIdx = mod(this.currentIdx + 1, this.capacity);
        this.redoIdx = this.currentIdx;
        // update state in memory
        this.states[this.currentIdx] = state;
        // sync local storage (optional, local storage might eb disabled)
        try {
            this._writeState(this.currentIdx, state);
            this._writeMeta();
        }
        catch (err) {
            log.warn('failed to write local storage');
            this._clearStorage();
        }
    };
    UndoStore.prototype.undo = function () {
        if (!this.undoLength)
            throw new RangeError('undo');
        this.currentIdx = mod(this.currentIdx - 1, this.capacity);
        this.states[this.currentIdx] = __assign({}, this.states[this.currentIdx], { id: uuid() });
        var state = this.states[this.currentIdx];
        try {
            this._writeState(this.currentIdx, state);
            this._writeMeta();
        }
        catch (_a) { }
        // return independent copy of scene to make sure the saved state is not altered
        return state && state.id && state.scene ? this._copyState(state) : null;
    };
    UndoStore.prototype.redo = function () {
        if (!this.redoLength)
            throw new RangeError('redo');
        this.currentIdx = mod(this.currentIdx + 1, this.capacity);
        try {
            this._writeMeta();
        }
        catch (_a) { }
        var state = this.states[this.currentIdx];
        // return independent copy of scene to make sure the saved state is not altered
        return state && state.id && state.scene ? this._copyState(state) : null;
    };
    UndoStore.prototype.reset = function () {
        this.undoIdx = 0;
        this.redoIdx = 0;
        this.currentIdx = 0;
        try {
            this._writeMeta();
        }
        catch (_a) { }
    };
    UndoStore.prototype._init = function (state) {
        if (!state)
            return;
        var meta = this._readMeta();
        if (meta && meta.currentIdx) {
            try {
                // this can throw an error when a Scene is in local storage that is incompatible with the current scene model
                var storageState = this._readState(meta.currentIdx);
                if (storageState && storageState.id && storageState.id === state.id) {
                    // can continue existing undo history from local storage
                    for (var i = 0; i < this.capacity; i++) {
                        this.states[i] = this._readState(i);
                    }
                    var idxLimits = new Limits(0, this.capacity - 1);
                    this.undoIdx = idxLimits.apply(Math.round(meta.undoIdx || 0));
                    this.redoIdx = idxLimits.apply(Math.round(meta.redoIdx || 0));
                    this.currentIdx = idxLimits.apply(Math.round(meta.currentIdx || 0));
                    return;
                }
            }
            catch (error) {
                log.error(error, 'Handled: undo store cleared');
                this._clearStorage();
                this.reset();
            }
        }
        // push initial state
        this._pushState(state);
    };
    UndoStore.prototype._readState = function (idx) {
        var obj = JSON.parse(this.storage.getItem([kUndoStorageKey, idx].join('_')));
        if (obj && obj.id && obj.scene) {
            return {
                id: obj.id,
                scene: new SceneModel2(obj.scene),
            };
        }
        else {
            return null;
        }
    };
    UndoStore.prototype._writeState = function (idx, state) {
        this.storage.setItem([kUndoStorageKey, idx].join('_'), JSON.stringify(state));
    };
    UndoStore.prototype._readMeta = function () {
        return JSON.parse(this.storage.getItem([kUndoStorageKey, 'meta'].join('_')));
    };
    UndoStore.prototype._writeMeta = function () {
        var meta = {
            currentIdx: this.currentIdx,
            undoIdx: this.undoIdx,
            redoIdx: this.redoIdx,
        };
        this.storage.setItem([kUndoStorageKey, 'meta'].join('_'), JSON.stringify(meta));
    };
    UndoStore.prototype._clearStorage = function () {
        this.storage.removeItem([kUndoStorageKey, 'meta'].join('_'));
        for (var i = 0; i < this.capacity; i++) {
            this.storage.removeItem([kUndoStorageKey, i].join('_'));
        }
    };
    UndoStore.prototype._copyState = function (state) {
        return {
            id: state.id,
            scene: state.scene ? state.scene.copy() : null,
        };
    };
    return UndoStore;
}());
export { UndoStore };
