/*!
 * Copyright 2020 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  AnnotationsClip,
  DeltaOperation,
  SceneModel2,
  sty,
  VideoClip,
} from '@castify/edit-models';
import {
  kQuillDefaultFontName,
  kQuillDefaultFontSize,
  kQuillDefaultTextColor,
} from '../annotations-tool/quill-config';

interface CountClip {
  total: number;
  video: number;
  audio: number;
  annotations: number;
}

@Injectable({
  providedIn: 'root',
})
export class StatsHelperService {
  /**
   * Generate a string map of count of each type
   */
  countClipTypes(scene: SceneModel2): CountClip {
    const typesCount = {} as CountClip;
    scene.clips.items.forEach((clip) => {
      if (!typesCount[clip.type]) typesCount[clip.type] = 0;
      typesCount[clip.type]++;
    });
    typesCount.total = scene.clips.items.length;
    return typesCount;
  }

  /**
   * Count the total number of a certain effect used in the project
   */
  countEffects(scene: SceneModel2, type: 'zoom' | 'blur') {
    return scene.clips.items
      .map((clip) => {
        let count = 0;
        if (clip instanceof VideoClip) {
          clip.effects.items.forEach((effect) => {
            if (effect.type === type) count++;
          });
        }
        return count;
      })
      .reduce((accumulator, reducer) => accumulator + reducer, 0);
  }

  /**
   * Count the total number of a certain effect used in the project
   */
  countCrops(scene: SceneModel2) {
    return scene.clips.items
      .map((clip) => {
        let count = 0;
        if (clip instanceof VideoClip && !!clip.transform.crop) {
          count++;
        }
        return count;
      })
      .reduce((accumulator, reducer) => accumulator + reducer, 0);
  }

  /**
   * Gets two fibanocci numbers that the value is in between
   * e.g. (11) => [8, 13]
   */
  getValueBucket(value: number): [number, number] {
    let lower = 0;
    let upper = 1;
    let tempUpper = upper;
    // bucket based on fibanocci sequence because it can scale up infinitely
    while (value > upper) {
      tempUpper = upper;
      upper += lower;
      lower = tempUpper;
    }
    return [lower, upper];
  }

  /**
   * Similar to getValueBucket but converts it to seconds, minutes, or hours
   */
  getDurationBucket(duration: sty.Milliseconds) {
    const [lower, upper] = this.getValueBucket(duration / 1000);
    const bounds = [lower, upper].map((v) => {
      if (v > 3600) {
        return `${Math.round((v / 3600) * 100) / 100}hours`;
      } else if (v > 60) {
        return `${Math.round((v / 60) * 100) / 100}minutes`;
      } else {
        return `${v}seconds`;
      }
    });
    return `between ${bounds[0]}-${bounds[1]}`;
  }

  /**
   * Returns an array representing the number of formats in each text box.
   * NOTE: this doesn't account for alignment change because alignment is a separate delta
   */
  countFormats(scene: SceneModel2) {
    const formatCountPerBox = [];
    scene.clips.items.forEach((clip) => {
      if (clip instanceof AnnotationsClip) {
        clip.boxes.map((box) => {
          const formats = new Set();
          box.delta.forEach((delta) => {
            // copy to avoid mutation
            const d = JSON.parse(JSON.stringify(delta));
            // the alignment are assigned on the next line, causing duplicate counts
            if (d.insert === '\n') return;
            this._setDefaults(d);
            const attributes = Object.entries(d.attributes);
            attributes.sort((a, b) => (a[0] > b[0] ? -1 : 1));
            formats.add(JSON.stringify(attributes));
          });
          formatCountPerBox.push(formats.size);
        });
      }
    });
    return formatCountPerBox;
  }

  /**
   * set delta operation defaults so when they are blank it coincidentally matches values set by user
   * {} === {color: kQuillDefaultTextColor, font: kQuillDefaultFontName, size: kQuillDefaultFontSize}
   */
  _setDefaults(delta: DeltaOperation) {
    delta.attributes = delta.attributes || {};
    delta.attributes.color = delta.attributes.color || kQuillDefaultTextColor;
    delta.attributes.font = delta.attributes.font || kQuillDefaultFontName;
    delta.attributes.size =
      delta.attributes.size || kQuillDefaultFontSize.toString() + 'px';
  }
}
