/*!
 * Copyright 2020 Screencastify LLC
 */
import { SignInComponent } from './components/sign-in/sign-in.component';
var exportDeclarations = [SignInComponent];
var LibAuthModule = /** @class */ (function () {
    function LibAuthModule() {
    }
    return LibAuthModule;
}());
export { LibAuthModule };
