/*!
 * Copyright 2018 Screencastify LLC
 */

/**
 * Global registry for all keyboard shortcuts.
 */
// Human readable name for mod key, same as in MouseTrap
const kModHumanName = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
  ? 'cmd'
  : 'ctrl';

export class KeyboardShortcut {
  combo: string[];

  constructor(combo: string | string[], public desc = '') {
    if (typeof combo === 'string') this.combo = [combo];
    else this.combo = combo;
  }

  get primaryCombo() {
    return this.combo[0];
  }

  /**
   * Keyboard combo as human-readable string for showing in tooltips etc.
   * Returns capitalized combo string like: Ctrl+Z, Del...
   */
  humanCombo(): string {
    return this.primaryCombo
      .replace('mod', kModHumanName)
      .split('+')
      .map((key) => key[0].toUpperCase() + key.slice(1))
      .join('+');
  }

  /**
   * Description with combo in brakets
   */
  descWithCombo(): string {
    return `${this.desc} (${this.humanCombo()})`;
  }
}

export const keyboardShortcuts: { [keyof: string]: KeyboardShortcut } = {
  cut: new KeyboardShortcut('mod+b', 'Cut'),
  del: new KeyboardShortcut(['del', 'backspace'], 'Delete'),
  undo: new KeyboardShortcut('mod+z', 'Undo'),
  zoom: new KeyboardShortcut('mod+shift+z', 'Zoom'),
  redo: new KeyboardShortcut(['mod+y', 'mod+shift-z'], 'Redo'),
  crop: new KeyboardShortcut('mod+shift+c', 'Crop'),
  add: new KeyboardShortcut('mod+a', 'Add clip'),
  text: new KeyboardShortcut('mod+o', 'Add text'),
  space: new KeyboardShortcut('space', 'Play/Pause'),
  blur: new KeyboardShortcut('mod+shift+b', 'Blur'),
  frame_forward: new KeyboardShortcut('right', 'Step one frame forward'),
  frame_back: new KeyboardShortcut('left', 'Step one frame backward'),
  crop_apply: new KeyboardShortcut(['enter', 'esc'], 'Apply crop'),
  back: new KeyboardShortcut(['esc'], 'Go back'),
};
