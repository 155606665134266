/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".placeholder-container[_ngcontent-%COMP%] {\n  display: flex;\n  pointer-events: none;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  padding: 10px;\n  height: calc(100% - 20px);\n  width: calc(100% - 20px);\n  overflow-wrap: break-word; }\n  .placeholder-container[_ngcontent-%COMP%]   .placeholder[_ngcontent-%COMP%] {\n    color: grey;\n    width: 100%;\n    font-size: 40px;\n    text-align: left;\n    font-family: 'Roboto'; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvbW9kdWxlcy9lZGl0LXVpL3Byb2plY3RzL2xpYi1lZGl0b3Ivc3JjL2xpYi9hbm5vdGF0aW9ucy10b29sL2NvbXBvbmVudHMvdGV4dC1wbGFjZWhvbGRlci90ZXh0LXBsYWNlaG9sZGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBYTtFQUNiLG9CQUFvQjtFQUNwQix5QkFBaUI7S0FBakIsc0JBQWlCO01BQWpCLHFCQUFpQjtVQUFqQixpQkFBaUI7RUFHakIsYUFBYTtFQUNiLHlCQUF5QjtFQUN6Qix3QkFBd0I7RUFFeEIseUJBQXlCLEVBQUE7RUFWM0I7SUFhSSxXQUFXO0lBQ1gsV0FBVztJQUdYLGVBQWU7SUFDZixnQkFBZ0I7SUFDaEIscUJBQXFCLEVBQUEiLCJmaWxlIjoicHJvamVjdHMvbGliLWVkaXRvci9zcmMvbGliL2Fubm90YXRpb25zLXRvb2wvY29tcG9uZW50cy90ZXh0LXBsYWNlaG9sZGVyL3RleHQtcGxhY2Vob2xkZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGxhY2Vob2xkZXItY29udGFpbmVyIHtcbiAgZGlzcGxheTogZmxleDtcbiAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG4gIHVzZXItc2VsZWN0OiBub25lO1xuXG4gIC8vIGFjY291bnQgZm9yIHBhZGRpbmcgaW4gc2l6aW5nXG4gIHBhZGRpbmc6IDEwcHg7XG4gIGhlaWdodDogY2FsYygxMDAlIC0gMjBweCk7XG4gIHdpZHRoOiBjYWxjKDEwMCUgLSAyMHB4KTtcblxuICBvdmVyZmxvdy13cmFwOiBicmVhay13b3JkO1xuXG4gIC5wbGFjZWhvbGRlciB7XG4gICAgY29sb3I6IGdyZXk7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICAvLyBzaG91bGQgbWF0Y2ggcXVpbGwgZGVmYXVsdHNcbiAgICBmb250LXNpemU6IDQwcHg7XG4gICAgdGV4dC1hbGlnbjogbGVmdDtcbiAgICBmb250LWZhbWlseTogJ1JvYm90byc7XG4gIH1cbn1cbiJdfQ== */"];
export { styles as styles };
