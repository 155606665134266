/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drive-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common-modals/spinner-modal/spinner-modal.component.ngfactory";
import * as i3 from "../common-modals/spinner-modal/spinner-modal.component";
import * as i4 from "./drive-import-progress-modal/drive-import-progress-modal.component.ngfactory";
import * as i5 from "./drive-import-progress-modal/drive-import-progress-modal.component";
import * as i6 from "@angular/common";
import * as i7 from "../common-modals/error-modal/error-modal.component.ngfactory";
import * as i8 from "../common-modals/error-modal/error-modal.component";
import * as i9 from "../../keyboard-shortcuts/keyboard.service";
import * as i10 from "angular2-hotkeys/src/hotkeys.service";
import * as i11 from "./drive-import-brain.service";
import * as i12 from "../../common/ui-api.service";
import * as i13 from "../../common/project.service";
import * as i14 from "../../common/undo-manager.service";
import * as i15 from "../../timeline/timeline-state.service";
import * as i16 from "../../common/drive-picker.service";
import * as i17 from "angulartics2/ga";
import * as i18 from "../../common/stats-helper.service";
import * as i19 from "./drive-import.component";
import * as i20 from "@angular/material/dialog";
var styles_DriveImportComponent = [i0.styles];
var RenderType_DriveImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DriveImportComponent, data: {} });
export { RenderType_DriveImportComponent as RenderType_DriveImportComponent };
function View_DriveImportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-spinner-modal", [], null, null, null, i2.View_SpinnerModalComponent_0, i2.RenderType_SpinnerModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DriveImportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "lib-drive-import-progress-modal", [], null, [[null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeEvent" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DriveImportProgressModalComponent_0, i4.RenderType_DriveImportProgressModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.DriveImportProgressModalComponent, [], { progress: [0, "progress"], importCount: [1, "importCount"], complete: [2, "complete"] }, { closeEvent: "closeEvent" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.brain.progress)); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.brain.importCount)); var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 4).transform(_co.brain.complete)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DriveImportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-error-modal", [], null, [[null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeEvent" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ErrorModalComponent_0, i7.RenderType_ErrorModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ErrorModalComponent, [], null, { closeEvent: "closeEvent" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DriveImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DriveImportComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DriveImportComponent_2)), i1.ɵdid(6, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DriveImportComponent_3)), i1.ɵdid(8, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.activeModal)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "spinner"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "progress"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "error"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_DriveImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "lib-drive-import", [], null, null, null, View_DriveImportComponent_0, RenderType_DriveImportComponent)), i1.ɵprd(131584, null, i9.KeyboardService, i9.KeyboardService, [i10.HotkeysService]), i1.ɵprd(131584, null, i11.DriveImportBrainService, i11.DriveImportBrainService, [i12.UiApiService, i13.ProjectService, i14.UndoManagerService, i15.TimelineStateService, i16.DrivePickerService, i17.Angulartics2GoogleAnalytics, i18.StatsHelperService]), i1.ɵdid(3, 245760, null, 0, i19.DriveImportComponent, [i20.MatDialogRef, i9.KeyboardService, i11.DriveImportBrainService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DriveImportComponentNgFactory = i1.ɵccf("lib-drive-import", i19.DriveImportComponent, View_DriveImportComponent_Host_0, {}, {}, []);
export { DriveImportComponentNgFactory as DriveImportComponentNgFactory };
