<div #bounds id="bounds" *ngIf="!(ctrl.isActive | async)">
  <div #container id="container" [ngStyle]="{
        width: (scaledWidth | async) + 'px',
        height: (scaledHeight | async) + 'px'
      }">
    <div
      *ngFor="
        let textBox of _textBoxes | async;
        index as idx;
      "
      [ngStyle]="{
        top: textBox.top * 100 + '%',
        left: textBox.left * 100 + '%',
        width: textBox.width * 100 + '%',
        height: textBox.height * 100 + '%',
        transform: 'rotate(' + textBox.rotation + 'deg)',
        transformOrigin: 'center center'
      }"
      class="annotations-box-positioning"
    >
      <div
        [ngStyle]="{
          width: 100 * (inverseScaleFactor | async) + '%',
          height: 100 * (inverseScaleFactor | async) + '%',
          transform: 'scale(' + (scaleFactor | async) + ')',
          transformOrigin: 'top left',
          background: textBox.backgroundColor,
          border: '6px solid ' + (textBox.borderColor === 'none' ? 'transparent' : textBox.borderColor),
          boxSizing: 'border-box'
        }"
      >
        <lib-quill-view [textBox]="textBox"></lib-quill-view>
      </div>
    </div>
  </div>
</div>
