/*!
 * Copyright 2020 Screencastify LLC
 */

import { version } from '../../../../version.json';

export const environment = {
  version,

  production: false,
  test: false,
  e2e: null as any,
  chromebook: null as any,

  // if not null then local worker with this id will be used
  // usually this should match your machines hostname - thats what castify-worker uses
  // useLocalWorker: 'manu-m15',
  useLocalWorker: null,
  externalLinks: {
    upgrade_license: 'https://www.screencastify.com/buy',
    feedback:
      'https://docs.google.com/forms/d/e/1FAIpQLSdkDYsVZg0dhpm15_xQt-8ScyHCwP_4E56fe5Z5GtuJ5_XYZw/viewform',
    support:
      'https://learn.screencastify.com/hc/en-us/categories/360003891334-Edit',
    getting_started:
      'https://learn.screencastify.com/hc/en-us/sections/360008756634-User-Guide-Getting-Started',
  },

  auth: {
    signInUrl: null,
    scopes: [
      'https://www.googleapis.com/auth/drive.file',
      // 'https://www.googleapis.com/auth/drive.photos.readonly',  // authenticate against photos and videos in g-drive
      'profile',
      'email',
    ],
  },
  stackdriverApiKey: null,

  // 30 minutes
  freePlanExportDurationLimit: (30 * 60 + 1) * 1000, // milliseconds

  uiApiUrl: `/api/uiApi`,
  preview: {
    apiUrl: '/preview',
    aspectRatio: 16 / 9,
    emptyApiResponseRetryDelay: 50,
  },

  video: {
    segmentDuration: 1000,
  },
};
