/*!
 * Copyright 2018 Screencastify LLC
 */
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UndoControlsComponent } from './components/undo-controls/undo-controls.component';
var exportDeclarations = [ToolbarComponent, UndoControlsComponent];
var LibToolbarModule = /** @class */ (function () {
    function LibToolbarModule() {
    }
    return LibToolbarModule;
}());
export { LibToolbarModule };
