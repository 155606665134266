/*!
 * Copyright 2020 Screencastify LLC
 */
import { TitlebarLogoComponent } from './components/titlebar-logo/titlebar-logo.component';
import { TitlebarComponent } from './components/titlebar/titlebar.component';
var exportDeclarations = [TitlebarComponent, TitlebarLogoComponent];
var LibTitlebarModule = /** @class */ (function () {
    function LibTitlebarModule() {
    }
    return LibTitlebarModule;
}());
export { LibTitlebarModule };
