/*!
 * Copyright 2019 Screencastify LLC
 */

import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { EditorProviderService } from 'lib-editor/lib/common/editor-provider.service';
import { Subscription } from 'rxjs';
import { EditorManagerService } from '../../../preview/editor-manager.service';

export interface InjectableToolbarComponent {
  closeToolbar(): Promise<void>;
}

@Component({
  selector: 'lib-tool-sidebar',
  templateUrl: './tool-sidebar.component.html',
  styleUrls: ['./tool-sidebar.component.scss'],
})
export class SideToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('toolbar', { read: ViewContainerRef })
  protected toolbarContainer: ViewContainerRef;
  toolbarComponent: ComponentRef<InjectableToolbarComponent>;

  private subscriptions = new Subscription();

  constructor(private editorManager: EditorManagerService) {
    this.subscriptions.add(
      this.editorManager.activeEditor.subscribe((editorProvider) =>
        this._setSideToolbar(editorProvider)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {}

  private async _setSideToolbar(activeEditor: EditorProviderService) {
    if (!this.toolbarContainer) return;

    if (this.toolbarComponent) {
      await this.toolbarComponent.instance.closeToolbar();
      this.toolbarContainer.clear();
      this.toolbarComponent = null;
      setTimeout(() => window.dispatchEvent(new Event('resize')));
    }

    if (activeEditor) {
      if (!activeEditor.getSideToolbar()) return;
      this.toolbarComponent = this.toolbarContainer.createComponent<InjectableToolbarComponent>(
        activeEditor.getSideToolbar()
      );
    }
  }
}
