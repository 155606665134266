/*!
 * Copyright 2019 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Base class for the services of editors
 */
@Injectable({
  providedIn: 'root',
})
export class EditorControllerService {
  readonly isActive = new BehaviorSubject<boolean>(false);
  readonly canActivate = new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * activates editor the editor
   */
  open(): void {
    this.isActive.next(true);
  }

  /**
   * saves current edit
   */
  save(): void {
    throw new Error('not implemented');
  }

  /**
   * Deactivates the editor. Intended to be overridden
   * and then called via super.close() in subclasses
   */
  close(): void {
    this.isActive.next(false);
  }
}
