/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-box-edit-border.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-common/directives/drag-handle.directive";
import * as i3 from "@angular/common";
import * as i4 from "./text-box-edit-rotate-border.component";
import * as i5 from "../../annotations-tool-controller.service";
var styles_TextBoxEditRotateBorderComponent = [i0.styles];
var RenderType_TextBoxEditRotateBorderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextBoxEditRotateBorderComponent, data: {} });
export { RenderType_TextBoxEditRotateBorderComponent as RenderType_TextBoxEditRotateBorderComponent };
function View_TextBoxEditRotateBorderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "gray-rotate-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rotate-knob"]], null, null, null, null, null))], null, null); }
function View_TextBoxEditRotateBorderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "rotate-border"], ["libDragHandle", ""]], null, [[null, "dragStart"], [null, "dragMove"], [null, "dragEnd"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).startDragWithHandlers($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).startDragWithHandlers($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragStart" === en)) {
        var pd_2 = (_co.onMoveStart() !== false);
        ad = (pd_2 && ad);
    } if (("dragMove" === en)) {
        var pd_3 = (_co.onMove($event) !== false);
        ad = (pd_3 && ad);
    } if (("dragEnd" === en)) {
        var pd_4 = (_co.onMoveEnd() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DragHandleDirective, [], null, { dragStart: "dragStart", dragMove: "dragMove", dragEnd: "dragEnd" })], null, null); }
export function View_TextBoxEditRotateBorderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ng-content"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { zIndex: 0, pointerEvents: 1 }), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TextBoxEditRotateBorderComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["id", "draggable-border"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TextBoxEditRotateBorderComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co._isSelected)) ? "+2" : "+0"), "none"); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.showGreyBox)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.showGreyBox)); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_TextBoxEditRotateBorderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-text-box-edit-rotate-border", [], null, null, null, View_TextBoxEditRotateBorderComponent_0, RenderType_TextBoxEditRotateBorderComponent)), i1.ɵdid(1, 49152, null, 0, i4.TextBoxEditRotateBorderComponent, [i5.AnnotationsToolControllerService], null, null)], null, null); }
var TextBoxEditRotateBorderComponentNgFactory = i1.ɵccf("lib-text-box-edit-rotate-border", i4.TextBoxEditRotateBorderComponent, View_TextBoxEditRotateBorderComponent_Host_0, { boxId: "boxId", parentDims: "parentDims" }, {}, ["*"]);
export { TextBoxEditRotateBorderComponentNgFactory as TextBoxEditRotateBorderComponentNgFactory };
