var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { BlurEffect, EffectTimeline, SceneEditor2, VideoClip, } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { EffectSelection, TimelineStateService, } from '../timeline/timeline-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../common/undo-manager.service";
import * as i3 from "../preview/preview-state.service";
var kDefaultBlurDuration = 4000;
var kInitialBlurBox = {
    width: 0.2,
    height: 0.2,
    top: 0.4,
    left: 0.4,
    intensity: 0.05,
};
var BlurEditorControllerService = /** @class */ (function (_super) {
    __extends(BlurEditorControllerService, _super);
    function BlurEditorControllerService(timelineState, undoManager, previewState) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.undoManager = undoManager;
        _this.previewState = previewState;
        _this._log = Log.create('BlurEditorControllerService');
        _this.effect = new BehaviorSubject(null);
        _this.selectedBox = new BehaviorSubject(null); // index of the currently selected box
        // sync canActivate
        combineLatest([_this.timelineState.selection, _this.isActive])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), selection = _b[0], isActive = _b[1];
            if (isActive) {
                return false;
            }
            else {
                return (!!_this.filterVideoSelection(selection) ||
                    !!_this.filterBlurSelection(selection));
            }
        }))
            .subscribe(_this.canActivate);
        return _this;
    }
    Object.defineProperty(BlurEditorControllerService.prototype, "numBlurBoxes", {
        get: function () {
            return this.effect.value.boxes.length;
        },
        enumerable: true,
        configurable: true
    });
    BlurEditorControllerService.prototype.open = function () {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening editor not fulfilled');
        this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
        // If the current selection is a blur effect, edit existing blur effect
        var blurSelection = this.filterBlurSelection(this.timelineState.selection.value);
        if (blurSelection) {
            this.clip.next(blurSelection.clip);
            this.effect.next(blurSelection.effect);
            this.selectedBox.next(this.numBlurBoxes - 1);
            // Otherwise if we're selecting a video without a blur effect, create one with a blur box
        }
        else if (this.filterVideoSelection(this.timelineState.selection.value)) {
            this._createBlurEffect();
            this.addBlurBox(kInitialBlurBox);
            this.save();
            this.timelineState.selection.next([
                new EffectSelection({
                    clip: this.clip.value,
                    effect: this.effect.value,
                }),
            ]);
        }
        else {
            throw new Error('insufficient selection to open blur editor');
        }
        // set override scene
        var scene = this.sceneEditor.scene.filterClipType(['video']).copy(); // remove text clips
        var clip = scene.clips.byId(this.clip.value.id);
        clip.effects = new EffectTimeline(); // remove effects
        clip.transform = {}; // remove transforms
        this.previewState.overrideScene.next(scene);
        _super.prototype.open.call(this);
    };
    BlurEditorControllerService.prototype.close = function () {
        _super.prototype.close.call(this);
        this.clip.next(null);
        this.effect.next(null);
        this.sceneEditor = null;
        this.selectedBox.next(null);
    };
    BlurEditorControllerService.prototype.save = function () {
        if (this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
            this._log.info('Blur effects saved');
            this.undoManager.update(this.sceneEditor.scene);
        }
    };
    /**
     * Creates a blur effect and attaches it to a clip near the current playheads location
     */
    BlurEditorControllerService.prototype._createBlurEffect = function () {
        this.clip.next(this.filterVideoSelection(this.timelineState.selection.value));
        var effect = this.sceneEditor
            .getClipEditor(this.clip.value)
            .addBlur(this.previewState.playhead.value - this.clip.value.startInScene, kDefaultBlurDuration);
        if (!effect) {
            throw Error('could not add blur');
        }
        this.effect.next(effect);
    };
    BlurEditorControllerService.prototype._editBlur = function (editFn) {
        var blurEditor = this.sceneEditor
            .getClipEditor(this.clip.value)
            .editBlur(this.effect.value);
        editFn(blurEditor);
        this.effect.next(blurEditor.effect.copy());
    };
    BlurEditorControllerService.prototype.addBlurBox = function (newBlurDimensions) {
        if (newBlurDimensions === void 0) { newBlurDimensions = kInitialBlurBox; }
        var newBox = __assign({}, newBlurDimensions);
        // If box overlaps box already placed, move it slightly and retry
        this.effect.value.boxes.forEach(function (box) {
            if (box.top === newBox.top && box.left === newBox.left) {
                newBox.top -= 0.05;
                newBox.left += 0.05;
                if (newBox.top <= 0 || newBox.left >= 1 - newBox.width) {
                    newBox.top = 1 - newBox.height;
                    newBox.left = 0;
                }
            }
        });
        this._editBlur(function (editor) { return editor.addBox(newBox); });
        this.selectedBox.next(this.effect.value.boxes.length - 1);
        this._log.info('Add new blur box');
    };
    /*
     * Deletes the selected blur box before moving the users selection to the front-most box
     */
    BlurEditorControllerService.prototype.deleteSelectedBox = function () {
        var boxIdx = this.selectedBox.value;
        this._editBlur(function (editor) { return editor.removeBox(boxIdx); });
        // If theres still boxes, make currently selected box the top-most box in the array
        if (this.effect.value.boxes[boxIdx])
            this.selectedBox.next(boxIdx);
        else if (this.numBlurBoxes > 0)
            this.selectedBox.next(this.numBlurBoxes - 1);
        else
            this.selectedBox.next(null);
        this._log.info('Delete selected blur box');
    };
    BlurEditorControllerService.prototype.filterBlurSelection = function (selection) {
        return ((selection.find(function (s) {
            return s instanceof EffectSelection &&
                !!s.clip &&
                s.effect instanceof BlurEffect;
        })) || null);
    };
    BlurEditorControllerService.prototype.filterVideoSelection = function (selection) {
        return selection.find(function (s) { return s instanceof VideoClip; }) || null;
    };
    BlurEditorControllerService.ngInjectableDef = i0.defineInjectable({ factory: function BlurEditorControllerService_Factory() { return new BlurEditorControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.UndoManagerService), i0.inject(i3.PreviewStateService)); }, token: BlurEditorControllerService, providedIn: "root" });
    return BlurEditorControllerService;
}(SingleClipEditorControllerService));
export { BlurEditorControllerService };
