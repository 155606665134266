/*!
 * Copyright 2020 Screencastify LLC
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { of } from 'rxjs';
import { combineLatest, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, } from 'rxjs/operators';
import { KeyboardService } from '../../keyboard-shortcuts/keyboard.service';
import { LocalImportBrainService } from './local-import-brain.service';
var LocalImportComponent = /** @class */ (function () {
    function LocalImportComponent(dialogRef, keyboardService, brain) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.keyboardService = keyboardService;
        this.brain = brain;
        this._log = Log.create('LocalImportComponent');
        this.subscriptions = new Subscription();
        /**
         * Observable instance member which derives UI state from
         * the latest values of observables in the brain service
         *
         * Shows the spinner only while we're waiting for the project
         * to be ready (edge case), which is here represented by
         * `this.brain.readyToAskUserForFile`.
         */
        this.activeModal = combineLatest([
            this.brain.readyToAskUserForFile,
            this.brain._importInit.pipe(startWith(null)),
            this.brain._fileToUpload.pipe(startWith(null)),
            this.brain.progress.pipe(startWith(null)),
        ]).pipe(
        // show progress as soon as all have emitted
        map(function (_a) {
            var _b = __read(_a, 3), gotFile = _b[2];
            if (gotFile)
                return 'progress';
            else
                return 'spinner';
        }), 
        /**
         * This overrides the above to show the error modal
         * if any of the observables throw
         */
        catchError(function (err) {
            _this._log.error(err);
            return of('error');
        }), distinctUntilChanged());
    }
    LocalImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Temporarily pause all editor keyboard shortcuts
        this.keyboardService.pauseAll();
        // start the brain service's subscriptions
        this.brain.init();
        // Open file dialog
        this.subscriptions.add(this.brain.readyToAskUserForFile.subscribe(function () { return _this.openFileDialog(); }));
        // close modal on completion
        this.subscriptions.add(this.brain.userFileReadyForEditing.subscribe(function () { return _this.close(); }));
    };
    LocalImportComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        // turn the keyboard shortcuts back on
        this.keyboardService.resumeAll();
    };
    /**
     * Opens a file dialogue and passes any results into the
     * brain's observables for kicking off uploads; catches a
     * cancel click in the file picker dialog and closes the
     * modal.
     **/
    LocalImportComponent.prototype.openFileDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filePickerOptions, files, file, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filePickerOptions = {
                            types: [
                                {
                                    description: 'Videos',
                                    accept: {
                                        'video/*': [
                                            '.mp4',
                                            '.webm',
                                            '.mkv',
                                            '.flv',
                                            '.f4v',
                                            '.avi',
                                            '.mov',
                                            '.wmv',
                                            '.mpg',
                                            '.mpeg',
                                            '.m2v',
                                            '.m4v',
                                            '.3gp',
                                            '.3g2',
                                        ],
                                    },
                                },
                            ],
                            // disallow selecting multiple files
                            multiple: false,
                            // disallow the user turning off the file extension
                            // filter in the browser/OS's file picker, which protects
                            // against uploading non-video files as video files
                            excludeAcceptAllOption: true,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, window.showOpenFilePicker(filePickerOptions)];
                    case 2:
                        files = _a.sent();
                        if (!files.length) return [3 /*break*/, 4];
                        return [4 /*yield*/, files[0].getFile()];
                    case 3:
                        file = _a.sent();
                        this.brain.setFile(file);
                        return [3 /*break*/, 5];
                    case 4:
                        this.close();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        // close the modal on a cancel click
                        if (err_1 instanceof DOMException) {
                            this.close();
                            // MDN documentation on showOpenFilePicker's errors
                            // is incomplete as of Feb 2021, so we leave this
                            // clause to catch anything else we might encounter
                        }
                        else {
                            this._log.error('unhandled filePicker error', err_1);
                            this.close();
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Any of the modal subcomponents can call this to close
     * the MatDialogue modal.
     */
    LocalImportComponent.prototype.close = function () {
        this.subscriptions.unsubscribe();
        this.dialogRef.close({ name: '' });
    };
    return LocalImportComponent;
}());
export { LocalImportComponent };
