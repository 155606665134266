<div class="container">
  <div id="title-container">
    <h2 matDialogTitle>Oops!</h2>
    <button mat-icon-button aria-label="close button" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    Import failed due to a system error. Please try again to see if this was a
    temporary issue, otherwise contact support.
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button (click)="onHelpClick()">Contact Support</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onCloseClick()"
      cdkFocusInitial
    >
      Try Again
    </button>
  </mat-dialog-actions>
  <div></div>
</div>
