/*!
 * Copyright 2020 Screencastify LLC
 */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ExportGoogleDriveOptionsDto,
  ExportOptionsDto,
  GoogleDriveImportOptionsDto,
  IExportDownloadResult,
  IExportGoogleDriveResult,
  IImportResult,
  IInitAuthResult,
  IInitUploadResult,
  ImportOptionsDto,
  PostLogPayloadDto,
  SetDefaultVideoRegistryOptionsDto,
  UploadImportOptionsDto,
} from '@castify/edit-models';
import { environment } from 'environments/environment';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class UiApiService {
  constructor(
    protected readonly userService: UserService,
    protected readonly http: HttpClient
  ) {}

  // auth
  authSyncLicense(): Observable<IInitAuthResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/client-auth/sync-license`
    );
  }

  // logging
  postAuthLogs(payload: PostLogPayloadDto): Observable<{}> {
    return this._postRequest(`${environment.uiApiUrl}/log`, payload);
  }
  postUnauthLogs(payload: PostLogPayloadDto): Observable<{}> {
    return this.http.post(
      `${environment.uiApiUrl}/log/unauthenticated`,
      payload,
      {
        headers: { auth: 'false' },
      }
    );
  }

  // export
  exportDownload(options: ExportOptionsDto): Observable<IExportDownloadResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/export/download`,
      options
    );
  }
  exportGoogleDrive(
    options: ExportGoogleDriveOptionsDto
  ): Observable<IExportGoogleDriveResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/export/google-drive`,
      options
    );
  }

  // import
  importGoogleDrive(
    options: GoogleDriveImportOptionsDto
  ): Observable<IImportResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/import/google-drive`,
      options
    );
  }
  initUpload(options: ImportOptionsDto): Observable<IInitUploadResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/import/upload/init`,
      options
    );
  }
  uploadImport(options: UploadImportOptionsDto): Observable<IImportResult> {
    return this._postRequest(
      `${environment.uiApiUrl}/import/upload/start`,
      options
    );
  }

  // video registry
  setDefaultVideoRegistryPrivacy(option: SetDefaultVideoRegistryOptionsDto) {
    return this._postRequest(`${environment.uiApiUrl}/video-registry`, option);
  }

  protected _getRequest<T extends Object>(
    url: string,
    urlParams?: HttpParams
  ): Observable<T> {
    return from(this.userService.currentUser.getIdToken()).pipe(
      switchMap(
        (authToken) => <Observable<T>>this.http.get(url, {
            responseType: 'json',
            headers: new HttpHeaders({
              authorization: `Bearer ${authToken}`,
            }),
            params: urlParams,
          })
      )
    );
  }

  protected _postRequest<T extends Object>(
    url: string,
    data: Object = {},
    urlParams?: HttpParams
  ): Observable<T> {
    return from(this.userService.currentUser.getIdToken()).pipe(
      switchMap(
        (authToken) => <Observable<T>>this.http.post(url, data, {
            responseType: 'json',
            headers: new HttpHeaders({
              authorization: `Bearer ${authToken}`,
            }),
            params: urlParams,
          })
      )
    );
  }
}
