/*!
 * Copyright 2019 Screencastify LLC
 */
import { EventEmitter } from '@angular/core';
var DragHandleDirective = /** @class */ (function () {
    function DragHandleDirective() {
        this.dragStart = new EventEmitter();
        this.dragMove = new EventEmitter();
        this.dragEnd = new EventEmitter();
    }
    DragHandleDirective.prototype.startDragWithHandlers = function (startEvent) {
        var _this = this;
        // Logs the movement delta contained in an emitted dragMove event
        var onMove = function (event) {
            _this.dragMove.emit(_this.createDragHandleEvent(startEvent, event));
        };
        // When the drag starts...
        // emit the change on every touchMove or mouseMove
        window.addEventListener(startEvent instanceof MouseEvent ? 'mousemove' : 'touchmove', onMove);
        // When the drag ends...
        window.addEventListener(startEvent instanceof MouseEvent ? 'mouseup' : 'touchend', function (event) {
            // Stop emitting further movements
            window.removeEventListener(event instanceof MouseEvent ? 'mousemove' : 'touchmove', onMove);
            // Emit one final movement
            _this.dragEnd.emit(_this.createDragHandleEvent(startEvent, event));
        }, { once: true });
        this.dragStart.emit(startEvent);
        // avoid starting multiple drag actions at once on stacked elements
        startEvent.stopPropagation();
    };
    /*
     * Creates an appropriate drag event for our listeners to emit
     */
    DragHandleDirective.prototype.createDragHandleEvent = function (startEvent, event) {
        var shiftY = this.calcYDelta(startEvent, event);
        var shiftX = this.calcXDelta(startEvent, event);
        return {
            event: event,
            startEvent: startEvent,
            shiftX: shiftX,
            shiftY: shiftY,
        };
    };
    /*
     * Finds the difference between the current X position and our starting X position
     */
    DragHandleDirective.prototype.calcXDelta = function (startEvent, event) {
        // Determines if we're using a mouse event or a touch event
        var getXValue = function (e) {
            return e instanceof MouseEvent ? e.clientX : e.changedTouches[0].clientX;
        };
        return getXValue(event) - getXValue(startEvent);
    };
    /*
     * Finds the difference between the current Y position and our starting Y position
     */
    DragHandleDirective.prototype.calcYDelta = function (startEvent, event) {
        // Determines if we're using a mouse event or a touch event
        var getYValue = function (e) {
            return e instanceof MouseEvent ? e.clientY : e.changedTouches[0].clientY;
        };
        return getYValue(event) - getYValue(startEvent);
    };
    return DragHandleDirective;
}());
export { DragHandleDirective };
