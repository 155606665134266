/*!
 * Copyright 2018 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { LibAnnotationsToolModule } from '../annotations-tool/lib-annotations-tool.module';
import { LibAudioToolModule } from '../audio-editor/audio-tool.module';
import { LibBlurEditorModule } from '../blur-editor/lib-blur-editor.module';
import { LibCropEditorModule } from '../crop-editor/lib-crop-editor.module';
import { LibStillClipToolModule } from '../still-clip-editor/still-clip-tool.module';
import { LibZoomEditorModule } from '../zoom-editor/lib-zoom-editor.module';
import { AudioClipViewComponent } from './components/audio-clip-view/audio-clip-view.component';
import { ClipViewComponent } from './components/clip-view/clip-view.component';
import { LowResSnackbarComponent } from './components/low-res-snackbar/low-res-snackbar.component';
import { PreviewComponent } from './components/preview/preview.component';
import { StillClipViewComponent } from './components/still-clip-view/still-clip-view.component';
import { VideoClipViewComponent } from './components/video-clip-view/video-clip-view.component';
import { VideoEffectViewComponent } from './components/video-effect-view/video-effect-view.component';

const kEntryComponents = [
  VideoClipViewComponent,
  AudioClipViewComponent,
  StillClipViewComponent,
];

@NgModule({
  declarations: [
    PreviewComponent,
    ClipViewComponent,
    VideoEffectViewComponent,
    LowResSnackbarComponent,
    ...kEntryComponents,
  ],
  imports: [
    CommonModule,
    LibCropEditorModule,
    LibZoomEditorModule,
    LibBlurEditorModule,
    // 👆 old naming convention
    // 👇 new naming convention `Lib<ToolName>ToolModule`
    LibAnnotationsToolModule,
    LibStillClipToolModule,
    LibAudioToolModule,
    HttpClientModule,
    MatButtonModule,
  ],
  exports: [PreviewComponent],
  entryComponents: kEntryComponents,
})
export class LibPreviewModule {}
