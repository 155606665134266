/*!
 * Copyright 2020 Screencastify LLC
 */
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
var DriveImportProgressModalComponent = /** @class */ (function () {
    function DriveImportProgressModalComponent() {
        this._progress = new BehaviorSubject(null);
        this._importCount = new BehaviorSubject(null);
        this._complete = new BehaviorSubject(null);
        this.closeEvent = new EventEmitter();
    }
    Object.defineProperty(DriveImportProgressModalComponent.prototype, "progress", {
        set: function (p) {
            this._progress.next(p);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriveImportProgressModalComponent.prototype, "importCount", {
        set: function (p) {
            this._importCount.next(p);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriveImportProgressModalComponent.prototype, "complete", {
        set: function (p) {
            this._complete.next(p);
        },
        enumerable: true,
        configurable: true
    });
    DriveImportProgressModalComponent.prototype.ngOnInit = function () { };
    /**
     * Emits an event to the parent which indicates the user has
     * requested the modal be closed
     */
    DriveImportProgressModalComponent.prototype.onCloseClick = function () {
        this.closeEvent.emit();
    };
    return DriveImportProgressModalComponent;
}());
export { DriveImportProgressModalComponent };
