var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { AnnotationsClip, SceneEditor2, VerticalAlignment, } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject, combineLatest, fromEventPattern, of, Subscription, } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, } from 'rxjs/operators';
import { SingleClipEditorControllerService } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { TimelineStateService } from '../timeline/timeline-state.service';
import { kMaxTextBoxes, kMaxTextBoxLength, } from './quill-config';
import { kAnnotationsClipDefaultDuration, kNewTextBoxDefaultFactory, } from './text-box-defaults';
import * as i0 from "@angular/core";
import * as i1 from "../timeline/timeline-state.service";
import * as i2 from "../common/undo-manager.service";
import * as i3 from "../preview/preview-state.service";
var AnnotationsToolControllerService = /** @class */ (function (_super) {
    __extends(AnnotationsToolControllerService, _super);
    /***************************************
     *          CONSTRUCTOR
     ***************************************/
    function AnnotationsToolControllerService(timelineState, undoManager, previewState) {
        var _this = _super.call(this, timelineState, previewState) || this;
        _this.timelineState = timelineState;
        _this.undoManager = undoManager;
        _this.previewState = previewState;
        _this._log = Log.create('AnnotationsToolControllerService');
        /*****************************************************
         *                Utility members
         *****************************************************/
        _this.subscriptions = new Subscription();
        /*****************************************************
         *    These members contain mutable component state
         *        from which other state is derived
         *****************************************************/
        // the active quill instance if any
        _this.activeQuill = new BehaviorSubject(null);
        // Idx of selcted box
        // External calls of its .next() should use setSelectedIndex method
        _this.selectedBoxId = new BehaviorSubject(null);
        _this.isRotationActive = new BehaviorSubject(false);
        _this.grayBoxRotation = new BehaviorSubject(null);
        // the "last seen" cursor and box styles
        // intended to facilitate "copying" styles
        // to new text boxes, but also by methods intended
        // to be consumed by the sidebar
        _this.lastSeenStyles = {
            quillAttributes: {},
            verticalAlignment: VerticalAlignment.top,
            backgroundColor: 'none',
            borderColor: 'none',
            top: 0.4,
            left: 0.4,
            width: 0.2,
            height: 0.2,
            rotation: 0,
        };
        // Manually triggers polling quill for selection/attribute
        // states, triggering updates to all sidebar state observbles.
        // Intended to allow manually trigger querying quill
        // selection states when the user interacts with sidebar
        // controls, but likely also emitted to elsewhere.
        _this.triggerQuillPoll = new BehaviorSubject(null);
        /*****************************************************
         *        These observable members describe
         *        the derivation of dependent state
         *****************************************************/
        // the selected box itself
        _this.activeBox = combineLatest(_this.clip.pipe(
        // possible for clip to be null in edge cases
        // where clip has been deleted from timeline
        // but is still being rendered by preview
        filter(function (clip) { return !!clip; }), map(function (clip) { return clip.boxes; })), _this.selectedBoxId.pipe(filter(function (id) { return !!id; }))).pipe(map(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], selectedBoxId = _b[1];
            return _this.clip.value.getBoxById(selectedBoxId);
        }), distinctUntilChanged());
        // Quill instance which updates only when changing the selected text box
        _this.quillInstance = _this.activeQuill.pipe(filter(function (quill) { return !!quill; }), distinctUntilChanged());
        // Merges a stream of Quill events with user-generated
        // sidebar interaction events
        _this.quillTextAndSelectionChange = _this.quillInstance.pipe(switchMap(function (quill) {
            return combineLatest([
                fromEventPattern(function (handler) {
                    quill.on('editor-change', handler);
                }, function (handler) {
                    quill.off('editor-change', handler);
                }),
                _this.triggerQuillPoll,
            ]);
        }), map(function () { return null; }), shareReplay());
        // Fires only when quill state changes
        // does not include selection updates
        _this.quillTextChanges = _this.quillInstance.pipe(switchMap(function (quill) {
            return fromEventPattern(function (handler) {
                quill.on('text-change', handler);
            }, function (handler) {
                quill.off('text-change', handler);
            });
        }), map(function () { return null; }));
        // Emits length of the text according to quill whenever this changes
        _this.quillTextLength = _this.quillTextChanges.pipe(switchMap(function () { return of(_this.activeQuill.value.getLength()); }), distinctUntilChanged());
        // Emits only when the user deletes all text in a box via any
        // means (delete, backspace, paste, etc.). Length 1 means
        // the box contains only a newline char (appears empty)
        _this.quillTextEmptyEvents = _this.quillTextLength.pipe(startWith(0), pairwise(), filter(function (_a) {
            var _b = __read(_a, 2), last = _b[0], current = _b[1];
            return last >= 1 && current === 1;
        }), map(function () { return null; }));
        // Emits an object describing the style of the cursor or of a text selection
        // if there is one every time the selection, the cursor, the selected text,
        // or active quill instance changes
        //
        // Blocked from emitting when the text length has dropped to 1, meaning the
        // text box is empty, which mitigating an edge case in which Quill "forgets"
        // all formatting state when the user deletes all of the text (length "1" means
        // there's only a single newline char)
        _this.quillSelectedAttributes = combineLatest([
            _this.quillInstance,
            _this.quillTextAndSelectionChange,
        ]).pipe(
        // need to explicitly call getSelection again so we can filter the null
        // values sometimes returned (inexplicably) as the selection
        map(function (_a) {
            var _b = __read(_a, 1), quill = _b[0];
            return [quill, quill.getSelection()];
        }), filter(function (_a) {
            var _b = __read(_a, 2), selection = _b[1];
            return !!selection;
        }), map(function (_a) {
            var _b = __read(_a, 2), quill = _b[0], selection = _b[1];
            return quill.getFormat(selection);
        }), map(function (rawAttribs) { return _this.normalizeQuillAttributes(rawAttribs); }));
        // Permit tool activation whenever timeline isn't empty
        _this.undoManager.scene
            .pipe(map(function (scene) { return scene && scene.clips && scene.clips.length > 0; }), distinctUntilChanged())
            .subscribe(_this.canActivate);
        // Persist delta changes in the active box to the models
        _this.subscriptions.add(_this.quillTextChanges.subscribe(function () { return _this.saveDeltaToSelectedBoxModel(); }));
        // Persist last-seen styles. Intended to help with system to copy
        // last-seen styles to new text boxes, but also the system which
        // prevents Quill from forgetting cursor formatting when deleting
        // all of the text in a text box.
        _this.subscriptions.add(_this.quillSelectedAttributes
            .pipe(filter(function () {
            return _this.activeQuill.value.getLength() > 1;
        }))
            .subscribe(function (attributes) {
            _this.updateLastSeenStyles(attributes);
        }));
        // Whenever the user clears the text box, update the cursor
        // to reflect the last-seen cursor style prior to the clear.
        // Fixes an issue in which Quill forgets last-seen cursor styles.
        _this.subscriptions.add(_this.quillTextEmptyEvents.subscribe(function () {
            _this.setQuillCursorStyles(_this.lastSeenStyles.quillAttributes);
        }));
        // deletes text in excess of max length
        _this.subscriptions.add(_this.quillTextLength.subscribe(function (length) {
            _this.deleteTextInExcessOfLimit(length);
        }));
        return _this;
    }
    Object.defineProperty(AnnotationsToolControllerService.prototype, "numBoxes", {
        // how many boxes are there
        get: function () {
            return this.clip.value.boxes.length;
        },
        enumerable: true,
        configurable: true
    });
    /*****************************************************
     *        Tool lifecycle methods
     *****************************************************/
    /**
     * Opens the tool; always uses the playhead position to select a clip,
     * creating a clip if one does not already exist. Can optionally be passed
     * the index of a text box that should receive focus when the tool is
     * opened (intended for use when opening the tool via clicks on text boxes).
     */
    AnnotationsToolControllerService.prototype.open = function (boxId) {
        if (!this.canActivate.value)
            throw new Error('preconditions for opening editor not fulfilled');
        this.previewState.pause();
        this.sceneEditor = new SceneEditor2(this.undoManager.scene.value.copy());
        // select annotations clip at playhead, creating a clip if one does not
        // already exist, before opening the clip with the tool
        var annotationsClipAtPlayhead = this._getAnnotationsClipAtPlayhead();
        if (!annotationsClipAtPlayhead) {
            // create clip with one text box, copying in persisted box-level styles
            annotationsClipAtPlayhead = this.makeNewAnnotationsClipUsingPersistedStyles();
        }
        this.clip.next(annotationsClipAtPlayhead);
        this.timelineState.selection.next([annotationsClipAtPlayhead]);
        // select textbox at given index if provided and update the
        // sidebar's snapshot of Quill's format attributes
        this.selectTextBoxById(boxId !== undefined
            ? this.clip.value.getBoxById(boxId).id
            : this.clip.value.getFrontBox().id);
        // set override scene
        this.timelineState.overrideScene.next(this.sceneEditor.scene);
        _super.prototype.open.call(this);
    };
    /**
     * Make new annotations clip, applies any last-used box-level styles
     * saved in this controller, overriding defaults.
     */
    AnnotationsToolControllerService.prototype.makeNewAnnotationsClipUsingPersistedStyles = function () {
        // Needed because we cannot control when Quill sets the cursor and triggers
        // persisted style updates, which may trigger observablesi in this class
        // that update persited styles
        var copyOfPersistedQuillAttribs = JSON.parse(JSON.stringify(this.lastSeenStyles.quillAttributes));
        // generate new clip in our copy of the scene
        var newClip = this.sceneEditor.addAnnotationsClip(this.previewState.playhead.value, kAnnotationsClipDefaultDuration, [this.overrideBoxStylesWithLastSeen(kNewTextBoxDefaultFactory())]);
        // set cursor styles of the new textbox
        this.setQuillCursorStyles(copyOfPersistedQuillAttribs);
        return newClip;
    };
    /**
     * Get the annotations clip at the playhead if there is one
     */
    AnnotationsToolControllerService.prototype._getAnnotationsClipAtPlayhead = function () {
        var timelineSelection = __spread(this.timelineState.scene.value.clips.getAllAtPos(
        // This handles the edge case of the playhead being
        // all the way at the end of the timeline, in which
        // no annotations clip is returned even if one is present.
        // Subtracting one makes sure we get the clip.
        Math.max(this.previewState.playhead.value - 1, 0)));
        var clipCandidates = timelineSelection.filter(function (clip) { return clip instanceof AnnotationsClip; });
        return clipCandidates[0] || null;
    };
    /**
     * Closes the editor; intended to be called in editor manager
     * when editors switch but also when user manually closes
     * editor via buttons.
     */
    AnnotationsToolControllerService.prototype.close = function () {
        this.clip.next(null);
        this.activeQuill.next(null);
        this.selectedBoxId.next(null);
        _super.prototype.close.call(this);
    };
    /**
     * Enables "soft" style persistence, intended to permit copying
     * last-seen styles to new text boxes within single user/browser sessions.
     * Not involved in persisting text box state to models or DB.
     *
     * Quill attributes must be passed in while non-quill attributes
     * can be pulled out inside the methods, as the former are observable
     * while the latter are not, in this design.
     */
    AnnotationsToolControllerService.prototype.updateLastSeenStyles = function (attributes) {
        var selectedTextBoxId = this.selectedBoxId.value;
        // object copy for safety
        this.lastSeenStyles.quillAttributes = JSON.parse(JSON.stringify(attributes));
        // Non-quill attributes will always be correct and safe to update
        var selectedTextBox = this.clip.value.getBoxById(selectedTextBoxId);
        this.lastSeenStyles.backgroundColor = selectedTextBox.backgroundColor;
        this.lastSeenStyles.verticalAlignment = selectedTextBox.verticalAlignment;
        this.lastSeenStyles.rotation = selectedTextBox.rotation;
    };
    /*****************************************************
     *         These methods concern altering &
     *     persisting alterations to text box data
     *****************************************************/
    /**
     * Intended to be called when the editor is closed. Makes sure scene
     * is updated with delta from active instance.
     */
    AnnotationsToolControllerService.prototype.save = function () {
        if (this.undoManager.scene.value.hash !== this.sceneEditor.scene.hash) {
            this._log.info('Annotations saved');
            this.undoManager.update(this.sceneEditor.scene);
        }
    };
    /**
     * Permits access to annotations mutator to allow scene data
     * to be changed
     */
    AnnotationsToolControllerService.prototype.editAnnotation = function (editFn) {
        var editor = this.sceneEditor.editAnnotationsClip(this.clip.value);
        editFn(editor);
        this.clip.next(editor.annotationClip.copy());
    };
    /**
     * Saves changed deltas for active quill to the model
     * corresponding to the selected text box. Intended to
     * be run when Quill emits change events.
     */
    AnnotationsToolControllerService.prototype.saveDeltaToSelectedBoxModel = function () {
        var selectedTextBoxId = this.selectedBoxId.value;
        var delta = this.activeQuill.value.getContents().ops;
        this.editAnnotation(function (mutator) {
            mutator.setQuillDelta(selectedTextBoxId, delta);
        });
        // push to timeline
        this.timelineState.overrideScene.next(this.sceneEditor.scene);
    };
    /**
     * Intended to be called after changes to non-quill
     * text box properties to make sure they can be copied
     * to new boxes (e.g. after move, bg change etc.)
     */
    AnnotationsToolControllerService.prototype.persistSelectedBoxToLastSeenStyles = function () {
        var selectedTextBoxId = this.selectedBoxId.value;
        var _a = this.clip.value.getBoxById(selectedTextBoxId), top = _a.top, left = _a.left, width = _a.width, height = _a.height, verticalAlignment = _a.verticalAlignment, backgroundColor = _a.backgroundColor, borderColor = _a.borderColor, rotation = _a.rotation;
        this.lastSeenStyles = __assign({}, this.lastSeenStyles, { top: top,
            left: left,
            width: width,
            height: height,
            verticalAlignment: verticalAlignment,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            rotation: rotation });
    };
    AnnotationsToolControllerService.prototype.deleteTextInExcessOfLimit = function (length) {
        if (length > kMaxTextBoxLength) {
            this.activeQuill.value.deleteText(kMaxTextBoxLength, length);
        }
    };
    /*****************************************************
     *      These methods handle text box creation,
     *         destruction, selection
     *****************************************************/
    /**
     * Intended to add new text boxes when the sidebar's
     * New Text Box button is clicked
     */
    AnnotationsToolControllerService.prototype.addAnnotationsBox = function (newTextBox) {
        var _this = this;
        var newBox = __assign({}, newTextBox);
        // If box overlaps box already placed, move it slightly and retry
        this.clip.value.boxes.forEach(function (box) {
            if (box.top === newBox.top && box.left === newBox.left) {
                newBox.top -= 0.05;
                newBox.left += 0.05;
                if (newBox.top <= 0 || newBox.left >= 1 - newBox.width) {
                    newBox.top = 1 - newBox.height;
                    newBox.left = 0;
                }
            }
        });
        this.editAnnotation(function (editor) { return editor.addBox(newBox); });
        this.selectTextBoxById(this.clip.value.getFrontBox().id);
        this._log.info('Add new annotation box');
        // focus quill and select all text
        // setTimeout needed because Quill is weird
        setTimeout(function () {
            if (_this.activeQuill.value) {
                _this.activeQuill.value.focus();
                var length_1 = _this.activeQuill.value.getLength();
                _this.activeQuill.value.setSelection(0, length_1, 'api');
            }
        }, 0);
    };
    /**
     * Take some quill attributes and apply them
     * to the cursor
     */
    AnnotationsToolControllerService.prototype.setQuillCursorStyles = function (attributes) {
        var _this = this;
        setTimeout(function () {
            if (_this.activeQuill.value) {
                Object.entries(attributes).forEach(function (_a) {
                    var _b = __read(_a, 2), key = _b[0], val = _b[1];
                    _this.activeQuill.value.format(key, val);
                });
            }
        }, 0);
    };
    /**
     * Combine last-seen box-level styles with another box's properties.
     * Intended to help with applying persisted style state to a new box.
     */
    AnnotationsToolControllerService.prototype.overrideBoxStylesWithLastSeen = function (textBox) {
        var _a = this.lastSeenStyles, backgroundColor = _a.backgroundColor, borderColor = _a.borderColor, verticalAlignment = _a.verticalAlignment, top = _a.top, left = _a.left, width = _a.width, height = _a.height, rotation = _a.rotation;
        return __assign({}, textBox, { backgroundColor: backgroundColor,
            borderColor: borderColor,
            verticalAlignment: verticalAlignment,
            top: top,
            left: left,
            width: width,
            height: height,
            rotation: rotation });
    };
    /**
     * Adds a new annotations box that applies the persisted styles
     */
    AnnotationsToolControllerService.prototype.addNewBoxUsingPersistedStyles = function () {
        // early return if we have already hit cap
        if (this.clip.value.boxes.length >= kMaxTextBoxes)
            return;
        // Needed because we cannot control when Quill sets the cursor and triggers
        // persisted style updates, which may trigger observablesi in this class
        // that update persited styles
        var copyOfPersistedQuillAttribs = JSON.parse(JSON.stringify(this.lastSeenStyles.quillAttributes));
        // actually create the new box
        this.addAnnotationsBox(this.overrideBoxStylesWithLastSeen(kNewTextBoxDefaultFactory()));
        // set quill cursor styles
        this.setQuillCursorStyles(copyOfPersistedQuillAttribs);
    };
    /**
     * Removes the currently selected text box from the clip
     */
    AnnotationsToolControllerService.prototype.deleteSelectedTextBox = function () {
        // If we have more than one box, swap selection to next logical box
        var boxToDelete = this.selectedBoxId.value;
        if (this.numBoxes > 1) {
            var boxBehindCurrentBox = this.clip.value.getBoxBelow(this.selectedBoxId.value);
            this.selectedBoxId.next(boxBehindCurrentBox.id);
        }
        // Remove the selected box
        this.editAnnotation(function (editor) { return editor.removeBox(boxToDelete); });
        if (this.numBoxes !== 0)
            this.putCursorAtEnd();
    };
    AnnotationsToolControllerService.prototype.moveTextBoxToFront = function () {
        var _this = this;
        this.editAnnotation(function (editor) {
            return editor.moveBoxToFront(_this.selectedBoxId.value);
        });
        this.putCursorAtEnd();
        this._log.info('Moved Text-Box to Front');
    };
    AnnotationsToolControllerService.prototype.moveTextBoxToBack = function () {
        var _this = this;
        this.editAnnotation(function (editor) {
            return editor.moveBoxToBack(_this.selectedBoxId.value);
        });
        this.putCursorAtEnd();
        this._log.info('Moved Text-Box to Back');
    };
    /**
     * Intended to be used for selecting a text box (e.g. on a click).
     * Mirrors quill delta from the active quill instance to the scene
     * to ensure it can be persisted later.
     */
    AnnotationsToolControllerService.prototype.selectTextBoxById = function (id) {
        this.selectedBoxId.next(id);
        this.putCursorAtEnd();
        this.persistSelectedBoxToLastSeenStyles();
    };
    /**
     * Puts the cursor at the end of the active text box
     */
    AnnotationsToolControllerService.prototype.putCursorAtEnd = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.activeQuill.value) {
                var cursorIndex = _this.activeQuill.value.getLength();
                _this.activeQuill.value.setSelection(cursorIndex);
            }
        }, 0);
    };
    /*****************************************************
     *         These methods concern observing and
     *          changing Quill attribute state
     *****************************************************/
    /**
     * Grabs the first style when there are multiple styles. Necessary because
     * Quill can output arrays when multiple styles are selected, but only for
     * some properties.
     */
    AnnotationsToolControllerService.prototype.normalizeQuillAttributes = function (attrib) {
        return Object.entries(attrib).reduce(function (acc, _a) {
            var _b = __read(_a, 2), key = _b[0], val = _b[1];
            acc[key] = Array.isArray(val) ? val[0] : val;
            return acc;
        }, {});
    };
    /**
     * Handle a request from the user to toggle one of the
     * toggleable Quill attributes. Decides whether to apply or
     * disable the attribute based on current state of
     * the Quill cursor or selection.
     */
    AnnotationsToolControllerService.prototype.triggerToggleable = function (attrib) {
        var _this = this;
        var quill = this.activeQuill.value;
        if (quill) {
            if (this.lastSeenStyles.quillAttributes[attrib])
                quill.format(attrib, 0);
            else
                quill.format(attrib, 1);
            // force observables to update
            this.triggerQuillPoll.next(null);
            // refocus quill after each interaction
            setTimeout(function () {
                if (_this.activeQuill.value) {
                    _this.activeQuill.value.focus();
                }
            }, 0);
        }
    };
    /**
     * Handle a request from the user to toggle one of the
     * non-toggleable Quill attributes. Always passes values passed in
     * to Quill (which hopefully has them whitelisted).
     */
    AnnotationsToolControllerService.prototype.triggerSettable = function (attributeName, value) {
        var _this = this;
        var quill = this.activeQuill.value;
        if (quill) {
            quill.format(attributeName, value);
            // force observables to update
            this.triggerQuillPoll.next(null);
            // refocus quill after each interaction
            setTimeout(function () {
                if (_this.activeQuill.value) {
                    _this.activeQuill.value.focus();
                }
            }, 0);
        }
    };
    /**
     * Make an observable for a toggleable attribute
     */
    AnnotationsToolControllerService.prototype.toggleableObsFactory = function (attribName) {
        return this.quillSelectedAttributes.pipe(map(function (attr) { return !!attr[attribName]; }), 
        // will get many repeat values here due to nature of Quill
        // event stream; prevent unnecessary DOM updates
        distinctUntilChanged());
    };
    /**
     * Make an observable of specified type for a non-toggleable attribute.
     * Configurable to not allow undefined values through
     */
    AnnotationsToolControllerService.prototype.nonToggleableObsFactory = function (attribName, filterFalsey) {
        return this.quillSelectedAttributes.pipe(map(function (attribs) { return attribs[attribName]; }), 
        // can be undefined, filter this out if asked to do so
        filter(function (attrib) { return (filterFalsey ? !!attrib : true); }), 
        // can be an array if multiple styles selcted.
        // we use only the first
        // will get many repeat values here due to nature of Quill
        // event stream; prevent unnecessary DOM updates
        distinctUntilChanged());
    };
    AnnotationsToolControllerService.ngInjectableDef = i0.defineInjectable({ factory: function AnnotationsToolControllerService_Factory() { return new AnnotationsToolControllerService(i0.inject(i1.TimelineStateService), i0.inject(i2.UndoManagerService), i0.inject(i3.PreviewStateService)); }, token: AnnotationsToolControllerService, providedIn: "root" });
    return AnnotationsToolControllerService;
}(SingleClipEditorControllerService));
export { AnnotationsToolControllerService };
