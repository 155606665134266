/*!
 * Copyright 2019 Screencastify LLC
 */

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { sty, VideoClip } from '@castify/edit-models';
import { environment } from 'environments/environment';
import { Log } from 'ng2-logger/browser';
import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  retry,
  switchMap,
} from 'rxjs/operators';
import { SeekDataCollectorService } from '../../seek-data-collector.service';
import { StreamerClientService } from '../../streamer-client.service';
import { PIDController } from '../../util/pid-controller';
import { ClipViewComponent } from '../clip-view/clip-view.component';
import { ConstantDurationSegmentHelper } from '../constant-duration-segment-helper';

// TODO: get that value from the api
const kSegmentDuration: sty.Milliseconds = 1000;
// determine mime string: https://gist.github.com/jimkang/f23ce12c359c7465e83f
// const mimeString = 'video/mp4; codecs="avc1.F4001E"'; // for local test videos
const kVideoMimeString = 'video/mp4; codecs="avc1.64000D"'; // TODO: move to environment

export interface ISegment {
  id: number;
  startInSegment?: sty.Milliseconds; // only set when not starting at the beginning of the segment
  endInSegment?: sty.Milliseconds; // only set when not ending at the end of the segment
  buffer: AsyncSubject<ArrayBuffer>; // the segments data, preloaded during buffering
  bufferSubscription: Subscription; // save subscription to be able to unsubscribe from request on unbuffer
  onPause?: () => void; // pause event handler that gets attached to the video Element when segment gets displayed
}

@Component({
  selector: 'lib-video-clip-view',
  templateUrl: './video-clip-view.component.html',
  styleUrls: ['./video-clip-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoClipViewComponent
  extends ClipViewComponent
  implements OnInit, OnDestroy {
  // data structures to manage displayed video
  @ViewChild('videoT') private _videoT: ElementRef<HTMLVideoElement>;
  @ViewChild('videoF') private _videoF: ElementRef<HTMLVideoElement>;
  protected _videoSelector = true; // stores which of the two video elements is currently displayed
  protected _getVideoElm(videoSelector: boolean): HTMLVideoElement {
    return videoSelector
      ? this._videoT.nativeElement
      : this._videoF.nativeElement;
  }
  protected _segmentOnPauseHandler?: () => void;

  private _log = Log.create('VideoClipViewComponent');

  // every new item will cause switch to specified segment and seek to that position in the segment.
  // also this._displaySegment will get updates as soon as the new segment is ready for playback
  private _seekTo = new Subject<{
    newSeg: ISegment;
    seekPosInSegment: sty.Milliseconds;
    preloadSeg: () => ISegment | null;
  }>();
  private _seekBusy = new BehaviorSubject<boolean>(false);
  protected _displaySegment = new BehaviorSubject<ISegment>(null);
  private _shouldPlay = false;
  private _hasBeenWarmedUp = false;

  // buffer for segments that are relevant for current playback indexed by segment id
  // NOTE: manage active segments through _getSegment and _discardSegmentsNotInRange
  private _bufferedSegments: { [keyof: number]: ISegment } = {};

  protected _clip: VideoClip;
  get currentTime(): sty.Milliseconds {
    const currentSeg = this._displaySegment.value;
    if (!currentSeg) return 0;
    const timeInSegment =
      this._getVideoElm(this._videoSelector).currentTime * 1000;
    const res = Math.min(
      this._clip.duration,
      currentSeg.id * environment.video.segmentDuration +
        timeInSegment -
        this._clip.startInFile
    );
    return res;
  }

  protected subscriptions = new Subscription();
  protected segHelper = new ConstantDurationSegmentHelper(
    environment.video.segmentDuration
  );

  // use scripts in _dev/tune_pid/ to determine pid parameters
  protected _syncPID = new PIDController(6 / 1000, 0.5 / 1000, 0.2 / 1000).set(
    0
  );

  constructor(
    private _seekDataCollector: SeekDataCollectorService,
    protected readonly renderer: Renderer2,
    protected readonly streamerClient: StreamerClientService,
    protected readonly http: HttpClient
  ) {
    super();
  }

  ngOnInit() {
    // generate canPlay observable for playback case
    // NOTE: when playback is running seek is either performed from the segment
    //       before or displaySegment is updated to be the same as the seeking segment. In those cases we want
    //       to wait with propagating state as seeking to another segment will cause the playback to change state to false.
    //       During playback the state should only be set to false in the worst case when we cannot switch segments
    const playbackCanPlay = combineLatest([
      this._seekTo,
      this._displaySegment,
    ]).pipe(
      map(([seekSeg, displaySeg]) => <ISegment[]>[seekSeg.newSeg, displaySeg]),
      filter(
        ([newSeg, displaySeg]) =>
          !!displaySeg &&
          (displaySeg.id + 1 === newSeg.id || displaySeg.id === newSeg.id)
      ),
      map(([newSeg, displaySeg]) => displaySeg.id === newSeg.id),
      distinctUntilChanged(),
      debounceTime(50), // debounce to not have a glitch on every segment change
      distinctUntilChanged()
    );

    // generate canPlay for general case without playback
    // NOTE: when we seek from any other segment than the previous we want to propagate the state as fast as possible
    //       If state propagation is stalled and we switch clips during a seek we can briefly see the previously loaded
    //       position due to the lag in state propagation. If we seek in the same clip the state could propagate lazily
    //       OR instantaneously since we will see the previously loaded segment either way. Here we propagate as fast as
    //       possible in both cases
    const generalCanPlay = combineLatest([
      this._seekTo,
      this._displaySegment,
    ]).pipe(
      map(([seekSeg, displaySeg]) => <ISegment[]>[seekSeg.newSeg, displaySeg]),
      filter(
        ([newSeg, displaySeg]) => !displaySeg || displaySeg.id + 1 !== newSeg.id
      ),
      map(
        ([newSeg, displaySeg]) => !!displaySeg && displaySeg.id === newSeg.id
      ),
      distinctUntilChanged()
    );

    // combine can plays only when both are true the state will be playable
    this.subscriptions.add(
      combineLatest([playbackCanPlay, generalCanPlay])
        .pipe(map(([playback, general]) => playback && general))
        .subscribe(this.canPlay)
    );

    // dispatch calls to _switchVideoSeg
    this.subscriptions.add(
      this._seekTo
        .pipe(
          // on emit: aborts any seek that might still be in progress and starts emitted seek
          switchMap((args) =>
            this._switchVideoSeg(
              args.newSeg,
              args.seekPosInSegment,
              args.preloadSeg
            )
          )
        )
        .subscribe()
    );

    // start playback when requested after seek completes
    this.subscriptions.add(
      this.paused
        .pipe(
          distinctUntilChanged(),
          filter((v) => !v), // filter paused = false
          switchMap(() =>
            this._seekBusy.pipe(
              filter((v) => !v), // filter busy = false
              first()
            )
          )
        )
        .subscribe(() => this._play(this.currentTime))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get startInScene(): sty.Milliseconds {
    return this._clip.startInScene;
  }

  get syncPriority() {
    return this._clip.syncPriority;
  }

  play(): void {
    if (this.paused.value) this.paused.next(false);
  }

  /**
   * play from position (clip time), this method schedules a recursive call to play the next segment
   */
  private _play(pos: sty.Milliseconds): void {
    // check if end is reached
    if (pos >= this._clip.duration) {
      // stop playback
      this.pause();
      return;
    }

    // remove old segment end handler
    this._segmentOnPauseHandler = undefined;
    const segIdx = this.segHelper.segIdxAtPos(this._clip, pos);
    const nextSegStartTime = this.segHelper.segStartPos(this._clip, segIdx + 1);

    // use reliable ended event listener to elm:
    //   ended event does not fire when file is not played to (the original files) end.
    //   So ended does not fire when an end time is set on a clip
    this._segmentOnPauseHandler = () => {
      if (!this.paused.value) {
        this._play(nextSegStartTime);
      }
    };

    // signal segments that they should play
    this._shouldPlay = true;
    this._seekTo.next({
      newSeg: this._bufferSegment(segIdx),
      seekPosInSegment: this.segHelper.segSeekTimeFromClipPos(
        this._clip,
        segIdx,
        pos
      ),
      preloadSeg: () => this._bufferSegment(segIdx + 1),
    });
  }

  pause(): void {
    this._segmentOnPauseHandler = undefined;
    this._shouldPlay = false;
    this._getVideoElm(this._videoSelector).pause();

    // update paused value
    if (!this.paused.value) this.paused.next(true);
  }

  seek(pos: sty.Milliseconds): void {
    if (this.paused.value) {
      // flush streamer queue to get next segment as fast as possible
      this.streamerClient.flushVideoQueue(this._clip.userFileId);
      // get segment at pos
      const segIdx = this.segHelper.segIdxAtPos(this._clip, pos);
      const seg = this._bufferSegment(segIdx);
      this._unbufferSegmentsNotInRange(segIdx - 1, segIdx + 1);
      // seg is null when seeking outside of clip range
      if (!seg) {
        this._log.error('seeking out of clip range');
        return;
      }

      // switch to segment
      this._seekTo.next({
        newSeg: seg,
        seekPosInSegment: this.segHelper.segSeekTimeFromClipPos(
          this._clip,
          segIdx,
          pos
        ),
        preloadSeg: () => null,
      });
    } else {
      this._log.error('Cannot seek during playback');
      return;
    }
  }

  /**
   * sync playback to other view. This should update internal playbackRate to
   * approach refPos (assuming refPos plays at constant rate).
   *
   * @param refPos  value of null indicates that clip is syncRef and should approach playbackRate=1
   * @returns current error to actual playheadPos (currentTime - refPos)
   */
  sync(refPos: sty.Milliseconds | null): sty.Milliseconds {
    // refPos=null indicates this view is sync master. Offset is 0 is this case
    const syncOffset = refPos === null ? 0 : this.currentTime - refPos;
    // adapt playback rate
    const pidOutput = this._syncPID.update(syncOffset);
    // limit to interval (0.5, 1,5)
    const playbackRate = 1 + Math.min(0.5, Math.max(-0.5, pidOutput));
    this._videoT.nativeElement.playbackRate = playbackRate;
    this._videoF.nativeElement.playbackRate = playbackRate;
    return syncOffset;
  }

  /**
   * Buffer segments within time interval [tStart, tStart+duration]
   */
  buffer(tStart: sty.Milliseconds, duration: sty.Milliseconds): void {
    // buffer segments
    for (
      let segIdx = this.segHelper.segIdxAtPos(this._clip, tStart);
      segIdx <= this.segHelper.segIdxAtPos(this._clip, tStart + duration);
      segIdx++
    ) {
      this._bufferSegment(segIdx);
    }
    // free memory
    this._unbufferSegmentsNotInRange(
      Math.max(
        0,
        this.segHelper.segIdxAtPos(
          this._clip,
          tStart - 1 * environment.video.segmentDuration
        )
      ),
      this.segHelper.segIdxAtPos(
        this._clip,
        tStart + duration + 2 * environment.video.segmentDuration
      )
    );

    if (!this._hasBeenWarmedUp) {
      // get earliest instance of bufferedSegment
      const earliestSeg = Object.keys(this._bufferedSegments)
        .map((segIdx) => Number(segIdx))
        .reduce(
          (minSegIdx, currSegIdx) => Math.min(minSegIdx, currSegIdx),
          Infinity
        );
      // seek to start of earliest segment
      // NOTE: we seek to the earliest buffered segment. We have to seek to a segment that was loaded from preview
      //       since we would crash playback otherwise. This ensures that the segment we seek to is (1) loaded and
      //       (2) the earliest in queue. If this happens to be segment 0 we have a lazy init, otherwise preview will
      //       handle the seeking.
      this.seek(
        (earliestSeg === Infinity
          ? 0
          : earliestSeg -
            Math.floor(this._clip.startInFile / kSegmentDuration)) *
          kSegmentDuration
      );
      this._hasBeenWarmedUp = true;
    }
  }

  protected _bufferSegment(segIdx: number): ISegment | null {
    // return null if segment is not in the clip
    if (
      segIdx < this.segHelper.segIdxAtPos(this._clip, 0) ||
      segIdx > this.segHelper.segIdxAtPos(this._clip, this._clip.duration - 1)
    )
      return null;

    // if segment is in buffer: just return the instance
    if (this._bufferedSegments[segIdx]) return this._bufferedSegments[segIdx];

    // We want to monitor events when the users scrubs or seeks. This only happens when the video is paused.
    // Even with the video is playing, to seek, the video pauses for an instant and then seeks.
    if (this.paused.value) {
      this._seekDataCollector.count();
    }

    // create new segment
    const segment = this._buildNewSegment(
      segIdx,
      this.streamerClient.fetchVideo(this._clip, segIdx)
    );
    // add to buffer
    this._bufferedSegments[segment.id] = segment;
    return segment;
  }

  private _buildNewSegment(
    segIdx: number,
    segmentRequest: Observable<ArrayBuffer>
  ): ISegment {
    // calculate timing
    const segStartInFile = segIdx * environment.video.segmentDuration; // file time at which the segment starts
    const startInSegment = Math.max(0, this._clip.startInFile - segStartInFile);
    const endInSegment = Math.min(
      environment.video.segmentDuration,
      this._clip.endInFile - segStartInFile
    );
    // build segment
    const buffer = new AsyncSubject<ArrayBuffer>();
    const seg: ISegment = {
      id: segIdx,
      buffer,
      bufferSubscription: this.subscriptions.add(
        segmentRequest
          .pipe(
            catchError((err) => {
              if (err && err.status === 404) {
                // load black segment instead
                return this.http
                  .get('/assets/black.mp4', { responseType: 'arraybuffer' })
                  .pipe(retry(1));
              }
              throw err;
            })
          )
          .subscribe(buffer)
      ), // start segmentRequest and store subscription
    };
    if (!!startInSegment) seg.startInSegment = startInSegment;
    if (endInSegment !== environment.video.segmentDuration)
      seg.endInSegment = endInSegment;
    return seg;
  }

  /**
   * remove segments that are not relevant for playback
   */
  protected _unbufferSegmentsNotInRange(
    startSegIdx: number,
    endSegIdx: number
  ): void {
    Object.entries(this._bufferedSegments)
      .map(([segIdx, seg]) => [Number(segIdx), seg])
      .filter(([segIdx, _]) => segIdx < startSegIdx || segIdx > endSegIdx)
      .forEach(([segIdx, seg]) => {
        // cancel pending requests
        (<ISegment>seg).bufferSubscription.unsubscribe();
        delete this._bufferedSegments[<number>segIdx];
      });
  }

  ////////////////////////////////////
  // manage current segment

  private _getMediaSourceObjectUrl(seg: ISegment): string {
    const srcObj = new MediaSource();
    const srcUrl = URL.createObjectURL(srcObj);

    function addBufferedSegmentData() {
      URL.revokeObjectURL(srcUrl);

      // hack: somehow this sometimes gets called without the MediaSource being actually open
      if (srcObj.readyState !== 'open') return;
      const srcBuffer = srcObj.addSourceBuffer(kVideoMimeString);
      srcObj.duration = environment.video.segmentDuration / 1000;
      // signal end of stream as soon as buffer has finished updating
      srcBuffer.addEventListener(
        'updateend',
        () => {
          if (srcObj.readyState === 'open') srcObj.endOfStream();
        },
        { once: true }
      );
      // push data to the buffer
      seg.buffer.pipe(filter((v) => !!v)).subscribe((data) => {
        // MediaSource might have been removed from video element in the meantime: only append if source is still open
        if (srcObj.readyState === 'open') srcBuffer.appendBuffer(data);
      });
    }
    srcObj.addEventListener('sourceopen', () => addBufferedSegmentData(), {
      once: true,
    });
    return srcUrl;
  }

  private _switchVideoSeg(
    newSeg: ISegment,
    seekPosInSegment: sty.Milliseconds,
    preloadSeg: () => ISegment | null
  ): Observable<void> {
    return new Observable<void>((observer) => {
      this._seekBusy.next(true);
      const currVid: HTMLVideoElement = this._getVideoElm(this._videoSelector);

      // changes the video elements source to point to seg (initiates load)
      // returns true if source had to be changed at all, otherwise false
      const attachSource = ((
        elm: HTMLVideoElement,
        seg?: ISegment
      ): boolean => {
        // change source only if not already set
        if (seg && seg.id === elm['segIdx']) return false;
        elm.src = seg ? this._getMediaSourceObjectUrl(seg) : '';
        elm.load();
        elm['segIdx'] = seg ? seg.id : undefined; // save segment id that is currently loaded by the video elements
        return true;
      }).bind(this);

      const hideOldVideo = (() => {
        currVid.pause();
        currVid.classList.add('hidden');
        // preload segment
        attachSource(currVid, preloadSeg());
        this._seekBusy.next(false);
        observer.complete();
      }).bind(this);
      const switchToNewVideo = (() => {
        // switch onPauseListener
        currVid.onpause = undefined;
        nextVid.onpause = () => {
          if (this._segmentOnPauseHandler) this._segmentOnPauseHandler();
        };
        // show new video
        if (this._shouldPlay)
          // TODO: returns a promise. Catch this here
          nextVid.play();
        nextVid.classList.remove('hidden');
        // schedule removal of old video
        setTimeout(hideOldVideo.bind(this), 1);
        // toggle video elements
        this._videoSelector = !this._videoSelector;
        this._displaySegment.next(newSeg);
      }).bind(this);

      const nextVid: HTMLVideoElement = this._getVideoElm(!this._videoSelector);
      // switch segment only if newSegment is not loaded on currVid
      if (newSeg.id === currVid['segIdx']) {
        // don't switch, only adjust time:
        currVid.currentTime = seekPosInSegment / 1000;
        if (this._shouldPlay)
          // TODO: returns a promise. Catch this here
          currVid.play();
        // preload segment
        attachSource(nextVid, preloadSeg());
        this._seekBusy.next(false);
        observer.complete();
      } else {
        // schedule switch to nextVid:
        const sourceChanged = attachSource(nextVid, newSeg);
        const seekChangedPosition =
          nextVid.currentTime !== seekPosInSegment / 1000;
        // seek to correct position
        nextVid.currentTime = seekPosInSegment / 1000;

        // optimize timing: seek and wait for canplay event only when needed (video is usually preloaded)
        if (
          !sourceChanged &&
          nextVid.readyState >= nextVid.HAVE_FUTURE_DATA && // ready to play
          !seekChangedPosition
        ) {
          // not actually seeked to new position
          switchToNewVideo();
        } else {
          // canplay fires after every seek (when currentTime gets updated even if the value does not change)
          //    because we always seek, this is ensured to fire
          nextVid.addEventListener('canplay', switchToNewVideo, { once: true });
        }
      }

      function abort() {
        nextVid.removeEventListener('canplay', switchToNewVideo);
      }
      return abort.bind(this);
    });
  }
}
