/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, Input } from '@angular/core';
import { TextBox } from '@castify/edit-models';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { kQuillDefaultAlignment } from '../../quill-config';

@Component({
  selector: 'lib-text-placeholder',
  templateUrl: './text-placeholder.component.html',
  styleUrls: ['./text-placeholder.component.scss'],
})
export class TextPlaceholderComponent {
  // the data used to decide whether/where to render a placeholder
  @Input()
  set textBox(box: TextBox) {
    this._textBox.next(box);
  }
  readonly _textBox = new BehaviorSubject<TextBox | null>(null);

  shouldRender = this._textBox.pipe(
    map((textBox) => !textBox.touched),
    distinctUntilChanged()
  );

  verticalAlignment = this._textBox.pipe(
    map((textBox) => this.getVerticalAlignment(textBox)),
    distinctUntilChanged()
  );

  horizontalAlignment = this._textBox.pipe(
    map((textBox) => this.getHorizontalAlignment(textBox)),
    distinctUntilChanged()
  );

  constructor() {}

  /**
   * Maps from the vertical alignments to flexbox
   * style strings. Intended to help with placeholder
   * positioning in the templates.
   */
  getVerticalAlignment(textBox: TextBox): string {
    const alignmentMap = {
      top: 'flex-start',
      center: 'center',
      bottom: 'flex-end',
    };
    return alignmentMap[textBox.verticalAlignment];
  }

  /**
   *
   */
  getHorizontalAlignment(textBox: TextBox): string {
    return textBox &&
      textBox.delta &&
      textBox.delta[0] &&
      textBox.delta[0].attributes &&
      textBox.delta[0].attributes.align
      ? textBox.delta[0].attributes.align
      : kQuillDefaultAlignment;
  }
}
