/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { FisProject, SceneModel2 } from '@castify/edit-models';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ProjectService } from './project.service';
import { UndoState, UndoStore } from './undo-store';

const log = Log.create('UndoManagerService');
const kUndoHistorySize = 16;

@Injectable({
  providedIn: 'root',
})
export class UndoManagerService {
  readonly scene = new BehaviorSubject<SceneModel2>(new SceneModel2());
  readonly canUndo = new BehaviorSubject<boolean>(false);
  readonly canRedo = new BehaviorSubject<boolean>(false);

  /**
   * true when database update is pending.
   */
  readonly busy = new BehaviorSubject<boolean>(false);

  protected _undoStore: UndoStore = null;

  constructor(protected readonly projectService: ProjectService) {
    this.projectService.project
      .pipe(distinctUntilChanged())
      .subscribe((v) => this._onProjectChange(v));
  }

  /**
   * restore last state from the database, will update this.scene
   */
  undo(): void {
    const project = this.projectService.project.value;
    if (!project) throw new Error('no project set');

    const state = this._undoStore.undo();
    this._syncUndoRedoState(project, state);
  }

  /**
   * similar to undo() but moves forward in history
   */
  redo(): void {
    const project = this.projectService.project.value;
    if (!project) throw new Error('no project set');

    const state = this._undoStore.redo();
    this._syncUndoRedoState(project, state);
  }

  protected _syncUndoRedoState(project: FisProject, state: UndoState): void {
    if (state) {
      this._updateCanUndoRedo();
      this._wrapBusy(project.update({ scene: state.scene, stateId: state.id }));
      this.scene.next(state.scene);
    } else {
      // reset undo store in case the history state cannot be read
      this._undoStore.reset();
      this._undoStore.pushState(project.scene);
      this._updateCanUndoRedo();
    }
  }

  /**
   * updates the scene, persists it to the database and adds an entry to the undo/redo history
   * @param scene the new scene
   * @param action the action message which operation is used on the Scene
   */
  update(scene: SceneModel2, action: string = ''): void {
    const project = this.projectService.project.value;
    if (!project) throw new Error('no project set');
    // make independent copy of the scene to ensure change detection gets triggered
    const state = this._undoStore.pushState(scene ? scene.copy() : null);
    this._syncUndoRedoState(project, state);
  }

  protected _onProjectChange(project: FisProject): void {
    if (!project) return;
    if (project.scene) {
      const initState = { scene: project.scene, id: project.stateId };
      this._undoStore = new UndoStore(initState, kUndoHistorySize);
      this._updateCanUndoRedo();
      this.scene.next(project.scene);
    } else {
      this._undoStore = new UndoStore(null, kUndoHistorySize);
      this.update(new SceneModel2());
    }
  }

  protected _updateCanUndoRedo(): void {
    if (this._undoStore) {
      this.canUndo.next(this._undoStore.undoLength > 1);
      this.canRedo.next(this._undoStore.redoLength > 0);
    } else {
      this.canUndo.next(false);
      this.canRedo.next(false);
    }
  }

  protected _wrapBusy<T>(promise: Promise<T>): void {
    this.busy.next(true);
    promise
      .then(() => this.busy.next(false))
      .catch((err) => {
        this.busy.next(false);
        log.error(err);
      });
  }
}
