/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blur-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blur-box/blur-box.component.ngfactory";
import * as i3 from "../blur-box/blur-box.component";
import * as i4 from "../../blur-editor-controller.service";
import * as i5 from "@angular/common";
import * as i6 from "./blur-view.component";
var styles_BlurViewComponent = [i0.styles];
var RenderType_BlurViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlurViewComponent, data: {} });
export { RenderType_BlurViewComponent as RenderType_BlurViewComponent };
function View_BlurViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-blur-box", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BlurBoxComponent_0, i2.RenderType_BlurBoxComponent)), i1.ɵdid(1, 245760, null, 0, i3.BlurBoxComponent, [i4.BlurEditorControllerService, i1.ElementRef], { blurBox: [0, "blurBox"], editable: [1, "editable"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_BlurViewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { bounds: 0 }), i1.ɵqud(402653184, 2, { container: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["bounds", 1]], null, 4, "div", [["id", "bounds"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["container", 1]], null, 3, "div", [["id", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BlurViewComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co._blurBoxes)); var currVal_1 = _co.trackByIndex; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_BlurViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-blur-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).updateDimensions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BlurViewComponent_0, RenderType_BlurViewComponent)), i1.ɵdid(1, 180224, null, 0, i6.BlurViewComponent, [i1.ElementRef], null, null)], null, null); }
var BlurViewComponentNgFactory = i1.ɵccf("lib-blur-view", i6.BlurViewComponent, View_BlurViewComponent_Host_0, {}, {}, []);
export { BlurViewComponentNgFactory as BlurViewComponentNgFactory };
