/*!
 * Copyright 2019 Screencastify LLC
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { AsyncSubject, BehaviorSubject, combineLatest, EMPTY, empty as observableEmpty, fromEvent as observableFromEvent, merge as observableMerge, of as observableOf, Subject, Subscription, } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, first, map, switchMap, takeWhile, tap, } from 'rxjs/operators';
import { StreamerClientService } from '../../streamer-client.service';
import { ClipViewComponent } from '../clip-view/clip-view.component';
import { ConstantDurationSegmentHelper } from '../constant-duration-segment-helper';
var kSegmentDuration = 10000;
var kAudioMimeString = 'audio/webm; codecs="opus"'; // TODO: move to environment
var AudioClipViewComponent = /** @class */ (function (_super) {
    __extends(AudioClipViewComponent, _super);
    function AudioClipViewComponent(streamerClient) {
        var _this = _super.call(this) || this;
        _this.streamerClient = streamerClient;
        _this._srcObject = new MediaSource();
        _this._srcHeaderLoaded = new AsyncSubject();
        _this._loadHeader = new Subject();
        _this._appendBuffer = new Subject();
        _this._bufferedRanges = new BehaviorSubject([]);
        _this._fetchSubscriptions = [];
        _this._seekTo = new Subject();
        _this._audioElmBusy = new BehaviorSubject(false);
        _this._seekBusy = new BehaviorSubject(false);
        // the difference between threshold of hearing and conversation
        _this._MIN_GAIN = -60;
        _this.subscriptions = new Subscription();
        _this.segHelper = new ConstantDurationSegmentHelper(kSegmentDuration);
        return _this;
    }
    Object.defineProperty(AudioClipViewComponent.prototype, "currentTime", {
        get: function () {
            // TODO: when segment loads, timeupdate sometimes goes to last seekable position
            //          can be reproduced by blocking a single segment (just return for segIdx==1 in _bufferSegment)
            return (this._audioElm.nativeElement.currentTime * 1000 - this._clip.startInFile);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Set gain node
     * Using gain node over audio.volume to can go over 100% volume
     * No need subscribe to clip and to update gain since component
     * re-renders after tool sidebar closes
     */
    AudioClipViewComponent.prototype._setupGainNode = function () {
        this._audioContext = new window.AudioContext();
        var source = this._audioContext.createMediaElementSource(this._audioElm.nativeElement);
        this._gainNode = this._audioContext.createGain();
        source.connect(this._gainNode);
        this._gainNode.connect(this._audioContext.destination);
        this._gainNode.gain.value = this.fromDecibelToRatio(this._clip.gain);
    };
    AudioClipViewComponent.prototype.fromDecibelToRatio = function (decibel) {
        if (decibel < this._MIN_GAIN)
            return 0;
        return Math.pow(10, decibel / 20);
    };
    AudioClipViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._setupGainNode();
        // add handler for appending new source buffers
        var bufferUpdated = new Subject();
        this.subscriptions.add(this._appendBuffer
            .pipe(
        // append one buffer at atime
        concatMap(function (buffer) {
            var updateend = observableFromEvent(_this._srcBuffer, 'updateend').pipe(first());
            _this._srcBuffer.appendBuffer(buffer);
            // wait for updateend event
            return updateend;
        }))
            .subscribe(bufferUpdated));
        // add seek handler, delay seek until the position in buffered
        this.subscriptions.add(this._seekTo
            .pipe(tap(function () { return _this._seekBusy.next(true); }), // seekBusy.next(false) is called in _doSeek()
        // generate observable that emits seekPos once when ready to seek
        switchMap(function (seekPos) {
            return observableMerge(observableOf(undefined), // provide an initial trigger
            bufferUpdated).pipe(filter(function () { return _this._isPositionBuffered(seekPos); }), map(function () { return seekPos; }), first());
        }))
            .subscribe(function (v) { return _this._doSeek(v); }));
        // handle play/pause
        var isPlaying = new BehaviorSubject(false);
        this.subscriptions.add(this.paused
            .pipe(map(function (v) { return !v; }), 
        // control when play and pause should be called:
        switchMap(function (shouldPlay) {
            return shouldPlay
                ? // shouldPlay == true -> play as soon as seeking is not busy
                    _this._seekBusy.pipe(map(function (v) { return !v; }), takeWhile(function (v) { return !v; }, true))
                : // shouldPlay == false -> pause as soon as _audioElmBusy becomes false (indicating that the audio element can take commands)
                    _this._audioElmBusy.pipe(takeWhile(function (v) { return !!v; }, true));
        }), distinctUntilChanged())
            .subscribe(isPlaying));
        this.subscriptions.add(isPlaying.subscribe(function (shouldPlay) { return _this._handlePlay(shouldPlay); }));
        // update canPlay
        // audio only goes into canPLay=false state when actually trying to start playback on an unbuffered
        // position. Audio does not need to be loaded for seeking.
        this.canPlay.next(true);
        // this reflects the state of the audio clip. Generally audio is almost always playable
        var playable = observableMerge(observableFromEvent(this._audioElm.nativeElement, 'canplay').pipe(map(function () { return true; })), observableFromEvent(this._audioElm.nativeElement, 'stalled').pipe(map(function () { return false; }))).pipe(distinctUntilChanged());
        // The addition of _seekBusy is desireable to keep the playhead at a correct position at all times.
        // if omitted we could see jumping when the state of audio-clip-view is not ready even though
        // audio is in fact playable.
        this.subscriptions.add(combineLatest([this._seekBusy, playable])
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), seekBusy = _b[0], playable = _b[1];
            return !seekBusy && playable;
        }))
            .subscribe(this.canPlay));
        // attach source object
        this._audioElm.nativeElement.src = URL.createObjectURL(this._srcObject);
        this._audioElm.nativeElement.load();
        this.subscriptions.add(combineLatest([
            this._loadHeader,
            observableFromEvent(this._srcObject, 'sourceopen'),
        ])
            .pipe(filter(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], sourceopen = _b[1];
            return !!sourceopen;
        }), filter(function () { return !!_this.streamerClient; }), // might get called after the clip has been destroyed
        concatMap(function () {
            return _this.streamerClient.fetchAudio(_this._clip, -1).pipe(
            // ignore errors and rely on buffering to retry on next seek
            catchError(function () { return observableEmpty(); }));
        }), first())
            .subscribe(function (buffer) {
            URL.revokeObjectURL(_this._audioElm.nativeElement.src);
            _this._srcBuffer = _this._srcObject.addSourceBuffer(kAudioMimeString);
            // set duration
            _this._srcObject.duration = _this._clip.duration / 1000;
            _this._appendBuffer.next(buffer);
            // resolve srcHeaderLoaded
            _this._srcHeaderLoaded.next(null);
            _this._srcHeaderLoaded.complete();
        }));
    };
    AudioClipViewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this._cancelFetchSubscriptionsNotInRange(-Infinity, -Infinity);
    };
    Object.defineProperty(AudioClipViewComponent.prototype, "startInScene", {
        get: function () {
            return this._clip.startInScene;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AudioClipViewComponent.prototype, "syncPriority", {
        get: function () {
            return this._clip.syncPriority;
        },
        enumerable: true,
        configurable: true
    });
    AudioClipViewComponent.prototype.play = function () {
        this.paused.next(false);
    };
    AudioClipViewComponent.prototype.pause = function () {
        this.paused.next(true);
    };
    AudioClipViewComponent.prototype._handlePlay = function (shouldPlay) {
        var _this = this;
        if (shouldPlay) {
            this._audioElmBusy.next(true);
            this._audioContext.resume();
            this._audioElm.nativeElement
                .play()
                .then(function () { return _this._audioElmBusy.next(false); })
                .catch(function () { return _this._audioElmBusy.next(false); });
        }
        else {
            this._audioElm.nativeElement.pause();
        }
    };
    AudioClipViewComponent.prototype.seek = function (pos) {
        this._seekTo.next(pos);
    };
    AudioClipViewComponent.prototype._doSeek = function (pos) {
        this._bufferSegment(this.segHelper.segIdxAtPos(this._clip, pos));
        this._audioElm.nativeElement.currentTime =
            (pos + this._clip.startInFile) / 1000;
        this._seekBusy.next(false);
    };
    AudioClipViewComponent.prototype.sync = function (refPos) {
        if (refPos !== null) {
            // adapting playback speed sounds bad, syncing requires additional thoughts.
            // Right now only one audio clip should be in the scene at any given position, so this is not required
            throw new Error('not implemented');
        }
        return 0;
    };
    AudioClipViewComponent.prototype.buffer = function (tStart, duration) {
        // trigger header load
        this._loadHeader.next();
        // buffer segments
        for (var segIdx = this.segHelper.segIdxAtPos(this._clip, tStart); segIdx <= this.segHelper.segIdxAtPos(this._clip, tStart + duration); segIdx++) {
            this._bufferSegment(segIdx);
        }
        // cancel all pending subscriptions for segments that are not in buffer range
        this._cancelFetchSubscriptionsNotInRange(Math.max(0, this.segHelper.segIdxAtPos(this._clip, tStart - 0.4 * kSegmentDuration)), this.segHelper.segIdxAtPos(this._clip, tStart + duration + 1.4 * kSegmentDuration));
    };
    AudioClipViewComponent.prototype._bufferSegment = function (segIdx) {
        var _this = this;
        // only load data if segment is in clip
        if (segIdx < this.segHelper.segIdxAtPos(this._clip, 0) ||
            segIdx > this.segHelper.segIdxAtPos(this._clip, this._clip.duration - 1))
            return;
        // skip loading if already loaded
        if (this._fetchSubscriptions[segIdx])
            return;
        // cancel all requests for thi segment might have been started previously
        if (this._fetchSubscriptions[segIdx])
            this._fetchSubscriptions[segIdx].unsubscribe();
        // start fetch but wait for srcHeaderLoaded to resolve before appending buffer
        this._fetchSubscriptions[segIdx] = combineLatest([
            this.streamerClient.fetchAudio(this._clip, segIdx).pipe(
            // ignore errors - will be retried on next buffer
            catchError(function () { return EMPTY; })),
            this._srcHeaderLoaded,
        ])
            .pipe(filter(function (_a) {
            var _b = __read(_a, 2), buffer = _b[0], _ = _b[1];
            return !!buffer;
        }), map(function (_a) {
            var _b = __read(_a, 2), buffer = _b[0], _ = _b[1];
            return buffer;
        }))
            .subscribe(function (buffer) { return _this._appendBuffer.next(buffer); });
    };
    AudioClipViewComponent.prototype._cancelFetchSubscriptionsNotInRange = function (startSegIdx, endSegIdx) {
        this._fetchSubscriptions.forEach(function (subscription, segIdx, arr) {
            if (subscription && (segIdx < startSegIdx || segIdx > endSegIdx)) {
                subscription.unsubscribe();
                arr[segIdx] = null;
            }
        });
    };
    /**
     * check if buffered at pos (in clip time)
     */
    AudioClipViewComponent.prototype._isPositionBuffered = function (pos) {
        var fileSeekTime = pos + this._clip.startInFile;
        var buffered = this._audioElm.nativeElement.buffered;
        for (var i = 0; i < buffered.length; i++) {
            if (buffered.start(i) * 1000 <= fileSeekTime &&
                buffered.end(i) * 1000 >= fileSeekTime)
                return true;
        }
        return false;
    };
    return AudioClipViewComponent;
}(ClipViewComponent));
export { AudioClipViewComponent };
