/*!
 * Copyright 2019 Screencastify LLC
 */

import { Component } from '@angular/core';
import { ClipModel2, sty } from '@castify/edit-models';
import { BehaviorSubject } from 'rxjs';

@Component({
  template: '',
})
export class ClipViewComponent<TClip extends ClipModel2 = ClipModel2> {
  set clip(clip: TClip) {
    this._clip = clip;
  }
  protected _clip: TClip;
  // not using Subject because HTMLMediaElement has nothing like an "currentTimeChange" event.
  get currentTime(): sty.Milliseconds {
    return this._currentTime;
  }
  protected _currentTime: sty.Milliseconds = 0;
  // indicates playback state. Implementations should make sure this fires whenever pausing for any reason.
  readonly paused = new BehaviorSubject<boolean>(true);
  // indicates whether enough data is buffered
  readonly canPlay = new BehaviorSubject<boolean>(false);

  constructor() {}

  get syncPriority(): number {
    return 0;
  }

  get startInScene(): sty.Milliseconds {
    throw new Error('not implemented');
  }

  /**
   * start playback
   */
  play(): void {
    throw new Error('not implemented');
  }

  /**
   * pause at current position
   */
  pause(): void {
    throw new Error('not implemented');
  }

  /**
   * perform precise seek to pos
   */
  seek(pos: sty.Milliseconds): void {
    throw new Error('not implemented');
  }

  /**
   * start displaying
   */
  onDisplay(): void {
    return;
  }

  /**
   * end displaying
   */
  onHide(): void {
    return;
  }

  /**
   * sync playback to other view. This should update internal playbackRate to
   * approach refPos (assuming refPos plays at constant rate).
   *
   * @param refPos  value of null indicates that clip is syncRef and should approach playbackRate=1
   * @returns current error to actual playheadPos (currentTime - refPos)
   */
  sync(refPos: sty.Milliseconds | null): sty.Milliseconds {
    this._currentTime = refPos;
    return this.currentTime - refPos;
  }

  /**
   * Buffer segments within time interval [tStart, tStart+duration]
   */
  buffer(tStart: sty.Milliseconds, duration: sty.Milliseconds): void {
    return;
  }
}
