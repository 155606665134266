/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2018 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./browser-guard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "./browser-guard.component";
var styles_BrowserGuardComponent = [i0.styles];
var RenderType_BrowserGuardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrowserGuardComponent, data: {} });
export { RenderType_BrowserGuardComponent as RenderType_BrowserGuardComponent };
export function View_BrowserGuardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-toolbar", [["class", "mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unsupported Browser"])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "mat-body-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry, your browser is not supported yet. "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Only up-to-date versions of "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://www.google.com/chrome/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Google Chrome"])), (_l()(), i1.ɵted(-1, null, ["Support for more browsers coming soon. "]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_BrowserGuardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edit-browser-guard", [], null, null, null, View_BrowserGuardComponent_0, RenderType_BrowserGuardComponent)), i1.ɵdid(1, 114688, null, 0, i6.BrowserGuardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrowserGuardComponentNgFactory = i1.ɵccf("edit-browser-guard", i6.BrowserGuardComponent, View_BrowserGuardComponent_Host_0, {}, {}, []);
export { BrowserGuardComponentNgFactory as BrowserGuardComponentNgFactory };
