/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
var EditorLogoComponent = /** @class */ (function () {
    function EditorLogoComponent() {
    }
    EditorLogoComponent.prototype.ngOnInit = function () { };
    return EditorLogoComponent;
}());
export { EditorLogoComponent };
