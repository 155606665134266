/*!
 * Copyright 2019 Screencastify LLC
 */
import { ZoomEditorComponent } from './components/zoom-editor/zoom-editor.component';
import { ZoomToolbarComponent } from './components/zoom-toolbar/zoom-toolbar.component';
var kEntryComponents = [ZoomToolbarComponent, ZoomEditorComponent];
var LibZoomEditorModule = /** @class */ (function () {
    function LibZoomEditorModule() {
    }
    return LibZoomEditorModule;
}());
export { LibZoomEditorModule };
