/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { UserService } from '../auth/user.service';
import { GapiLoaderService } from './gapi-loader.service';

@Injectable({
  providedIn: 'root',
})
export class DrivePickerService {
  private _mimeTypes = [
    'video/mp4',
    'video/webm',
    'video/mkv',
    'video/flv',
    'video/f4v',
    'video/avi',
    'video/mov',
    'video/wmv',
    'video/mpg',
    'video/mpeg',
    'video/m2v',
    'video/m4v',
    'video/3gp',
    'video/3g2',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-matrosk',
  ];
  private _shareClient = null;

  constructor(
    private user: UserService,
    private _gapiLoader: GapiLoaderService
  ) {}

  /**
   * Show drive sharing settings dialog
   * @param ids file ids to change sharing settings for.
   */
  async openShareDialog(ids: string[]) {
    // Cache shareClient, docs do not state how to dispose it. Using it multiple times seems to
    // create multiple dialog shadows.
    if (!this._shareClient) {
      await this._gapiLoader.loadShareLibs();
      const gapi = window['gapi'];
      this._shareClient = new gapi.drive.share.ShareClient(
        environment.auth.driveAppId
      );
    }
    const client = this._shareClient;
    const authToken = (await this.user.gapiAuth()).access_token;
    client.setOAuthToken(authToken);
    client.setItemIds(ids);
    client.showSettingsDialog();
  }

  /**
   * Open drive file picker dialog.
   */
  async openPicker(): Promise<any[]> {
    await this._gapiLoader.loadPickerLibs();
    const google = window['google'];
    const view = new google.picker.DocsView();
    view.setMimeTypes(this._mimeTypes.join(','));
    view.setIncludeFolders(true);
    view.setOwnedByMe(true);
    const authToken = (await this.user.gapiAuth()).access_token;

    return new Promise<any[]>((resolve) => {
      const picker = new google.picker.PickerBuilder()
        // .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setTitle('Select videos to import')
        .setAppId(environment.auth.driveAppId)
        .setOAuthToken(authToken)
        .addView(view)
        .addView(google.picker.ViewId.DOCS_VIDEOS)
        // .addView(new google.picker.DocsUploadView())
        .setCallback((result) => {
          const picker = google.picker;
          if (result[picker.Response.ACTION] === picker.Action.PICKED) {
            resolve(result[picker.Response.DOCUMENTS]);
          } else if (result[picker.Response.ACTION] === picker.Action.CANCEL) {
            resolve(null);
          }
        })
        .build();
      picker.setVisible(true);
    });
  }
}
