/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Limits, Size } from '@castify/models';

@Component({
  selector: 'lib-move-icon',
  templateUrl: './move-icon.component.html',
  styleUrls: ['./move-icon.component.scss'],
})
export class MoveIconComponent implements OnInit {
  @ViewChild('moveIcon', { read: ElementRef })
  moveIcon: ElementRef<HTMLElement>;

  constructor(private hostElm: ElementRef<HTMLDivElement>) {}

  ngOnInit() {
    this.resize();
  }

  resize(): void {
    if (!this.hostElm || !this.moveIcon) return;
    const maxSize = 0.7; // fraction of height (or with) of the parent element that the icon can take
    const hostDims = Size.fromObj(
      this.hostElm.nativeElement.parentElement.getBoundingClientRect()
    );
    const iconDims = new Size(
      this.moveIcon.nativeElement.offsetWidth,
      this.moveIcon.nativeElement.offsetHeight
    );
    const scale =
      iconDims.scaleToFit(hostDims.mul(maxSize)).width / iconDims.width; // doesn't matter if we're using width or height
    this.moveIcon.nativeElement.style.transform = `scale(${new Limits(
      0,
      1
    ).apply(scale)})`;
  }
}
