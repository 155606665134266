/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blur-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component.ngfactory";
import * as i3 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component";
import * as i4 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./blur-toolbar.component";
import * as i13 from "../../blur-editor-controller.service";
import * as i14 from "../../../keyboard-shortcuts/keyboard.service";
var styles_BlurToolbarComponent = [i0.styles];
var RenderType_BlurToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlurToolbarComponent, data: {} });
export { RenderType_BlurToolbarComponent as RenderType_BlurToolbarComponent };
export function View_BlurToolbarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { _toolbarContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 26, "lib-tool-sidebar-content", [], null, null, null, i2.View_SideToolbarContentComponent_0, i2.RenderType_SideToolbarContentComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.SideToolbarContentComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["id", "toolbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["lens_blur"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Blur"])), (_l()(), i1.ɵeld(9, 0, null, 0, 15, "div", [["id", "toolbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h4", [["id", "field-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Intensity"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "input", [["class", "slider"], ["max", "1"], ["min", "0"], ["step", "0.01"], ["type", "range"]], [[8, "value", 0]], [[null, "input"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onIntensityChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.keyboardShortcuts.pauseAll() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.keyboardShortcuts.resumeAll() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "labels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Low"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["High"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["id", "new-blur-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(23, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" + New Blur "])), (_l()(), i1.ɵeld(25, 0, null, 0, 2, "button", [["id", "done-button"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(26, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Done "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.sliderGradient)); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).inline; var currVal_1 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 15).transform(_co.intensity)); _ck(_v, 12, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 23).disabled || null); var currVal_5 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); _ck(_v, 22, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 26).disabled || null); var currVal_7 = (i1.ɵnov(_v, 26)._animationMode === "NoopAnimations"); _ck(_v, 25, 0, currVal_6, currVal_7); }); }
export function View_BlurToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-blur-toolbar", [], null, null, null, View_BlurToolbarComponent_0, RenderType_BlurToolbarComponent)), i1.ɵdid(1, 180224, null, 0, i12.BlurToolbarComponent, [i13.BlurEditorControllerService, i14.KeyboardService], null, null)], null, null); }
var BlurToolbarComponentNgFactory = i1.ɵccf("lib-blur-toolbar", i12.BlurToolbarComponent, View_BlurToolbarComponent_Host_0, {}, {}, []);
export { BlurToolbarComponentNgFactory as BlurToolbarComponentNgFactory };
