/*!
 * Copyright 2019 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import { fbWrapper } from '@castify/models';
import { environment } from 'environments/environment';
import * as firebase from 'firebase';
import { Log } from 'ng2-logger/browser';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { GapiLoaderService } from '../common/gapi-loader.service';

const log = Log.create('UserService');

/**
 * provides user and license information
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private fbAuth: firebase.auth.Auth = fbWrapper().auth();
  readonly user = new BehaviorSubject<firebase.User | null>(undefined);

  /**
   * current signed in user or null if no user signed in
   */
  get currentUser(): firebase.User | null {
    return this.user.value || null;
  }

  /**
   * true when a user issigned in
   */
  get isSignedIn(): boolean {
    return !!this.user.value;
  }

  constructor(private gapiLoader: GapiLoaderService) {
    // provide observable for firebase user
    this.fbAuth.onAuthStateChanged((user) => {
      if (user !== this.user.value) this.user.next(user);
    });
  }

  /**
   * Sign in with a gapi access token. This is
   */
  async signInWithGoogleAccessToken(accessToken: string): Promise<void> {
    log.info('signing in with gapi access token');
    const cred = firebase.auth.GoogleAuthProvider.credential(null, accessToken);
    await this.fbAuth.signInWithCredential(cred);
    this.fbAuth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => log.info('updated auth persistence'))
      .catch((err) => log.error('failed to set persistence', err));
  }

  async signInFromCache(): Promise<boolean> {
    if (this.isSignedIn) return true;
    // wait cached firebase session
    await this.user
      .pipe(
        filter((u) => u !== undefined), // undefined indicates that firebase auth cache has not been read yet
        first()
      )
      .toPromise();
    return this.isSignedIn;
  }

  /**
   * Obtain a gapi auth response for the currently signed in user (firebase user)
   * without prompting the user.
   * This works, because the dashboard authenticates against gapi. That auth state is
   * then simply read when we auth silently.
   * gapi also caches the auth state, so that the returned promise resolves immediately
   * if a previously received access_token is not expired.
   */
  async gapiAuth(): Promise<gapi.auth2.AuthorizeResponse> {
    if (!this.isSignedIn) throw new Error('not signed in');

    await this.gapiLoader.loadAuth2(); // ensure auth api is loaded
    return await new Promise<gapi.auth2.AuthorizeResponse>(
      (resolve, reject) => {
        gapi.auth2.authorize(
          {
            client_id: environment.auth.clientId,
            scope: environment.auth.scopes.join(' '),
            prompt: 'none',
            login_hint: UserService.getGoogleUid(this.currentUser),
          },
          (resp) => {
            if (resp.error) {
              reject(resp.error);
            } else {
              resolve(resp);
            }
          }
        );
      }
    );
  }

  /**
   * dev signin with email and passowrd.
   * NOTE: this will produce a crippeled auth state, because we can only authenticate firebase using email+password,
   *  but nor gapi. So access to google drive will not be possible / only possible from backends, given that a
   *  valid offline access code/refresh token is present in datastore
   */
  async _signInFirebaseWithPassword(
    email: string,
    password: string
  ): Promise<void> {
    await this.fbAuth.signInWithEmailAndPassword(email, password);
    await this.fbAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  /**
   * Signs out the current user and redirects to dashboard to select new account
   */
  async signOut(): Promise<void> {
    await this.fbAuth.signOut();
    // redirect to dashboard sign in endpoint
    this.initSignIn('', true);
  }

  async initSignIn(
    returnPath: string,
    selectAccount: boolean = false
  ): Promise<void> {
    if (environment.auth.signInUrl) {
      // build the complete url for requesting signin from the dashboard
      const url = new URL(environment.auth.signInUrl);
      url.searchParams.set(
        'returnPath',
        `/signin?returnUrl=${encodeURIComponent(returnPath)}`
      );
      // signal dashboard that users should select a new account
      if (selectAccount) url.searchParams.set('selectAccount', '');
      // navigate to dashboard
      log.info('navigating to dashboard signin:', url.toString());
      window.location.replace(url.toString());
    } else {
      window.location.replace(
        `/signin?returnUrl=${encodeURIComponent(returnPath)}`
      );
    }
  }

  /**
   * @returns google UID of the user
   */
  public static getGoogleUid(user: firebase.User): string {
    return user.providerData.find(
      (profile) => profile.providerId === 'google.com'
    ).uid;
  }
}
