/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatSliderModule,
  MatTooltipModule,
} from '@angular/material';
import { LibToolSidebarModule } from '../tool-sidebar/tool-sidebar.module';
import { AudioToolbarComponent } from './components/audio-toolbar/audio-toolbar.component';

const kEntryComponents = [AudioToolbarComponent];

@NgModule({
  declarations: [...kEntryComponents],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    LibToolSidebarModule,
    MatIconModule,
    MatSliderModule,
  ],
  entryComponents: kEntryComponents,
})
export class LibAudioToolModule {}
