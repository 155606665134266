/*!
 * Copyright 2020 Screencastify LLC
 */
import { ConfirmNewProjectComponent } from './components/confirm-new-project/confirm-new-project.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
var exportDeclarations = [SidenavComponent];
var entryComponents = [ConfirmNewProjectComponent];
var LibSidenavModule = /** @class */ (function () {
    function LibSidenavModule() {
    }
    return LibSidenavModule;
}());
export { LibSidenavModule };
