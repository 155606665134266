/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crop-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component.ngfactory";
import * as i3 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component";
import * as i4 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./crop-toolbar.component";
import * as i12 from "../../crop-editor-controller.service";
var styles_CropToolbarComponent = [i0.styles];
var RenderType_CropToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropToolbarComponent, data: {} });
export { RenderType_CropToolbarComponent as RenderType_CropToolbarComponent };
export function View_CropToolbarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { _toolbarContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "lib-tool-sidebar-content", [], null, null, null, i2.View_SideToolbarContentComponent_0, i2.RenderType_SideToolbarContentComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.SideToolbarContentComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["id", "toolbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["crop"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Crop Clip"])), (_l()(), i1.ɵeld(9, 0, null, 0, 0, "div", [["id", "toolbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "button", [["id", "done-button"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Done "]))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).inline; var currVal_1 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_CropToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-crop-toolbar", [], null, null, null, View_CropToolbarComponent_0, RenderType_CropToolbarComponent)), i1.ɵdid(1, 49152, null, 0, i11.CropToolbarComponent, [i12.CropEditorControllerService], null, null)], null, null); }
var CropToolbarComponentNgFactory = i1.ɵccf("lib-crop-toolbar", i11.CropToolbarComponent, View_CropToolbarComponent_Host_0, {}, {}, []);
export { CropToolbarComponentNgFactory as CropToolbarComponentNgFactory };
