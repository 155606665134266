/*!
 * Copyright 2020 Screencastify LLC
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatListModule,
  MatToolbarModule,
} from '@angular/material';
import { LibAuthModule } from '../auth/lib-auth.module';
import { LibTitlebarModule } from '../titlebar/lib-titlebar.module';
import { ConfirmNewProjectComponent } from './components/confirm-new-project/confirm-new-project.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

const exportDeclarations = [SidenavComponent];
const entryComponents = [ConfirmNewProjectComponent];
@NgModule({
  declarations: [
    ...exportDeclarations,
    ...entryComponents,
    UserAvatarComponent,
  ],
  imports: [
    CommonModule,
    LibAuthModule,
    LibTitlebarModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: exportDeclarations,
  entryComponents,
})
export class LibSidenavModule {}
