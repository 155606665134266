/*!
 * Copyright 2019 Screencastify LLC
 */
import { BehaviorSubject } from 'rxjs';
var ProjectServiceMock = /** @class */ (function () {
    function ProjectServiceMock() {
        this.project = new BehaviorSubject(null);
    }
    return ProjectServiceMock;
}());
export { ProjectServiceMock };
