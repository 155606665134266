var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { interval } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "angulartics2/ga";
/**
 * Centralized service to track when seeks/scrubs are happening
 * Used to get an idea of how much random access seeks are requested on the system
 * and how frequently the user ask for per time interval
 */
var SeekDataCollectorService = /** @class */ (function () {
    function SeekDataCollectorService(_angulartics) {
        var _this = this;
        this._angulartics = _angulartics;
        this._seekCount = 0;
        this.CATEGORY = 'seeking/scrubbing';
        // tracks seek rate every 5 seconds
        // TODO: remove when done - it's very noisy
        interval(5 * 1000)
            .pipe(map(function () { return _this._seekCount; }), pairwise())
            .subscribe(function (_a) {
            var _b = __read(_a, 2), previousSeekCount = _b[0], currentSeekCount = _b[1];
            // To avoid flooding our system, we only capture 10% of seek rate
            // analytics only tracks the first 500 events per session
            if (++_this._seekCount && Math.random() < 0.1) {
                _this._angulartics.eventTrack('seek rate (seeks/second)', {
                    label: Math.round((currentSeekCount - previousSeekCount) / 5),
                    category: _this.CATEGORY,
                    value: 1,
                    noninteraction: true,
                });
            }
        });
    }
    /**
     * call this method each time a seek is happening in the app
     */
    SeekDataCollectorService.prototype.count = function () {
        // To avoid flooding our system, we only capture 1% of seek events
        // analytics only tracks the first 500 events per session
        if (++this._seekCount && Math.random() < 0.01) {
            this._angulartics.eventTrack('count (sampled @ 1%)', {
                category: this.CATEGORY,
                value: 1,
                noninteraction: true,
            });
        }
    };
    SeekDataCollectorService.ngInjectableDef = i0.defineInjectable({ factory: function SeekDataCollectorService_Factory() { return new SeekDataCollectorService(i0.inject(i1.Angulartics2GoogleAnalytics)); }, token: SeekDataCollectorService, providedIn: "root" });
    return SeekDataCollectorService;
}());
export { SeekDataCollectorService };
