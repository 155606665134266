/*!
 * Copyright 2020 Screencastify LLC
 */
import { EventEmitter, OnInit } from '@angular/core';
import { LicenseService } from 'lib-editor/lib/auth/license.service';
/**
 * This component exists to encapsulate all of the titlebar logic
 */
var TitlebarComponent = /** @class */ (function () {
    function TitlebarComponent(license) {
        this.license = license;
        this.openNav = new EventEmitter();
    }
    TitlebarComponent.prototype.ngOnInit = function () { };
    return TitlebarComponent;
}());
export { TitlebarComponent };
