/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*!
 * Copyright 2020 Screencastify LLC

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./still-clip-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component.ngfactory";
import * as i4 from "../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component";
import * as i5 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./still-clip-toolbar.component";
import * as i11 from "../../still-clip-tool-controller.service";
var styles_StillClipToolbarComponent = [i0.styles];
var RenderType_StillClipToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StillClipToolbarComponent, data: {} });
export { RenderType_StillClipToolbarComponent as RenderType_StillClipToolbarComponent };
function View_StillClipToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "swatch"], ["type", "button"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ctrl.setBackgroundColor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { selected: 0, white: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "swatch"; var currVal_2 = _ck(_v, 3, 0, (i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.ctrl.backgroundColor)) === _v.context.$implicit), (_v.context.$implicit === "ffffff")); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("#" + _v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
export function View_StillClipToolbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _toolbarContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "lib-tool-sidebar-content", [], null, null, null, i3.View_SideToolbarContentComponent_0, i3.RenderType_SideToolbarContentComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i4.SideToolbarContentComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "div", [["id", "toolbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["id", "title-clips-icon"], ["src", "assets/title-clips-icon-white.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title"])), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "div", [["id", "toolbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [["id", "field-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Background"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["id", "swatch-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StillClipToolbarComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "button", [["id", "done-button"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Done "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorChoices; _ck(_v, 12, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 14).disabled || null); var currVal_2 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_1, currVal_2); }); }
export function View_StillClipToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-still-clip-toolbar", [], null, [["window", "mousedown"]], function (_v, en, $event) { var ad = true; if (("window:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeIfClickedOutside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StillClipToolbarComponent_0, RenderType_StillClipToolbarComponent)), i1.ɵdid(1, 49152, null, 0, i10.StillClipToolbarComponent, [i11.StillClipToolControllerService], null, null)], null, null); }
var StillClipToolbarComponentNgFactory = i1.ɵccf("lib-still-clip-toolbar", i10.StillClipToolbarComponent, View_StillClipToolbarComponent_Host_0, {}, {}, []);
export { StillClipToolbarComponentNgFactory as StillClipToolbarComponentNgFactory };
