/*!
 * Copyright 2020 Screencastify LLC
 */

import { HttpClient, HttpEventType } from '@angular/common/http';
import {
  HttpEvent,
  HttpUploadProgressEvent,
} from '@angular/common/http/src/response';
import { Injectable, OnDestroy } from '@angular/core';
import {
  FisUserFile,
  IImportResult,
  IInitUploadResult,
  SceneEditor2,
} from '@castify/edit-models';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { StatsHelperService } from 'lib-editor/lib/common/stats-helper.service';
import { Log, Logger } from 'ng2-logger/browser';
import {
  AsyncSubject,
  combineLatest,
  defer,
  Observable,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  endWith,
  filter,
  first,
  last,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import { FisMediaInfo } from '../../../../../../../models/lib';
import { ProjectService } from '../../common/project.service';
import { UiApiService } from '../../common/ui-api.service';
import { UndoManagerService } from '../../common/undo-manager.service';
import { TimelineStateService } from '../../timeline/timeline-state.service';

/**
 * When files are uploaded and a progress bar is shown to the user,
 * what percentage of the progress bar represents upload progress
 * (vs. import progress). Import progress weight will be 1 minus this value
 */
export const kUploadToImport = 0.8;

@Injectable()
export class LocalImportBrainService implements OnDestroy {
  private _log: Logger<any> = Log.create('LocalImportBrainService');
  private subscriptions = new Subscription();
  /**
   * Emits when we're ready to ask the user for a file
   */
  readonly readyToAskUserForFile = defer(() => this._project.project).pipe(
    filter((project) => !!project)
  );

  /**
   * Emits once the service knows what file should be uploaded
   */
  _fileToUpload = new AsyncSubject<File>();

  /**
   * Triggers the api call to initialize an upload
   */
  _importInit = defer(() =>
    combineLatest([this._fileToUpload.pipe(last()), this._project.project])
  ).pipe(
    filter(([file, project]) => !!file && !!project),
    map(([, project]) => project),
    switchMap((project) => this._doInitializeUpload(project.id)),
    shareReplay()
  );

  /**
   * Uploads a file, transforming the response to a number
   * between 0 and 1 representing progress, completing
   * when the upload has finished
   */
  _uploadProgress = defer(() =>
    combineLatest([
      this._fileToUpload.pipe(last()),
      this._importInit.pipe(map((initResp) => initResp.uploadUrl)),
    ])
  ).pipe(
    // kick off a put request, get back observable HttpEvents
    switchMap(([file, uploadUrl]) => this._uploadFile(file, uploadUrl)),

    // Completes the observable when we get a response event
    takeWhile((event) => event && event.type !== HttpEventType.Response, true),

    // Discard all but UploadProgress events
    filter((event) => event && event.type === HttpEventType.UploadProgress),

    // Transform to number describing progress
    map((event: HttpUploadProgressEvent) => event.loaded / event.total),

    // Progress always starts with zero and ends with 1
    startWith(0),
    endWith(1),

    distinctUntilChanged(),
    shareReplay()
  );

  /**
   * Triggers the api call to start importing the uploaded file
   *
   * Must be subscribed externally to kick off import on upload completion
   */
  _importStart = defer(() =>
    combineLatest([this._importInit, this._uploadProgress.pipe(last())])
  ).pipe(
    filter(([, uploadProgress]) => !!uploadProgress),
    switchMap(([uploadInitResp]) => this._startImport(uploadInitResp)),
    shareReplay()
  );

  /**
   * Tracks progress of the UserFile that is being imported.
   * Completes when user file ready for editing
   */
  _userFileProgress = defer(() => this._importInit).pipe(
    map((uploadInitResp) => uploadInitResp.file),
    switchMap((file) => FisUserFile.byId<FisUserFile>(file.id).onProgress()),
    // complete when ready for editing
    takeWhile((file) => !file.sourceFileLoaded || !file.mediaInfo, true),
    shareReplay()
  );

  /**
   * Emits accumulated progress for upload + import
   */
  readonly progress = defer(() =>
    combineLatest([
      this._uploadProgress,
      this._userFileProgress.pipe(
        filter((v) => !!v),
        map((userFile) => userFile.progress)
      ),
    ])
  ).pipe(
    map(([uploadProgess, importProgress]) =>
      this._calculateOverallProgress(uploadProgess, importProgress)
    ),
    shareReplay()
  );

  /**
   * Emits user file when it's ready to be added to the scene
   *
   * Must be subscribed to externally; subscription should call _addFileToTimeline
   */
  readonly userFileReadyForEditing = defer(() => this._userFileProgress).pipe(
    last()
  );

  constructor(
    private _uiApi: UiApiService,
    private _project: ProjectService,
    private _http: HttpClient,
    private _undoManager: UndoManagerService,
    private _angulartics: Angulartics2GoogleAnalytics,
    private _statsHelper: StatsHelperService,
    private _timelineState: TimelineStateService
  ) {}

  init(): void {
    // Start the import once file has been uploaded
    this.subscriptions.add(this._importStart.subscribe());

    // Add the clip to the timeline & close the modal
    // when import is complete and it is ready for editing
    this.subscriptions.add(
      this.userFileReadyForEditing.subscribe((userFile) => {
        this._addFileToTimeline(userFile);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Use this to input any file that should be uploaded from local
   * disk and imported
   */
  public setFile(file: File): void {
    this._fileToUpload.next(file);
    this._fileToUpload.complete();
  }

  /**
   * Use to PUT a file to a url and report on progress
   */
  _uploadFile(file: File, uploadUrl: string): Observable<HttpEvent<Blob>> {
    this._log.data('Upload from local machine %o', file);
    return this._http.put(uploadUrl, file, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      reportProgress: true,
      observe: 'events',
    });
  }

  /**
   * Kicks off an import via the api
   */
  _startImport(uploadInitResp: IInitUploadResult): Observable<IImportResult> {
    return this._uiApi.uploadImport({ userFileId: uploadInitResp.file.id });
  }

  /**
   * Adds a file to the timeline
   */
  _addFileToTimeline(file: FisUserFile): void {
    const scene = this._undoManager.scene.value;
    const newClips = new SceneEditor2(scene).addClipsFromFile(file);
    this._undoManager.update(scene);
    this._timelineState.selection.next(newClips);
    this._timelineState.scroll(
      -newClips[0].startInScene + this._timelineState.displayDuration.value / 10
    );
    this._log.data('File added to timeline %o', file.mediaInfo);
    if (file.mediaInfo) {
      this._trackMediaInfo(file.mediaInfo);
    }
  }

  private _trackMediaInfo(mediaInfo: FisMediaInfo) {
    // track duration
    if (mediaInfo.duration) {
      this._angulartics.eventTrack('local - duration', {
        label: this._statsHelper.getDurationBucket(mediaInfo.duration),
        category: 'import',
        value: mediaInfo.duration,
        noninteraction: true,
      });
    }
    // track size
    const bounds = this._statsHelper.getValueBucket(mediaInfo.size);
    this._angulartics.eventTrack('local - size', {
      label: `between ${bounds[0]}bytes-${bounds[1]}bytes`,
      category: 'import',
      value: mediaInfo.size,
      noninteraction: false, // count as user interaction
    });
    // track codecs
    if (mediaInfo.video) {
      this._angulartics.eventTrack('local - video codec', {
        label: mediaInfo.video.codec,
        category: 'import',
        noninteraction: false, // count as user interaction
      });
    }
    if (mediaInfo.audio) {
      this._angulartics.eventTrack('local - audio codec', {
        label: mediaInfo.audio.codec,
        category: 'import',
        noninteraction: false, // count as user interaction
      });
    }
  }

  /**
   * Initializes an upload, preparing a GCS URL
   * to which a PUT request can be made
   */
  _doInitializeUpload(projectId: string): Observable<IInitUploadResult> {
    return this._uiApi.initUpload({ projectId }).pipe(first());
  }

  /**
   * Calculate an overal progress value from the progres of upload and import
   */
  _calculateOverallProgress(
    uploadProgess: number,
    importProgress: number
  ): number {
    return (
      uploadProgess * kUploadToImport + importProgress * (1 - kUploadToImport)
    );
  }
}
