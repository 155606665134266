/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable } from '@angular/core';
import {
  AnnotationsClip,
  ClipModel2,
  ClipTimeline,
  FileClipModel2,
  SceneEditor2,
  SceneModel2,
  StillClip,
  sty,
  VideoClip,
  VideoClipEffect,
} from '@castify/edit-models';
import { applyProximityRounding, Limits } from '@castify/models';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMapTo,
  tap,
} from 'rxjs/operators';
import { IToolSidebarData } from '../common/single-clip-editor-controller.service';
import { UndoManagerService } from '../common/undo-manager.service';
import { PreviewStateService } from '../preview/preview-state.service';
import { Trim, TrimSide } from './types';

export const kScrollIntervalDistance: sty.Milliseconds = 10; // distance (as time) scrolled when trimming crosses window bounds
const kScrollBorderFactor = 0.01; // Factor of displayDuration which is used as an offset at the start of the timeline
export const kTrimSnapDistance: sty.Pixels = 7; // Distance for trim snap to trigger

export class EffectSelection {
  clip: VideoClip;
  effect: VideoClipEffect;
  constructor(fields: Partial<EffectSelection> = {}) {
    Object.assign(this, fields);
  }
}
const kFullSceneWidth: sty.Pixels = 1000;
const kDefaultZoomFactor = 0.01;

@Injectable({
  providedIn: 'root',
})
export class TimelineStateService {
  // zoomFactor in units of Pixels/Millisecond -> how wide (in pixels) a millisecond of the scene is displayed
  zoomFactor = new BehaviorSubject<number>(kDefaultZoomFactor);
  zoomLimits = new BehaviorSubject<Limits>(new Limits(kDefaultZoomFactor, 1));
  startTime = new BehaviorSubject<sty.Milliseconds>(0); // controls where the scene starts relative to the timeline
  readonly displayDuration = new BehaviorSubject<sty.Milliseconds>(0); // amount of time that is displayed over the width of the timeline
  selection = new BehaviorSubject<(ClipModel2 | EffectSelection)[]>([]);
  scene = new BehaviorSubject<SceneModel2>(new SceneModel2());
  // use this to temporarily (e.g. during editing) display a different scene in the timeline
  overrideScene = new BehaviorSubject<SceneModel2 | null>(null);
  private scrollData: {
    playheadPosition: sty.Milliseconds;
    initialStartTime: sty.Milliseconds;
    maxOverflow: sty.Milliseconds;
  };
  // position of the playhead, diverges from preview.playhead during scrubbing.
  readonly playheadCursor = new BehaviorSubject<sty.Milliseconds>(0);
  readonly toolSidebarData = new BehaviorSubject<IToolSidebarData>(null);
  readonly scrubCursor = new BehaviorSubject<sty.Milliseconds | null>(null);
  readonly overrideCursor = new BehaviorSubject<sty.Milliseconds | null>(null);

  constructor(
    private undoManager: UndoManagerService,
    private previewState: PreviewStateService
  ) {
    const currentScene = combineLatest([
      this.undoManager.scene,
      this.overrideScene,
    ]);
    // scroll the timeline further when the beginning is visible
    currentScene
      .pipe(
        // only scroll when the scene is not currently under change
        filter(([_scene, override]) => !override),
        // 'private' params since override is null as per filter and scene is not needed for calculation
        map(([_scene, _override]) => {
          if (
            this.startTime.value + this.leftBorderOffset > 0 &&
            this.startTime.value <= 0
          ) {
            return this.startTime.value + this.leftBorderOffset;
          } else {
            return this.startTime.value;
          }
        })
      )
      .subscribe(this.startTime);

    // apply override scene
    currentScene
      .pipe(
        // select override when it is set, otherwise use scene from undo manager
        map(([scene, override]) => override || scene)
      )
      .subscribe(this.scene);

    // update playhead cursor position during playback
    combineLatest([this.previewState.playhead, this.previewState.isPlaying])
      .pipe(
        filter(([_, isPlaying]) => isPlaying),
        map(([pos, _]) => pos),
        distinctUntilChanged(),
        tap((pos) => {
          // scroll during playback when playhead is out of display duration
          if (
            Math.abs(this.startTime.value) + this.displayDuration.value <
            pos
          ) {
            // scroll to 10% of displayDuration
            this.scroll(
              this.startTime.value - this.displayDuration.value * 0.9
            );
          }
        })
      )
      .subscribe(this.playheadCursor);

    // make sure playhead is in scene: update playheadCursor when scene changes
    this.undoManager.scene
      .pipe(switchMapTo(this.previewState.playhead.pipe(first())))
      .subscribe(this.playheadCursor);

    // end scrubbing when playback starts
    this.previewState.shouldPlay
      .pipe(filter((v) => !!v))
      .subscribe(() => this.endScrub());

    // seek preview
    combineLatest([
      this.scrubCursor.pipe(distinctUntilChanged()),
      this.overrideCursor,
    ])
      .pipe(
        map(([scrubPos, overridePos]) => {
          // select cursor to use for seek
          if (overridePos !== null) {
            return overridePos;
          } else if (scrubPos !== null) {
            return scrubPos;
          } else {
            return this.playheadCursor.value;
          }
        })
      )
      .subscribe((seekPos) => this.previewState.seek(seekPos));

    // update zoom limits to allow fitting the whole scene in the timeline
    this.undoManager.scene
      .pipe(
        map(
          (scene) =>
            new Limits(
              Math.min(kFullSceneWidth / scene.duration, kDefaultZoomFactor),
              1
            )
        )
      )
      .subscribe(this.zoomLimits);
  }

  setZoom(zoomFactor: number) {
    this.zoomFactor.next(this.zoomLimits.value.apply(zoomFactor));
  }

  static pixelsToTime(
    pixels: sty.Pixels,
    zoomFactor: number
  ): sty.Milliseconds {
    return pixels / zoomFactor;
  }
  pixelsToTime(pixels: sty.Pixels): sty.Milliseconds {
    return TimelineStateService.pixelsToTime(pixels, this.zoomFactor.value);
  }
  static timeToPixels(time: sty.Milliseconds, zoomFactor: number): sty.Pixels {
    return time * zoomFactor;
  }
  timeToPixels(time: sty.Milliseconds): number {
    return TimelineStateService.timeToPixels(time, this.zoomFactor.value);
  }

  /**
   * space at the border of the timeline when scrolled to left side
   */
  get leftBorderOffset(): number {
    return this.displayDuration.value * kScrollBorderFactor;
  }

  /**
   * scrolls to a given startTime with limits enforced
   * @returns actual scroll position
   */
  scroll(startTime: sty.Milliseconds): sty.Milliseconds {
    const startTimeLimits = new Limits(
      this.displayDuration.value - this.scene.value.duration,
      this.leftBorderOffset
    );
    startTime = startTimeLimits.apply(startTime);
    this.startTime.next(startTime);
    return startTime;
  }

  /**
   * Make preparations for a trim. Copy the scene into this.overrideScene.
   */
  startTrim(originalClip: ClipModel2): void {
    this.previewState.pause();

    this.scrollData = {
      playheadPosition: this.playheadCursor.value,
      initialStartTime: this.startTime.value,
      maxOverflow: this.startTime.value,
    };
    this.overrideScene.next(this.undoManager.scene.value.copy());

    // change preview: display only clip that is being trimmed in it's entirety
    if (originalClip instanceof FileClipModel2) {
      const tmpClip = <FileClipModel2>originalClip.copy();
      // remove any trim from clip
      tmpClip.startInScene = 0;
      tmpClip.startInFile = 0;
      tmpClip.duration = tmpClip.srcDuration;
      // set preview override scene
      this.previewState.overrideScene.next(
        new SceneModel2({ clips: new ClipTimeline([tmpClip]) })
      );

      // set override playhead
      if (this.scrubCursor.value !== null) {
        this.overrideCursor.next(
          this.scrubCursor.value -
            originalClip.startInScene +
            originalClip.startInFile
        );
      } else {
        this.overrideCursor.next(
          this.playheadCursor.value -
            originalClip.startInScene +
            originalClip.startInFile
        );
      }
    }
  }

  /**
   * Trim a clip and all passively affected clips.
   *
   * @param originalClip - The clip to trim.
   * @param trim - The information needed for a trim operation. Defined in {@link @Types#Trim}.
   */
  updateTrimClip(originalClip: ClipModel2, trim: Trim): void {
    if (trim.value === 0 || originalClip === undefined) return;

    const scene = this.undoManager.scene.value;
    const resultScene = scene.copy();
    const resultClip = resultScene.clips.byId(originalClip.id);
    let trimAmount;
    // trim clip
    if (trim.side === TrimSide.LEFT) {
      trimAmount =
        resultClip.startInScene -
        applyProximityRounding(
          resultClip.startInScene - this.pixelsToTime(trim.value),
          [...scene.clips.changeKeys, this.scrollData.playheadPosition],
          this.pixelsToTime(kTrimSnapDistance),
          [resultClip.startInScene]
        );
      new SceneEditor2(resultScene).trimClipStart(resultClip, trimAmount);
      trimAmount = resultClip.endInScene - originalClip.endInScene;
    } else {
      trimAmount =
        applyProximityRounding(
          resultClip.endInScene + this.pixelsToTime(trim.value),
          [...scene.clips.changeKeys, this.scrollData.playheadPosition],
          this.pixelsToTime(kTrimSnapDistance),
          [resultClip.endInScene]
        ) - resultClip.endInScene;
      new SceneEditor2(resultScene).trimClipEnd(resultClip, trimAmount);
      // update trim amount to reflect actual trim amount with limits applied by scene editor
      trimAmount = resultClip.endInScene - originalClip.endInScene;
    }

    this.overrideScene.next(resultScene);

    // scrolling: make sure the trimmed end of the clip is displayed in the timeline
    const leftBorder = Math.max(0, this.scrollData.initialStartTime);
    if (trim.side === TrimSide.LEFT) {
      const startTime = Math.max(
        this.scrollData.initialStartTime - trimAmount,
        -resultClip.startInScene
      );
      const overflow =
        startTime - (this.scrollData.initialStartTime - trimAmount);
      this.scrollData.maxOverflow = Math.max(
        this.scrollData.maxOverflow,
        overflow
      );
      this.startTime.next(
        startTime + this.scrollData.maxOverflow - overflow - leftBorder
      );
    } else {
      const displayDuration = this.displayDuration.value;
      const initialEndTime = displayDuration - this.scrollData.initialStartTime;
      const overflow = resultClip.endInScene - initialEndTime;
      this.scrollData.maxOverflow = Math.max(
        this.scrollData.maxOverflow,
        0,
        overflow
      );
      this.startTime.next(
        this.scrollData.initialStartTime -
          this.scrollData.maxOverflow +
          leftBorder
      );
    }

    // update overrideCursor
    if (originalClip instanceof FileClipModel2) {
      if (trim.side === TrimSide.LEFT) {
        this.overrideCursor.next(originalClip.startInFile - trimAmount);
        // make playhead cursor appear fixed relative to clip start
        this.playheadCursor.next(this.scrollData.playheadPosition + trimAmount);
      } else {
        this.overrideCursor.next(originalClip.endInFile + trimAmount);
      }
    }
  }

  /**
   * End a trim operation. Clear private data and update the undoManager with the new scene.
   */
  trimEnd(originalClip: ClipModel2): void {
    if (this.startTime.value > 0) {
      this.scroll(this.scrollData.initialStartTime);
    }
    this.scrollData = {
      playheadPosition: 0,
      initialStartTime: 0,
      maxOverflow: 0,
    };
    if (!!this.overrideScene) {
      const newTimingHash = this.overrideScene.value.clips
        .byId(originalClip.id)
        .timingHash();
      if (originalClip.timingHash() !== newTimingHash) {
        // only push new undo state when clip was actually trimmed (timing hash changed)
        this.undoManager.update(this.overrideScene.value.copy(), 'trim');
      }
      this.overrideScene.next(null);
    }
    this.previewState.overrideScene.next(null);
    this.overrideCursor.next(null);
  }

  /**
   * Make preparations for a move. Copy the scene into this.overrideScene.
   */
  startMove(): void {
    this.overrideScene.next(this.undoManager.scene.value.copy());
  }

  /**
   * Moves a clips @see ClipModel2 representation and all clips passively affected by
   * the operation
   */
  updateMoveClip(clip: ClipModel2, offset: number): void {
    if (offset === 0 || !clip) {
      return;
    }

    const resultScene = this.undoManager.scene.value.copy();
    // const currScene = this.overrideScene.value;
    const originalScene = this.undoManager.scene.value;
    const originalClip = originalScene.clips.byId(clip.id);
    // cut clips at start and end of current clip
    if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
      new SceneEditor2(resultScene).cutTerminalAnnotationsClips(
        resultScene.clips.byId(clip.id)
      );
    }

    const newStart = this._getMoveClipStart(
      offset,
      originalScene,
      originalClip
    );
    // get clips passively affected by the operation
    const affectedClips = this._getMoveAffectedClips(
      clip,
      resultScene,
      originalScene,
      Math.sign(offset)
    );
    // put affected clips in selection
    this.selection.next(affectedClips);
    // apply move for every affected clip
    affectedClips.forEach((affectedClip) => {
      const originalCurrentClip = originalScene.clips.byId(affectedClip.id);
      const originalCurrStart = !!originalCurrentClip
        ? originalCurrentClip.startInScene
        : originalClip.startInScene;
      // Add difference of starts so that affected clips stay over the video clip
      const newStartForClip =
        newStart + originalCurrStart - originalClip.startInScene;
      // Move clip
      new SceneEditor2(resultScene).ignoreCollisionMoveClip(
        affectedClip,
        newStartForClip,
        affectedClip.id === clip.id
      );
    });

    // save the current scene
    this.overrideScene.next(resultScene);
  }

  /**
   * calculate the new start time during move with snapping applied
   */
  private _getMoveClipStart(
    offset: sty.Milliseconds,
    originalScene: SceneModel2,
    originalClip: ClipModel2
  ): sty.Milliseconds {
    let newStart = originalClip.startInScene + this.pixelsToTime(offset);
    if (originalClip instanceof AnnotationsClip) {
      newStart = applyProximityRounding(
        newStart,
        [
          // snap to start of moved clip
          ...originalScene.clips.changeKeys,
          // snap to end of moved clip
          ...originalScene.clips.changeKeys
            .map((v) => v - originalClip.duration)
            .filter((v) => v > 0),
          // snap to playhead
          this.playheadCursor.value,
          this.playheadCursor.value - originalClip.duration,
        ],
        this.pixelsToTime(kTrimSnapDistance),
        [
          originalClip.startInScene,
          originalClip.startInScene - originalClip.duration,
          originalClip.endInScene,
          originalClip.endInScene - originalClip.duration,
        ]
      );
    }
    return newStart;
  }

  /**
   * Makes the final move of the clip through SceneEditor2.moveClip(), thus respecting bounds etc.
   * @param clip The clip to be actively moved
   * @param offset The final offset to move
   */
  endMove(clip: ClipModel2, offset: number): void {
    if (clip === undefined || !this.overrideScene.value) {
      return;
    }

    const resultScene = this.undoManager.scene.value.copy();
    const originalScene = this.undoManager.scene.value;
    const originalClip = originalScene.clips.byId(clip.id);

    if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
      new SceneEditor2(resultScene).cutTerminalAnnotationsClips(
        resultScene.clips.byId(clip.id)
      );
    }

    // calculate the new Start of the clip based on the start before the move
    let newStart = this._getMoveClipStart(offset, originalScene, originalClip);
    // get clips passively affected by the operation
    const affectedClips = this._getMoveAffectedClips(
      clip,
      resultScene,
      originalScene,
      Math.sign(offset)
    );

    affectedClips.forEach((affectedClip) => {
      const originalCurrentClip = originalScene.clips.byId(affectedClip.id);
      const originalCurrStart = !!originalCurrentClip
        ? originalCurrentClip.startInScene
        : originalClip.startInScene;
      // Add difference of starts so that affected clips stay over the video clip
      const newStartForClip =
        newStart + originalCurrStart - originalClip.startInScene;
      // Either trigger a move operation with specified clips to be excluded or without excluded clips for text clips
      new SceneEditor2(resultScene).moveClip(affectedClip, newStartForClip);
      if (affectedClip instanceof FileClipModel2 || clip instanceof StillClip) {
        // add difference if clip was further moved newStart suggests
        // i.e. releasing on another clip will move the clip to the end of said clip
        newStart += affectedClip.startInScene - newStart;
      }
    });

    if (
      resultScene.clips.byId(clip.id).timingHash() !== originalClip.timingHash()
    ) {
      // only push undo state when clip has actually been moved
      this.undoManager.update(resultScene, 'move');
    }
    this.overrideScene.next(null);
    this.selection.next([resultScene.clips.byId(clip.id)]);
  }

  /**
   * Returns an array of the clips affected by an edit of @param clip
   * @remarks
   * @param clip The actively edited clip
   * @param scene Specifies the {@link SceneModel2} to search in for the clips
   * @param originalScene The {@link SceneModel2} the action is based on.
   * @param sign the sign of the offset. (left negative, right positive)
   * @returns An array of actively and possibly passively edited clips
   */
  private _getMoveAffectedClips(
    clip: ClipModel2,
    scene: SceneModel2,
    originalScene: SceneModel2,
    sign: number
  ): ClipModel2[] {
    const originalClip = originalScene.clips.byId(clip.id);

    let affectedClips: ClipModel2[] = [scene.clips.byId(clip.id)];
    if (clip instanceof FileClipModel2 || clip instanceof StillClip) {
      const annotationsOnly = scene.filterClipType(['annotations']).clips;
      const seekStart = originalClip.startInScene;
      // use previous change pos to not select clips starting at clip.endInScene
      const seekDuration =
        annotationsOnly.getPreviousChangePos(originalClip.endInScene) -
        seekStart;
      // sort text clips based on distance from start in scene and sign of offset. Those clips should be sorted descending in direction of
      // movement. i.e. right: ------->   left: <-------   otherwise they will interfere during placement.
      //                      [3][2][1]         [1][2][3]
      affectedClips = affectedClips.concat(
        [...annotationsOnly.getAllInRange(seekStart, seekDuration)].sort(
          (a, b) =>
            sign *
            (Math.abs(originalClip.startInScene - b.startInScene) -
              Math.abs(originalClip.startInScene - a.startInScene))
        )
      );
    }

    return affectedClips;
  }

  /**
   * set the scrub cursor to a new position
   * @returns actual scrub position
   */
  scrub(pos: sty.Milliseconds): sty.Milliseconds {
    const limitedPos = this.previewState.applySeekLimits(pos);
    this.scrubCursor.next(limitedPos);
    return limitedPos;
  }

  /**
   * end scrubbing to seek back to playhead pos
   */
  endScrub(): void {
    this.scrubCursor.next(null);
  }

  /**
   * set the playhead to a new position
   * @returns actual seek position
   */
  seek(pos: sty.Milliseconds): sty.Milliseconds {
    const limitedPos = this.previewState.applySeekLimits(pos);
    this.playheadCursor.next(limitedPos);
    // update scrub cursor as well, when scrubbing is active
    if (this.scrubCursor.value !== null) this.scrubCursor.next(limitedPos);
    return limitedPos;
  }

  /**
   * return if effect of selection is of type @param type
   */
  isEffectOfTypeSelected(type: string): boolean {
    const selection = this.selection.value;
    // check if only one clip/effect selected and it's an EffectSelection
    if (selection.length === 1 && selection[0] instanceof EffectSelection) {
      // check if effect has given type
      return (<EffectSelection>selection[0]).effect.type === type;
    }
    return false;
  }
}
