/*!
 * Copyright 2020 Screencastify LLC
 */

import { Component, HostListener, ViewChild } from '@angular/core';
import { sty } from '@castify/edit-models';
import { filter, map } from 'rxjs/operators';
import { SideToolbarContentComponent } from '../../../tool-sidebar/components/tool-sidebar-content/tool-sidebar-content.component';
import { InjectableToolbarComponent } from '../../../tool-sidebar/components/tool-sidebar/tool-sidebar.component';
import { AudioToolControllerService } from '../../audio-tool-controller.service';

const keepOpenSelectors = ['lib-audio-toolbar'];

@Component({
  selector: 'lib-audio-toolbar',
  templateUrl: './audio-toolbar.component.html',
  styleUrls: ['./audio-toolbar.component.scss'],
})
export class AudioToolbarComponent implements InjectableToolbarComponent {
  @ViewChild(SideToolbarContentComponent)
  _toolbarContainer: SideToolbarContentComponent;
  /**
   * max gain in dB (~6.3x)
   */
  MAX_GAIN: sty.Decibels = 16;

  /**
   * min gain in dB (~0.001x)
   */
  MIN_GAIN: sty.Decibels = -61;
  private closable = false;

  constructor(public ctrl: AudioToolControllerService) {
    // HACK: touchscreen registers mousedown events too early,
    // triggering closeIfClickedOutside on opening
    setTimeout(() => (this.closable = true), 0);
  }

  async closeToolbar(): Promise<void> {
    if (this._toolbarContainer) {
      await this._toolbarContainer.close();
    }
  }

  public closeEditor(): void {
    this.ctrl.save();
    this.ctrl.close();
  }

  @HostListener('window:mousedown', ['$event'])
  closeIfClickedOutside(event: MouseEvent) {
    const toolClicked = event.composedPath().some(
      (v: HTMLElement) =>
        v &&
        // check if tag name indicates it is part of the tool
        ((v.tagName && keepOpenSelectors.includes(v.tagName.toLowerCase())) ||
          // check if element id indicates it is part of the tool
          (v.id && keepOpenSelectors.includes(`#${v.id}`)))
    );
    if (!toolClicked && this.closable) {
      this.closeEditor();
    }
  }

  /**
   * Manually control slider's color. material's native slider color control
   * was removed with `-webkit-appearance: none;`
   * TODO: deduplicate into a shared component
   */
  sliderGradient = this.ctrl.gain.pipe(
    filter((gain) => !Number.isNaN(gain)),
    map((gain: sty.Decibels) => {
      const leftColor = '#ff8282';
      const rightColor = '#fff';
      const fullRange: sty.Decibels = this.MAX_GAIN - this.MIN_GAIN;
      const val = gain - this.MIN_GAIN;
      return {
        background: `linear-gradient(
          to right,
          ${leftColor} 0%,
          ${leftColor} ${(val / fullRange) * 100}%,
          ${rightColor} ${(val / fullRange) * 100}%,
          ${rightColor} 100%)`,
      };
    })
  );

  // type set to number before setting gain
  setGain(gain: string) {
    this.ctrl.setGain(Number(gain));
  }
}
