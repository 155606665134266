/*!
 * Copyright 2020 Screencastify LLC
 */
import { OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { AnnotationsToolControllerService } from '../../annotations-tool-controller.service';
import { kEditorFonts, kEditorFontSizeMultiplier, kMaxFontSizeInclusive, kMinFontSize, kQuillDefaultFontName, kQuillDefaultFontSize, NonToggleableAttribute as nonToggleable, } from '../../quill-config';
var AnnotationsSidebarFontComponent = /** @class */ (function () {
    function AnnotationsSidebarFontComponent(ctrl) {
        this.ctrl = ctrl;
        /**
         * Expose constants to the template
         */
        this.minFontSize = kMinFontSize;
        this.maxFontSizeInclusive = kMaxFontSizeInclusive;
        this.editorFonts = Object.entries(kEditorFonts);
        /**
         * Attribute observables
         */
        this.font = this.ctrl.nonToggleableObsFactory(nonToggleable.font, true);
        this.fontDisplayName = this.font.pipe(startWith(kQuillDefaultFontName), map(function (font) { return kEditorFonts[font]; }));
        this.size = this.ctrl
            .nonToggleableObsFactory(nonToggleable.size, false)
            .pipe(
        // if font is not set (e.g. if is default) show the default
        map(function (attr) {
            return attr ? +attr.slice(0, -2) : kQuillDefaultFontSize;
        }), 
        // Apply font size multiplier to values coming out of Quill
        map(function (fontSize) { return fontSize / kEditorFontSizeMultiplier; }));
        /**
         * Enables color in slider
         */
        this.sliderGradient = this.size.pipe(map(function (x) { return x / kMaxFontSizeInclusive; }), map(function (value) {
            var leftColor = '#ff8282';
            var rightColor = '#fff';
            return {
                background: "linear-gradient(\n            to right,\n            " + leftColor + " 0%,\n            " + leftColor + " " + value * 100 + "%,\n            " + rightColor + " " + value * 100 + "%,\n            " + rightColor + " 100%)",
            };
        }));
    }
    AnnotationsSidebarFontComponent.prototype.ngOnInit = function () { };
    /**
     * Non-toggleable attribute setters
     */
    AnnotationsSidebarFontComponent.prototype.setFont = function (font) {
        this.ctrl.triggerSettable(nonToggleable.font, font);
    };
    AnnotationsSidebarFontComponent.prototype.setSize = function (target) {
        var size = parseInt(target.value, 10);
        // constrains size of numbers entered via keyboard to
        // the input element
        var constrainedSize = size;
        if (size > kMaxFontSizeInclusive) {
            constrainedSize = kMaxFontSizeInclusive;
            target.value = constrainedSize.toString();
        }
        if (size < kMinFontSize) {
            constrainedSize = kMinFontSize;
            target.value = constrainedSize.toString();
        }
        this.ctrl.triggerSettable(nonToggleable.size, 
        // Apply font size multiplier to values going in to Quill
        kEditorFontSizeMultiplier * constrainedSize + "px");
        // refocus quill after each interaction
        this.ctrl.activeQuill.value.focus();
    };
    return AnnotationsSidebarFontComponent;
}());
export { AnnotationsSidebarFontComponent };
