/*!
 * Copyright 2018 Screencastify LLC
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { KeyboardShortcut } from './keyboard-shortcuts';

/**
 * Helper service to register shortcuts in a directive.
 * Automatically removes all shortcuts when destroyed.
 * Make sure to inject with @Self annotation.
 *
 * We currently wrap around angular2-hotkeys, but should probably dump that and use
 * Moustrap directly, since we are not using much its functionality anyway.
 *
 * Note that this is set up as a component-scoped/directive-scoped service
 */
@Injectable()
export class KeyboardService implements OnDestroy {
  private _hotKeysList: Hotkey[] = [];
  private _oldHotKeys: Hotkey[] = [];

  /**
   * Holds hotkeys that are paused, e.g. for disabling all
   * keyboard shortcuts while a modal obscures the screen.
   *
   * Note that this service must be provided to a modal
   * for it to be scoped properly to allow it to have
   * persistent state to make this work.
   */
  private pausedHotkeys: Hotkey[];

  constructor(private _hotkeysService: HotkeysService) {}

  add(
    shortcut: KeyboardShortcut,
    callback: (event: KeyboardEvent, combo: string) => boolean | void
  ) {
    const hotkeyObj = new Hotkey(
      shortcut.combo,
      (event, combo) => {
        return callback(event, combo) || false;
      },
      undefined,
      shortcut.desc
    );

    for (const combo of shortcut.combo) {
      const oldHotkey: Hotkey = <Hotkey>this._hotkeysService.get(combo);

      if (oldHotkey !== null) {
        this._oldHotKeys.push(oldHotkey);
        this._hotkeysService.remove(oldHotkey);
      }
    }
    this._hotKeysList.push(hotkeyObj);
    this._hotkeysService.add(hotkeyObj);
  }

  /**
   * Pauses any active hotkeys (e.g. for when a modal is open which should
   * function as a focus trap)
   */
  pauseAll(): void {
    // for why we copy the array here, see:
    // https://github.com/brtnshrdr/angular2-hotkeys/issues/101
    this.pausedHotkeys = [...this._hotkeysService.hotkeys];
    this._hotkeysService.pause(this.pausedHotkeys);
  }

  /**
   * Resumes any previously paused hotkeys.
   */
  resumeAll(): void {
    this._hotkeysService.unpause(this.pausedHotkeys);
    this.pausedHotkeys = [];
  }

  ngOnDestroy() {
    for (const hotkey of this._hotKeysList) {
      this._hotkeysService.remove(hotkey);
    }
    for (const oldKey of this._hotKeysList) this._hotkeysService.add(oldKey);
  }
}
